import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form'
import { Form } from 'semantic-ui-react';
import AnnotatedPasswordField from '../../../components/annotatedPasswordField/AnnotatedPasswordField';
import { minChars, hasOneUpperCase, numberOrSpecialChar, exactMatch } from '../../../utils/validators';


class ConfirmPasswordForm extends Component {

  state = { 
    form: {
      password: '',
      passwordConfirm: ''
    },
    validationState: {
      password: {
        validators: [
          { validationFunction: minChars, error:"8 Characters", props: { length: 8 }, isValid: false },
          { validationFunction: hasOneUpperCase, error:"1 uppercase character", props: {}, isValid: false },
          { validationFunction: numberOrSpecialChar, error:"1 number or special character", props: {}, isValid: false },
        ],
        isValidField: false
      },
      passwordConfirm: {
        validators: [
          { validationFunction: exactMatch, error:"Both passwords must match", props:{compare:'password'}, isValid: false }
        ], 
        isValidField: false
      }
    } 
  }

  validateFields = async (field, value) => {
    let validationSettings = { ...this.state.validationState[field] };
    validationSettings.validators.forEach(validator => {
      validator.isValid = validator.validationFunction(value, this.state.form, validator.props);
    })
    validationSettings.isValidField = validationSettings.validators.every(({ isValid }) => isValid);
    await this.setState({ 
      validationState: { ...this.state.validationState, [field]: validationSettings }, 
      form: {...this.state.form, [field]: value }
    });
  }

  validateForm = () => {
    const validationSettings = this.state.validationState;
    return Object.values(validationSettings).every(({ isValidField }) => isValidField);
  }

  onChange = field => async (e, value) => {
    const { onChange = () => {} } = this.props;
    await this.validateFields(field, value);
    onChange({ ...this.state.form, isValidForm: this.validateForm() });
  }

  render() {
    const { 
      state: {
        form: { 
          password,
          passwordConfirm
        },
        validationState
      }
    } = this;
    return (
      <>
        <Form.Field>
          <Field
            name="password"
            component={AnnotatedPasswordField}
            type="password"
            label="New Password"
            toolTip={true}
            value={password}
            validationState={validationState.password}
            onChange={this.onChange('password')}
          />
        </Form.Field>
        <Form.Field>
          <Field
            name="passwordConfirm"
            type="password"
            component={AnnotatedPasswordField}
            label="Confirm Password"
            toolTip={true}
            onChange={this.onChange('passwordConfirm')}
            value={passwordConfirm}
            validationState={validationState.passwordConfirm}
          />
        </Form.Field>
      </>
    );
  }
}

ConfirmPasswordForm.propTypes = {
  onChange: PropTypes.func
};

export default ConfirmPasswordForm;