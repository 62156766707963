export default {
  DASHBOARD: {
    path: "/dashboard",
    routes: "/dashboard/:productId?/:assetClassName?/:managerTitle?",
    title: "Dashboard",
    isPublic: false,
    children: {
      DASHBOARD_MAIN: {
        path: "/dashboard",
        title: "Dashboard",
      },
      MAKENA_PRODUCT: {
        path: "/dashboard/:productId",
      },
      MAKENA_ASSET: {
        path: "/dashboard/:productId/:assetClassName",
      },
    },
  },
  EXPLORE: {
    path: "/explore",
    routes: "/explore/:managerTitle?",
    title: "Explore",
    isPublic: false,
    children: {
      EXPLORE_MAIN: {
        path: "/explore",
        title: "Explore",
      },
      EXPLORE_MANAGER: {
        path: "/explore/:managerTitle",
      },
    },
  },
  TAX_ELECTION_FORMS: {
    path: "/tax-election-forms",
    routes: "/tax-election-forms/:uuid?",
    title: "Tax Election Forms",
    isPublic: false,
    children: {
      TAX_ELECTION_FORMS_LIST: {
        path: "/tax-election-forms",
        title: "Tax Election Forms list",
      },
      TAX_ELECTION_FORMS_LIST_DETAILS: {
        path: "/tax-election-forms/:uuid",
      },
    },
  },
  SETTINGS: {
    path: "/settings",
    title: "Account Settings",
  },
  INSIGHTS: {
    path: "/insights-and-news",
    title: "Insights and News",
  },
  MY_MANAGERS: {
    path: "/mymanagers",
    title: "My Managers",
  },
  LOGIN: {
    path: "/login",
    routes: "/login",
    title: "Login",
    isPublic: true,
  },
  UPDATE_BROWSER: {
    path: "/update-browser",
    routes: "/update-browser",
    title: "Update Browser",
    isPublic: true,
  },
  TERMS_AND_CONDITIONS: {
    path: "/terms-and-conditions",
    routes: "/terms-and-conditions",
    title: "Terms and Conditions",
  },
  PRIVACY_POLICY: {
    path: "/privacy",
    routes: "/privacy",
    title: "Privacy Policy",
  },
  INVESTOR_DOCUMENTS: {
    path: "/investor-documents",
    routes: "/investor-documents",
    title: "Investor Documents",
  },
  PASSWORD_RESET: {
    path: "/password-reset",
    routes: "/password-reset/:token?/:emailHash?",
    title: "Password Reset",
    isPublic: true,
    children: {
      PASSWORD_RESET_MAIN: {
        path: "/password-reset",
        title: "Password Reset Request",
      },
      WITH_TOKEN: {
        path: "/password-reset/:token/:emailHash",
        title: "Password Reset",
      },
    },
  },
  NO_MATCH: {
    path: "/nomatch",
    routes: "/nomatch",
    title: "404 Error",
  },
  ACCOUNT_INVITATION: {
    path: "/invite",
    routes: "/invite/:token?/:emailHash?",
    title: "Account Setup",
    isPublic: true,
  },
};
