import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  matchPath,
} from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import IdleTimer from "react-idle-timer";

import PrivateRoute from "./routes/PrivateRoute";
import Dashboard from "../containers/dashboard/Dashboard";
import LoginPageV2 from "../containers/auth/loginPageV2/LoginPageV2";
import LoginPage from "../containers/auth/loginPage/LoginPage";
import PasswordResetForm from "../containers/auth/passwordReset/PasswordResetForm";

import Header from "../components/header/Header";

import { ROUTES, SESSION_TIMEOUT_MESSAGE } from "./constants/index";
import { getPublicRoutes } from "./routes/routes.helper";
import {
  validateUserAuth,
  logout as logoutAction,
} from "../services/auth/authService";
import { makenaFundsFetch } from "../services/makenaFund/makenaFundService";
import { makenaAssetClassesFetch } from "../services/makenaAssetClass/makenaAssetClassService";
import { makenaManagersFetch } from "../services/makenaManagers/makenaManagerService";
import { getAppHierarchy } from "../services/appHierarchy/appHierarchyService";

import "../assets/theme/semantic.less";
import "../assets/styles/global.less";
import "../assets/theme/makena.less";

import "./locales";

import { MergeAll } from "../helpers/globalHelpers";
import DocumentsPageView from "../containers/documentsPageView/DocumentsPageView";
import NoMatch from "../containers/noMatch/NoMatch";
import cx from "classnames";
import UpdateBrowser from "../containers/updateBrowser/updateBrowser";
import SurveyModule from "../components/survey/SurveyModule";

const BrowserState = {
  SUPPORTED: 1,
  UNSUPPORTED: 2,
  BYPASSED: 3,
};

const MergeHierarchyFunds = ({ hierarchy, funds, assetClasses, managers }) => {
  const _funds = hierarchy.products
    .map((hierarchyFund) => {
      const makenaFund = funds.find(
        (_makenaFund) => _makenaFund.entityId === hierarchyFund.entity_id
      );
      if (!makenaFund) return;
      return {
        ...makenaFund,
        assetClasses: hierarchyFund.assetClasses,
      };
    })
    .filter((fund) => !!fund);

  return {
    managers,
    assetClasses,
    hierarchy,
    funds: _funds,
    allFunds: funds,
  };
};

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      contentOffset: 125,
      supportedBrowserState: BrowserState.SUPPORTED,
      data: { hierarchy: { products: [] } },
    };
  }

  async componentDidMount() {
    this.checkIfIE();
    const {
      finishLoading,
      props: {
        location,
        auth: { isLoggedIn } = { isLoggedIn: false },
        validateUserAuthAction,
      },
    } = this;
    const publicRoutes = getPublicRoutes();
    const curRoute = location.pathname;
    await validateUserAuthAction();
    if (publicRoutes.includes(curRoute)) window.scrollTo(0, 0);
    if (!isLoggedIn) return finishLoading({});
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      finishLoading,
      refresh,
      props: {
        selectedClientId,
        location,
        isLoggedIn,
        clientInfo: { entityId } = {},
      },
    } = this;
    const { clientInfo = {} } = prevProps;

    if (isLoggedIn === prevState.isLoggedIn) return;
    if (isLoggedIn) {
      if (
        Object.keys(this.state.data).length === 0 ||
        prevProps.selectedClientId !== selectedClientId
      )
        await refresh().then(finishLoading);
    } else {
      if (entityId !== clientInfo.entityId) await refresh().then(finishLoading);
    }
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
    if (
      prevState.supportedBrowserState !== 2 &&
      this.state.supportedBrowserState === 2
    ) {
      this.props.history.replace("/update-browser", {
        from: this.props.location.pathname,
      });
    }
  }

  onServerEventMessage = (ev) => {
    const { logout, history } = this.props;
    const {
      location: { state },
    } = history;

    const data = ev.data;
    const dataObj = JSON.parse(data);

    if (dataObj.action == "LOGOUT") {
      const locationState = { ...state, flashMessage: dataObj.message };
      logout(() => {
        history.push(ROUTES.LOGIN.path, locationState);
      });
    }
  };

  idleTimer = null;

  checkIfIE = () => {
    if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.includes("Trident/")
    ) {
      this.setState({ supportedBrowserState: BrowserState.UNSUPPORTED });
    }
  };

  redirectUpdateBrowser = () => {
    return <Redirect exact from="/login" to={ROUTES.UPDATE_BROWSER.path} />;
  };

  bypassUnsupportedBrowser = (e) => {
    e.preventDefault();
    const from = this.props.location.state.from;
    this.setState({ supportedBrowserState: BrowserState.BYPASSED }, () => {
      this.props.history.replace(from, {});
      setTimeout(() => {
        this.setState({ supportedBrowserState: BrowserState.UNSUPPORTED });
      }, 1000 * 60 * 60 * 24);
    });
  };

  onAppIdle = () => {
    const { logout, history, isLoggedIn } = this.props;
    const {
      location: { state },
    } = history;
    if (isLoggedIn) {
      const locationState = { ...state, flashMessage: SESSION_TIMEOUT_MESSAGE };
      logout(() => {
        history.push(ROUTES.LOGIN.path, locationState);
      });
    }
  };

  onAppActive = () => {};

  onAppAction = () => {};

  refresh = () => {
    const { clientInfo: { entityId = "" } = {} } = this.props;
    const defaultHierarchy = { hierarchy: { products: [] } };
    const promises = [
      entityId
        ? getAppHierarchy()
          .then((hierarchy) => ({ hierarchy }))
          .catch(() => defaultHierarchy)
        : new Promise((resolve) => resolve(defaultHierarchy)),
      makenaFundsFetch()
        .then((funds) => ({ funds }))
        .catch(() => []),
      makenaAssetClassesFetch()
        .then((assetClasses) => ({ assetClasses }))
        .catch(() => []),
      makenaManagersFetch()
        .then((managers) => ({ managers }))
        .catch(() => []),
    ];
    return Promise.all(promises).then(MergeAll).then(MergeHierarchyFunds);
  };

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (data) => {
    this.setState({
      data,
      isLoading: false,
    });
  };

  // apply primaryLayout for all pages, except dashboard children
  isPrimaryLayout = () => {
    const { location } = this.props;

    const match = matchPath(location.pathname, ROUTES.DASHBOARD.routes);
    if (!match) return true;

    const { params } = match;
    const isDashboardChild =
      params && Object.values(params).filter((v) => !!v).length;

    return !isDashboardChild;
  };

  showHeader = ({ pathname }) => {
    const inviteUrls = new RegExp("/invite");
    return (
      pathname !== "/login" &&
      pathname !== "/register" &&
      pathname !== "/update-browser" &&
      !inviteUrls.test(pathname)
    );
  };

  areMissingStates = () => {
    const {
      props: { clientInfo = {}, isLoggedIn = false, auth: { level } = {} },
      state: {
        data: { hierarchy = {} },
      },
    } = this;
    const isLoginPage = !this.showHeader(location);
    if (isLoginPage || !isLoggedIn) return false;
    if (!Object.keys(hierarchy).length) return true;
    if (!Object.keys(clientInfo).length && level !== "document") return true;
    return false;
  };

  isFullScreenLayoutPage = () => {
    const {
      location: { pathname },
    } = this.props;
    const investorDocs = new RegExp("/investor-documents");
    return investorDocs.test(pathname);
  };

  getHeaderDisplayConfig = () => {
    const defaultConfig = {
      leftNav: true,
      fundsMenu: true,
      taxElectionForms: true,
      managerMenu: true,
      investorDocsMenu: true,
      userMenu: true,
      asOfDate: false,
    };
    const {
      location: { pathname },
    } = this.props;
    const investorDocs = new RegExp("/investor-documents");
    const explore = new RegExp("/explore");
    const dashboard = new RegExp("/dashboard");
    const taxElectionForms = new RegExp("/tax-election-forms");

    if (investorDocs.test(pathname)) {
      return { ...defaultConfig, leftNav: false };
    }

    if (explore.test(pathname) || dashboard.test(pathname)) {
      return { ...defaultConfig, asOfDate: true };
    }

    if (taxElectionForms.test(pathname)) {
      return { ...defaultConfig, asOfDate: true };
    }

    return defaultConfig;
  };

  getHeaderStyles = (config) => {
    let options = {
      impersonatedHeader: config.isImpersonated,
      fullScreenHeader: config.isFullScreenLayout,
    };

    return cx(options);
  };

  renderHeader = () => {
    const {
      getHeaderStyles,
      getHeaderDisplayConfig,
      isFullScreenLayoutPage,
      props: { clientInfo, location, auth },
      state: {
        data: { funds },
      },
      showHeader,
    } = this;
    const isLoginPage = !showHeader(location);
    if (isLoginPage) return;
    const { authContext: { id } = {} } = auth;
    const headerConfigStyles = {
      isImpersonated: Boolean(id),
      isFullScreenLayout: isFullScreenLayoutPage(),
    };
    const headerStyles = getHeaderStyles(headerConfigStyles);
    return (
      <Header
        clientInfo={clientInfo}
        id="header"
        layoutStyles={headerStyles}
        location={location}
        funds={funds}
        auth={auth}
        config={getHeaderDisplayConfig()}
      />
    );
  };

  renderSurveyModule = (isAuthenticated) => {
    if (!isAuthenticated) return null;
    return <SurveyModule loggedIn={!!isAuthenticated} />;
  };

  renderRedirectDefault = (haveFullAccessLevel) => {
    if (!haveFullAccessLevel) {
      return (
        <Route
          exact
          path="/"
          render={() => <Redirect to={ROUTES.INVESTOR_DOCUMENTS.path} />}
        />
      );
    }
    return (
      <Route
        exact
        path="/"
        render={() => <Redirect to={ROUTES.DASHBOARD.path} />}
      />
    );
  };

  renderDashboard = (haveFullAccessLevel, params) => {
    if (!haveFullAccessLevel) return null;
    return (
      <PrivateRoute
        path={ROUTES.DASHBOARD.routes}
        component={Dashboard}
        openModal={this.handleModalOpen}
        {...this.props}
        {...params}
      />
    );
  };

  renderExplore = (haveFullAccessLevel, params) => {
    if (!haveFullAccessLevel) return null;
    return (
      <PrivateRoute
        exact
        path={ROUTES.EXPLORE.routes}
        component={Dashboard}
        openModal={this.handleModalOpen}
        {...this.props}
        {...params}
      />
    );
  };

  renderTaxElectionForm = (params) => {
    return (
      <PrivateRoute
        path={ROUTES.TAX_ELECTION_FORMS.routes}
        component={Dashboard}
        openModal={this.handleModalOpen}
        {...this.props}
        {...params}
      />
    );
  };

  renderInsights = (haveFullAccessLevel, params) => {
    if (!haveFullAccessLevel) return null;
    return (
      <PrivateRoute
        path={ROUTES.INSIGHTS.path}
        component={Dashboard}
        {...this.props}
        {...params}
      />
    );
  };

  render() {
    const {
      areMissingStates,
      renderRedirectDefault,
      renderDashboard,
      renderInsights,
      renderExplore,
      renderHeader,
      renderSurveyModule,
      renderTaxElectionForm,
      state: {
        isLoading,
        data: { hierarchy, allFunds, funds, assetClasses, managers },
      },
      props: { auth },
    } = this;
    const { authContext = {}, level = "documents" } = auth;
    if (isLoading || areMissingStates()) return null;
    const isImpersonated = authContext.id;
    const haveFullAccessLevel = level === "full";
    const isPrimaryLayout = this.isPrimaryLayout();
    const isFullScreenLayout = this.isFullScreenLayoutPage();
    const commonProps = {
      assetClasses,
      managers,
      hierarchy,
      funds,
      allFunds,
      isPrimaryLayout,
    };
    return (
      <div className="container">
        {renderHeader()}
        {renderSurveyModule()}
        <main
          className={cx(`content`, {
            impersonatedContent: isImpersonated,
            fullScreenPage: isFullScreenLayout,
          })}
        >
          <Switch>
            {renderRedirectDefault(haveFullAccessLevel)}
            {renderDashboard(haveFullAccessLevel, commonProps)}
            {renderExplore(haveFullAccessLevel, commonProps)}
            {renderTaxElectionForm(commonProps)}
            {renderInsights(haveFullAccessLevel, commonProps)}
            <PrivateRoute
              path={ROUTES.TERMS_AND_CONDITIONS.path}
              component={Dashboard}
              {...this.props}
              {...commonProps}
            />
            <PrivateRoute
              path={ROUTES.PRIVACY_POLICY.path}
              component={Dashboard}
              {...this.props}
              {...commonProps}
            />
            <PrivateRoute
              path={ROUTES.INVESTOR_DOCUMENTS.path}
              component={DocumentsPageView}
              {...this.props}
              {...commonProps}
            />
            <PrivateRoute
              path={ROUTES.SETTINGS.path}
              component={Dashboard}
              openModal={this.handleModalOpen}
              exact
              {...this.props}
              {...commonProps}
            />
            <PrivateRoute
              path={ROUTES.NO_MATCH.routes}
              component={NoMatch}
              openModal={this.handleModalOpen}
              {...this.props}
              {...commonProps}
            />
            <Route
              path={ROUTES.LOGIN.routes}
              component={LoginPageV2}
              openModal={this.handleModalOpen}
              {...this.props}
              isPrimaryLayout
            />
            <Route
              path={ROUTES.ACCOUNT_INVITATION.routes}
              component={LoginPage}
              openModal={this.handleModalOpen}
              {...this.props}
              isPrimaryLayout
            />
            <Route
              path={ROUTES.PASSWORD_RESET.routes}
              component={PasswordResetForm}
              openModal={this.handleModalOpen}
              {...this.props}
              isPrimaryLayout
            />
            <Route
              path={ROUTES.UPDATE_BROWSER.routes}
              render={(props) => (
                <UpdateBrowser
                  {...props}
                  bypassUnsupportedBrowser={this.bypassUnsupportedBrowser}
                />
              )}
              {...this.props}
              isPrimaryLayout
            />
            <Route
              render={() =>
                this.props.isLoggedIn ? (
                  <NoMatch {...this.props} />
                ) : (
                  <Redirect to="/login" />
                )
              }
              isPrimaryLayout
            />
            <Redirect from="/" to="/nomatch" />
          </Switch>
          {renderSurveyModule(auth.id)}
        </main>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onAction={this.onAppAction}
          onIdle={this.onAppIdle}
          onActive={this.onAppActive}
          debounce={250}
          timeout={1000 * 60 * 15}
        />
      </div>
    );
  }
}

App.propTypes = {
  makenaClient: PropTypes.shape({
    title: PropTypes.string,
    meta_json: PropTypes.string,
  }),
  location: PropTypes.shape({
    state: PropTypes.object,
    pathname: PropTypes.string,
  }),
  history: PropTypes.object,
  auth: PropTypes.object,
  validateUserAuthAction: PropTypes.func,
  logout: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  clientInfo: PropTypes.object,
  selectedClientId: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isAdmin: state.auth.isAdmin,
  errors: state.errors,
  clientInfo: state.makenaClient,
  auth: state.auth,
  selectedClientId: state.settings && state.settings.selectedClient,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    validateUserAuthAction: validateUserAuth,
    logout: logoutAction,
  })
)(App);
