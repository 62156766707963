import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { withRouter }       from 'react-router-dom';
import { compose }          from 'redux';
import { connect }          from 'react-redux';
import { ROUTES }           from '../../app/constants/index';
import styles               from './headerNavIconMenu.local.less';
import { auditLog }         from '../../services/logging/loggingService';
import { LOG_EVENTS }       from '../../app/constants/clientLogs';


class HeaderNavIconMenu extends Component {
  goToSettings = () => {
    const { history: { push }, onOptionClicked = () => {} } = this.props;
    push(ROUTES.SETTINGS.path);
    onOptionClicked ();
  }

  handleClick = (event, type) => {
    switch (type) {
      case 'admin':
        auditLog(LOG_EVENTS.MENU_OPTIONS.ADMIN);
        window.location = '/admin';
        break;
      case 'settings':
        auditLog(LOG_EVENTS.MENU_OPTIONS.SETTINGS);
        this.goToSettings();
        break;
      case 'sign out':
        auditLog(LOG_EVENTS.MENU_OPTIONS.SIGN_OUT);
        this.props.handleLogout(event);
        break;
      default:
        break;
    }
  }

  render() {
    const { isLoggedIn, isAdmin, open, id } = this.props;
    if (!isLoggedIn || !open) return null;
    return (
      <nav className={styles.headerNavMenu} id={id}>
        <ul>
          {
            isAdmin ? 
              <li className={styles.headerNavMenuItem}>
                <button className={styles.menuItem} onClick={(e) => this.handleClick(e, 'admin')}>
                  Admin
                </button>
              </li> 
              : null
          }
          <li className={styles.headerNavMenuItem}>
            <button className={styles.menuItem} onClick={(e) => this.handleClick(e, 'settings')}>Settings</button>
          </li>
          <li className={styles.headerNavMenuItem}>
            <button className={styles.menuItem} onClick={(e) => this.handleClick(e, 'sign out')}>
              Sign out
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

HeaderNavIconMenu.propTypes = {
  handleLogout: PropTypes.func,
  onOptionClicked: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isAdmin: PropTypes.bool,
  open: PropTypes.bool,
  id: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func }),
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isAdmin: state.auth.isAdmin,
  };
}

export default compose(
  connect(mapStateToProps, {}),
  withRouter,
)(HeaderNavIconMenu);
