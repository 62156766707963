import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './checkboxAccordionItem.local.less';
import Svg from '../svg/Svg';
import checkmark from '../../assets/svg/checkmark-blue.svg';

class CheckboxAccordionItem extends Component {

  onClick = () => {
    const { onChange = () => {}, selected } = this.props;
    onChange(!selected);
  }

  render() {
    const { props: { label, mark, selected } } = this;
    return (
      <div className={styles.checkboxAccordionItem} onClick={this.onClick}>
        <div className={`${styles.box} ${selected? styles.active : ''}`}>
          { selected? <Svg svg={checkmark}/> : null }
        </div>
        <div className={styles.label}>
          { mark? <div className={styles.mark}></div> : null}
          {label}
        </div>
      </div>
    )
  }
}

CheckboxAccordionItem.propTypes = {
  label: PropTypes.string,
  mark: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func
};

export default CheckboxAccordionItem;