import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import get                          from 'lodash/get';
import { compose }                  from 'redux';
import { connect }                  from 'react-redux';
import { withRouter }               from 'react-router-dom';
import MakenaAsset                  from './MakenaAsset';
import MakenaAssetPublic            from './MakenaAssetPublic';

import { 
  growthOfADollarAssetClassTransform 
} from '../../helpers/fundsHelpers/charts';
import {
  clientPortalAssetClassSectorFetch,
  clientPortalAssetClassGeographyFetch,
  clientPortalAssetClassStrategyFetch,
  clientPortalAssetClassREPropertyTypeFetch,
  clientPortalAssetClassNRIndustryFetch,
  clientPortalAssetClassPESectorFetch,
  clientPortalGrowthOfADollarByAssetClassFetch,
  clientPortalProductAssetAllocationFetch,
  clientPortalProductOverviewFetch,
  clientPortalProductOverviewPublicFetch,
} from '../../services/reporting/reportingService';

class MakenaAssetContainer extends Component {
  state = {
    isLoading: true,
    data: {
      assetClass: {},
      assetClasses: [],
      exposures: {},
      fund: {},
      growthOfADollar: {},
      overview: {},
      publicOverview: {}
    },
    errors: {
      assetFetchError: null
    },
  };

  async componentDidMount() {
    const { initReset } = this;

    await initReset();
  }

  async componentDidUpdate(prevProps) {    
    const {
      initReset,
      props: { fund: { entityId }, assetClass: { title } }
    } = this;
    if (entityId === prevProps.fund.entityId && title === prevProps.assetClass.title) return;

    await initReset();
  }

  initReset = async () => {
    const {
      finishLoading, 
      noMatch,
      startLoading,
      updateState
    } = this;

    try { await startLoading(); await updateState(); }
    catch(e) { noMatch(); }
    finally { await finishLoading(); }
  }

  updateState = () => {
    const {
      mergeFundData,
      props: {
        fund,
        assetClass
      }
    } = this;
    const assetName = assetClass.title;
    const portfolioId = fund.sidepocket? fund.portfolioId : null;
    const { type, entityId } = fund;
    
    if (type === 'PERPETUAL') return noMatch();

    let promises = [
      clientPortalAssetClassGeographyFetch(entityId, portfolioId).then(clientPortalAssetClassGeography => ({ clientPortalAssetClassGeography })),
      clientPortalAssetClassSectorFetch(entityId, portfolioId).then(clientPortalAssetClassSector => ({ clientPortalAssetClassSector })),
      clientPortalAssetClassStrategyFetch(entityId, portfolioId).then(clientPortalAssetClassStrategy => ({ clientPortalAssetClassStrategy })),
      clientPortalProductAssetAllocationFetch(entityId, portfolioId).then(clientPortalProductAssetAllocation => ({ clientPortalProductAssetAllocation })),
      clientPortalAssetClassREPropertyTypeFetch(entityId, portfolioId, assetName).then(clientPortalAssetClassREPropertyType => ({ clientPortalAssetClassREPropertyType })),
      clientPortalAssetClassNRIndustryFetch(entityId, portfolioId, assetName).then(clientPortalAssetClassNRIndustry => ({ clientPortalAssetClassNRIndustry })),
      clientPortalAssetClassPESectorFetch(entityId, portfolioId, assetName).then(clientPortalAssetClassPESector => ({ clientPortalAssetClassPESector })),
      clientPortalGrowthOfADollarByAssetClassFetch(entityId, portfolioId, assetName).then(growthOfADollar => ({ growthOfADollar })),
      clientPortalProductOverviewFetch(entityId, portfolioId).then(overview => ({ overview })),
      clientPortalProductOverviewPublicFetch(entityId, portfolioId).then(publicOverview => ({ publicOverview }))  
    ];
      
    return Promise.all(promises)
      .then(mergeFundData)
      .then(data => this.setState(data));
  }

  transformExposuresReports = ({ title, exposuresReports }, object) => {
    return Object.keys(exposuresReports).map(
      key => {
        const value = exposuresReports[key],
          _data = get(object[value.report], 'children.data', {}),
          _title = key,
          id    = value.index,
          data  = _data[title]? _data[title] : _data;
        return { data, title: _title, id: id.toString() };
      }
    ).sort((a, b)=> a.id - b.id);
  }

  mergeFundData = arrayData => {
    const {
      props: { assetClass },
      transformExposuresReports
    } = this;
    let object = {};
    
    arrayData.forEach(data => object = { ...object, ...data });

    const { 
      growthOfADollar = {}, 
      overview = {}, 
      publicOverview = {}, 
    } = object;

    return {
      data: {
        exposures: transformExposuresReports(assetClass, object),
        growthOfADollar,
        overview,
        publicOverview
      }
    };
  }

  noMatch = () => this.props.history.push('/nomatch');

  startLoading = async () => {
    return this.setState({
      isLoading: true,
    });
  };

  finishLoading = () => {
    return this.setState({
      isLoading: false
    });
  };


  render() {
    const {
      state: {
        isLoading,
        data: {
          exposures,
          growthOfADollar,
          overview,
          publicOverview
        }
      },
      props: { assetClass, asOfDate, openModal, fund }
    } = this;
    if (isLoading) return null;
    
    const { isPrivate } = assetClass;

    return isPrivate? (
      <MakenaAsset
        assetClass={assetClass}
        exposures={exposures}
        fund={fund}
        growthOfADollar={growthOfADollarAssetClassTransform(fund, assetClass, asOfDate, growthOfADollar)}
        overview={overview}
        openModal={openModal}
      />
    ) : (
      <MakenaAssetPublic
        assetClass={assetClass}
        exposures={exposures}
        fund={fund}
        growthOfADollar={growthOfADollarAssetClassTransform(fund, assetClass, asOfDate, growthOfADollar)}
        overview={publicOverview}
        openModal={openModal}
      />
    );
  }
}

MakenaAssetContainer.propTypes = {
  asOfDate    : PropTypes.string,
  history     : PropTypes.shape({ push: PropTypes.func }),
  fund        : PropTypes.object,
  assetClass  : PropTypes.object,
  openModal   : PropTypes.func,
};

const mapStateToProps = state => ({ asOfDate: state.auth.settings.reportingQuarterEnd });

const mapDispatchToProps = { };

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MakenaAssetContainer);
