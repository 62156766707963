import fileDownload           from 'js-file-download';
import getApiRootUrl          from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper         from '../../helpers/apiHelpers/requestWrapper';
import downloadWrapper        from '../../helpers/apiHelpers/downloadWrapper';
import { API_URLS, ERRORS }   from '../../app/constants/index';
import { getErrorMessage }    from '../errors/errorHelper';
import { getSettings }        from '../auth/authHelper';
import { fabricReportingUrl } from './reportingHelper';
import {
  clientPortalManagerSectorFetchSuccess,
  clientPortalManagerREPropertyTypeFetchSuccess,
  clientPortalManagerNRIndustryFetchSuccess,
  clientPortalManagerGeographyFetchSuccess,
  clientPortalManagerVintageFetchSuccess,
  clientPortalManagerRiskFactorFetchSuccess,
  clientPortalManagerMakenaFundsFetchSuccess,
  clientPortalManagerGrowthOfADollarStaticFetchSuccess,
  clientPortalClearProductDataAction
}                             from './reportingActions';

const getReportingUrl = fabricReportingUrl(API_URLS);

// eslint-disable-next-line import/prefer-default-export
export const clientPortalOverviewFetch = async entityId => {
  const reportType = 'clientPortalOverview';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerFetch = async entityId => {
  const reportType = 'clientPortalManager';
  const transformer = 'raw';
  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalGrowthOfADollarByProductFetch = async (entityId, portfolioId) => {
  const reportType = `clientPortalGrowthOfADollar${portfolioId ? 'SP' : '' }ByProduct`;
  const transformer = 'recharts';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalGrowthOfADollarByClientFetch = async entityId  => {
  const reportType = 'clientPortalGrowthOfADollarByClient';
  const transformer = 'recharts';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalFundAllocationFetch = async entityId => {
  const reportType = 'clientPortalFundAllocation';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalAssetAllocationFetch = async entityId => {
  const reportType = 'clientPortalAssetAllocation';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalRiskFactorFetch = async entityId => {
  const reportType = 'clientPortalRiskFactor';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalGeographyFetch = async entityId => {
  const reportType = 'clientPortalGeography';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductOverviewFetch = async (entityId, portfolioId) => {
  const reportType = `clientPortalProduct${portfolioId ? 'SP' : ''}Overview`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductOverviewClosedEndFetch = async entityId => {
  const reportType = `clientPortalProductOverviewClosedEnd`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductJCurveFetch = async entityId => {
  const reportType = `clientPortalProductJCurve`;
  const transformer = 'recharts';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductOverviewPublicFetch = async (entityId, portfolioId) => {
  const reportType = `clientPortalProduct${portfolioId ? 'SP': ''}OverviewPublic`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductAssetAllocationFetch = async (entityId, portfolioId) => {
  const reportType = `clientPortalProduct${portfolioId ? 'SP': ''}AssetAllocation`;
  const transformer = 'raw';
  
  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductClosedAssetAllocationFetch = async entityId => {
  const reportType = `clientPortalProductClosedAssetAllocation`;
  const transformer = 'raw';
  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductRiskFactorFetch = async (entityId, portfolioId) => {
  const reportType = `clientPortalProduct${portfolioId ? 'SP': ''}RiskFactor`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductClosedRiskFactorFetch = async entityId => {
  const reportType = `clientPortalProductClosedRiskFactor`;
  const transformer = 'raw';
  
  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductGeographyFetch = async (entityId, portfolioId) => {
  const reportType = `clientPortalProduct${portfolioId ? 'SP': ''}Geography`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductCurrencyFetch = async (entityId, portfolioId) => {
  const reportType = `clientPortalProduct${portfolioId ? 'SP': ''}Currency`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalProductClosedGeographyFetch = async entityId => {
  const reportType = `clientPortalProductClosedGeography`;
  const transformer = 'raw';
  
  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalAssetClassSectorFetch = async (entityId, portfolioId, assetClassTitle) => {
  const reportType = `clientPortalAssetClass${portfolioId ? 'SP' : ''}Sector`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, assetClassTitle, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalAssetClassNRIndustryFetch = async (entityId, portfolioId, assetClassTitle) => {
  const reportType = `clientPortalAssetClass${portfolioId ? 'SP' : ''}NRIndustry`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, assetClassTitle, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalAssetClassPESectorFetch = async (entityId, portfolioId, assetClassTitle) => {
  const reportType = `clientPortalAssetClass${portfolioId ? 'SP' : ''}PESector`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, assetClassTitle, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};


// eslint-disable-next-line import/prefer-default-export
export const clientPortalAssetClassREPropertyTypeFetch = async (entityId, portfolioId, assetClassTitle) => {
  const reportType = `clientPortalAssetClass${portfolioId ? 'SP' : ''}REPropertyType`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, assetClassTitle, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalAssetClassGeographyFetch = async (entityId, portfolioId, assetClassTitle) => {
  const reportType = `clientPortalAssetClass${portfolioId ? 'SP' : ''}Geography`;
  const transformer = 'raw';
  
  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, assetClassTitle, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalAssetClassStrategyFetch = async (entityId, portfolioId, assetClassTitle) => {
  const reportType = `clientPortalAssetClass${portfolioId ? 'SP' : '' }Strategy`;
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, assetClassTitle, transformer, reportingQuarterStart, reportingQuarterEnd });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalGrowthOfADollarByAssetClassFetch = async (entityId, portfolioId, assetClassTitle) => {
  const reportType = `clientPortalGrowthOfADollar${portfolioId ? 'SP' : '' }ByAssetClass`;
  const transformer = 'recharts';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const reportingUrl = getReportingUrl({ portfolioId, reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd, assetClassTitle });
    const url = `${getApiRootUrl()}${reportingUrl}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerGeographyFetch = (entityId, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerGeography';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerGeography = response.data.data;

    dispatch(clientPortalManagerGeographyFetchSuccess(clientPortalManagerGeography));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerSectorFetch = (entityId, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerSector';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerSector = response.data.data;

    dispatch(clientPortalManagerSectorFetchSuccess(clientPortalManagerSector));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerNRIndustryFetch = (entityId, managerTitle, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerNRIndustry';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, managerTitle, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerNRIndustry = response.data.data;

    dispatch(clientPortalManagerNRIndustryFetchSuccess(clientPortalManagerNRIndustry));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};


// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerREPropertyTypeFetch = (entityId, managerTitle, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerREPropertyType';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, managerTitle, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerREPropertyType = response.data.data;

    dispatch(clientPortalManagerREPropertyTypeFetchSuccess(clientPortalManagerREPropertyType));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerGrowthOfADollarStaticFetch = (entityId, managerTitle, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerGrowthOfADollarStatic';
  const transformer = 'recharts';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, managerTitle, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerGrowthOfADollarStatic = response.data.data;

    dispatch(clientPortalManagerGrowthOfADollarStaticFetchSuccess(clientPortalManagerGrowthOfADollarStatic));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerRiskFactorFetch = (entityId, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerRiskFactor';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerRiskFactor = response.data.data;

    dispatch(clientPortalManagerRiskFactorFetchSuccess(clientPortalManagerRiskFactor));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerFundFetch = async entityId => {
  const reportType = 'clientPortalManagerFund';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerMakenaFundsFetch = (entityId, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerMakenaFunds';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerMakenaFunds = response.data.data;

    dispatch(clientPortalManagerMakenaFundsFetchSuccess(clientPortalManagerMakenaFunds));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerVintageFetch = (entityId, onSuccess) => async (dispatch) => {
  const reportType = 'clientPortalManagerVintage';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    const clientPortalManagerVintage = response.data.data;

    dispatch(clientPortalManagerVintageFetchSuccess(clientPortalManagerVintage));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalRecentInvestmentsFetch = async entityId => {
  const reportType = 'clientPortalRecentInvestments';
  const transformer = 'raw';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH({ reportType, entityId, transformer, reportingQuarterStart, reportingQuarterEnd })}`;
    const response = await requestWrapper.get(url);
    return response.data.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchBenchmarks = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_FETCH_BENCHMARKS}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const getReport = async (report, settings) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.REPORTING_DEFAULT_FETCH({...report, ...settings})}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_DEFAULT_FETCH_ERROR(report.reportType));
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const clientPortalClearProductData = () => async (dispatch) => {
  dispatch(clientPortalClearProductDataAction());
};

// eslint-disable-next-line import/prefer-default-export
export const exportFetch = async (type, name = type) => {
  const reportType = 'export';

  try {
    const { reportingQuarterStart, reportingQuarterEnd } = getSettings();
    const url = `${getApiRootUrl()}${API_URLS.EXPORT_FETCH({ reportType, reportingQuarterStart, reportingQuarterEnd, type })}`;
    const res = await downloadWrapper.get(url);
    fileDownload(res.data, `${name}_${reportType}_${reportingQuarterEnd}.xlsx`);
    return;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};
