import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './toggleButton.local.less';


class ToggleButton extends Component {
  
  componentDidUpdate = prevProps => {
    if (!prevProps.reset && !this.props.reset) return;
    if (prevProps.reset && this.props.toggled) return;
    if (prevProps.reset === this.props.reset) return;
  }

  onClick = () => {
    const { onChange = () => {}, toggled } = this.props;
    onChange(!toggled)
  }

  render() {
    const { 
      props: { label, toggled, borderStyle, disabled },
    } = this;
    const className = `${styles.toggleButton} ${borderStyle} ${toggled? styles.active : ''} ${disabled? styles.disabled : ''}`;
    return (<div className={className} onClick={!disabled? this.onClick : () => {}}>{label}</div>)
  }
}

ToggleButton.propTypes = {
  label: PropTypes.string,
  borderStyle: PropTypes.string,
  reset: PropTypes.any,
  onChange: PropTypes.func,
  toggled: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ToggleButton;