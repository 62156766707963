import * as R from 'ramda';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './managerLabel.local.less';


class ManagerLabel extends Component {
  isActive = (col, row) => {
    const { labelX, labelY, } = this.props;
    const rows = labelX.split(','), cols = labelY.split(',');
    if (!R.includes(row, rows) || !R.includes(col, cols)) return '';
    return styles.active;
  };
  render() {
    const {
      layoutStyle,
      strategyGridX1,
      strategyGridX2,
      strategyGridX3,
      strategyGridY1,
      strategyGridY2,
      strategyGridY3,
    } = this.props;
    return (
      <div className={`${styles.managerLabel} ${layoutStyle}`}>
        <div className={`${styles.managerLabelColumnTitle} ${styles.grid42}`}>
          {strategyGridX1}
        </div>
        <div className={`${styles.managerLabelColumnTitle} ${styles.grid43}`}>
          {strategyGridX2}
        </div>
        <div className={`${styles.managerLabelColumnTitle} ${styles.grid44}`}>
          {strategyGridX3}
        </div>
        <div className={`${styles.managerLabelRowTitle} ${styles.grid11}`}>
          {strategyGridY3}
        </div>
        <div className={`${styles.managerLabelRowTitle} ${styles.grid21}`}>
          {strategyGridY2}
        </div>
        <div className={`${styles.managerLabelRowTitle} ${styles.grid31}`}>
          {strategyGridY1}
        </div>
        <div className={`${styles.managerLabelContent} ${styles.grid12} ${this.isActive(strategyGridY3, strategyGridX1)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid13} ${this.isActive(strategyGridY3, strategyGridX2)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid14} ${this.isActive(strategyGridY3, strategyGridX3)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid22} ${this.isActive(strategyGridY2, strategyGridX1)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid23} ${this.isActive(strategyGridY2, strategyGridX2)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid24} ${this.isActive(strategyGridY2, strategyGridX3)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid32} ${this.isActive(strategyGridY1, strategyGridX1)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid33} ${this.isActive(strategyGridY1, strategyGridX2)}`}></div>
        <div className={`${styles.managerLabelContent} ${styles.grid34} ${this.isActive(strategyGridY1, strategyGridX3)}`}></div>
      </div>
    );
  }
}

ManagerLabel.propTypes = {
  layoutStyle: PropTypes.string,
  labelX: PropTypes.string,
  labelY: PropTypes.string,
  strategyGridX1: PropTypes.string,
  strategyGridX2: PropTypes.string,
  strategyGridX3: PropTypes.string,
  strategyGridY1: PropTypes.string,
  strategyGridY2: PropTypes.string,
  strategyGridY3: PropTypes.string,
};

ManagerLabel.defaultProps = {
  labelX: '',
  labelY: '',
  strategyGridX1: '',
  strategyGridX2: '',
  strategyGridX3: '',
  strategyGridY1: '',
  strategyGridY2: '',
  strategyGridY3: '',
};

export default ManagerLabel;
