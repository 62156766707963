import getApiRootUrl from '../../../helpers/apiHelpers/getApiRootUrl';
import API_URLS from '../../../app/constants/apiUrls';
import requestWrapper from '../../../helpers/apiHelpers/requestWrapper';
import ERRORS from '../../../app/constants/errors';
import { getErrorMessage } from '../../errors/errorHelper';

export const getGlobalSettings = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.GLOBAL_SETTINGS}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.GLOBAL_SETTINGS);
    throw new Error(errorMessage);
  }
};

export const setGlobalSettings = async json => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.GLOBAL_SETTINGS_UPDATE}`;
    const data = (await requestWrapper.put(url, { json })).data.data;

    if (data.err) throw new Error(data.err.displayMessage);

    return data;

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};