import React from 'react';
import PropTypes from 'prop-types';

import styles from './fieldValidationChecklist.local.less';


const FieldValidationCheckList = ({validators}) => {
  return(
    <ul className={styles.validationChecklist}>
      {validators.map((validator, index) => {
        return (
          <li key={index}>
            <span className={styles.validatorIcon}>{validator.isValid ? <> &#10003; </> : <span className={styles.dot}/>}</span>
            <span>{validator.error}</span>
          </li>
        );
      })}
    </ul>
  );
}

FieldValidationCheckList.propTypes = {
  validators : PropTypes.array
}

export default FieldValidationCheckList;