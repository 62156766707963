import React                       from 'react';
import PropTypes                   from 'prop-types';
import Card                        from '../card/Card';
import CardHeader                  from '../card/CardHeader';
import CardBody                    from '../card/CardBody';

import FundOpenEndPerformanceTable from './FundOpenEndPerformanceTable';

const FundOpenEndPerformanceCard = ({
  fund,
  overview,
  layoutStyle,
  title,
  assetClasses = []
}) => (
  <Card
    layoutStyle={layoutStyle}
    trackEventLabel={title}
    trackEventCategory='Fund'
  >
    <CardHeader>
      {title}
    </CardHeader>
    <CardBody>
      <FundOpenEndPerformanceTable fund={fund} assetClasses={assetClasses} overview={overview}/>
    </CardBody>
  </Card>
);


FundOpenEndPerformanceCard.propTypes = {
  fund: PropTypes.object,
  assetClasses: PropTypes.array,
  overview: PropTypes.object,
  layoutStyle: PropTypes.string,
  title: PropTypes.string
}

FundOpenEndPerformanceCard.defaultProps = {
  layoutStyle: '',
  title: 'Asset Class Performance'
}

export default FundOpenEndPerformanceCard;


