import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { 
  getAuthToken,
  getRefreshToken,
  setRefreshToken,
  setAuthToken,
  parseAuthResponse
} from '../../services/auth/authHelper';
import { trackEvent } from '../gaHelpers/gaHelpers';
import getApiRootUrl from './getApiRootUrl';

const config = {
  baseURL: getApiRootUrl(),
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
  },
};

const getApiTrackingUuid = () => uuidv4();

const axiosInstance = axios.create(config);

let activeRequests = {};

// before the request is sent to the server, add jwt to the Authorization header
axiosInstance.interceptors.request.use((reqConfig) => {
  const authToken = getAuthToken();
  const sessionRefreshToken = getRefreshToken();

  if (authToken) {
    const updatedReqConfig = Object.assign({}, reqConfig);
    const requestId = getApiTrackingUuid();

    updatedReqConfig.headers.Authorization = `Bearer ${authToken}`;
    updatedReqConfig.headers['x-request-id'] = requestId;
    updatedReqConfig.headers['X-Refresh-Token'] = sessionRefreshToken;
    activeRequests[requestId] = updatedReqConfig;

    return updatedReqConfig;
  }

  return reqConfig;
});

// whenever a response is received from the node layer
axiosInstance.interceptors.response.use(
  response => {
    const updatedRefresh = response.headers['x-refresh-token-status'] || null;
    const reqId = response.headers['x-request-id'];
    delete activeRequests[reqId];

    if (updatedRefresh) {
      const { authToken, refreshToken } = parseAuthResponse(response);
      setRefreshToken(refreshToken);
      setAuthToken(authToken);
    }

    return response 
  },
  error => {
    if(error.response.status === 404) {
      trackEvent('404', {
        event_category: 'Page errors',
        event_label: response.config.url
      });
    }

    return Promise.reject(error)
  }
);

export default axiosInstance;