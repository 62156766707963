import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Svg from '../../components/svg/Svg';
import Logo from '../../assets/svg/icon-account-menu.svg';
import Close from '../../assets/svg/icon-menu-blue-close.svg';
import styles from './headerNavIcon.local.less';
import HeaderNavIconMenu from '../headerNavIconMenu/HeaderNavIconMenu';
import { ROUTES } from '../../app/constants/index';
import { getRootUrl } from '../../utils/req';
import { logout as logoutAction } from '../../services/auth/authService';
import { auditLog } from '../../services/logging/loggingService';
import { LOG_EVENTS } from '../../app/constants/clientLogs';

class HeaderNavIcon extends Component {

  constructor(props) {
    super(props);
    this.state = {open: false};
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }
  handleOnClick(event) {
    event.preventDefault();
    this.setState({ open: true }, () => {
      document.addEventListener('click', this.handleOnBlur);
    });
    auditLog(LOG_EVENTS.USER_MENU.AVATAR);
  }
  handleOnBlur(event) {
    const el = document.getElementById("HeaderNavIconMenu");
    if (!el || el.contains(event.target)) return;
    this.setState({ open: false }, () => {
      document.removeEventListener('click', this.handleOnBlur);
    });  
  }

  handleLogout = (ev) => {
    ev.preventDefault();
    const { logout, history, auth } = this.props;
    const { authContext = null } = auth;

    logout(() => {
      if (!authContext) {
        history.push(ROUTES.LOGIN.path);
      }
      else {
        const rootUrl = getRootUrl(window.location.href);
        const clientUrl = `${rootUrl}admin`;
        window.location.href = clientUrl;
      }
    });
    
  };

  onOptionClicked = () => this.setState({ open: false });

  render() {
    return (
      <div className={styles.headerNavIconContainer}>
        <button className={styles.headerNavIcon} onClick={this.handleOnClick}>
          {
            this.state.open ? <Svg svg={Close}/> : <Svg svg={Logo}/>
          }
        </button>
        <HeaderNavIconMenu open={this.state.open} id="HeaderNavIconMenu" onOptionClicked={this.onOptionClicked} handleLogout={this.handleLogout} />
      </div>
    );
  }
}

HeaderNavIcon.propTypes = {
  logout: PropTypes.func,
  auth: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default compose(
  connect(null, {
    logout: logoutAction
  }), withRouter)(HeaderNavIcon);