import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReduxProviderWrapper from './app/ReduxProviderWrapper';
import App from './app/App';


hydrate(
  <ReduxProviderWrapper>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProviderWrapper>,
  document.getElementById('app'),
);
