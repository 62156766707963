import React, { Component }                 from 'react';
import PropTypes                            from 'prop-types';
import Card                                 from '../card/Card';
import CardBody                             from '../card/CardBody';
import CardHeader                           from '../card/CardHeader';
import { MAKENA_MANAGERS_COUNT_FIELDS }     from '../../app/constants';
import * as Helper                          from './allManagersCard.const';
import Datatable                            from '../datatable/Datatable';
import DatatableFilterBadges                from '../datatable/DatatableFilterBadges';
import DatatableSmallPaginator              from '../datatable/DatatableSmallPaginator';
import docStyles                            from './allManagersCard.local.less';
import { ROUTES }                           from '../../app/constants/index';
import InfoIcon                             from '../../assets/svg/icon-info.svg';
import ManagerDisclaimerPopUp               from '../managerDisclaimer/ManagerDisclaimerPopUp';
import Svg                                  from '../svg/Svg';
import {
  getDatatableMakenaManagers,
  getMakenaManagersCountByField
} from '../../services/makenaManagers/makenaManagerService';
import { trackEvent }                       from '../../helpers/gaHelpers/gaHelpers';

const filterParser = {
  assetClass: 'assetClasses',
  location: 'locations',
  funds: 'funds'
};

const fetchParser = {
  funds: 'makenaManagersCountByFunds',
  locations: 'makenaManagersCountByLocations',
  assetClasses: 'makenaManagersCountByAssetClasses'
}

class AllManagersCard extends Component {
  state = {
    isLoading: true,
    payload: {},
    headers: Helper.headers,
    initFilterOptions: {},
    restart: 0,
    resetFilters: false,
    managers: {},
    makenaManagersCountByLocations: [],
    makenaManagersCountByAssetClasses: [],
    makenaManagersCountByFunds: [],
    showPopUpDisclaimer: false,
  };

  async componentDidMount() {
    let data = {
      managers: {},
      makenaManagersCountByLocations: [],
      makenaManagersCountByAssetClasses: [],
      makenaManagersCountByFunds: []
    };

    try {
      this.startLoading();
      try {
        const promises = [
          getDatatableMakenaManagers({}).then(_data => ({ managers: _data })),
          ..._.map(MAKENA_MANAGERS_COUNT_FIELDS, field => {
            return getMakenaManagersCountByField(field)
              .then(_data => _.forEach({..._data}, item => item.name = item.name.toString()))
              .then(_data => ({ [fetchParser[field]]: _data }))
          })
        ];
        await Promise.all(promises)
          .then(responses => _.forEach(responses, item => data = { ...data, ...item }))
        this.clearError('makenaManagersFetchError');
      } catch (error) {
        this.setError('makenaManagersFetchError', error.message);
      }
    } finally {
      this.finishLoading(data);
    }

  }

  async componentDidUpdate(prevProps, prevState) {
    const oldPayload = prevState.payload;
    const oldRestart = prevState.restart;
    const newPayload = this.state.payload;
    const newRestart = this.state.restart;

    if ((JSON.stringify(oldPayload) === JSON.stringify(newPayload)) && (oldRestart === newRestart)) {
      return;
    }

    let promises = [];

    let data = {
      managers: {},
      makenaManagersCountByLocations: [],
      makenaManagersCountByAssetClasses: [],
      makenaManagersCountByFunds: []
    };

    const filterPromises = _.map(MAKENA_MANAGERS_COUNT_FIELDS, field => {
      const filters = _.omit(this.state.payload.filters, [filterParser[field]]);
      return getMakenaManagersCountByField(field, { filters })
        .then(_data => _.forEach({..._data}, item => item.name = item.name.toString()))
        .then(_data => ({ [fetchParser[field]]: _data }));
    });

    promises = [...filterPromises];
    promises.push(getDatatableMakenaManagers(this.state.payload).then(_data => ({ managers: _data })))

    await Promise.all(promises)
      .then(responses => _.forEach(responses, item => data = { ...data, ...item }))
      .then(() => this.setState({...data}));
  }

  getInitFilterOptions = data => {
    const { initFilterOptions = {} } = this.state;

    const {
      makenaManagersCountByLocations,
      makenaManagersCountByAssetClasses,
      makenaManagersCountByFunds
    } = data;
    let options = { ...initFilterOptions };
    _.forEach(makenaManagersCountByLocations, item => !_.get(options, `location.${item.name}`, null) ? _.set(options, `location.${item.name}`, item) : null);
    _.forEach(makenaManagersCountByAssetClasses, item => !_.get(options, `assetClass['${item.name}']`, null) ? _.set(options, `assetClass['${item.name}']`, item) : null);
    _.forEach(makenaManagersCountByFunds, item => !_.get(options, `funds.${item.name}`, null) ? _.set(options, `funds.${item.name}`, item) : null);

    return options;
  }

  setError = (errorName, errorMessage) => {
    this.setState({
      ...this.state,
      errors: {
        [errorName]: errorMessage,
      },
    });
  };

  clearError = (errorName) => {
    this.setState({
      ...this.state,
      errors: {
        [errorName]: null,
      },
    });
  };

  startLoading = () => {
    this.setState({
      ...this.state,
      isLoading: true,
    });
  };

  finishLoading = data => {
    this.setState({
      ...this.state,
      ...data,
      payload: { sort: [{field: "makenaInvestmentDate", direction: "DESC"}] },
      initFilterOptions: this.getInitFilterOptions(data),
      isLoading: false,
    });
  };

  parse = (stateValue, values, transform) => {
    let options = [];
    _.forEach(stateValue, (value, key) => {
      let item = _.find(values, ({ name }) => name.toString() === key.toString() );
      options = [...options, transform(item || { name: stateValue[key].name, count: 0 }) ];
    });

    return options;
  };

  normalizeFilters = () => {
    const {
      initFilterOptions: { location, assetClass, funds },
      makenaManagersCountByLocations,
      makenaManagersCountByAssetClasses,
      makenaManagersCountByFunds
    } = this.state;

    const transformTitleCase = ({ name, count }) => ({ name, count, title: name });
    const transformUpperCase = ({ name, count }) => ({ name, count, title: name });
    return {
      location: this.parse(location, makenaManagersCountByLocations, transformTitleCase),
      assetClass: this.parse(assetClass, makenaManagersCountByAssetClasses, transformTitleCase),
      funds: this.parse(funds, makenaManagersCountByFunds, transformUpperCase)
    }
  };

  onSortChange = sort => {
    trackEvent('sort', {
      event_category: 'Managers',
      event_label: R.compose (
        c => `${c.field} ${c.direction}`,
        R.head,
      ) (sort),
    });
    const { payload } = this.state;
    this.setState({ ...this.state, payload: { ...payload, sort } });
  }

  onPageChange = value => {
    trackEvent('pagination', {
      event_category: 'Managers',
      event_label: `offset: ${value.offset}`,
    })
    const { payload } = this.state;
    this.setState({ ...this.state, payload: { ...payload, offset: value.offset} });
  }

  onFilterOptionClicked = output => {
    trackEvent('filter', {
      event_category: 'Managers',
      event_label: output.name + ' ' + output.value,
    })
    const { headers } = this.state;
    let outputFilters = {};
    _.forEach(_.keys(output), key => {
      if (output[key].length === 0 || output[key][0] !== 'All')
        return outputFilters[key] = output[key];
      outputFilters[key] = [];
    });

    let headersCopy = _.clone(headers);

    const filters = { ...this.state.payload.filters, ...outputFilters };

    const resetFilters = _.flatten(_.values(filters)).length === 0;

    this.setState({
      resetFilters,
      payload: { ...this.state.payload, offset: 0, filters: !resetFilters? filters : {} },
      headers: headersCopy,
    });
  }

  onRowClick = ({ managerTitle }) => {
    trackEvent('click', {
      event_category: 'Managers',
      event_label: managerTitle,
    });
    const { history } = this.props;
    history.push(`${ROUTES.EXPLORE.path}/${managerTitle}`);
  }

  toggleDisclaimer = () => this.setState({ showPopUpDisclaimer: !this.state.showPopUpDisclaimer });

  renderDisclaimerInfoIcon = (showPopUpDisclaimer, toggleDisclaimer) => (
    <div className={docStyles.reviewCardDisclaimerIcon} onClick={toggleDisclaimer}>
      <Svg svg={InfoIcon}></Svg>
      { showPopUpDisclaimer? <ManagerDisclaimerPopUp position="right" onDismiss={toggleDisclaimer}/> : <></> }
    </div>
  );

  render() {
    const { isLoading, headers, managers, showPopUpDisclaimer } = this.state;
    const { showDisclaimer } = this.props;
    if (isLoading || !Object.keys(managers).length) return null;
    const data = Helper.transform(managers);
    const { count, totalPages, currentPage, total, filtered, pageSize } = data;

    const {
      location,
      funds,
      assetClass
    } = this.normalizeFilters();
    let copyHeaders = Helper.assignFiltersToHeaders(headers, { funds, location, assetClass }, _.get(this.state, `payload.filters`, {}), _.get(this.state, `payload.sort.0`, {}));
    const cardHeader = `All Managers`;
    const entityName = 'Makena Managers';

    return (
      <Card
        layoutStyle="contentRowFull cardMain"
        type="flex"
        trackEventLabel={cardHeader}
        trackEventCategory='Managers'
      >
        <CardHeader>
          <div className={docStyles.reviewCardHeader}>
            <div className={docStyles.reviewCardTitle}>Managers</div>
            { showDisclaimer? <></> : this.renderDisclaimerInfoIcon(showPopUpDisclaimer, this.toggleDisclaimer) }
          </div>
        </CardHeader>
        <CardBody>
          <div className={docStyles.statusBar}>
            <DatatableFilterBadges headers={copyHeaders} onFilterOptionClicked={this.onFilterOptionClicked}/>
            <DatatableSmallPaginator entityName={entityName} pageSize={pageSize} count={count} total={total} filtered={filtered} currentPage={currentPage} totalPages={totalPages} onPageChange={this.onPageChange}/>
          </div>
          <Datatable
            entityName={entityName}
            content={data}
            headers={copyHeaders}
            onColumnClick={this.onColumnClick}
            onRowClick={this.onRowClick}
            onFilterOptionClicked={this.onFilterOptionClicked}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
            onDataChange={this.onDataChange}/>
        </CardBody>
      </Card>
    );
  }
}

AllManagersCard.propTypes = {
  history: PropTypes.any,
  showDisclaimer: PropTypes.bool
}

export default AllManagersCard;
