import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';
import { ACCOUNT_SERVER_ONLY_FIELDS } from './accountHelper';
import {
  accountCreateSuccess,
  accountDeleteSuccess,
  accountsFetchSuccess,
  accountUpdateSuccess
} from './accountActions';
import { getErrorMessage } from '../errors/errorHelper';
import { generateRandom } from '../auth/authHelper';

const maybeParsePermissions = (acc) => {
  let parsed;
  try {
    parsed = {...acc, permissionsJson: acc.permissionsJson && JSON.parse(acc.permissionsJson)}
  }
  catch(ex) {
    parsed = acc;
  }
  return parsed;
};

export const accountLog = async name => {
  try {
    await requestWrapper.post(`${getApiRootUrl()}${API_URLS.ACCOUNT_LOG}`, { name });
    return;
  } catch (err) {
    const defaultErrorMessage = `${ERRORS.ACCOUNT_LOG_ERROR}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
}

export const accountCreate = (accountFieldsFromUi, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_CREATE}`;

    const newAccount = {
      ...ACCOUNT_SERVER_ONLY_FIELDS,
      ...accountFieldsFromUi,
      password: generateRandom(12),
    };
    const response = await requestWrapper.post(url, newAccount);

    const account = response.data.data;

    if (account.err) return { error: true, message: account.message };
    if (onSuccess) onSuccess(account);

    const parsed = maybeParsePermissions(account);
    dispatch(accountCreateSuccess(parsed));
    
    return account;

  } catch (err) {
    const { email } = accountFieldsFromUi || {};
    const defaultErrorMessage = `${ERRORS.ACCOUNT_CREATE_ERROR} ${email}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountDispatchInvite = (uuid, ttl = 1440) => async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_INVITE_USER(uuid)}`;
    const invitation = {
      id: uuid,
      ttl
    };
    await requestWrapper.post(url, invitation);

  } catch (err) {
    const defaultErrorMessage = `${ERRORS.ACCOUNT_INVITE_ERROR} ${uuid}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
}

export const accountSafeDelete = (uuid, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_DELETE(uuid)}`;
    await requestWrapper.delete(url);

    dispatch(accountDeleteSuccess(uuid));
    if (onSuccess) onSuccess();
  } catch (err) {
    const defaultErrorMessage = `${ERRORS.ACCOUNT_DELETE_ERROR} ${uuid}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountsFetch = (search, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNTS_FETCH_DATATABLE}`;
    const response = await requestWrapper.post(url, { filters: { search: [search] } });
    const accountList = response.data.data.data;

    dispatch(accountsFetchSuccess(accountList));
    if (onSuccess) onSuccess();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNTS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

export const accountsBulkInvite = async payload => {
  const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_BULK_INVITE}`;
  const response = await requestWrapper.put(url, payload);
  return response.data.data;
}

export const accountUpdate = (uuid, accountFieldsFromUi, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_UPDATE(uuid)}`;
    const response = await requestWrapper.put(url, accountFieldsFromUi);
    const account = response.data.data;
    if (account.err) return { error: true, message: account.message };
    if (onSuccess) onSuccess();
    dispatch(accountUpdateSuccess(account));
    return response.data.data;
  } catch (err) {
    const { email } = accountFieldsFromUi || {};
    const defaultErrorMessage = `${ERRORS.ACCOUNT_UPDATE_ERROR} ${email}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountUpdatePassword = (uuid, accountFieldsFromUi, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_UPDATE_PASSWORD(uuid)}`;
    const response = await requestWrapper.post(url, accountFieldsFromUi);

    dispatch(accountUpdateSuccess(response.data.data));
    if (onSuccess) onSuccess();
  } catch (err) {
    const defaultErrorMessage = ERRORS.ACCOUNT_UPDATE_PASSWORD_ERROR;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountPermissionsUpdate = async (fieldsFromUi) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_PERMISSIONS_UPDATE}`;
    const response = await requestWrapper.post(url, fieldsFromUi);
    return response.data.data;
  } catch (err) {
    const defaultErrorMessage = `${ERRORS.ACCOUNT_UPDATE_ERROR}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountClientsUpdate = async (uuid, fieldsFromUi) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_CLIENTS_UPDATE(uuid)}`;
    const response = await requestWrapper.put(url, fieldsFromUi);
    return response.data.data;
  } catch (err) {
    const defaultErrorMessage = `${ERRORS.ACCOUNT_UPDATE_ERROR}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountSetup = async (token, accountFieldsFromUi, onSuccess) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_SETUP(token)}`;
    const response = await requestWrapper.put(url, accountFieldsFromUi);
    const account = response.data.data;

    if (onSuccess) onSuccess(account);
  } catch (err) {
    const { email } = accountFieldsFromUi || {};
    const defaultErrorMessage = `${ERRORS.ACCOUNT_SETUP_ERROR} ${email}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountConfirm = async (id, token, onSuccess) => {
  try {
    if (token) {
      const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_SETUP(token)}`;
      await requestWrapper.put(url, {status: 1});
    }
    else {
      const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_CONFIRM_USER(id)}`;
      await requestWrapper.get(url);
    }

    if (onSuccess) onSuccess();
  } catch (err) {
    const defaultErrorMessage = `${ERRORS.ACCOUNT_CONFIRM_ERROR}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};

export const accountSetupById = async (uuid, accountFieldsFromUi, onSuccess) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_SETUP_BY_ID(uuid)}`;
    const response = await requestWrapper.put(url, accountFieldsFromUi);
    const account = response.data.data;

    if (onSuccess) onSuccess(account);
  } catch (err) {
    const { email } = accountFieldsFromUi || {};
    const defaultErrorMessage = `${ERRORS.ACCOUNT_SETUP_ERROR} ${email}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
}

export const accountPermissionsFetchbyContactId = async contactId => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_PERMISSIONS_FETCH_BY_CONTACT_ID(contactId)}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNTS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
}
