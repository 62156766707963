export const LOG_EVENTS = {
  USER_MENU: {
    AVATAR: {
      id: 'A - 1',
      detail: 'User Menu - Click Avatar',
    },
  },
  MENU_OPTIONS: {
    ADMIN: {
      id: 'B - 1',
      detail: 'Menu Options - Admin',
    },
    SETTINGS: {
      id: 'B - 2',
      detail: 'Menu Options - Settings',
    },
    SIGN_OUT: {
      id: 'B - 3',
      detail: 'Menu Options - Sign Out',
    },
  },
  HEADER: {
    GO_TO_PORTAL: {
      id: 'C - 1',
      detail: 'Header - Go to portal',
    },
    SIGN_OUT: {
      id: 'C - 2',
      detail: 'Header - Sign Out',
    },
  },
  SIDEBAR_MENU: {
    LOGINS_AND_DOCS: {
      id: 'D - 1',
      detail: 'Sidebar Menu - Logins and Docs',
    },
    BY_CLIENTS: {
      id: 'D - 2',
      detail: 'Sidebar Menu - By Client',
    },
    BY_USERS: {
      id: 'D - 3',
      detail: 'Sidebar Menu - By User',
    },
    USERS: {
      id: 'D - 4',
      detail: 'Sidebar Menu - Users',
    },
    CLIENTS: {
      id: 'D - 5',
      detail: 'Sidebar Menu - Clients',
    },
    ACCOUNT_NAMES: {
      id: 'D - 6',
      detail: 'Sidebar Menu - Account Names',
    },
    FOUNDS: {
      id: 'D - 7',
      detail: 'Sidebar Menu - Funds',
    },
    ASSET_CLASSES: {
      id: 'D - 8',
      detail: 'Sidebar Menu - Asset Classes',
    },
    MANAGERS: {
      id: 'D - 9',
      detail: 'Sidebar Menu - Managers',
    },
    GLOBAL: {
      id: 'D - 10',
      detail: 'Sidebar Menu - Global',
    },
    SSC_DOCS: {
      id: 'D - 11',
      detail: 'Sidebar Menu - SS&C Docs',
    },
    SSC_DOCS_DISPLAYED: {
      id: 'D - 11 - 1',
      detail: 'SS&C Docs - Investor Documents Displayed',
    },
    CLIENT_DOCS: {
      id: 'D - 12',
      detail: 'Sidebar Menu - Client Docs',
    },
    TAX_FORMS: {
      id: 'D - 13',
      detail: 'Sidebar Menu - Tax Forms',
    },
    UPDATES: {
      id: 'D - 14',
      detail: 'Sidebar Menu - Updates',
    },
    MAKENA_PEOPLE: {
      id: 'D - 15',
      detail: 'Sidebar Menu - Makena People',
    },
    NOTIFICATION_SETTINGS: {
      id: 'D - 16',
      detail: 'Sidebar Menu - Notification Settings',
    },
  },
  USER_ACTIONS: {
    MENU: {
      id: 'E - 1',
      detail: 'User Actions - Open Menu',
    },
    FUNDS: {
      id: 'E - 2',
      detail: 'User Actions - Founds Dropdown',
    },
    MANAGERS: {
      id: 'E - 3',
      detail: 'User Actions - Managers',
    },
    INVESTOR_DOCUMENTS: {
      id: 'E - 4',
      detail: 'User Actions - Investor Documents',
    },
    FORMS: {
      id: 'E - 5',
      detail: 'User Actions - Forms',
    },
  },
  F: {},
  G: {},
  H: {},
  J: {
    DISPLAYED: {
      id: 'J - 4',
      detail: 'Document - Displayed'
    },
    DOWNLOADED: {
      id: 'J - 5',
      detail: 'Document - Downloaded'
    }
  }
};
