import React from 'react';
import PropTypes from 'prop-types';

const IconCircleWarning = ({title='', className=''}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px" className={className}>
      <title>{title}</title><path d="M50,8A42,42,0,1,1,8,50,42,42,0,0,1,50,8m0-3A45,45,0,1,0,95,50,45,45,0,0,0,50,5Z"></path><path d="M47.08,46.44q-.79-4-1.18-6.42t-.51-3.54q-.11-1.15-.11-2a4.3,4.3,0,0,1,1.29-3.25,5.36,5.36,0,0,1,6.84,0,4.3,4.3,0,0,1,1.29,3.25q0,.84-.11,2T54.1,40q-.39,2.38-1.18,6.42T50.79,57.1H49.21Q47.87,50.48,47.08,46.44Zm-.34,15.88a4.6,4.6,0,0,1,7.85,3.25,4.16,4.16,0,0,1-1.35,3.17,4.82,4.82,0,0,1-6.51,0,4.16,4.16,0,0,1-1.35-3.17A4.43,4.43,0,0,1,46.75,62.31Z"></path>
    </svg>
  );
}

IconCircleWarning.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

export default IconCircleWarning; 

