import React, { Component }                 from 'react';
import _                                    from 'lodash';
import PropTypes                            from 'prop-types';
import { withRouter }                       from 'react-router-dom';
import * as R                               from 'ramda';

import { compose }                          from 'redux';
import connect                              from 'react-redux/es/connect/connect';
import { titleCase }                        from 'title-case';
import moment                               from 'moment';
import { Input }                            from 'semantic-ui-react';
import { INVESTOR_DOCUMENTS_COUNT_FIELDS }  from '../../app/constants';
import API_URLS                             from '../../app/constants/apiUrls';
import CheckboxAccordion                    from '../../components/checkboxAccordion/CheckboxAccordion';
import Datatable                            from '../../components/datatable/Datatable';
import DatatableFilterBadges                from '../../components/datatable/DatatableFilterBadges';
import DatatableSmallPaginator              from '../../components/datatable/DatatableSmallPaginator';

import QuartersAccordion                    from '../../components/quartersAccordion/QuartersAccordion';
import Footer                               from '../../components/footer/Footer';
import getApiRootUrl                        from '../../helpers/apiHelpers/getApiRootUrl';
import { trackEvent }                       from '../../helpers/gaHelpers/gaHelpers';
import { getAuthToken }                     from '../../services/auth/authHelper';
import SideBar                              from '../../components/sideBar/SideBar';

import {
  accountLog
} from '../../services/account/accountService';

import {
  makenaFundsFetch
} from '../../services/makenaFund/makenaFundService';

import {
  markDocumentsAsRead,
  markDocumentsAsUnread,
  downloadPDFsZip,
  getDatatableInvestorDocuments,
  getInvestorDocumentsCountByField
} from '../../services/document/documentService';

import { auditLog } from '../../services/logging/loggingService';

import docStyles    from './documentsPageView.local.less';
import { Helper }   from './documentsPageView.const';
import { IsMobile } from '../../helpers/globalHelpers';
import { LOG_EVENTS } from '../../app/constants/clientLogs';

const event_category = 'Document';

class DocumentsPageView extends Component {
  state = {
    isLoading: true,
    payload: {},
    checkedData: {},
    headers: Helper.headers,
    initFilterOptions: {},
    restart: 0,
    resetFilters: false,
    investorDocuments: {},
    investorDocumentsCountByAccounts: [],
    investorDocumentsCountByCategories: [],
    investorDocumentsCountByFunds: [],
    investorDocumentsCountByReadUnread: [],
    investorDocumentsCountByReleaseDate: [],
    sscKeys: {}
  };

  async componentDidMount() {
    let data = {
      investorDocuments: {},
      investorDocumentsCountByAccounts: [],
      investorDocumentsCountByCategories: [],
      investorDocumentsCountByFunds: [],
      investorDocumentsCountByReadUnread: [],
      investorDocumentsCountByReleaseDate: [],
      sscKeys: {}
    };

    accountLog(`Investor Documents`);
    try {
      this.startLoading();
      try {
        const promises = [
          makenaFundsFetch().then(_data => ({ sscKeys: Helper.TransformFundsBySscKey(_data) })),
          getDatatableInvestorDocuments({}).then(_data => ({ investorDocuments: _data })),
          ..._.map(INVESTOR_DOCUMENTS_COUNT_FIELDS, field => {
            return getInvestorDocumentsCountByField(field)
              .then(_data => _.forEach({..._data}, item => item.name = item.name.toString()))
              .then(_data => ({ [Helper.countFunctions[field]]: _data }))
          })
        ];
        await Promise.all(promises)
          .then(responses => _.forEach(responses, item => data = { ...data, ...item }))
        this.clearError('investorDocumentsFetchError');
      } catch (error) {
        this.setError('investorDocumentsFetchError', error.message);
      }
    } finally {
      this.finishLoading(data);
    }

  }

  async componentDidUpdate(prevProps, prevState) {
    const oldPayload = prevState.payload;
    const oldCheckedData = prevState.checkedData;
    const oldRestart = prevState.restart;
    const newPayload = this.state.payload;
    const newCheckedData = this.state.checkedData;
    const newRestart = this.state.restart;

    if (
      (JSON.stringify(oldPayload) === JSON.stringify(newPayload)) &&
      (JSON.stringify(oldCheckedData) === JSON.stringify(newCheckedData)) &&
      (oldRestart === newRestart)
    ) {
      return
    }

    let promises = [];

    let data = {
      investorDocuments: {},
      investorDocumentsCountByAccounts: [],
      investorDocumentsCountByCategories: [],
      investorDocumentsCountByFunds: [],
      investorDocumentsCountByReadUnread: [],
      investorDocumentsCountByReleaseDate: []
    };

    const filterPromises = _.map(INVESTOR_DOCUMENTS_COUNT_FIELDS, field => {
      const filters = _.omit(this.state.payload.filters, [Helper.filterFields[field]]);
      return getInvestorDocumentsCountByField(field, { filters })
        .then(_data => _.forEach({..._data}, item => item.name = item.name.toString()))
        .then(_data => ({ [Helper.countFunctions[field]]: _data }));
    });

    promises = [...filterPromises];
    promises.push(getDatatableInvestorDocuments(this.state.payload).then(_data => ({ investorDocuments: _data })))

    await Promise.all(promises)
      .then(responses => _.forEach(responses, item => data = { ...data, ...item }))
      .then(() => this.setState({...data}));
  }


  titleSearchTimeout = null;

  getInitFilterOptions = data => {
    const { initFilterOptions = {} } = this.state;

    const {
      investorDocumentsCountByCategories,
      investorDocumentsCountByFunds,
      investorDocumentsCountByAccounts,
      investorDocumentsCountByReleaseDate,
    } = data;

    let options = { ...initFilterOptions, unread: { read: null, unread: null } };
    _.forEach(investorDocumentsCountByCategories, item => !_.get(options, `categories.${item.name}`, null) ? _.set(options, `categories.${item.name}`, item) : null);
    _.forEach(investorDocumentsCountByFunds, item => !_.get(options, `funds.${item.name}`, null) ? _.set(options, `funds.${item.name}`, item) : null);
    _.forEach(investorDocumentsCountByAccounts, item => !_.get(options, `accounts.${item.name}`, null) ? _.set(options, `accounts.${item.name}`, item) : null);
    _.forEach(investorDocumentsCountByReleaseDate, item => !_.get(options, `releaseDate.${item.name}`, null) ? _.set(options, `releaseDate.${item.name}`, item) : null);
    options.accounts = { ... options.accounts };
    return options;
  }

  setError = (errorName, errorMessage) => {
    this.setState({
      ...this.state,
      errors: {
        [errorName]: errorMessage,
      },
    });
  };

  clearError = (errorName) => {
    this.setState({
      ...this.state,
      errors: {
        [errorName]: null,
      },
    });
  };

  startLoading = () => {
    this.setState({
      ...this.state,
      isLoading: true,
    });
  };

  finishLoading = data => {
    this.setState({
      ...this.state,
      ...data,
      payload: { sort: [{field: "modifyTime", direction: "DESC"}] },
      checkedData: {},
      initFilterOptions: this.getInitFilterOptions(data),
      isLoading: false,
    });
  };

  handleCheckboxOnChange = fieldName => output => {
    const filters = { [fieldName]: output };
    const { headers } = this.state;

    let headersCopy = _.clone(headers);
    _.forEach(headersCopy.columns, column => {
      if (column.type !== 'checkbox') return;
      column.toggleStatus = 'none';
    });

    this.setState({
      checkedData: {},
      resetFilters: false,
      payload: { ...this.state.payload, offset: 0, filters: { ...this.state.payload.filters, ...filters } },
      headers: headersCopy,
    });

    trackEvent('filter', {
      event_category,
      event_label: fieldName,
    });
  };

  transformUpperCase = ({ name, count }) => ({ 
    name, 
    count, 
    title: this.state.sscKeys[name] || name.toUpperCase()
  });

  transformTitleCase = ({name, count}) => ({ name, count, title: titleCase(name)});

  transformSscLegalName = ({name, legalName, count}) => ({ name, count, title: legalName});

  transformQuarter = ({name, count}) => ({ title: name, count, name: name.split(' ').reverse().join('-')});

  findReadUnread = (arr, title) => {
    let item = _.find(arr, checkboxItem => checkboxItem.name === title);
    item = this.transformTitleCase(item? item : { name: title, count: 0 });
    return title === 'unread'? { ...item, mark: true } : item;
  };

  parse = (stateValue, values, transform) => {
    let options = [];
    _.forEach(stateValue, (value, key) => {
      let item = _.find(values, ({ name }) => name.toString() === key.toString() );
      options = [...options, transform(item || { legalName: stateValue[key].legalName, name: stateValue[key].name, count: 0 }) ];
    });
    return options;
  };

  getEarliestYear = (datestring) => {
    const date = Date.parse(datestring);
    if (isNaN(date)) {
      return new Date().getFullYear() - 4;
    } else {
      return new Date(date).getFullYear();
    }
  }

  normalizeFilters = () => {
    const {
      initFilterOptions: { categories, accounts, funds, releaseDate },
      investorDocumentsCountByCategories,
      investorDocumentsCountByAccounts,
      investorDocumentsCountByFunds,
      investorDocumentsCountByReadUnread,
      investorDocumentsCountByReleaseDate
    } = this.state;
    return {
      categories: this.parse(categories, investorDocumentsCountByCategories, this.transformTitleCase),
      accounts: this.parse(accounts, investorDocumentsCountByAccounts, this.transformSscLegalName),
      funds: this.parse(funds, investorDocumentsCountByFunds, this.transformUpperCase),
      releaseDate: this.parse(releaseDate, investorDocumentsCountByReleaseDate, this.transformQuarter),
      readUnreads: [
        this.findReadUnread(investorDocumentsCountByReadUnread, 'read'),
        this.findReadUnread(investorDocumentsCountByReadUnread, 'unread')
      ],
    }
  };

  onFilterOptionClicked = output => {
    const { headers } = this.state;
    let outputFilters = {};
    _.forEach(_.keys(output), key => {
      if (output[key].length === 0 || output[key][0] !== 'All')
        return outputFilters[key] = output[key];
      outputFilters[key] = [];
    });

    let headersCopy = _.clone(headers);
    _.forEach(headersCopy.columns, column => {
      if (column.type !== 'checkbox') return;
      column.toggleStatus = 'none';
    });

    const filters = { ...this.state.payload.filters, ...outputFilters };

    const resetFilters = _.flatten(_.values(filters)).length === 0;

    this.setState({
      checkedData: {},
      resetFilters,
      payload: { ...this.state.payload, offset: 0, filters: !resetFilters? filters : {} },
      headers: headersCopy,
    });
    const key = R.compose(R.head, R.keys)(output);
    trackEvent('filter', {
      event_category,
      event_label: `${key}: ${output[key].join(', ')}`,
    });
  }

  onColumnClick = changedColumn => {
    const { headers, investorDocuments } = this.state;
    let headersCopy = _.clone(headers);
    let toggleStatus = 'none';
    _.forEach(headersCopy.columns, column => {
      if (column.type !== 'checkbox') return;
      column.toggleStatus = changedColumn.value? 'all' : 'none';
      toggleStatus = column.toggleStatus;
    })

    let newCheckedData = {};
    if (toggleStatus === 'all') _.forEach(investorDocuments.data, doc => newCheckedData[doc.uuid] = true );

    this.setState({ ...this.state, headers: headersCopy, checkedData: newCheckedData});
    trackEvent('filter', {
      event_category,
      event_label: changedColumn.name + ' ' + changedColumn.value,
    });
  }

  onDataChange = value => {
    const { headers, checkedData, investorDocuments } = this.state;
    let newCheckedData = _.clone(checkedData);
    let headersCopy = _.clone(headers);
    newCheckedData[value.uuid] = !newCheckedData[value.uuid];
    const checkedDataLength = _.filter(_.keys(newCheckedData), uuid => newCheckedData[uuid]).length;

    let toggleStatus = 'all';
    if (checkedDataLength === 0) toggleStatus = 'none';
    else if (checkedDataLength === investorDocuments.data.length) toggleStatus = 'all';
    else toggleStatus = 'some';

    _.forEach(headersCopy.columns, column => {
      if (column.type !== 'checkbox') return;
      column.toggleStatus = toggleStatus;
    })

    this.setState({ ...this.state, checkedData: newCheckedData, headers: headersCopy });
  }

  onSortChange = sort => {
    const { payload } = this.state;
    this.setState({ ...this.state, payload: { ...payload, sort } });
    trackEvent('sort', {
      event_category,
      event_label: R.compose (
        c => `${c.field} ${c.direction}`,
        R.head,
      ) (sort),
    });
  }

  onPageChange = value => {
    const { payload } = this.state;
    this.setState({ ...this.state, payload: { ...payload, offset: value.offset} });
    trackEvent('paginate', {
      event_category,
      event_label: `offset: ${value.offset}`,
    })
  }

  onTitleSearch = (event, { value }) => {
    if (this.titleSearchTimeout) clearTimeout(this.titleSearchTimeout);
    this.titleSearchTimeout = setTimeout(() => {
      this.titleSearchTimeout = null;
      this.setState(R.set (R.lensPath (['payload', 'filters', 'title']), value === '' ? [] : [value]))
      trackEvent('search', {
        event_category,
        event_label: value,
        search_term: value,
      })
    }, 500);
  }

  bulkMarkAs = type => async () => {
    const { checkedData, headers: { columns } } = this.state;
    const checkedUUIDS = _.filter(_.keys(checkedData), uuid => checkedData[uuid]);
    _.forEach(columns, column => {
      if (column.selector !== "isUnread") return;
      column.toggleStatus = 'none';
    });
    type === 'unread'? await markDocumentsAsUnread(checkedUUIDS) : await markDocumentsAsRead(checkedUUIDS);
    this.setState({ ...this.state, checkedData: {}, headers: { ...this.state.headers, columns } });
    trackEvent('bulk_mark_as', {
      event_category,
      event_label: 'mark as ' + type,
    })
  }

  downloadPDFs = () => {
    const { checkedData } = this.state;
    const uuids = _.filter(_.keys(checkedData), uuid => checkedData[uuid]);
    if (!uuids.length) return;
    downloadPDFsZip({uuids});
    this.bulkMarkAs('read')();
    trackEvent('download', {
      event_category,
      value: uuids.length,
      non_interaction: true
    });
  }

  showFile = async (fileName, uuid) => {
    const documentUrl = API_URLS.DOCUMENT_DOWNLOAD(fileName);
    const apiUrl = getApiRootUrl();
    window.open(`${apiUrl}${documentUrl}?attachment=false&token=${getAuthToken()}&uuid=${uuid}`, "_blank");
  }

  onRowClick = async ({ uuid, fundCode, legalName, releaseDate, title, read, category, timestamp }, event) => {
    const logData = {
      documentName: title,
      effectiveDate: releaseDate,
      account: legalName,
      fund: fundCode,
      category,
      posted: timestamp
    };
    const fileName = `${fundCode.toUpperCase()} ${titleCase(legalName)} ${titleCase(title)} ${moment.utc(releaseDate).format('YYYY-MM-DD')}`;

    const clickedElementClass = event.target.getAttribute('class') ? event.target.getAttribute('class') : '';
    const localName = event.target.localName;

    if (localName === 'img') {
      if (category === 'exposures reports') {
        auditLog({ ...LOG_EVENTS.J.DOWNLOADED, logData });
      }
      if (read !== 'unread') return;
      await markDocumentsAsRead([uuid]);
      this.setState({ restart: this.state.restart + 1 });
      trackEvent('view', {
        event_category,
        event_label: title,
        value: 1,
        non_interaction: true
      })
    } else {
      if (clickedElementClass.substring(0, 4) === 'box_') return;
      if (category === 'exposures reports') {
        auditLog({ ...LOG_EVENTS.J.DISPLAYED, logData });
      }
      if (read === 'unread') await markDocumentsAsRead([uuid]);

      await this.showFile (fileName, uuid);
      trackEvent('download', {
        event_category,
        event_label: title,
        non_interaction: true,
        value: 1,
      });
      this.setState({ restart: this.state.restart + 1 });
    }
  }

  renderActionButtons = () => {
    const { investorDocuments: { data } } = this.state;

    const markAsUnread = _.find(data, row => row.isChecked && row.read === 'unread');
    const disabled = !_.find(data, row => row.isChecked);
    const classes = `${docStyles.actionButton} ${disabled? docStyles.disabled : docStyles.enabled}`;
    return (
      <div className={`${docStyles.ivActionRow} statusBarItem`}>
        {
          IsMobile() ? null :
            <button
              className={classes}
              onClick={this.downloadPDFs}>Download</button>
        }
        <button
          className={classes}
          onClick={this.bulkMarkAs(markAsUnread ? 'read' : 'unread')}>Mark
          as { markAsUnread ? 'Read' : 'Unread'}</button>
      </div>
    )
  };

  renderTextInputSearch = spacing => <Input icon="search" placeholder="Search document names" onChange={this.onTitleSearch} className={spacing? docStyles.titleSearch: null}/>;

  renderSideBar(sideBarParams) {
    const {
      appHierarchy,
      assetClassName,
      clientPortalManager,
      makenaClientName,
      managerTitle,
      openMenu,
      productId,
      total
    } = sideBarParams;

    return <SideBar
      activeAssetClassName={assetClassName}
      activeManagerTitle={managerTitle}
      activeProductId={productId}
      appHierarchy={appHierarchy}
      clientPortalManager={clientPortalManager}
      makenaClientName={makenaClientName}
      total={total}
      openMenu={openMenu}
    />
  }

  render() {
    const { isLoading, headers, checkedData, resetFilters, investorDocuments, sscKeys } = this.state;

    if (isLoading) return null;
    
    const data = Helper.InvestorDocumentsTransform(investorDocuments, checkedData, sscKeys);
    const { count, totalPages, currentPage, total, filtered, pageSize } = data;

    const {
      categories,
      accounts,
      funds,
      readUnreads,
      releaseDate
    } = this.normalizeFilters();

    const entityName = 'documents';
    let copyHeaders = Helper.AssignFiltersToHeaders(headers, { legalName: accounts, fundCode: funds, category: categories, read: readUnreads, releaseDate }, _.get(this.state, `payload.filters`, {}), _.get(this.state, `payload.sort.0`, {}));
    const formattedDates = _.find(copyHeaders.columns, column => column.selector == 'releaseDate').filterOptions;
    const footerConfig = {textContent: false};

    const { auth: { authContext = { } } = { } } = this.props;
    const {
      ivDocsPage,
      ivDocsContainer,
      ivDocsTitle,
      ivSearchBox,
      ivDocsFilters,
      ivDocsActions,
      ivDocsActionsSeparator,
      ivDocsDatatableContainer,
      ivDocsActionsHorizontalBottomSeparator,
      ivDocsActionsHorizontalTopSeparator,
    } = Helper.GetClasses(authContext.id);

    return (
      <div className={`mcpApp ${ivDocsPage}`}>
        <div className={ivDocsContainer}>
          <h1 className={ivDocsTitle}>Investor Documents</h1>
          <div className={ivSearchBox}>
            { this.renderTextInputSearch(true) }
          </div>
          <div className={ivDocsFilters}>
            <div className={docStyles.indexFiltersContent}>
              <QuartersAccordion
                title="EFFECTIVE DATES"
                dates={formattedDates}
                reset={resetFilters}
                onChange={this.handleCheckboxOnChange('date')}
              />
              <CheckboxAccordion
                title="ACCOUNTS"
                options={_.orderBy(accounts, ['title'], ['asc'])}
                reset={resetFilters}
                onChange={this.handleCheckboxOnChange('account')}
              />
              <CheckboxAccordion
                title="FUNDS"
                options={_.orderBy(funds, ['title'], ['asc'])}
                reset={resetFilters}
                onChange={this.handleCheckboxOnChange('fund')}
              />
              <CheckboxAccordion
                title="READ / UNREAD"
                options={readUnreads}
                reset={resetFilters}
                onChange={this.handleCheckboxOnChange('read')}
              />
              <CheckboxAccordion
                title="CATEGORIES"
                options={_.orderBy(categories, ['title'], ['asc'])}
                reset={resetFilters}
                onChange={this.handleCheckboxOnChange('category')}
              />
            </div>
          </div>
          <div className={ivDocsActionsHorizontalBottomSeparator}/>
          <div className={ivDocsActions}>
            { this.renderActionButtons() }
            <div className={ivDocsActionsSeparator}/>
            <DatatableFilterBadges headers={copyHeaders} onFilterOptionClicked={this.onFilterOptionClicked}/>
            <DatatableSmallPaginator entityName={entityName} pageSize={pageSize} count={count} total={total} filtered={filtered} currentPage={currentPage} totalPages={totalPages} onPageChange={this.onPageChange}/>
          </div>
          <div className={ivDocsActionsHorizontalTopSeparator}/>
          <div className={ivDocsDatatableContainer}>
            <Datatable
              entityName={entityName}
              content={data}
              headers={copyHeaders}
              onColumnClick={this.onColumnClick}
              onFilterOptionClicked={this.onFilterOptionClicked}
              onPageChange={this.onPageChange}
              onSortChange={this.onSortChange}
              onRowClick={this.onRowClick}
              onDataChange={this.onDataChange}/>
            <Footer config={footerConfig} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  appHierarchy: state.appHierarchy,
  openMenu: state.menu.open,
  authContext: state.auth
});

const mapDispatchToProps = ({});

DocumentsPageView.propTypes = {
  clientPortalManager: PropTypes.shape({}),
  clientPortalProductOverview: PropTypes.shape({}),
  makenaClient: PropTypes.shape({}),
  makenaProducts: PropTypes.shape({}),
  match: PropTypes.shape({}),
  auth: PropTypes.shape({})
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DocumentsPageView);
