import React            from 'react';
import PropTypes        from 'prop-types';
import moment           from 'moment';

import Card             from '../../components/card/Card';
import CardHeader       from '../../components/card/CardHeader';
import CardBody         from '../../components/card/CardBody';
import DynamicImage     from '../image/DynamicImage';
import Svg              from '../svg/Svg';

import styles           from './insightsCard.local.less';
import icon             from './../../assets/svg/m-large.svg';
import getApiRootUrl    from '../../helpers/apiHelpers/getApiRootUrl';
import API_URLS         from '../../app/constants/apiUrls';
import { getAuthToken } from '../../services/auth/authHelper';

const transform = ({uuid, logo, title, releaseDate, type, author}) => {
  const displayType = type.split('/')[1].toUpperCase();
  const filename = title.replace(/ /g, '-') + '.' + displayType.toLowerCase();
  const date = moment.utc(releaseDate).format('M/YYYY');
  return {
    uuid,
    logo,
    filename,
    title,
    author,
    id: `${title}${author}${date}`,
    url: `${getApiRootUrl()}${API_URLS.DOCUMENT_DOWNLOAD(title)}?attachment=false&token=${getAuthToken()}&uuid=${uuid}`,
    date
  }
};

const renderNoDocuments = () => {
  return (<div className="noContent">
    <div className="noContentContainer">
      <div>Your insight documents will appear here.</div>
      <div className="marginBottom">Please check back next quarter.</div>
      <div>
        If you have any questions, please contact your Makena account manager or write to us at
        <a href="mailto:ClientOperations@makenacap.com"> ClientOperations@makenacap.com</a>.
      </div>
    </div>
  </div>)
}

const InsightsCard = ({
  documents,
  inlineStyles,
  layoutStyle,
}) => {
  const rows = _.uniqBy(_.map(documents, transform), 'id');
  return (
    <Card
      layoutStyle={layoutStyle}
      trackEventLabel='Insights Card'
      trackEventCategory='Makena Insights'
    >
      <CardHeader>
        <section className={styles.insightsCard}>
          <div className={styles.leftHeader}>
            Makena Insights
          </div>
          <div className={styles.rightHeader}>
            <a href='/insights-and-news'>See more</a>
          </div>
        </section>
      </CardHeader>
      <CardBody>
        <div className={styles.cardBodyInner}  style={inlineStyles}>
          {
            rows.length ?
              rows.map(({ uuid, title, filename, url, logo, author, date }) => {
                return (
                  <a
                    key={`recent-investment-${uuid}`}
                    download={filename}
                    target='_blank'
                    href={url}
                  >
                    <section className={styles.insightsCard}>
                      <div className={styles.leftBody} title={title}>
                        {
                          logo? <DynamicImage
                            path={`managers/${logo}`}
                            isAsset={true}
                            className={styles.avatar}
                          /> : <Svg className={styles.avatar} svg={ icon }/>
                        }
                      </div>
                      <div className={styles.middleBody}>
                        <span className={styles.subtitle} style={{ marginBottom: '4px' }}>{title}</span>
                        <span className={styles.author} style={{ display: 'none' }}>{author}</span>
                      </div>
                      <div className={styles.rightBody}>
                        <span>{date}</span>
                      </div>
                    </section>
                  </a>
                )})
              : renderNoDocuments()
          }
        </div>
      </CardBody>
    </Card>
  );
}

InsightsCard.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  layoutStyle: PropTypes.string,
  inlineStyles: PropTypes.object
};

InsightsCard.defaultProps = {
  documents: [],
  inlineStyles: {}
};

export default InsightsCard;
