import React from 'react';
import PropTypes from 'prop-types';

import DynamicImage from '../image/DynamicImage';

import styles from './contactCard.local.less';

const ContactProfile = ({
  email, 
  name, 
  phone,
  picture,
  role
}) => (
  <div className={styles.contactCardBody}>
    <DynamicImage
      path={`people/${picture}`}
      alt={name}
      className={styles.avatar}
      isAsset={true}
    />
    <span className={styles.subtitle}>
      {name}
    </span>
    <span className={styles.repRole}>
      {role}
    </span>
    <a 
      className={styles.link} 
      href={`mailto:${email}`}
    >
      { email }
    </a>
    <span className={styles.repRole}>
      { phone }
    </span>
  </div>
);

ContactProfile.propTypes = {
  email: PropTypes.string.isRequired, 
  name: PropTypes.string.isRequired, 
  phone: PropTypes.string.isRequired,
  picture: PropTypes.string .isRequired,
  role: PropTypes.string.isRequired
};

export default ContactProfile;