import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, SubmissionError } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { login as loginAction } from '../../../services/auth/authService';
import LoginForm from '../loginForm/LoginForm';
import CreateSessionForm from '../createSessionForm/CreateSessionForm';
import { LoginTOSPanel, LoginTOSDeclinePanel } from '../loginForm/LoginTOSPanel';
import moment from 'moment';
import Svg from '../../../components/svg/Svg';
import Icon from '../../../assets/svg/m-large.svg';
import { ROUTES, ERRORS, ACCOUNT_STATUS, ACCESS_LEVEL, TokenStatus } from '../../../app/constants/index';
import styles from './loginPage.local.less';

import { getUserProfile } from '../../../services/auth/authHelper';
import { updateApplicationSettings as updateApplicationSettingsAction } from '../../../services/application/applicationSettingsService';
import { getErrorMessage } from '../../../services/errors/errorHelper';

import { validateInvitationToken } from '../../../services/auth/authService';
import {
  accountSetup,
  accountConfirm,
  accountSetupById
} from '../../../services/account/accountService';
import AccountPasswordSetupForm from '../accountPasswordSetupForm/AccountPasswordSetupForm';
import ContentCardView from '../../../components/contentCardView/ContentCardView';
import { SetupCompleteMessage, SimpleMessage } from '../renderers/loginPageRenderers';
import panelStyles from '../renderers/loginPageRenderers.local.less';
import '../../../assets/theme/makena.forms.less';
import helpWithSignUpPdf from '../../../assets/help_with_your_account__makena.pdf';


const INIT_STATE = {
  isSuccessfulLogin: false,
  isFirstLogin: false,
  hasDeclinedTOS: false,
  isActiveUser: false,
  isInvitedUser: false,
  isRegisteredUser: false,
  isUnknownUser: false,
  hasCompletedSetup: false,
  userEmail: null,
  account: null,
  token: null,
  toeknState: null
};

class LoginPage extends Component {
  constructor (props) {
    super(props);
    this.state = INIT_STATE;
  }

  async componentDidMount() {
    const { match: { params } = {} } = this.props;
    const { token = null } = params;

    if (!token) { return }

    try {
      const { user = null, status } = await(validateInvitationToken(token));
      this.handleConfirmValidationToken({user, status});
    }
    catch (ex) {
      console.log(ex);
    }
  }

  confirmRegisteredAccount = async (user) => {
    const { uuid, email } = user;
    const { match: { params } = {} } = this.props;
    const { token = null } = params;

    try {
      await accountConfirm(uuid, token);
      const newState = { isActiveUser: true, userEmail: email, account: user, isFirstLogin: true };
      this.setState(newState);
    }
    catch(err) {
      const defaultErrorMessage = `${ERRORS.ACCOUNT_CONFIRM_ERROR}`;
      const errorMessage = getErrorMessage(err, defaultErrorMessage);
      throw new Error(errorMessage);
    }
  }

  handleConfirmValidationToken = (account) => {
    const { err = null, status: tokenStatus, user } = account;
    const { match: { params } = {} } = this.props;
    const { token = null } = params;

    if (err || !user) { return; }
    const { email, status } = user;

    
    if (status === ACCOUNT_STATUS.ACTIVE) {
      const newState = { userEmail: email, isActiveUser: true };
      this.setState(() => newState);
      return;
    }
    
    if (tokenStatus === TokenStatus.EXPIRED) {
      this.setState(() => ({
        isInvitedUser: true,
        tokenState: tokenStatus,
        userEmail: email
      }));
      return;
    }

    // edge case - Only send activation email for users without tokens
    if (status === ACCOUNT_STATUS.REGISTERED) {
      this.confirmRegisteredAccount(user);
      return;
    }

    if (status === ACCOUNT_STATUS.PENDING) {
      this.setState(() => ({
        userEmail: email,
        isInvitedUser: true,
        account,
        token
      }));
    }
    else {
      const errorMessage = getErrorMessage(err, ERRORS.ACCOUNT_INVITE_VALIDATION_ERROR);
      throw new Error(errorMessage);
    }
  }

  handleLogin = async ({password}) => {
    const email = this.state.userEmail;

    try {
      const { login } = this.props;
      await login({ email, password }, () => {
        this.handleLoginSuccess();
      });
    } catch (error) {
      throw new SubmissionError({ _error: error.message });
    }
  };

  handleOkButtonClick = () => {
    this.setState(() => INIT_STATE);
  }


  handleBackButtonClick = () => {
    const resetState = {
      isActiveUser    : false,
      isInvitedUser   : false,
      isUnknownUser   : false,
      isRegisteredUser: false
    };

    this.setState(() => resetState);
  }


  handleValidSessionCreated = async (emailParam, account) => {
    const { status = ACCOUNT_STATUS.PENDING, email, err } = account;
    const { token } = this.state;
    let newState;

    if ((!account) || (err)) {
      newState = { isUnknownUser: true, userEmail: emailParam};
      this.setState(() => newState);
      return;
    }

    switch (status) {
      case ACCOUNT_STATUS.PENDING:
        if (token) {
          newState = { isInvitedUser: true, userEmail: email,  account };
          this.setState(() => newState);
        }
        else {
          newState = { isRegisteredUser: true, userEmail: email, hasCompletedSetup: true, account };
          this.setState(() => newState);
        }
        break;

      case ACCOUNT_STATUS.ACTIVE:
        newState = { isActiveUser: true, userEmail: email, account };
        this.setState(() => newState);
        break;

      case ACCOUNT_STATUS.REGISTERED:
        newState = { isRegisteredUser: true, userEmail: email, account};
        this.setState(() => newState);
        break;

      default:
        newState = { isUnknownUser: true, userEmail: email, account };
        this.setState(() => newState);
        break;
    }
  }


  handleAccountSetupRequest = async ({password }) => {
    const { token, isInvitedUser, isRegisteredUser, account = null, userEmail } = this.state;
    const { login } = this.props;

    if (!account) {
      const newState = { hasCompletedSetup: true};
      this.setState(newState);
      return;
    }

    if (!token && !isInvitedUser) {
      if (isRegisteredUser && (account.status != ACCOUNT_STATUS.PENDING)) {
        try {
          const newState = { hasCompletedSetup: true};
          this.setState(newState)
        }
        catch (err) {
          const defaultErrorMessage = `${ERRORS.ACCOUNT_INVITE_ERROR} ${account.id}`;
          const errorMessage = getErrorMessage(err, defaultErrorMessage);
          throw new Error(errorMessage);
        }
      }
      else {
        // status is active or pending
        const { account: { id } } = this.state;

        try {
          await accountSetupById(id, { password });
          const newState = {
            hasCompletedSetup: true
          };
          this.setState(() => newState);
        }
        catch(error) {
          throw new SubmissionError({ _error: error.message });
        }
      }
    }
    else {
      try {
        await accountSetup(token, { password });
        await login({ email: userEmail, password }, () => {
          this.handleLoginSuccess();
        });
      } catch(error) {
        throw new SubmissionError({ _error: error.message });
      }
    }
  }


  handleTOSAccept = async () => {
    const { updateApplicationSettings } = this.props;
    const currentDate = moment().format('YYYY-MM-DD');
    const updatedSettings = {
      TOSSignatureDate: currentDate,
    };

    try {
      await updateApplicationSettings(updatedSettings, () => {
        this.handleLoginFlowSuccess();
      });
    } catch (error) {
      throw new SubmissionError({ _error: error.message });
    }
  }


  handleTOSDecline = () => {
    const { logout } = this.props;

    const newState = {
      hasDeclinedTOS: true
    };

    this.setState(() => newState);
    logout();
  }

  handleResetLoginFlowClick = () => {
    this.setState(() => ({
      isSuccessfulLogin: false,
      isFirstLogin: true,
      hasDeclinedTOS: false
    }));
  }

  handleLoginFlowSuccess = () => {
    const { handleLoginSuccess } = this.props;
    const userProfile = getUserProfile();
    const { clients = [] } = userProfile;
    if (handleLoginSuccess) {
      handleLoginSuccess();
      return;
    }
    const { history, location } = this.props;
    if (clients.length && userProfile.level == ACCESS_LEVEL.FULL) {
      const locationObj = (location.state && location.state.from) ? location.state :  { from: { pathname: ROUTES.DASHBOARD.path } };
      const { from } = locationObj;
      history.push(from.pathname);
    } else {
      const investorDocs = { pathname: ROUTES.INVESTOR_DOCUMENTS.path };
      history.push(investorDocs.pathname);
    }
  };

  handleLoginSuccess = () => {
    const userProfile = getUserProfile();

    const hasAcceptedTOS = tos => !!tos;

    const userSettings = userProfile.settings;
    const newState = {
      isActiveUser: true,
      isSuccessfulLogin: true,
      isFirstLogin: !hasAcceptedTOS(userSettings.TOSSignatureDate)
    };

    this.setState(() => newState);
  };

  getActivePanel = () => {
    const {
      isSuccessfulLogin,
      isFirstLogin,
      hasDeclinedTOS,
      isActiveUser,
      isInvitedUser,
      isRegisteredUser,
      isUnknownUser,
      hasCompletedSetup,
      userEmail,
      tokenState
    } = this.state;
    const { match: { params } = {}, history } = this.props;
    const { token = null } = params;

    if (isActiveUser) {
      if (!isSuccessfulLogin) {
        return (
          <LoginForm email={userEmail} handleLogin={this.handleLogin} token={token} handleBackButtonClick={this.handleBackButtonClick} history={history} />
        );
      }

      if (isFirstLogin && !hasDeclinedTOS) {
        return (
          <LoginTOSPanel handleTOSAccept={this.handleTOSAccept} handleTOSDecline={this.handleTOSDecline} />
        );
      }

      if (hasDeclinedTOS) {
        return (
          <LoginTOSDeclinePanel handleLoginFlowResetClick={this.handleResetLoginFlowClick} />
        );
      }
    }
    else {
      if (isInvitedUser || isUnknownUser || isRegisteredUser) {

        if (hasCompletedSetup) {
          const body = {
            primaryMessage: (
              <p className={styles.createAccountEmailSentMessage}>
                Thank you. If <span>{userEmail}</span> is associated with a Makena account, we’ve emailed instructions to you so you can activate your account.
              </p>
            ),
            secondaryMessage: 'If you don’t receive the email shortly, please contact your Makena account manager.',
            handleOkClick: this.handleOkButtonClick
          };

          return (
            <ContentCardView
              headerContent="Email Sent"
              bodyContent={body}
              bodyStyle={panelStyles.loginMessagePanelBody}
              BodyRenderer={SetupCompleteMessage}
              layoutStyle={`cardMain ${styles.loginCardPanel}`}
            />
          );
        }

        if (tokenState === TokenStatus.EXPIRED) {
          let message = (<>This invitation will also remain active for 24 hours. If you don't receive that email shortly, please contact <a className={styles.link} href="mailto:websupport@makenacap.com">websupport@makenacap.com</a></>);

          const body = {
            primaryMessage: (
              <p className={styles.createAccountEmailSentMessage}>
                Welcome! For your security, the previous invitation expired. We've sent a new invitation to {userEmail}.
              </p>
            ),
            secondaryMessage: message
          };
          return (
            <ContentCardView
              headerContent="Makena Investor Site"
              bodyContent={body}
              bodyStyle={panelStyles.loginMessagePanelBody}
              BodyRenderer={SimpleMessage}
              layoutStyle={`cardMain ${styles.loginCardPanel}`}
            />
          );
        }

        return (
          <AccountPasswordSetupForm
            email={userEmail}
            handleAccountSetupRequest={this.handleAccountSetupRequest}
            handleLoginFlowResetClick={this.handleResetLoginFlowClick}
            handleBackButtonClick={this.handleBackButtonClick}
          />
        );
      }
      return (
        <CreateSessionForm handleValidationSuccess={this.handleValidSessionCreated} history={history}  />
      );
    }

    return this.handleLoginFlowSuccess();
  }

  render() {
    return (
      <div className={styles.loginContainer}>
        <div className={styles.loginFormPanel}>
          <div className={styles.loginFormContent}><Svg svg={Icon} className={styles.icon} /></div>
          <div className={styles.loginFormContent}>{ this.getActivePanel() }</div>
          <div className={styles.loginFormTrouble}><a href={helpWithSignUpPdf} target='_blank'>Having trouble with your account?</a></div>
        </div>
        <div className={styles.loginQuotePanel}>
          <div className={styles.quoteWrapper}>
            <p>
            &ldquo;Know what you own, and know why you own it.&rdquo;
              <br/><br/>
                — Peter Lynch
            </p>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  logout: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.shape({}),
  handleLoginSuccess: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  login: PropTypes.func,
  updateApplicationSettings: PropTypes.func
};

export default compose(
  connect(
    null,
    {
      login: loginAction,
      updateApplicationSettings: updateApplicationSettingsAction
    }
  ),
  reduxForm({ form: 'loginPage' }),
)(LoginPage);

