import React from 'react';
import PropTypes from 'prop-types';

import styles from './badge.local.less';

const Badge = ({content, value=null, type, onClick}) => {
  const handleOnClick = () => {
    if (onClick) onClick(content, value);
  }

  return (
    <div className={`${styles.badge} ${type ? styles[type] : ''}`} onClick={()=> handleOnClick()}>
      <div>{content}</div>
      <div className={styles.icon}>x</div>
    </div>
  );
};


Badge.propTypes = {
  content: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
}

export default Badge;