import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import _ from 'lodash';
import get from 'lodash/get';
import { rounder, format } from '../../helpers/formatters/formatters';
import { getSettings } from '../../services/auth/authHelper';
import SectionLink from '../sectionLink/SectionLink';

import styles from './fundClosedEndPerformanceTable.local.less';

const columns = [
  'Fund', 
  'Vintage Year',
  'Fund Size ($M)',
  'IRR',
  'Total MOC',
  'Realized MOC'
];

const columnsDict = {
  'Vintage Year': 'MCM Vintage Year',
  'IRR': 'Adjusted IRR (Since Inception, USD)',
  'Total MOC' : 'MCM MOC',
  'Realized MOC': 'MCM DPI',
};

const getColumnValue = (value, field) => value[columnsDict[field]];

const noFractionDigits = { 
  minimumFractionDigits: 0, 
  maximumFractionDigits: 0
};

const oneFractionDigits = { 
  minimumFractionDigits: 1, 
  maximumFractionDigits: 1
};

class FundClosedEndPerformanceTable extends Component {

  state = {
    isLoading: true,
    lastQuarter: null
  };

  componentDidMount() {
    this.startLoading();
    const lastQuarter = getSettings().reportingQuarterEnd;

    this.finishLoading(lastQuarter);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (lastQuarter) => {
    this.setState({
      lastQuarter: lastQuarter,
      isLoading: false,
    });
  };

  parseFundSize = value => `$${format(noFractionDigits, value / 1000000)}`;
  
  parseRatio = value => `${rounder(oneFractionDigits, value, 1)}x`;

  parsePercentage = value => `${rounder(oneFractionDigits, value, 1)}%`;

  transform = value => {
    const { fund: { size, inceptionDate } } = this.props;
    const year = moment.utc(inceptionDate).format('YYYY');
    const irr = getColumnValue(value, 'IRR');
    const totalMOC = getColumnValue(value, 'Total MOC');
    const realizedMOC = getColumnValue(value, 'Realized MOC');
    return {
      'Fund': `${value.name} (Net)`,
      'Vintage Year': year? year : ' - ',
      'Fund Size ($M)': size? this.parseFundSize(size) : ' - ',
      'IRR': irr? this.parsePercentage(irr * 100) : ' - ',
      'Total MOC': totalMOC? this.parseRatio(totalMOC) : ' - ',
      'Realized MOC': realizedMOC? this.parseRatio(realizedMOC) : ' - '
    }
  }
    
  getTotal = () => {
    const { overview } = this.props;
    let value = get(overview, 'total.children.data.values', {});
    value.name = get(overview, 'total.children.data.name', '');
    return this.transform(value);
  }
  
  render () {
    const firstColumn = _.first(columns);
    const dataColumns = _.take(_.tail(columns), columns.length - 1);
    const total = this.getTotal();
    
    if (this.state.isLoading) return null;

    return (
      <div className='table'>
        <div className={cx('tableRow tableRowHeader', styles.assetRow)}>
          {
            columns.map((column, index) => (
              <div 
                key={index} 
                className='tableRowCell'
              >
                {column}
              </div>
            ))
          }
        </div>
        <SectionLink className={cx('tableRow', styles.assetRow, 'total')}>
          <div className='tableRowCell bold big first'>
            {total[firstColumn]}
          </div>
          {
            dataColumns.map((column, index) => (
              <div 
                key={index}
                className='tableRowCell bold big' 
              >
                {total[column]}
              </div>
            ))
          }
        </SectionLink>
      </div>
    )
  }
}

FundClosedEndPerformanceTable.propTypes = {
  fund: PropTypes.object,
  overview: PropTypes.object
};

export default FundClosedEndPerformanceTable;