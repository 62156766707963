import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Card from '../card/Card';
import CardHeader from '../card/CardHeader';
import CardBody from '../card/CardBody';

import FundClosedEndPerformanceTable from './FundClosedEndPerformanceTable';

const FundClosedEndPerformanceCard = ({
  overview,
  fund,
  layoutStyle,
  title,
}) => (
  <Card
    layoutStyle={layoutStyle}
    trackEventLabel={title}
    trackEvenetCategory='Fund'
  >
    <CardHeader>
      {title}
    </CardHeader>
    <CardBody>
      {
        overview?
          <FundClosedEndPerformanceTable fund={fund} overview={overview} />
          :
          <Table.Row>
            <Table.Cell>No Info</Table.Cell>
          </Table.Row>
      }
    </CardBody>
  </Card>
);


FundClosedEndPerformanceCard.propTypes = {
  fund: PropTypes.object,
  overview: PropTypes.object,
  layoutStyle: PropTypes.string,
  title: PropTypes.string
}

FundClosedEndPerformanceCard.defaultProps = {
  layoutStyle: '',
  title: 'Fund Summary',
}

export default FundClosedEndPerformanceCard;


