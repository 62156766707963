import _ from 'lodash';
import { ACTIONS } from '../../app/constants/index';

export default function (state = {}, action = { account: {}, accountList: [], type: '' }) {
  switch (action.type) {
    case ACTIONS.ACCOUNT_CREATE_SUCCESS:
    case ACTIONS.ACCOUNT_UPDATE_SUCCESS: {
      return {
        ...state,
        [action.account.uuid]: action.account,
      };
    }

    case ACTIONS.ACCOUNTS_FETCH_SUCCESS: {
      return _.mapKeys(action.accountList, 'uuid');
    }

    case ACTIONS.ACCOUNT_DELETE_SUCCESS: {
      return _.omit(state, action.accountId);
    }

    default:
      return state;
  }
}
