import getApiRootUrl        from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper       from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';
import { getErrorMessage }  from '../errors/errorHelper';

// eslint-disable-next-line import/prefer-default-export
export const makenaManagersFetch = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.MAKENA_MANAGERS_FETCH}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.MAKENA_FUNDS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const isMakenaManagerVisited = async title => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.MAKENA_MANAGERS_VISITED_FETCH(title)}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.MAKENA_FUNDS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const visitMakenaManager = async managerTitle => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.MAKENA_MANAGERS_VISITED_FETCH_ALL}`;
    const response = await requestWrapper.post(url, { managerTitle });
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.MAKENA_FUNDS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};


export const getDatatableMakenaManagers = async (payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_MANAGER_FETCH_DATATABLE}`;
    const data = (await requestWrapper.post(url, payload)).data.data;
    return data;

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getMakenaManagersCountByField = async (field, payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_MANAGER_COUNT_FETCH_BY_FIELD(field)}`;
    const data = (await requestWrapper.post(url, payload)).data.data;
    return data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};