
import React from 'react';
import PropTypes from 'prop-types';
import ManagerGallery from '../managerGallery/ManagerGallery';
import Section from '../section/Section';
import styles from './exploreManagerSlider.local.less';

const ExploreManagerSlider = ({ recentInvestments, managersWithFunds }) => (
  <Section
    className={styles.managerSliderContainer}
    isFullWidthLayout
    withArrows
    paddingSize='none'
  >
    <ManagerGallery 
      recentInvestments={ recentInvestments } 
      managersWithFunds={ managersWithFunds }
    />
  </Section> 
);

ExploreManagerSlider.propTypes = {
  recentInvestments: PropTypes.arrayOf(PropTypes.object),
  managersWithFunds: PropTypes.arrayOf(PropTypes.object)
}

export default ExploreManagerSlider;