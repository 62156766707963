import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';

import CardBody                   from '../../components/card/CardBody';
import CardHeader                 from '../../components/card/CardHeader';
import CardPanel                  from '../../components/cardPanel/CardPanel';
import ExposuresCard              from '../../components/exposuresCard/ExposuresCard';
import Footer                     from '../../components/footer/Footer';
import InsightsCard               from '../../components/insightsCard/InsightsCard';
import InvestorDocumentsCard      from '../../components/investorDocumentsCard/InvestorDocumentsCard';
import NewsGallery                from '../../components/newsGallery/NewsGallery';
import PieChart                   from '../../components/pieChart/PieChartCustom';
import Portfolio                  from '../../components/portfolio/Portfolio';
import RecentInvestmentsCard      from '../../components/recentInvestmentsCard/RecentInvestmentsCard';
import WelcomeCard                from '../../components/welcomeCard/WelcomeCard';
import PerformanceClientChartCard from '../../components/performanceClientChartCard/PerformanceClientChartCard';

const NEWS = [
  {
    id: 1,
    title: 'Baupost Group Holdings Letter',
    image: 'blue_tbg.jpg',
  },
  {
    id: 2,
    title: 'KPCB State of the Internet',
    image: 'blue_internet.jpg',
  },
  {
    id: 3,
    title: 'Shifting Real Estate Investments',
    image: 'blue_reit.jpg',
  },
  {
    id: 4,
    title: 'Makena 2017 Q3 letter',
    image: '',
  },
];


class UserDashboard extends Component {
  state = {
    isLoading: false
  }

  async componentDidMount() {
    const { lazySetRightRailCardHeight } = this;
    window.addEventListener('scroll', this.toggleAnchorNavVisibility);
    const mutationObserver = new MutationObserver(lazySetRightRailCardHeight);
    await this.setState({mutationObserver});

    setTimeout(() => {
      const observedNode = document.querySelector('.jsWelcomeCard');
      lazySetRightRailCardHeight();
      window.addEventListener('resize', lazySetRightRailCardHeight);
      if(observedNode) {
        mutationObserver.observe(
          observedNode, 
          { childList: true, subtree: true }
        );
      }
    }, 400);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleAnchorNavVisibility);
    window.removeEventListener('resize', this.lazySetRightRailCardHeight);
  }

  lazySetRightRailCardHeight = () => {
    const welcomeCard = document.querySelector('.jsWelcomeCard');
    const performanceCard = document.querySelector('.jsPerformanceChartCard');
    const portfolioCard = document.querySelector('.jsPortfolioCard');
    const cardHeight = welcomeCard.getBoundingClientRect().height;
    const performanceHeight = performanceCard.getBoundingClientRect().height;
    const portfolioHeight = portfolioCard.getBoundingClientRect().height;
    this.setState(() => ({
      welcomeCardHeight: cardHeight,
      performanceCardHeight: performanceHeight,
      portfolioCardHeight: portfolioHeight,
      isWelcomeCardRendered: true
    }));
  };

  toggleAnchorNavVisibility = () => {
    const { isAnchorNavVisible } = this.state;

    if (window.pageYOffset > 40 && !isAnchorNavVisible) {
      this.setState({
        isAnchorNavVisible: true,
      });
    }

    if (window.pageYOffset === 0) {
      this.setState({
        isAnchorNavVisible: false,
      });
    }
  };

  renderExposures = charts => charts.map((
    {
      id,
      data,
      title
    }) => (
    <CardPanel 
      key={id} 
      panelStyle="cardLightGrey cardPanel"
    >
      <PieChart 
        data={data}
        products={this.props.funds}
        title={title} 
      />
    </CardPanel>
  ));

  renderNewsCard = openModal => (
    <CardBody>
      <NewsGallery
        news={NEWS}
        onClickHandler={openModal}
      />
    </CardBody>
  );

  renderInsightsCard = insightsDocuments => {
    if (!this.state.isWelcomeCardRendered) {
      return <div className='cardSidebar' />;
    }

    const style = {
      height: `${this.state.welcomeCardHeight - 85}px`,
      overflowY: 'scroll'
    };

    return (
      <InsightsCard
        layoutStyle='cardSidebar' inlineStyles={style}
        documents={insightsDocuments}
      />
    );
  };

  renderRecentInvestments = () => {
    if (!this.state.portfolioCardHeight) {
      return (<div className='cardSidebar'></div>);
    }
    const { recentInvestments } = this.props;
    const style = {
      height: `${this.state.portfolioCardHeight - 85}px`,
      overflowY: 'scroll'
    };
    return (
      <RecentInvestmentsCard
        inlineStyles={style}
        layoutStyle='cardSidebar' 
        recentInvestments={recentInvestments}
      />
    );
  };

  renderInvestorDocumentsCard = investorDocuments => {
    if (!this.state.isWelcomeCardRendered) {
      return (<div className='cardSidebar'></div>);
    }
    const style = {
      height: `${this.state.performanceCardHeight - 85}px`,
      overflowY: 'scroll'
    };
    
    return (
      <InvestorDocumentsCard
        layoutStyle='cardSidebar' inlineStyles={style}
        documents={investorDocuments.data}
      />
    );
  };

  unclickableFunds = () => {
    const { 
      overview,
      funds
    } = this.props;
    const data = _.get(overview, 'total.children.data', []);
    const entityIds = funds.map(({ entityId }) => entityId);
    return _.keys(_.omit(data, entityIds));
  }
  
  render() {
    const {
      asOfDate,
      makenaClient,
      assetClassUpdates = [],
      insightDocuments = [],
      investorDocuments = {},
      overview = {},
      growthOfADollar = {},
      exposures = {},
      funds = []
    } = this.props;
    
    const insightsCard = this.renderInsightsCard(insightDocuments);

    const investorDocumentsCard = this.renderInvestorDocumentsCard(investorDocuments);
    
    const exposureItems = this.renderExposures(exposures);
    
    const assetClassUpdate = assetClassUpdates.find(({assetClassTitle}) => assetClassTitle === 'Welcome');
    
    return (
      <div className="dashboardTopPadding">
        <div className='contentRowSplitTwoThirds'>
          <div>
            <WelcomeCard 
              contentStyle='cardLightGrey'
              layoutStyle='cardMain jsWelcomeCard' 
              makenaClient={makenaClient} 
              makenaAssetClassUpdate={assetClassUpdate} 
            />
          </div>
          {insightsCard}
        </div>
        <div className='contentRowSplitTwoThirds'>
          <div>
            <Portfolio 
              layoutStyle='cardMain jsPortfolioCard' 
              unclickableFunds={this.unclickableFunds()}
              funds={funds}
              overview={overview} 
              quarter={asOfDate}
            />
          </div>
          {this.renderRecentInvestments()}
        </div>
        <div className='contentRowSplitTwoThirds'>
          <div>
            <PerformanceClientChartCard
              ignoreBenchmarks={true}
              labels={{'Your Portfolio': 'Your Portfolio'}}
              layoutStyle='cardMain jsPerformanceChartCard'
              performance={growthOfADollar}
              subTitle='Trailing 5 Year'
              title='Growth of a Dollar'
            />
          </div>
          {investorDocumentsCard}
        </div>
        <div className='contentRowSplitTwoThirds'>
          <ExposuresCard 
            layoutStyle='cardContentSplitTwo'
            trackEventLabel='Your Exposures'
          >
            <CardHeader>
              Your Exposures
            </CardHeader>
            <div className='cardContentPanels'>
              {exposureItems}
            </div>
          </ExposuresCard>
        </div>
        <Footer/>
      </div>
    );
  }
}

UserDashboard.propTypes = {
  asOfDate: PropTypes.string,
  makenaClient: PropTypes.object,
  assetClassUpdates: PropTypes.array,
  recentInvestments: PropTypes.array,
  funds: PropTypes.array,
  insightDocuments: PropTypes.array,
  investorDocuments: PropTypes.object,
  overview: PropTypes.object,
  growthOfADollar: PropTypes.object,
  exposures: PropTypes.array,
};


export default UserDashboard;
