export const TAC_TITLE = 'Terms And Conditions';

export const TAC_SECTIONS = [
  "Terms and Conditions",
  "Jurisdictional Issues",
  "Investment Risk",
  "Restrictions on use of materials",
  "Trademarks and copyrights",
  "Login ID and passwords",
  "Links to other websites",
  "Termination",
  "Disclaimers",
  "Limitation of Liability",
  "Indemnification",
  "Governing Law and Consent to Jurisdiction",
  "Waiver",
  "Integration and severability",
  "Effect on Other Agreements",
  "For Information Purposes Only"
];

export const TAC_CONTENT = {
  "Terms and Conditions": [
    "The terms and conditions of use set forth herein apply to each of the pages of this website (collectively, the “Site”). Please read these terms and conditions of use carefully before using this Site.",
    "By using this Site, you signify your agreement with the following terms and conditions of use without limitation or qualification. Makena Capital Management, LLC, its subsidiaries and affiliates (collectively referred to herein as \"Makena\") may at any time revise these conditions by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the then-current terms and conditions of use to which you are bound."
  ],
  "Jurisdictional Issues": [
    "Unless otherwise specified, the materials in or accessible through the Site are directed at residents of the United States, its territories, possessions, and protectorates. The Site is controlled and operated by Makena from its offices within the State of California, United States of America. Makena makes no representation that material in or accessible through the Site is appropriate or available for use in other locations or that access to them where their content is located is not illegal and prohibited. Those who choose to access the Site from other locations do so on their own initiative and are responsible for establishing the legality, usability and correctness of any information or materials under any or all jurisdictions and the compliance of that information or material with local laws, if and to the extent local laws are applicable. You may not use or export the information or material in violation of U.S. export laws and regulations. The information provided in or accessible through the Site is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject Makena to any registration or other requirement within such jurisdiction or country."
  ],
  "Investment Risk": [
    "Makena makes no representation, and it should not be assumed, that past investment performance is an indication of future results. Moreover, wherever there is the potential for profit there is also the possibility of loss. Certain of Makena’s strategies involve investments that are illiquid, are subject to a substantial risk of loss and are not suitable for many investors."
  ],
  "Restrictions on use of materials": [
    "Except as otherwise permitted by Makena, no materials from this Site or any website owned, operated, licensed or controlled by Makena may be copied, reproduced, republished, uploaded, posted, transmitted or distributed in any way. You may download material displayed on this website for non-commercial, personal use only, provided you also retain all copyright and other proprietary notices contained on the materials. You may not distribute, modify, transmit, reuse, report or use the content of this Site for public or commercial purposes, including the text, images, audio and video, without Makena’s prior written permission."
  ],
  "Trademarks and copyrights": [
    "This Site and all its content including, all trademarks, service marks, trade names, logos, and icons are proprietary to Makena. Nothing contained on the Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark displayed on this Site without the prior written permission of Makena or such third party that may own the trademarks displayed on this Site. Your use of the trademarks displayed on this Site, or any other content on this Site, except as provided herein, is strictly prohibited.",
    "Inquiries concerning use of Makena’s trademarks, service marks, trade names, logos, icons, copyrights or other intellectual property should be addressed to Makena Capital Management, LLC, Attn. Legal Department, 2755 Sand Hill Road, Suite 200, Menlo Park, CA 94025.",
    "Images displayed on this Site are either the property of, or used with permission by, Makena. The use of these images by you, or anyone else authorized by you, is prohibited unless specifically permitted herein. Any unauthorized use of the images may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes."
  ],
  "Login ID and passwords": [
    "Access to specific areas of this Site requires the usage of a Login ID and associated password. If you have a Login ID and password, you are responsible for the activities which occur in connection with their usage and as such should take steps to protect them from misuse. If you become aware of any unauthorized use of, loss or theft of your Login ID and password, immediately inform a representative of Makena."
  ],
  "Links to other websites": [
    "This Site may contain links to other websites. Makena does not review or monitor any websites linked from or to the Site and will not be responsible for the content of any such linked website. If you decide to access the linked website, you do so at your own risk. Makena is not responsible for the information, materials or services obtained on or from such other websites and will not be liable in any respect for any damages arising from your access of such websites. Any such links are provided merely for the convenience of the users of the Site and the inclusion of the links does not imply any endorsement, representation or warranty by Makena with respect to any such website or the content or services contained through such websites. Furthermore, you agree not to link any websites to this Site without the express prior written consent of Makena.",
    "Images displayed on this Site are either the property of, or used with permission by, Makena. The use of these images by you, or anyone else authorized by you, is prohibited unless specifically permitted herein. Any unauthorized use of the images may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes."
  ],
  "Termination": [
    "These terms and conditions of use are effective until terminated by Makena, provided that you may terminate the terms and conditions of use at any time by destroying all information and materials obtained by you or on your behalf from the Site and all related documentation, copies, and installations whether made under these terms or otherwise. Such termination by you will not be effective if Makena, in its sole discretion and without notice to you, determines that you failed to comply with any term or condition stated herein. Upon termination of these terms you must destroy all information and materials obtained by you or on your behalf from the Site and all associated documentation, copies, and installation whether made under these terms or otherwise."
  ],
  "Disclaimers": [
    "Makena makes no warranties or representations as to the accuracy of this Site. Under no circumstances shall Makena or any party involved in creating, producing, or delivering the Site be liable for any direct, incidental, consequential, indirect, or punitive damages that result from the use of, or the inability to use, the materials on this Site, even if a Makena authorized representative has been advised of the possibility of such damages. Applicable law may not allow the limitation or exclusion of liability or incidental or consequential damages, so the above limitation or exclusion may not apply to you.",
    "Makena also assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property on account of your access to, use of, or browsing in this Site or your downloading of any materials, data, text, images, video, or audio from this Site.",
    "WITHOUT LIMITING THE FOREGOING OR ANYTHING ELSE SET FORTH HEREIN, THE SITE AND ALL OF ITS CONTENTS ARE MADE AVAILABLE BY MAKENA \"AS IS,\" WITH ALL FAULTS, AND WITHOUT ANY WARRANTIES WHATSOEVER, EXPRESS OR IMPLIED. ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, UNINTERRUPTED USE, QUIET ENJOYMENT, SYSTEM INTEGRATION, OR ACCURACY, SECURITY OF INFORMATION, OR RELIABILITY, ARE SPECIFICALLY EXCLUDED AND DISCLAIMED. MAKENA MAKES NO WARRANTY THAT THE SITE, IN WHOLE OR IN PART, WILL MEET USERS’ REQUIREMENTS. USERS OF THE SITE ASSUME ALL RISK AS TO THE QUALITY, ACCURACY, SUITABILITY, AVAILABILITY AND PERFORMANCE OF THE SITE AND ITS CONTENTS AND ACKNOWLEDGE THAT THE SITE AND ITS CONTENTS MAY BE REVISED, ALTERED AND CHANGED FROM TIME TO TIME IN THE SOLE DISCRETION OF MAKENA. MAKENA DOES NOT REPRESENT OR WARRANT THAT ANY SITE CONTENT WILL OPERATE CONTINUOUSLY OR WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR ANY SITE REFERRED OR LINKED TO HEREIN IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS."
  ],
  "Limitation of Liability": [
    "MAKENA ASSUMES NO LIABILITY FOR LOSS OR DAMAGE RESULTING FROM ANY USE OF OR RELIANCE UPON THE SITE OR ITS CONTENTS. UNDER NO CIRCUMSTANCES WILL MAKENA NOR ANY OF ITS RESPECTIVE DIRECTORS, OFFICERS, MEMBERS, PARTNERS, PRINCIPALS, EMPLOYEES, AGENTS, OR REPRESENTATIVES (COLLECTIVELY, “ASSOCIATES”), NOR ANY OF ITS RESPECTIVE THIRD-PARTY CONTENT PROVIDERS, DESIGNERS, CONTRACTORS, SPONSORS, LICENSORS OR THE LIKE BE LIABLE FOR ANY LOST PROFITS, LOST SAVINGS, LOST DATA, BUSINESS INTERRUPTION, OR OTHER DIRECT, INDIRECT, EXEMPLARY, PUNITIVE, SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR CLAIMS (WHETHER IN CONTRACT, IN TORT, OR OTHERWISE) ARISING OUT OF OR RELATING TO THE SITE, ITS CONTENTS, THE USE THEREOF, OR THE INABILITY TO USE THE SITE, EVEN IF MAKENA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE."
  ],
  "Indemnification": [
    "You agree to defend, indemnify and hold Makena and its Associates harmless from any and all claims, liabilities, costs and expenses, including, without limitation, reasonable attorneys' fees, arising in any way from (i) your use of the Site, in whole or in part, or (ii) your breach or violation of law or any provisions contained herein. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with any such defense."
  ],
  "Governing Law and Consent to Jurisdiction": [
    "These terms and conditions of use shall be governed by and construed in accordance with the laws of the state of [California/Delaware/New York] applicable to agreements made and to be performed wholly within such state. You agree that any dispute or action at law or inequity arising out of or relating to these terms and conditions of use or your use of the Site shall be commenced only in the state or federal courts located in [_________] and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of any such dispute or action."
  ],
  "Waiver": [
    "No waiver by Makena of any right under or term or provision of these terms and conditions of use will be deemed a waiver of any other right, term or provision of these terms and conditions of use at that time or a waiver of that or any other right, term or provision of these terms and conditions of use at any other time."
  ],
  "Integration and severability": [
    "If any provision of these terms and conditions of use is found to be invalid, unlawful, void or for any reason unenforceable then that provision shall be deemed severable from these terms and conditions of use and shall not affect the validity and enforceability of any remaining provisions. These terms and conditions of use represent and constitute the entire agreement between Makena and you relating to the subject matter discussed herein."
  ],
  "Effect on Other Agreements": [
    "Nothing contained in these Terms and Conditions of Use is intended to modify or amend any other written agreement you may have with Makena (including without limitation any customer agreement, participation agreement or account agreement) (collectively, “Other Agreements”), if any, that may currently be in effect. In the event of any inconsistency between these Terms and Conditions and any Other Agreement, the Other Agreement will govern. Some pages within the Site contain supplemental terms and conditions and additional disclosure and disclaimers, which are in addition to these Terms and Conditions. In the event of a conflict, such supplemental terms and conditions and additional disclosures and disclaimers will govern for those sections or pages."
  ],
  "For Information Purposes Only": [
    "The information on this Site is provided for informational purposes only. This Site and the information contained herein do not constitute and should not be construed as an offer to sell or a solicitation of any offer to buy the securities or services of Makena or any other issuer. Offers can only be made where lawful under, and in compliance with, applicable law. Responses to any inquiry that may involve the rendering of personalized investment advice or effecting or attempting to effect transactions in securities will not be made absent compliance with applicable laws or regulations (including broker dealer, investment adviser or applicable agent or representative registration requirements), or applicable exemptions or exclusions therefrom.",
    "Makena makes no representations that transactions, products or services discussed on the Site are available or appropriate for sale or use in all jurisdictions or by all investors. Those who access the Site do so at their own initiative and are responsible for compliance with local laws or regulations.",
    "Makena is not utilizing the Site to provide investment or other advice, and no information or material at the Site is to be deemed a recommendation to buy or sell any securities or is to be relied upon for the purpose of making or communicating investment or other decisions. In addition, no information, content or other materials contained on the Site should be construed or relied upon as investment, legal, accounting, tax or other professional advice or in connection with any offer or sale of securities. Any transactions listed on the Site are included as representative transactions and are not necessarily reflective of overall performance.",
    "Makena does not advise on the tax consequences of any investment."
  ]
};