import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from '../constants/index';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (rest.isLoggedIn ? (
      <Component {...props} {...rest} />
    ) : (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN.path,
          state: { from: props.location },
        }}
      />
    ))
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.component,
  location: PropTypes.object
}


export default PrivateRoute;
