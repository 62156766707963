import { ACTIONS } from '../../app/constants/index';

const INITIAL_STATE = {
  id: undefined,
  email: undefined,
  name: undefined,
  surname: undefined,
  isLoggedIn: false,
  isAdmin: false,
  clients: []
};

export default function (state = INITIAL_STATE, action = { userProfile: {}, type: '' }) {
  switch (action.type) {
    case ACTIONS.AUTH_LOGIN_SUCCESS: 
    case ACTIONS.ADMIN_IMPERSONATE_USER_SUCCESS: 
    case ACTIONS.AUTH_UPDATE_USER_PROFILE:
      return { ...state, ...action.userProfile, isLoggedIn: true };
    default:
      return state;
  }
}
