import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './performanceClientChartCardTooltip.local.less';
import { getSettings } from '../../services/auth/authHelper';

const PerformanceClientChartCardTooltip = (props) => {
  const { label } = props;
  const date = label? moment(label) : moment(getSettings().reportingQuarterEnd);
  const newLabel = date.format('[Q]Q \'YY');
  return (
    <div className={styles.performanceTooltip}>
      <div className={styles.tooltipContent}>
        {newLabel}
      </div>
    </div>
  )
}

PerformanceClientChartCardTooltip.propTypes = {
  label: PropTypes.number
}

export default PerformanceClientChartCardTooltip;