import React from 'react';
import PropTypes from 'prop-types';

const CardPanel = ({ children, panelStyle }) => (
  <div className={panelStyle}> 
    {children}
  </div>
);

CardPanel.propTypes = {
  panelStyle: PropTypes.string,
}

export default CardPanel;
