import React        from 'react';
import PropTypes    from 'prop-types';
import { NavLink }  from 'react-router-dom';
import styles       from './sidebar.local.less';

const SideBarManager = props => {
  const {
    manager,
    assetClass,
    fund,
    activeLevels
  } = props;
  const { entityId } = fund;
  const { title } = assetClass;
  const {
    activeAssetClassName,
    activeManagerTitle,
    activeProductId
  } = activeLevels
  const isActive = entityId === activeProductId && title === activeAssetClassName && manager === activeManagerTitle; 
  return <div className={`${styles.managers} ${isActive? styles.isActive :''}`}>
    { 
      <NavLink
        key={manager}
        to={`/dashboard/${entityId}/${encodeURIComponent(title)}/${encodeURIComponent(manager)}`}
      >
        {manager}
      </NavLink>
    }
  </div>;
};

SideBarManager.propTypes = {
  activeLevels: PropTypes.object,
  manager: PropTypes.string,
  fund: PropTypes.object,
  assetClass: PropTypes.object
};

SideBarManager.defaultProps = {
  activeLevels: {},
  manager: '',
  fund: {},
  assetClass: {}
};

export default SideBarManager;
