import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import Svg                  from '../svg/Svg';
import styles               from './datatable.local.less';
import LeftChevronBlue      from '../../assets/svg/icon-arrow-left-blue.svg';
import LeftChevronWhite     from '../../assets/svg/icon-arrow-left-white.svg';
import RightChevronBlue     from '../../assets/svg/icon-arrow-right-blue.svg';
import RightChevronWhite    from '../../assets/svg/icon-arrow-right-white.svg';

const formatSettings = {
  minimumFractionDigits: 0, 
  maximumFractionDigits: 0
};
const formatter = new Intl.NumberFormat('en-US', formatSettings)

class DatatableSmallPaginator extends Component {

  onPageChange = number => {
    const { pageSize, onPageChange = () => {}} = this.props;
    onPageChange({ page: number, offset: (number - 1) * pageSize});
  }

  render(){ 
    const { currentPage, totalPages, pageSize, total, filtered, entityName } = this.props;
    const offset = pageSize * (currentPage-1);
    const offsetPageSize = offset + pageSize;
    const totalItems = total === filtered? total : filtered;
    const limit = offsetPageSize > totalItems? totalItems : offsetPageSize;
    const showDocuments = !isNaN(limit) && !isNaN(offset)? `${formatter.format(offset + 1)}-${formatter.format(limit)}` : '0';
    return (
      <div className={ `${styles.paginator} ${styles.small} statusBarItem`}>
        {`${showDocuments} of ${formatter.format(totalItems)} ${entityName}`}
        <div className={`${styles.control} ${currentPage !== 1? styles.enabled : ''}`}
          onClick={currentPage <=1? () => {} : () => this.onPageChange(currentPage - 1)}>
          <Svg svg={currentPage === 1? LeftChevronWhite : LeftChevronBlue}></Svg>
        </div>
        <div className={`${styles.page} ${styles.nonPage}`}>{ `${formatter.format(currentPage)} of ${formatter.format(totalPages)}` }</div>
        <div className={`${styles.control} ${currentPage !== totalPages? styles.enabled : ''}`}
          onClick={currentPage >= totalPages? () => {} : () => this.onPageChange(currentPage + 1)}>
          <Svg svg={currentPage === totalPages? RightChevronWhite : RightChevronBlue}></Svg>
        </div>
      </div>
    );
  
  }
}

DatatableSmallPaginator.propTypes = {
  onPageChange: PropTypes.func,
  entityName: PropTypes.string,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  filtered: PropTypes.number,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number
}

export default DatatableSmallPaginator;