import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';
import { applicationSettingsUpdateSuccess } from './applicationSettingsAction';
import {
  getUserProfile,
  setUserProfile,
} from '../auth/authHelper';

import { getErrorMessage } from '../errors/errorHelper';

export const updateApplicationSettings = (appSettings, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.APPLICATION_SETTINGS_UPDATE}`;

    const newSettings = {
      json: appSettings
    };
    const response  = await requestWrapper.put(url, newSettings);
    const settings  = response.data.data;
    setUserProfile({...getUserProfile(), settings});

    dispatch(applicationSettingsUpdateSuccess(settings));
    if (onSuccess) onSuccess();
  } catch (err) {
    const defaultErrorMessage = `${ERRORS.APPLICATION_SETTINGS_UPDATE_ERROR}`;
    const errorMessage = getErrorMessage(err, defaultErrorMessage);
    throw new Error(errorMessage);
  }
};
