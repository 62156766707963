/* eslint-disable */
import numeral from 'numeral';

// load a locale. Docs: https://github.com/adamwdraper/Numeral-js/blob/master/locales/en-gb.js
numeral.register('locale', 'us', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: '',
    million: '',
    billion: '',
    trillion: '',
  },
  ordinal(number) {
    const b = number % 10;
    return (~~(number % 100 / 10) === 1) ? 'TH'
      : (b === 1) ? 'ST'
        : (b === 2) ? 'ND'
          : (b === 3) ? 'RD' : 'TH';
  },
  currency: {
    symbol: '$',
  },
});

numeral.locale('us');
