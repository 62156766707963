import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import cx                    from 'classnames';
import moment                from 'moment';
import { NavLink }           from 'react-router-dom';
import { pick, tail, take }  from 'lodash';
import { rounder }           from '../../helpers/formatters/formatters';
import { getSettings }       from '../../services/auth/authHelper';
import SectionLink           from '../sectionLink/SectionLink';
import Svg                   from '../svg/Svg';

import { ROUTES } from '../../app/constants/index';

import styles from './fundOpenEndPerformanceTable.local.less';
import More from '../../assets/svg/icon-arrow-right-blue.svg';
import { shouldDashBenchmark } from '../../helpers/portfolioHelpers/shouldDashBenchmark';

const columns = [
  'Asset Class', 
  'Allocation',
  'QTD',
  '1 Year',
  '3 Year Ann.',
  '5 Year Ann.',
  ''
];

const parseColumnField = {
  'Allocation': 'Adjusted % Of Portfolio (By Grouping, Visible)',
  'QTD': 'Adjusted TWR (Current Quarter, USD)',
  '1 Year': '1 Yr. Adjusted TWR (USD)',
  '3 Year Ann.' : '3 Yr. Annualized Adjusted TWR (USD)',
  '5 Year Ann.': '5 Yr. Annualized Adjusted TWR (USD)',
}

const formatSettings = { 
  minimumFractionDigits: 1, 
  maximumFractionDigits: 1 
};

const acceptAllocation = row => row['Allocation'] !== ' - ' && row['Allocation'] !== '0.0%';

class FundOpenEndPerformanceTable extends Component {

  state = {
    isLoading: true,
    lastQuarter: null
  };

  componentDidMount() {
    this.startLoading();
    const lastQuarter = getSettings().reportingQuarterEnd;

    this.finishLoading(lastQuarter);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (lastQuarter) => {
    this.setState({
      lastQuarter: lastQuarter,
      isLoading: false,
    });
  };

  parseYear = (item, field, years) => {
    const {
      getValue,
      props: { fund: { inceptionDate } },
      state: { lastQuarter }
    } = this;

    const parsedValue     = getValue(item, field),
      _lastQuarter    = moment.utc(lastQuarter, 'YYYY-MM-DD'),
      _inceptionDate  = moment.utc(inceptionDate, 'MM/DD/YYYY');
          
    switch(years) {
      case 1: return _lastQuarter.diff(_inceptionDate, 'days') >= 363 ? parsedValue : ' - ';
      case 3: 
      case 5: return _lastQuarter.diff(_inceptionDate, 'years') >= years ? parsedValue : ' - ';
      default: return ' - ';
    }
  }

  getValue = (item, field) => {
    let value = item[parseColumnField[field]] * 100;
    if (!value) return ' - ';
    value = rounder(formatSettings, value, 1);
    return parseFloat(value)? `${value}%` : ' - ';
  };

  getPath = ({ entityId, name }) => {
    const { assetClasses = [] } = this.props;
    const { title } = assetClasses.find(({ displayName }) => displayName === name) || { title: name }; 
    return ROUTES.DASHBOARD.children.MAKENA_ASSET.path
      .replace(':productId', entityId)
      .replace(':assetClassName', encodeURIComponent(title));
  }

  transform = ({ name, values }) => {
    const { getValue, parseYear } = this;

    return {
      'Asset Class': name,
      'Allocation': getValue(values, 'Allocation'),
      'QTD': getValue(values, 'QTD'),
      '1 Year': parseYear(values, '1 Year', 1),
      '3 Year Ann.': parseYear(values, '3 Year Ann.', 3),
      '5 Year Ann.': parseYear(values, '5 Year Ann.', 5)
    }
  }

  pickValues = data => {
    const { props: { fund: { entityId } }, transform } = this;
    const rows = data.map(({ name, values }) => ({ name, values, fundId: entityId }));
    return rows.map(transform);
  }

  getRows = () => {
    const { 
      assignPath,
      pickValues,
      props: { overview: { total: { children : { data: { assetClasses: { data = {} } } } } } } 
    } = this;
    return pickValues(Object.values(data)).filter(acceptAllocation)
      .filter((item) => item['Asset Class'] !== 'Cash')
      .map(assignPath)
      .sort((a, b) => a['Asset Class'] > b['Asset Class']? 1 : -1);
  }
  
  getBenchmarks = (dashQTD) => {
    const { 
      pickValues,
      props: { overview: { benchmarks: { data } }, fund: { benchmarks } }
    } = this;
    
    return pickValues(Object.values(pick(data, benchmarks))).map(benchmark => {
      if (dashQTD) benchmark.QTD = ' - ';
      return benchmark;
    });
  }
  
  getTotal = () => {
    const { 
      pickValues,
      props: { overview: { total: { children: { data } } } }
    } = this;

    const total = {
      ...data,
      name: 'Total (Net)',
      values: {
        ...data.values,
        'Adjusted % Of Portfolio (By Grouping, Visible)': 1
      }
    };

    return pickValues(Object.values([ total ]))[0];
  }
  
  getCash = () => {
    const { 
      pickValues,
      props: { overview: { total: { children : { data: { assetClasses: { data = {} } } } } } } 
    } = this;

    return pickValues(Object.values(data)).filter((item) => item['Asset Class'] === 'Cash').find(acceptAllocation);
  };

  assignPath = item => {
    const { getPath, props: { fund: { entityId } } } = this;

    return {
      ...item,
      path: getPath({ entityId, name: item['Asset Class'] })
    }
  }

  render () {
    const { 
      getBenchmarks,
      getCash,
      getRows,
      getTotal,
      state: {
        isLoading
      }
    } = this;
    const firstColumn = columns[0];
    const dataColumns = take(tail(columns), columns.length - 2);
    const rows = getRows();
    const cash = getCash();
    const total = getTotal();
    const benchmarks = getBenchmarks(shouldDashBenchmark([...rows, cash, total]));
    
    if (isLoading) return null;

    return (
      <div className='table'>
        <div className={cx('tableRow tableRowHeader', styles.assetRow)}>
          {
            columns.map((column, index) => (
              <div 
                key={index} 
                className='tableRowCell'
              >
                {column}
              </div>
            ))
          }
        </div>
        {
          rows.map((row, index) => (
            <SectionLink 
              key={index} 
              className={cx('tableRow', styles.assetRow)}
              to={row.path}  
            >
              <div className='tableRowCell first bold'>
                {row[firstColumn]}
              </div>
              {
                dataColumns.map((column, idx) => (
                  <div 
                    key={idx}
                    className='tableRowCell' 
                  >
                    {row[column]}
                  </div>
                )
                )
              }
              <div className='tableRowCell last'>
                <NavLink to={row.path} >
                  <Svg svg={More}/>
                </NavLink>
              </div>
            </SectionLink>
          ))
        }
        {
          cash ?
            <SectionLink className={`tableRow ${styles.assetRow}`}>
              <div className='tableRowCell bold first'>
                {cash[firstColumn]}
              </div>
              {
                dataColumns.map((column, idx) => (
                  <div 
                    key={idx}
                    className='tableRowCell' 
                  >
                    {column === 'Allocation' ? cash['Allocation'] : '-'}
                  </div>
                ))
              }
              <div className='tableRowCell last'></div>
            </SectionLink>
            : null
        }
        <SectionLink className={cx('tableRow', styles.assetRow, 'total')}>
          <div className='tableRowCell bold big first'>
            {total[firstColumn]}
          </div>
          {
            dataColumns.map((column, index) => (
              <div 
                key={index}
                className='tableRowCell bold big' 
              >
                {total[column]}
              </div>
            ))
          }
          <div className='tableRowCell last'></div>
        </SectionLink>
        {
          benchmarks.map((row, index) => (
            <SectionLink 
              key={index} 
              className={cx('tableRow', styles.assetRow)}
            >
              <div className='tableRowCell italic first'>
                {row[firstColumn]}
              </div>
              {
                dataColumns.map((column, idx) =>  (
                  <div 
                    key={idx}
                    className='tableRowCell italic' 
                  >
                    {row[column]}
                  </div>
                ))
              }
              <div className='tableRowCell italic last' />
            </SectionLink>
          ))
        }
      </div>
    );
  }
}

FundOpenEndPerformanceTable.propTypes = {
  fund: PropTypes.object,
  assetClasses: PropTypes.array,
  overview: PropTypes.object
};

export default FundOpenEndPerformanceTable;