import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from '../card/Card';
import CardBody from '../card/CardBody';
import CardHeader from '../card/CardHeader';
import Modal from '../modal/Modal';
import DocumentIcon from '../../assets/svg/document.svg';
import DynamicImage from '../image/DynamicImage';

import ContactCard from './ContactCard.js';
import styles from './welcomeCard.local.less';

class WelcomeCard extends Component {
  state = {
    open: false
  }
  
  openModal = () => {
    this.setState({ open: true });
  }

  handleOnClose = () => {
    this.setState({ open: false });
  }  
  
  render() {
    const { 
      makenaClient: {
        accountManagerEmail,
        accountManagerFirstName,
        accountManagerLastName,
        accountManagerPhone,
        accountManagerPicture,
        investmentContactEmail,
        investmentContactName,
        investmentContactPhone,
        investmentContactPicture
        
      },
      makenaAssetClassUpdate
    } = this.props;
    const cardHeading = `Makena Updates`;
    const accountManagerName = `${accountManagerFirstName} ${accountManagerLastName}`;
    const authorName = `${makenaAssetClassUpdate.authorFirstName} ${makenaAssetClassUpdate.authorLastName}`;
    const updateHyperlink = makenaAssetClassUpdate.hyperlink.split('"').join('');
    
    return (
      <Card 
        layoutStyle={this.props.layoutStyle} 
        contentStyle={this.props.contentStyle}
        trackEventLabel={cardHeading}
      >
        <CardHeader>
          <section className={styles.welcomeCard}>
            <div className={styles.leftHeader}>
              {cardHeading}
            </div>
            <div className={styles.rightHeader}>
              <span className={styles.link} onClick={this.openModal}>Have questions? Contact us.</span>
            </div>
          </section>
        </CardHeader>
        <CardBody>
          <section className={styles.welcomeCard}>
            <div className={styles.leftBody}>

              <DynamicImage 
                className={styles.avatar}
                alt={authorName}
                path={ `people/${makenaAssetClassUpdate.authorImage}` } 
                isAsset={true}/>
              <span className={styles.subtitle} style={{ marginBottom: '4px' }}>
                {authorName}
              </span>
              <span className={styles.repRole}>{makenaAssetClassUpdate.authorTitle}</span>
            </div>
            <div className={styles.rightBody}>
              <span className={styles.subtitle}>{makenaAssetClassUpdate.title}</span>
              <p className={styles.overview}>
                {makenaAssetClassUpdate.body}
              </p>
              <p>
                <img src={DocumentIcon} className={styles.documentIcon} />
                <a className={styles.link} href={updateHyperlink} target="_blank">
                  {makenaAssetClassUpdate.hyperlinkText}
                </a>
              </p>
            </div>
          </section>
          {
            this.state.open ? 
              <Modal handleOnClose={this.handleOnClose}>
                <ContactCard 
                  contact={{
                    email: accountManagerEmail,
                    name: accountManagerName,
                    phone: accountManagerPhone,
                    picture: accountManagerPicture
                  }}
                  investmentContact={{
                    email: investmentContactEmail,
                    name: investmentContactName,
                    phone: investmentContactPhone,
                    picture: investmentContactPicture
                  }}
                  onClose={this.handleOnClose} 
                />
              </Modal>
              : null
          }
        </CardBody>
      </Card>
    );
  }
}

WelcomeCard.propTypes = {
  makenaClient: PropTypes.object,
  makenaAssetClassUpdate: PropTypes.object,
  layoutStyle: PropTypes.string,
  contentStyle: PropTypes.string,
};

export default WelcomeCard;
