import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';

import CardHeader                 from '../../components/card/CardHeader';
import ExposuresCard              from '../../components/exposuresCard/ExposuresCard';
import ExposureChartList          from '../../components/exposureChartList/ExposureChartList';
import Footer                     from '../../components/footer/Footer';
import MakenaAssetClassCard       from './MakenaAssetClassCard';
import MakenaAssetPerformanceCard from '../../components/makenaAssetPerformanceTable/MakenaAssetPerformanceCard';
import PerformanceChartCard       from '../../components/performanceChartCard/PerformanceChartCard';
import TextDisplayCard            from '../../components/textDisplayCard/TextDisplayCard';

class MakenaAsset extends Component {
  render () {
    const {
      assetClass,
      exposures,
      fund,
      growthOfADollar,
      overview
    } = this.props;

    const {
      benchmarks,
      displayName,
      strategy,
      updates
    } = assetClass;
    
    return (
      <div className="dashboard-content">
        <div className='contentRowFullHeader'>
          <h1 className='title'>{displayName}</h1>
        </div>
        <div className='contentRowSplitTwo'>
          <MakenaAssetClassCard
            layoutStyle='cardRowItem cardContentLeft'
            imagePath={`people/${updates[0].authorImage}`}
            imageAlt={updates[0].authorName}
            cardInfo={updates[0]}
            isHtmlText={true}
          />
          <TextDisplayCard
            layoutStyle='cardRowItem cardContentRight'
            headerText={'Our Objective & Strategy'}
            bodyText={strategy}
            isHtmlText={true}
          />
        </div>
        <div className='contentRowSplitTwo makenaProduct'>
          <PerformanceChartCard
            benchmarks={benchmarks}
            labels={{'Your Portfolio': displayName, defaultBenchmark: benchmarks[0] }}
            layoutStyle='cardMain cardRowItem makenaProductPerformance'
            performance={growthOfADollar}
            subTitle='Trailing 5 Year'
            title='Growth of a Dollar'
          />
          <MakenaAssetPerformanceCard
            overview={overview}
            assetClass={assetClass}
            fund={fund}
            layoutStyle='cardRowItem makenaProductAssets'
          />
          <ExposuresCard 
            layoutStyle='cardRowItem makenaProductExposures'
            trackEventLabel='Asset Class Exposures'
          >
            <CardHeader>Asset Class Exposures</CardHeader>
            <ExposureChartList charts={exposures} />
          </ExposuresCard>
        </div>
        <Footer/>
      </div>
    );
  }
}

MakenaAsset.propTypes = {
  assetClass: PropTypes.object,
  exposures: PropTypes.array,
  fund: PropTypes.shape({}),
  growthOfADollar: PropTypes.shape({}),
  overview: PropTypes.shape({})
};

export default MakenaAsset;
