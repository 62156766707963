import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda'
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { capitalCase } from 'change-case';
import {
  Cell, Pie, PieChart, ResponsiveContainer,
} from 'recharts';

import {
  splitAndCapitalizeString,
} from '../../helpers/formatters/formatters';
import { getChartCellColorForIndex } from '../../helpers/chartHelpers/chartHelpers';
import { trackEvent } from '../../helpers/gaHelpers/gaHelpers';
import { MAKENA_COLORS } from '../../app/constants/colors';

import styles from './pieChart.local.less';

const formatSettings = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
};

const formatter = new Intl.NumberFormat('en-US', formatSettings);

const renderPiePlaceholderForEmptyData = () => (
  <div className={styles.pieWrap}>
    <div className={styles.noPieContainer}>
      <div>Exposure data will appear when capital has been called.</div>
      <div className={styles.marginBottom}>Please check back next quarter.</div>
      <div>
        If you have any questions, please contact your Makena account manager or write to us at
        <a href="mailto:ClientOperations@makenacap.com"> ClientOperations@makenacap.com</a>.
      </div>
    </div>
  </div>
);

const trackHover = (index) => trackEvent('hover_chart', {
  event_category: 'Funds',
  event_label: index + ' index',
})

const formatValue = value => formatter.format(value * 100);

const LESS_THAN_1 = 'Less Than 1%';

class PieChartCustom extends Component {
  state = {
    activeIndex: null,
    isChartInFocus: false,
  };

  onPieEnter = (data, index) => {
    trackHover(index);
    this.setState({
      activeIndex: index,
    });
  };

  onMouseEnter = () => {
    this.setState({
      isChartInFocus: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      activeIndex: null,
      isChartInFocus: false,
    });
  };

  groupValues = chartData => {
    const addAccDataInIndex = (index, acc, data) => R.always(
      R.over(
        R.lensIndex(index),
        R.concat([R.evolve({ value: Math.abs }, data)]),
        acc
      ));

    const [ normalValues, smallValues, negativeValues ] = R.reduce(
      (acc, data) => {
        const concat = R.partialRight(addAccDataInIndex, [acc, data]);

        return R.cond([
          [R.compose(R.not, R.gt(0.01)), concat(0)],
          [R.lt(0), concat(1)],
          [R.gt(0), concat(2)],
          [R.T, R.always(acc)],
        ])(data.value)
      }, [[], [], []], chartData);

    return R.compose(
      R.append(negativeValues),
      arr => R.of(arr),
      R.cond([
        [R.gt(2), R.concat(normalValues)],
        [R.T, _smallValues => _.concat(
          ...normalValues,
          {
            name: LESS_THAN_1,
            value: _.sumBy(_smallValues, 'value'),
            children: _.orderBy(_smallValues, ['value'], ['desc']),
          }
        )]
      ])
    )(smallValues);
  }

  filterValues = value => !!value && parseFloat(Math.abs(value) * 100, 10).toFixed(1) !== '0.0';

  getChartData = R.compose(
    R.map(
      R.compose(R.sortWith([R.ascend(R.prop('value'))]))
    ),
    this.groupValues,
    R.filter(R.compose(
      R.propSatisfies(this.filterValues, 'value')
    )),
    R.sortBy(R.prop('value')),
    o => R.compose(
      R.map(key => R.zipObj(
        ['name', 'value'], [key, o[key]]
      )),
      R.keys
    )(o),
    R.clone
  );

  sortChartData = R.compose(
    R.sortWith([
      R.descend(R.prop('value'))
    ])
  );

  sortPositiveChartData = positiveChartData => {
    const testLessThanOne = R.compose(
      R.test(/Less Than 1%/),
      R.prop('name')
    );

    return R.converge(R.concat, [
      R.compose(
        this.sortChartData,
        R.reject(testLessThanOne)
      ),
      R.filter(testLessThanOne),
    ])(positiveChartData)
  };

  renderLegendValue = (value, isNegative) => {
    const { omitRatioCalculation } = this.props;
    if (omitRatioCalculation) return `${isNegative ? '-': ''}${value}`;
    return `${isNegative ? '-': ''}${formatValue(value)}%`;
  }

  renderProductLegend = (entry, settings, length) => {
    const {
      cssClass,
      index,
      isChartInFocus,
      isChild,
      backgroundColorIndex,
      products,
      isNegative,
      sectorCount,
      syncedIndex,
    } = settings;

    const str = entry.name;

    const arrayToObject = (array) =>
      array.reduce((obj, item) => {
        obj[item.displayName] = item
        return obj
      }, {})

    const productObj = arrayToObject(products);

    const name = R.propOr('', 'displayName')(productObj[str]);

    if (str !== name) return this.renderLegend(entry, settings, length);

    const entityId = R.propOr('', 'entity_id')(productObj[str]);

    const children = _.get(entry, 'children', []);

    return (
      <NavLink
        key={entry.name}
        onMouseEnter={() => this.onPieEnter(entry, syncedIndex)}
        className={cx(styles.chartLegendContainer, cssClass)}
        to={`/dashboard/${entityId}`}
      >
        <div className={styles.chartLegendItem}>
          {
            isChild
              ? null
              : (
                <div
                  className={cx(styles.legendColorBox, cssClass)}
                  style={{
                    backgroundColor: getChartCellColorForIndex(backgroundColorIndex, sectorCount, true),
                    opacity: isChartInFocus && syncedIndex !== index ? 0.25 : null,
                  }}
                />
              )
          }
          <div className={styles.legendTitle}>
            {entry.name}
          </div>
          <div className={styles.legendPercentage}>
            {this.renderLegendValue(entry.value, isNegative)}
          </div>
        </div>
        <div className={styles.chartLegendItemChildren}>
          { children.map(child => this.renderLegend(child, {...settings, isChild: true }, children.length))}
        </div>
      </NavLink>
    )
  }

  renderLegend = (entry, settings) => {
    const {
      cssClass,
      index,
      backgroundColorIndex,
      isChartInFocus,
      isChild,
      sectorCount,
      syncedIndex,
      isNegative
    } = settings;
    const { activeIndex } = this.state;
    const children = _.get(entry, 'children', []);
    const _syncedIndex = isNegative? sectorCount + index + 1: syncedIndex;
    const isActive = isNegative? (activeIndex === _syncedIndex ? styles.isActive : null) : cssClass;
    return (
      <div
        key={entry.name}
        onMouseEnter={() => this.onPieEnter(entry, _syncedIndex)}
        className={cx(styles.chartLegendContainer, isActive)}
      >
        <div className={`${styles.chartLegendItem}${entry.name === LESS_THAN_1? ' unprintable' : ''}`}>
          {
            isChild
              ? null
              : (
                <div
                  className={cx(styles.legendColorBox, isActive)}
                  style={{
                    backgroundColor: getChartCellColorForIndex(backgroundColorIndex, sectorCount, true),
                    opacity: isChartInFocus && syncedIndex !== index ? 0.25 : null,
                  }}
                />
              )
          }
          <div className={styles.legendTitle}>
            {capitalCase(entry.name)}
          </div>
          <div className={styles.legendPercentage}>
            {this.renderLegendValue(entry.value, isNegative)}
          </div>
        </div>
        <div className={styles.chartLegendItemChildren}>
          { children.map(child => this.renderLegend(child, {...settings, isChild: true }))}
        </div>
      </div>
    );
  };

  renderLegends = (chartData, sectorCount, pieChartDataLength, isChartInFocus, isNegative) => {
    const { activeIndex } = this.state;
    const { products } = this.props;

    return chartData.map((entry, index) => {
      const syncedIndex = R.cond([
        [R.always(isNegative), R.compose(
          R.subtract(pieChartDataLength, R.__),
          R.subtract(R.subtract(pieChartDataLength, sectorCount), R.__),
          R.subtract(R.length(chartData), R.__),
        )],
        [R.T, R.compose(R.subtract(sectorCount, R.__))]
      ])(index);
      const cssClass = activeIndex === syncedIndex ? styles.isActive : null;
      const fnName = products ? 'renderProductLegend' : 'renderLegend';
      const backgroundColorIndex = R.cond([
        [R.always(isNegative), R.compose(R.add(sectorCount), R.inc)],
        [R.T, R.identity]
      ])(index);
      return this[fnName](entry, {cssClass, index, backgroundColorIndex, isChartInFocus,  products, sectorCount, syncedIndex, isNegative }, chartData.length);
    });
  };

  renderChartTitle = (title, pieDiameter) => {
    const titleArray = splitAndCapitalizeString(title);
    const pieRadius = Number(pieDiameter) / 2;

    return (
      <text
        x="50%"
        y={titleArray.length > 1 ? pieRadius - 10 : pieRadius}
        textAnchor="middle"
        className={styles.pieTitle}
        fill={MAKENA_COLORS.textColor}
      >
        {
          titleArray.map((word, index) => <tspan x="50%" key={word} dy={index * 20}>{word}</tspan>)
        }
      </text>
    );
  };

  render() {
    const {
      state: { activeIndex, isChartInFocus },
      props: { title, data, pieDiameter },
    } = this;

    if (R.isEmpty(data)) {
      return renderPiePlaceholderForEmptyData();
    }

    const [ positiveData, negativeData ] = this.getChartData(data);

    if (R.isEmpty(positiveData))  {
      return null;
    }
    const pieChartData = R.concat(positiveData, negativeData);
    const sectorCountPositiveData = R.compose(R.dec, R.prop('length'))(positiveData);
    const pieChartDataLength = R.compose(R.dec, R.prop('length'))(pieChartData);
    const sortedPositiveData = this.sortPositiveChartData(positiveData);
    const sortedNegativeData = R.reverse(this.sortChartData(negativeData));
    return (
      <div
        className={styles.pieWrap}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={styles.pie}>
          <ResponsiveContainer height={pieDiameter} width="100%">
            <PieChart>
              <Pie
                activeIndex={isChartInFocus ? activeIndex : null}
                data={pieChartData}
                dataKey="value"
                innerRadius="63%"
                outerRadius="100%"
                blendStroke
                paddingAngle={2}
                onMouseEnter={this.onPieEnter}
                isAnimationActive
                startAngle={90}
                endAngle={450}
                margin={{
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                {
                  pieChartData.map((entry, index) => (
                    <Cell
                      key={entry}
                      fill={getChartCellColorForIndex(index, sectorCountPositiveData, false)}
                      fillOpacity={isChartInFocus && activeIndex === index ? 1 : null}
                      opacity={isChartInFocus && activeIndex !== index ? 0.25 : 1}
                    />
                  ))
                }
              </Pie>

              {this.renderChartTitle(title, pieDiameter)}
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className={styles.legendWrap}>
          {this.renderLegends(sortedPositiveData, sectorCountPositiveData, pieChartDataLength, isChartInFocus, false)}
          {this.renderLegends(sortedNegativeData, sectorCountPositiveData, pieChartDataLength, isChartInFocus, true)}
        </div>
      </div>
    );
  }
}

PieChartCustom.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({}),
  products: PropTypes.array,
  pieDiameter: PropTypes.number,
  omitRatioCalculation: PropTypes.bool
};

PieChartCustom.defaultProps = {
  pieDiameter: 270
};

export default PieChartCustom;
