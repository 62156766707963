import ERRORS from '../../app/constants/errors';
import { getErrorMessage } from '../../services/errors/errorHelper';
import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import API_URLS from '../../app/constants/apiUrls';
import requestWrapper from '../../helpers/apiHelpers/requestWrapper';

export const auditLog = async payload => {
  try {
    const payloadToSend = {
      id: payload.id ?? '',
      detail: payload.detail ?? '',
      success: payload.success ?? null,
      errorMessage: payload.errorMessage ?? '',
      logData: payload.logData ?? null,
    };
    const url = `${getApiRootUrl()}${API_URLS.AUDIT_LOG}`;
    const response = await requestWrapper.post(url, payloadToSend);
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.CLIENT_LOGGING_ERROR);
    throw new Error(errorMessage);
  }
};
