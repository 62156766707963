export default {
  AUTH_LOGIN_ERROR: 'Sorry, the information you entered is not correct. Please double-check your information and try again.',
  AUTH_PASSWORD_UPDATE_ERROR: "Sorry, the password you entered doesn't match our records. Please double-check your information",
  ACCOUNT_CREATE_ERROR: 'There was a problem creating ',
  ACCOUNT_LOG_ERROR: 'There was a problem with the logging process ',
  ACCOUNTS_FETCH_ERROR: 'Something went wrong',
  ACCOUNT_DELETE_ERROR: 'There was a problem deleting ',
  ACCOUNT_UPDATE_ERROR: 'There was a problem updating ',
  ACCOUNT_UPDATE_PASSWORD_ERROR: 'The Current Password you entered is not correct. Please double-check your Current Password and try again.',
  ACCOUNT_INVITE_ERROR: 'There was a problem sending an invitation',
  ACCOUNT_INVITE_VALIDATION_ERROR: 'There was a problem validating the invitation',
  ACCOUNT_CONFIRM_ERROR: 'There was a problem confirming the account',
  ACCOUNT_EMAIL_ERROR: 'There was a problem validating the email',
  ACCOUNT_IMPERSONATE_ERROR: 'There was a problem impersonating this user',
  ACCOUNT_TOS_ERROR: 'This account has not accepted the terms of service',
  ACCOUNT_CREATE_CLIENT_ERROR: 'Could not create new client',
  ACCOUNT_UPDATE_CLIENT_ERROR: 'Could not update client',
  MAKENA_ADDEPAR_ERROR: 'Could not fetch addepar metadata',
  MAKENA_CLIENTS_FETCH_ERROR: 'Could not fetch clients',
  MAKENA_ADDEPAR_PUBLISH_ERROR: 'Could not publish Addeppar cache',
  MAKENA_ADDEPAR_IMPORT_ERROR: 'Could not import Addepar cache',
  MAKENA_CLIENT_BY_ID_FETCH_ERROR: 'Could not fetch client',
  MAKENA_PEOPLE_FETCH_ERROR: 'Could not fetch makena people',
  MAKENA_PERSON_CREATE_ERROR: 'Could not create makena person',
  MAKENA_PERSON_UPDATE_ERROR: 'Could not find makena person',
  MAKENA_MANAGER_UPDATE_ERROR: 'Could not find makena manager',
  MAKENA_PRODUCTS_FETCH_ERROR: 'Could not fetch products',
  MAKENA_PRODUCT_BY_ID_FETCH_ERROR: 'Could not fetch product',
  MAKENA_PRODUCTS_BY_CLIENT_ID_FETCH_ERROR: 'Could not fetch products',
  MAKENA_ASSETS_FETCH_ERROR: 'Could not fetch assets',
  MAKENA_ASSETS_BY_ID_FETCH_ERROR: 'Could not fetch asset',
  MAKENA_ASSETS_CREATE_ERROR: 'Could not create asset',
  MAKENA_ASSETS_UPDATE_ERROR: 'Could not update asset',
  MAKENA_ASSETS_DELETE_ERROR: 'Could not delete asset',
  INVESTOR_DOCUMENTS_FETCH_ERROR: 'Could not fetch investor documents',
  INVESTOR_DOCUMENTS_BY_UUID_FETCH_ERROR: 'Could not fetch investor documents',
  INVESTOR_DOCUMENTS_CREATE_ERROR: 'Could not create investor document',
  INVESTOR_DOCUMENTS_UPDATE_ERROR: 'Could not update investor document',
  INVESTOR_DOCUMENTS_DELETE_ERROR: 'Could not delete investor document',
  CLIENT_DOCUMENTS_FETCH_ERROR: 'Could not fetch client documents',
  CLIENT_DOCUMENTS_BY_UUID_FETCH_ERROR: 'Could not fetch client documents',
  CLIENT_DOCUMENTS_CREATE_ERROR: 'Could not create client document',
  CLIENT_DOCUMENTS_UPDATE_ERROR: 'Could not update client document',
  CLIENT_DOCUMENTS_DELETE_ERROR: 'Could not delete client document',
  GLOBAL_DOCUMENTS_FETCH_ERROR: 'Could not fetch global documents',
  GLOBAL_DOCUMENTS_BY_UUID_FETCH_ERROR: 'Could not global client documents',
  GLOBAL_DOCUMENTS_CREATE_ERROR: 'Could not create global document',
  GLOBAL_DOCUMENTS_UPDATE_ERROR: 'Could not update global document',
  MAKENA_MANAGERS_FETCH_ERROR: 'Could not fetch managers',
  MAKENA_ASSET_CLASSES_FETCH_ERROR: 'Could not fetch asset classes',
  MAKENA_FUNDS_FETCH_ERROR: 'Could not fetch funds',
  TAX_ELECTION_FORMS_FETCH_ERROR: 'Could not fetch tax election forms',
  APPLICATION_SETTINGS_UPDATE_ERROR: 'Could not apply new settings',
  GLOBAL_SETTINGS_UPDATE_ERROR: 'Could not apply new global settings',
  WELCOME_EMAIL_ERROR: 'Could not send welcome email',
  PASSWORD_RESET_ERROR: email => `Could not reset the password for ${email}`,
  TOKEN_VALIDATION_ERROR: 'Could not validate token',
  USAGE_LOGIN: 'Could not fetch login usage info',
  USAGE_DOCUMENTS: 'Could not fetch documents usage info',
  USAGE_NAVIGATION: 'Could not fetch navigation usage info',
  REPORTING_FETCH_ERROR: type => `Could not fetch [${type}] chart data`,
  MAKENA_ASSET_CLASS_UPDATES_FETCH_ERROR: 'Could not fetch updates',
  MAKENA_ASSET_CLASS_UPDATES_FETCH_BY_ID_ERROR: id => 'Could not fetch update ' + id,
  MAKENA_ASSET_CLASS_UPDATES_CREATE_ERROR: 'Could not create update',
  MAKENA_ASSET_CLASS_UPDATES_UPDATE_ERROR: id => 'Could not update entity ' + id,
  MAKENA_ASSET_CLASS_UPDATES_DELETE_ERROR: id => 'Could not delete update ' + id,
  MAKENA_SSC_ID_UPDATE_ERROR: sscId => 'Could not update makena sscId ' + sscId,
  NOTIFICATION_SETTINGS_UPDATE_ERROR: 'Could not update survey',
  NOTIFICATION_SETTINGS_CREATE_ERROR: 'Could not create survey',
  NOTIFICATION_SETTINGS_FETCH_ERROR: 'Could not fetch survey',
  CLIENT_LOGGING_ERROR: 'Could not send log of client action'
};
