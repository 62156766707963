import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Card from '../../../components/card/Card';
import CardPanel from '../../../components/cardPanel/CardPanel';
import CardHeader from '../../../components/card/CardHeader';
import {
  Button, Form, Message,
} from 'semantic-ui-react';
import AnnotatedPasswordField from '../../../components/annotatedPasswordField/AnnotatedPasswordField';
import {minChars, hasOneUpperCase, numberOrSpecialChar, exactMatch} from '../../../utils/validators';

import styles from './accountPasswordSetupForm.local.less';


class AccountPasswordSetupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidForm: false,
      password: '',
      passwordConfirm: '',
      validationState: this.validateFields({
        password: {},
        passwordConfirm: {}
      })
    };
  }

  validateFields = (fields) => {
    const validationState = {
      password: {
        validators: [
          {validationFunction: minChars, error:"8 Characters", props: {length: 8}, isValid: false},
          {validationFunction: hasOneUpperCase, error:"1 uppercase character", props: {}, isValid: false},
          {validationFunction: numberOrSpecialChar, error:"1 number or special character", props: {}, isValid: false},
        ],
        isValidField: false
      },
      passwordConfirm: {
        validators: [
          {validationFunction: exactMatch, error:"Both passwords must match", props:{compare:'password'}, isValid: false }
        ], 
        isValidField: false
      }
    };

    let formFields = Object.keys(validationState);
    
    formFields.forEach(field => {
      const validators = validationState[field].validators;
      validationState[field].isValidField = true; 

      validators.forEach(validator => {
        validator.isValid = validator.validationFunction(fields[field], fields, validator.props);
        if (!validator.isValid && validationState[field].isValidField) {
          validationState[field].isValidField = false;
        }
      })
    });

    return validationState;
  }

  validateForm = (validationState) => {
    let formFields = Object.keys(validationState);
    return formFields.every(field => validationState[field].isValidField);
  }

  handleFormChange = (e) => {
    let changedField = e.target.name;

    const changedState = Object.assign({}, this.state, {
      [changedField]: e.target.value 
    });

    const formFields = {
      password : changedState.password,
      passwordConfirm: changedState.passwordConfirm
    }

    const validationState = this.validateFields(formFields);
    const validForm = this.validateForm(validationState);
    const newState = Object.assign({}, changedState, { 
      validationState: validationState, 
      isValidForm: validForm
    });
    this.setState(() => newState);
  }

  renderPasswordForm = () => {
    const { password, passwordConfirm } = this.state.validationState;
    return (
      <>
        <Form.Field>
          <Field
            name="password"
            component={AnnotatedPasswordField}
            type="password"
            label="Create a password"
            toolTip={true}
            value={this.state.password}
            validationState={password}
            onChange={this.handleFormChange}
          />
        </Form.Field>
        <Form.Field>
          <Field
            name="passwordConfirm"
            type="password"
            component={AnnotatedPasswordField}
            label="Type your new password again"
            toolTip={true}
            position='top left'
            onChange={this.handleFormChange}
            value={this.state.passwordConfirm}
            validationState={passwordConfirm}
          />
        </Form.Field>
      </>
    )
  }

  render() {
    const {
      handleAccountSetupRequest,
      handleBackButtonClick,
      handleSubmit,
      email,
      submitting,
      error
    } = this.props;

    const { isValidForm }= this.state;
    const shouldDisableButton = submitting || (!isValidForm)
    
    return (
      <Card layoutStyle="cardMain">
        <CardHeader>Makena Investor Site</CardHeader>
        <CardPanel panelStyle="cardPanel noMargin">
          <Form
            onSubmit={handleSubmit(handleAccountSetupRequest)}
            className={`attached fluid ${styles.resetFormWrap}`}
          >
            <Message negative hidden={!error}>
            </Message>
            <div className={`${styles.panelUserEmail}`}>
              <p>
                Welcome! This email address does not yet have an account. 
                Please create a password for your account below.
              </p>

              <h5>Email</h5>
              <span className={styles.emailLabel}>{email}</span>
            </div>
            {this.renderPasswordForm()}
            <div className={`${styles.controlRow} formItem`}>
              <Button disabled={shouldDisableButton}>
                Continue
              </Button>
              <div className={styles.linkButton} onClick={handleBackButtonClick}>
                back
              </div>
            </div>
          </Form>
        </CardPanel>
      </Card>
    );
  }
}

AccountPasswordSetupForm.propTypes = {
  email: PropTypes.string,
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleAccountSetupRequest: PropTypes.func,
  handleBackButtonClick: PropTypes.func,
  submitting: PropTypes.bool,
  match: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default compose(
  withRouter,
  reduxForm({ form: 'accountSetupForm' }),
)(AccountPasswordSetupForm);
