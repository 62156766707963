import React                          from 'react';
import PropTypes                      from 'prop-types';
import ManagerGalleryCardTextContent  from './ManagerGalleryCardTextContent';
import styles                         from './managerGalleryCard.local.less';

const ManagerGalleryCardContent = ({ 
  texts: { 
    assetClass, 
    date, 
    strategy,
  } 
}) => (
  <div className={styles.cardContentContainer}>
    <ManagerGalleryCardTextContent
      heading='Initial Investment'
      text={date}
    />
    <ManagerGalleryCardTextContent
      heading='Asset Class'
      text={assetClass}
    />
    <ManagerGalleryCardTextContent
      heading='Strategy'
      text={strategy}
    />
  </div>
);

ManagerGalleryCardContent.propTypes = {
  texts: PropTypes.shape({
    assetClass: PropTypes.string,
    makenaInvestmentDate: PropTypes.string,
    strategy: PropTypes.string,
    date: PropTypes.string
  })
};

export default ManagerGalleryCardContent;
