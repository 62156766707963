import React, { Component }               from 'react';
import connect                            from 'react-redux/es/connect/connect';
import PropTypes                          from 'prop-types';
import get                                from 'lodash/get';

import UserDashboard                      from './UserDashboard';
import {
  getInsightsDocuments,
  getInvestorDocumentsForCard
}                                         from '../../services/document/documentService';
import { makenaAssetClassUpdatesFetch }   from '../../services/makenaAssetClassUpdate/makenaAssetClassUpdateService';
import { growthOfADollarClientTransform } from '../../helpers/fundsHelpers/charts';
import { MergeAll }                       from '../../helpers/globalHelpers';

import {
  clientPortalGrowthOfADollarByClientFetch,
  clientPortalAssetAllocationFetch,
  clientPortalFundAllocationFetch,
  clientPortalGeographyFetch,
  clientPortalRiskFactorFetch,
} from '../../services/reporting/reportingService';

const getInceptionDate = overview => get(overview, 'total.values.Inception Date', '');

const parseAssetAllocation = assetClasses => assetAllocation => {
  const {
    children: {
      data = {}
    } = {}
  } = assetAllocation;
  const newAssetAllocationData = {};
  Object.keys(data).forEach(_title => {
    const {
      displayName = _title
    } = assetClasses.find(({ title }) => _title === title) || {};
    newAssetAllocationData[displayName] = data[_title];
  });
  return {
    ...assetAllocation,
    children: {
      ...assetAllocation.children,
      data: newAssetAllocationData
    }
  }
}

const parseFundsAllocation = funds => fundAllocation => {
  const {
    children: {
      data = {}
    } = {}
  } = fundAllocation;
  const newFundAllocationData = {};
  Object.keys(data).forEach(_title => {
    const {
      displayName = _title
    } = funds.find(({ symbol }) => _title === symbol) || {};
    newFundAllocationData[displayName] = data[_title];
  });
  return {
    ...fundAllocation,
    children: {
      ...fundAllocation.children,
      data: newFundAllocationData
    }
  }
}

class UserDashboardContainer extends Component {
  state = { isLoading: true, data: {} };

  async componentDidMount() {
    const { props: { makenaClient: { entityId }, assetClasses = [], funds = [] }, finishLoading, startLoading, mergeFundData } = this;

    startLoading();    
    
    let promises = [
      getInsightsDocuments().then(insightDocuments => ({ insightDocuments })),
      getInvestorDocumentsForCard().then(investorDocuments => ({ investorDocuments })),
      makenaAssetClassUpdatesFetch().then(assetClassUpdates => ({ assetClassUpdates })),
      clientPortalGrowthOfADollarByClientFetch(entityId).then(growthOfADollar => ({ growthOfADollar })),
      clientPortalFundAllocationFetch(entityId).then(parseFundsAllocation(funds)).then(fundAllocation => ({ fundAllocation })),
      clientPortalAssetAllocationFetch(entityId).then(parseAssetAllocation(assetClasses)).then(assetAllocation => ({ assetAllocation })),
      clientPortalGeographyFetch(entityId).then(geography => ({ geography })),
      clientPortalRiskFactorFetch(entityId).then(riskFactor => ({ riskFactor }))
    ];

    return Promise.all(promises)
      .then(mergeFundData)
      .then(finishLoading);
    
  }

  mergeFundData = arrayData => {
    const { 
      insightDocuments,
      investorDocuments,
      assetClassUpdates,
      growthOfADollar = {}, 
      riskFactor = {}, 
      assetAllocation = {}, 
      geography = {}, 
      fundAllocation = {}
    } = MergeAll(arrayData);
    return {
      exposures: [
        { title: 'Fund Allocation'  , data: get(fundAllocation  , 'children.data', []), id: 'fund' },
        { title: 'Asset Allocation' , data: get(assetAllocation , 'children.data', []), id: 'asset' },
        { title: 'Risk Factor'      , data: get(riskFactor      , 'children.data', []), id: 'risk'  },
        { title: 'Geography'        , data: get(geography       , 'children.data', []), id: 'geo'   },
      ],
      assetClassUpdates,
      growthOfADollar,
      insightDocuments,
      investorDocuments
    };
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = data => this.setState({ isLoading: false, data });

  areMissingProps = () => {
    const {
      asOfDate = '',
      overview = {},
      funds = [],
      makenaClient,
      recentInvestments,
    } = this.props;
    if (!asOfDate.length) return true;
    if (!Object.keys(overview).length) return true;
    if (!funds.length) return true;
    if (!Object.keys(makenaClient).length) return true;
    if (!recentInvestments.length) return true;
    return false;
  }

  render() {
    const {
      areMissingProps,
      state: {
        isLoading,
        data: {
          assetClassUpdates,
          growthOfADollar,
          investorDocuments,
          insightDocuments,
          exposures,
        }
      },
      props: {
        asOfDate,
        overview,
        funds,
        openModal,
        makenaClient,
        recentInvestments
      }
    } = this;
    
    if (isLoading || areMissingProps()) return null;
    
    return (
      <UserDashboard
        recentInvestments={recentInvestments}
        assetClassUpdates={assetClassUpdates}
        growthOfADollar={growthOfADollarClientTransform(getInceptionDate(overview), asOfDate, growthOfADollar)}
        investorDocuments={investorDocuments}
        insightDocuments={insightDocuments}
        asOfDate={asOfDate}
        makenaClient={makenaClient}
        overview={overview}
        openModal={openModal}
        funds={funds}
        exposures={exposures}
      />
    );
  }
}

UserDashboardContainer.propTypes = {
  asOfDate: PropTypes.any,
  overview: PropTypes.any,
  funds: PropTypes.array,
  recentInvestments: PropTypes.array,
  makenaClient: PropTypes.object,
  openModal: PropTypes.func,
  assetClasses: PropTypes.array
}

const mapStateToProps = state => ({
  asOfDate: state.auth.settings.reportingQuarterEnd,
  makenaClient: state.makenaClient,
});

const mapDispatchToProps = ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDashboardContainer);
