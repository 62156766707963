import { ACTIONS } from '../../app/constants/index';
import * as R from 'ramda';


const INITIAL_STATE = {
  clientDocuments: [],
  investorDocuments: [],
  investorDocumentsCount: {}
};

export default function (state = INITIAL_STATE, action = { clientDocuments: [], insightsDocuments: [], investorDocuments: [], investorDocumentsDatatable: {}, investorDocumentsCountByField: {}, type: '' }) {
  switch (action.type) {
    case ACTIONS.CLIENT_DOCUMENTS_FETCH_SUCCESS: {
      return { ...state, clientDocuments : action.clientDocuments };
    }
    case ACTIONS.INSIGHTS_DOCUMENTS_FETCH_SUCCESS: {
      return { ...state, insightsDocuments : action.insightsDocuments };
    }
    case ACTIONS.INVESTOR_DOCUMENTS_FETCH_SUCCESS: {
      return { ...state, investorDocuments : action.investorDocuments };
    }
    case ACTIONS.INVESTOR_DOCUMENTS_DATATABLE_FETCH_SUCCESS: {
      return { 
        ...state, 
        investorDocumentsDatatable: R.over(
          R.lensProp(['data']), 
          R.values,
          action.investorDocumentsDatatable
        )
      };
    }
    case ACTIONS.INVESTOR_DOCUMENTS_COUNT_BY_FIELD_FETCH_SUCCESS: {
      return {
        ...state,
        investorDocumentsCount: {
          ...state.investorDocumentsCount,
          ...action.investorDocumentsCountByField
        }
      };
    }
    default:
      return state;
  }
}
