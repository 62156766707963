import { clone, get } from 'lodash';

const AssignFiltersToHeaders = (_headers, filters, appliedFilters, sortObject) => {
  let newHeaders = clone(_headers);
  newHeaders.columns.forEach(column => {
    if (column.isFilterable) {
      column.filterOptions = filters[column.selector];
      const filteredFilters = get(appliedFilters, column.filterField, []);
      column.filterOptions.forEach(filterOption => {
        filterOption.filterSelected = filteredFilters.some(filterValue => filterValue === filterOption.name);
      });
    }

    if (column.isSortable) {
      if (sortObject.field !== column.filterField)
        delete column.sortDirection;
      else
        column.sortDirection = sortObject.direction;
    }
  });
  return newHeaders;
};

export const Helper = {
  AssignFiltersToHeaders
}