import React      from 'react';
import PropTypes  from 'prop-types';
import Card       from '../card/Card';
import CardHeader from '../card/CardHeader';
import CardBody   from '../card/CardBody';

import MakenaAssetPerformanceTable from './MakenaAssetPerformanceTable';

const MakenaAssetPerformanceCard = ({assetClass, fund, overview, layoutStyle=""}) => {
  const { displayName } = assetClass;
  const cardHeaderContent = `${displayName} Performance (IRR)`;

  return (
    <Card
      layoutStyle={layoutStyle}
      trackEventLabel={cardHeaderContent}
      trackEventCategory='Fund'
    >
      <CardHeader>
        {cardHeaderContent}
      </CardHeader>
      <CardBody>
        <MakenaAssetPerformanceTable
          assetClass={assetClass}
          fund={fund}
          overview={overview}
        />
      </CardBody>
    </Card>
  );
};


MakenaAssetPerformanceCard.propTypes = {
  assetClass  : PropTypes.shape({
    displayName: PropTypes.string
  }),
  fund        : PropTypes.shape({}),
  layoutStyle : PropTypes.string,
  overview    : PropTypes.shape({})
}

export default MakenaAssetPerformanceCard;


