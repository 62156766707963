import { env } from '../envHelpers/env';

const getApiRootUrl = () => {
  let environmentValue;
  if (process.env.NODE_ENV === 'development') {
    environmentValue = process.env.REACT_APP_API_ROOT
  } else {
    environmentValue = env.REACT_APP_API_ROOT;
  }

  if (!environmentValue) {
    throw new Error('Missing REACT_APP_API_ROOT environment variable');
  }

  return environmentValue;
};

export default getApiRootUrl;
