import getApiRootUrl        from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper       from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';
import { getErrorMessage }  from '../errors/errorHelper';

// eslint-disable-next-line import/prefer-default-export
export const fetchTaxElectionForms = async (year) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.TAX_ELECTION_FORMS_FETCH}/year/${year}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.TAX_ELECTION_FORMS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchTaxElectionForm = async (adminMode, uuid) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.TAX_ELECTION_FORMS_FETCH_UUID(uuid)}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.TAX_ELECTION_FORMS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchTaxElectionFormBySSCAndYear = async (adminMode, sscId, year) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.TAX_ELECTION_FORMS_FETCH}/ssc/${sscId}/year/${year}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.TAX_ELECTION_FORMS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const saveTaxElectionForm = async (adminMode, data) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.TAX_ELECTION_FORMS_FETCH}`;
    const response = await requestWrapper.post(url, data);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.TAX_ELECTION_FORMS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const updateTaxElectionForm = async (adminMode, uuid, data) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.TAX_ELECTION_FORMS_FETCH_UUID(uuid)}`;
    const response = await requestWrapper.put(url, data);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.TAX_ELECTION_FORMS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};
