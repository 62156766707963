export const PRIVACY_TITLE = 'Privacy ';
export const PRIVACY_SECTIONS = [
  "Privacy Policy",
  "Collection of Personal Information",
  "Source of Personal Information",
  "Uses of Personal Information",
  "Protection of Personal Information",
  "Transfer of Personal Information",
  "Retention of Personal Information",
  "Rights under the European Union's General Data Protection Regulation"
];

export const PRIVACY_CONTENT = {
  "Privacy Policy":[
    "Your privacy is very important to us. This Privacy Notice sets forth the policies of Makena Capital Management, LLC ('Makena') with respect to non-public personal information of investors, prospective investors and former investors (collectively for purposes of this notice only, 'Investors') in any of the Makena funds (the 'Funds'). These policies apply to individuals only and may be changed at any time, provided a notice of such change is given to you.  If you provide personal information to us about any person other than yourself, you must ensure that they understand how their information will be used.", 
    "Makena believes that protecting the privacy of Investors' non-public personal information is of the utmost importance, and we are committed to maintaining the privacy of Investors' non-public personal information in our possession."
  ],
  "Collection of Personal Information": [
    "In connection with making available investment products and services to current and prospective clients and investors as well as to fulfill regulatory requirements, we collect and process personal information directly from you.  This information may include, but is not limited to, your name, address, email address and other contact details, date of birth, employment information, details from passports or other government issued documents (including identifying numbers, country and date of issuance, and expiration date), social security number (where permitted) or other tax or national identification numbers, account name, account number, financial institution, wire transfer instructions and other financial status (including source of funds), investor profile, and suitability information as required to determine eligibility to invest.  We may also collect personal information, such as the name, address, email address and other contact details, date of birth, social security number (where permitted) or other tax or national identification number of your individual representatives, underlying investors and/or beneficial owners, and control persons, where applicable.", 
    "In addition to collecting personal information directly from you, we collect personal information from other sources, as detailed further below."
  ],
  "Source of Personal Information": [
    "We collect and receive non-public personal information from the following sources: (i) information we or our representatives receive from you in conversations, through written correspondence, or information set out in any subscription agreements, investor questionnaires and related fund documents; (ii) documents prepared in connection with your transactions and interactions with Makena and/or the Funds; (iii) client due diligence information we receive from you or third party sources; and (iv) information captured on websites or investor portals operated by us or our affiliates.",
    "We use your non-public personal information (i) to comply with our legal and regulatory obligations and (ii) when such information is necessary to enter into contractual relationships with third parties."
  ],
  "Uses of Personal Information": [
    "We do not sell your non-public personal information to anyone. ",
    "We do not disclose your non-public personal information to anyone, other than to:", 
    "- Our affiliates for proper business purposes in connection with the provision of management and other services to the Funds and Investors;", 
    "- Non-affiliated third parties (such as brokers, custodians, attorneys, auditors, administrators and investment funds and their respective managers) as necessary for us to provide agreed upon services and products to you and the Funds consistent with applicable law. Such entities are not allowed to use your non-public personal information for their own purposes and are contractually obligated to maintain strict confidentiality. We limit their use of your non-public personal information to the performance of the specific services we have requested;",
    "- You, persons we believe to be your authorized agent or representative, and, if compelled to do so, regulators and courts in order to satisfy our regulatory and other obligations to such entities in accordance with applicable law;", 
    "- Any central bank, governmental, regulatory or tax authority in connection with any direction, request or requirement (whether or not having the force of law); and",
    "- Other parties pursuant to your express consent and to fulfill your instructions."
  ],
  "Protection of Personal Information": [
    "We seek to carefully safeguard your private information and, to that end, restrict access to non-public personal information about you to those employees and other persons who need to know the information to enable Makena to provide services to you. We maintain physical, electronic and procedural safeguards to protect your non-public personal information."
  ],
  "Transfer of Personal Information": [
    "Personal information may be transferred to or shared with entities located in the United States, the European Economic Area (\"EEA\") or other jurisdictions, including jurisdiction where data protection laws might be of a lower standard than in the United States and the EEA.  Safeguards have been put in place to ensure your personal information remains adequately protected when such information is being transferred outside of the United States and the EEA."
  ],
  "Retention of Personal Information": [
    "We will retain your personal information for as long as necessary to fulfill the purposes set forth in this Privacy Notice or as long as we are legally required or permitted to do so."
  ],
  "Rights under the European Union's General Data Protection Regulation": [
    "The rights described below are applicable only if you reside in a jurisdiction where such provisions are required.",
    "You have, in certain circumstances, (i) the right to access your personal information; (ii) the right to correct, erase or transfer your personal information to other organizations; (iii) the right to object to processing of your personal information; (iii) the right to withdraw your consent to the processing of your personal information if such consent has been previously given; or (iv) the right to object to direct marketing at any time.  While we strongly encourage you to first raise any questions or concerns about your personal data directly with us, you have a right to submit a complaint with the relevant supervisory authority.",  
    "If you would like to exercise any of your rights or have any questions concerning this Privacy Notice, please email clientoperations@makenacap.com."
  ]
};
