import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import CardPanel from '../cardPanel/CardPanel';
import PieChart from '../pieChart/PieChartCustom';

const ExposureChartList = 
({ 
  charts = [] 
}) => charts.map(
  ({ 
    id, 
    title,
    data = {}
  }) => (
    !isEmpty(data) && (
      <CardPanel 
        key={id} 
        panelStyle='cardLightGrey cardPanel'
      >
        <PieChart 
          data={data} 
          title={title} 
        />
      </CardPanel>
    )
  ));

ExposureChartList.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.shape(
    {
      data: PropTypes.object,
      id: PropTypes.string,
      title: PropTypes.string
    }))
};

export default ExposureChartList;