import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './modal.local.less';


class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
    this.handleOnOverlayClick = this.handleOnOverlayClick.bind(this);
  }
  async handleOnOverlayClick(event) {
    event.stopPropagation();
    if (event.target.className !== styles.modalOverlay) return;
    await this.setState({open: false});
    if (!this.props.handleOnClose) return;
    this.props.handleOnClose();
  }

  render() {
    const { children } = this.props;
    if (!this.state.open) return null;
    return (
      <div className={styles.modalOverlay} onClick={this.handleOnOverlayClick}>
        <div className={styles.modalContainer}>
          {children}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  handleOnClose: PropTypes.func,
}

export default Modal;
