import * as R from "ramda";
import _ from "lodash";
import getApiRootUrl from "../../helpers/apiHelpers/getApiRootUrl";
import API_URLS from "../../app/constants/apiUrls";
import requestWrapper from "../../helpers/apiHelpers/requestWrapper";
import { getErrorMessage } from "../errors/errorHelper";
import { resetUserDimensions } from "../../helpers/gaHelpers/gaHelpers";
import { makenaClientByIdFetch } from "../makenaClient/makenaClientService";
import { authLoginSuccess } from "./authActions";

import ERRORS from "../../app/constants/errors";
import { ACCESS_LEVEL } from "../../app/constants/index";

export const getDefaultSettings = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem("settings"));
  } catch (e) {
    return { selectedClient: null };
  }
};
export const setDefaultSettings = (settings) => {
  try {
    return window.sessionStorage.setItem(
      "settings",
      JSON.stringify({ ...settings })
    );
  } catch (e) {
    return null;
  }
};

export const getAuthToken = () => window.sessionStorage.getItem("authToken");

export const setAuthToken = (authToken) =>
  window.sessionStorage.setItem("authToken", authToken);

export const getRefreshToken = () =>
  window.sessionStorage.getItem("refreshToken");

export const setRefreshToken = (refreshToken) =>
  window.sessionStorage.setItem("refreshToken", refreshToken);

export const setUserProfile = (userProfile) =>
  window.sessionStorage.setItem("userProfile", JSON.stringify(userProfile));

export const getUserProfile = () => {
  const userProfile = window.sessionStorage.getItem("userProfile");

  try {
    return userProfile && JSON.parse(userProfile);
  } catch (ex) {
    return null;
  }
};

export const getSettings = () => _.get(getUserProfile(), "settings", {});

export const clearStorage = () => {
  window.sessionStorage.clear();
};

export const parseAuthResponse = (res) => {
  const authToken = res.headers["x-auth-token"];
  const refreshToken = res.headers["x-refresh-token"];
  const userProfile = res.headers["x-profile"];

  return { authToken, refreshToken, userProfile };
};

export const parseUserCredentials = (response) => {
  const { authToken, refreshToken } = parseAuthResponse(response);
  const userProfile = response.data && response.data.data;

  userProfile.isAdmin = R.compose(
    R.includes(R.__, ["ADMIN", "LIMITED ADMIN"]),
    R.pathOr("USER", ["permissionsJson", "ROLE"])
  )(userProfile);
  userProfile.isLimitedAdmin = R.compose(
    (value) => value === "LIMITED ADMIN",
    R.pathOr("USER", ["permissionsJson", "ROLE"])
  )(userProfile);

  // LEGACY ROLES SUPPORT
  if (!userProfile.isAdmin) {
    userProfile.isAdmin = R.pathOr(
      false,
      ["permissionsJson", "IS_ADMIN_ACCOUNT"],
      userProfile
    );
  }

  return {
    authToken,
    refreshToken,
    userProfile,
  };
};

export const loginAndReturnUserInfo = async (email, password) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.LOGIN}`;
    const response = await requestWrapper.post(url, { email, password });
    const { authToken, refreshToken, userProfile } =
      parseUserCredentials(response);

    return {
      authToken,
      refreshToken,
      userProfile,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.AUTH_LOGIN_ERROR);
    throw new Error(errorMessage);
  }
};

export const registerActiveSession = async (response, dispatch) => {
  const { authToken, refreshToken, userProfile } =
    parseUserCredentials(response);

  clearStorage();
  resetUserDimensions();
  setAuthToken(authToken);
  setRefreshToken(refreshToken);
  setUserProfile(userProfile);

  const clients = getClientList(userProfile);

  if (clients.length && userProfile.level == ACCESS_LEVEL.FULL) {
    await makenaClientByIdFetch(getDefaultClientId(clients))(dispatch);
  }

  dispatch(authLoginSuccess(userProfile));
};

export const generateRandom = (len = null) => {
  const validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.charCodeAt(x % validChars.length));
  const randomState = String.fromCharCode.apply(null, array);

  if (len) {
    return randomState.substring(0, len);
  }
  return randomState;
};

export const getDefaultClient = R.compose(
  R.find(R.propEq("default", 1)),
  R.defaultTo([])
);

export const getDefaultClientId = R.compose(R.prop("id"), getDefaultClient);

export const getClientList = (profile) => {
  const { clients } = profile;
  let subAccounts = clients;

  if (typeof clients === "string" || clients instanceof String) {
    try {
      try {
        subAccounts = JSON.parse(clients);
      } catch {
        subAccounts = JSON.parse(`[${subAccounts}]`);
      }
    } catch (ex) {
      subAccounts = [];
    }
  }

  return Array.isArray(subAccounts) ? subAccounts : [subAccounts];
};
