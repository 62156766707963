import React, { Component }      from 'react';
import PropTypes                 from 'prop-types';
import cx                        from 'classnames';
import { get, pick, tail, take } from 'lodash';
import moment                    from 'moment';

import SectionLink               from '../sectionLink/SectionLink';
import { rounder }               from '../../helpers/formatters/formatters';
import { getSettings }           from '../../services/auth/authHelper';
import styles                    from './MakenaAssetPublicPerformanceTable.local.less';

const columns = [
  '_',
  'QTD',
  '1 Year',
  '3 Year Ann.',
  '5 Year Ann.'
];

const parseColumnField = {
  'QTD': 'Adjusted TWR (Current Quarter, USD)',
  '1 Year': '1 Yr. Adjusted TWR (USD)',
  '3 Year Ann.' : '3 Yr. Annualized Adjusted TWR (USD)',
  '5 Year Ann.': '5 Yr. Annualized Adjusted TWR (USD)',
  '1 Year Standard Deviation': '1 Yr. Adjusted Sample Standard Deviation (USD, Returns, Extrapolated to One Year)',
  '3 Year Ann. Standard Deviation' : '3 Yr. Adjusted Sample Standard Deviation (USD, Returns, Extrapolated to One Year)',
  '5 Year Ann. Standard Deviation': '5 Yr. Adjusted Sample Standard Deviation (USD, Returns, Extrapolated to One Year)',
};

const formatSettings = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
};

const getValue = (asset, field, isInvalid) => {
  if (isInvalid) return ' - ';
  let value = get(asset, `${parseColumnField[field]}`) * 100;
  if (!value) return ' - ';
  value = rounder(formatSettings, value, 1);
  return parseFloat(value)? `${value}%` : ' - ';
}

class MakenaAssetPublicPerformanceTable extends Component {

  state = {
    isLoading: true,
    lastQuarter: null
  };

  componentDidMount() {
    this.startLoading();
    const lastQuarter = getSettings().reportingQuarterEnd;
    this.finishLoading(lastQuarter);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (lastQuarter) => {
    this.setState({
      lastQuarter: lastQuarter,
      isLoading: false,
    });
  };

  transformValues = item => {
    const { 
      props: { fund: { inceptionDate } },
      state: { lastQuarter } 
    } = this;
    
    const _inceptionDate = moment.utc(inceptionDate);
    const quarterDate = moment.utc(lastQuarter);
    const diff = quarterDate.diff(_inceptionDate, 'years');
  
    return {
      '_': item.name,
      'QTD': getValue(item, 'QTD'),
      '1 Year': getValue(item, '1 Year', diff < 1),
      '3 Year Ann.': getValue(item, '3 Year Ann.', diff < 3),
      '5 Year Ann.': getValue(item, '5 Year Ann.', diff < 5),
      'QTD Standard Deviation': '-',
      '1 Year Standard Deviation': getValue(item, '1 Year Standard Deviation', diff < 1),
      '3 Year Ann. Standard Deviation': getValue(item, '3 Year Ann. Standard Deviation', diff < 3),
      '5 Year Ann. Standard Deviation': getValue(item, '5 Year Ann. Standard Deviation', diff < 5),
    }
  };
  
  getTotal = () => {
    const {
      transformValues,
      props: { overview: { total: { children: { data: { assetClasses: { data } } } } }, assetClass: { title } }
    } = this;
    
    const { values } = data[title];
    
    return transformValues({ ...values, ...columns, name: `Total` });
  };

  getBenchmarks = (dashQTD) => {
    const {
      props: {
        assetClass: { benchmarks },
        overview: { benchmarks: { data } }
      },
      transformValues
    } = this;
    
    return Object.values(pick(data, benchmarks))
      .map(({ name, values }) => ({ name, ...values }))
      .map(transformValues)
      .map(benchmark => {
        if (dashQTD) benchmark.QTD = ' - ';
        return benchmark;
      });
  };

  render() {
    const {
      getBenchmarks,
      getTotal,
      props: { assetClass: { displayName } },
      state: { lastQuarter }
    } = this;
    if (!lastQuarter) return null;
    
    const firstColumn = columns[0];
    const dataColumns = take(tail(columns), columns.length - 1);
    const total = getTotal();
    const benchmarks = getBenchmarks(!total || !total.QTD || total.QTD === ' - ');
    
    return (
      <div className='table'>
        <div className={cx('tableRow tableRowHeader', styles.assetRow)}>
          {
            columns.map(
              (column, index) => (
                <div 
                  key={index} 
                  className='tableRowCell'
                >
                  {column !== '_' && column}
                </div>
              ))
          }
        </div>

        <SectionLink className={cx('tableRow', styles.assetRow, 'total')}>
          <div className='tableRowCell bold big first'>
            {displayName}
          </div>
          {
            dataColumns.map((column, index) => {
              return (
                <div 
                  key={index}
                  className='tableRowCell bold big' 
                >
                  {total[column]}
                </div>
              )
            })
          }
          <div className='tableRowCell last' />
        </SectionLink>
        {
          benchmarks.map((row, index) => (
            <SectionLink
              key={index}
              className={`tableRow ${styles.assetRow} ${styles.private}`}
            >
              <div className='tableRowCell italic first'>
                {row[firstColumn]}
              </div>
              {
                dataColumns.map((column, idx) => (
                  <div
                    key={idx}
                    className='tableRowCell italic'
                  >
                    {row[column]}
                  </div>
                ))
              }
            </SectionLink>
          ))
        }
      </div>
    )
  } 
}

MakenaAssetPublicPerformanceTable.propTypes = {
  assetClass: PropTypes.object,
  fund: PropTypes.object,
  overview: PropTypes.object
};

export default MakenaAssetPublicPerformanceTable;
