import React from 'react';
import PropTypes from 'prop-types';
import styles from './splitHeaderRenderer.local.less';

/**
 * 
 * @param {object} header 
 * @example header {
      left: {
        html: false (default)
        value: "Our Review",
      },
      right: {
        html: true,
        value: "<a href='seemorelink'>See More</a>",
        styles: "rightHeaderStyle"
      }
    };} content 
 */

const renderContent = (content) => {
  if (content.html) {
    const htmlContent = {__html: content.value };
    return (
      <div dangerouslySetInnerHTML={htmlContent} className={content.styles}></div>
    )
  }
  return content.value;
}

const SplitHeaderRenderer = (header) => {
  let headerLeftStyles = header.left.styles? `${styles.left} ${header.left.styles}` : styles.left;
  let headerRightStyles = header.right.styles? `${styles.right} ${header.right.styles}` : styles.right;

  return (
    <div className={styles.splitHeader}>
      <div className={headerLeftStyles}>
        {renderContent(header.left)}
      </div>
      <div className={headerRightStyles}>
        {renderContent(header.right)}
      </div>
    </div>
  );
}

SplitHeaderRenderer.propTypes = {
  header: PropTypes.shape({})
};

export default SplitHeaderRenderer;