import { combineReducers }            from 'redux';
import { reducer as formReducer }     from 'redux-form';
import authReducer                    from './services/auth/authReducer';
import passwordResetReducer           from './services/passwordReset/passwordResetReducer';
import accountReducer                 from './services/account/accountReducer';
import reportingReducer               from './services/reporting/reportingReducer';
import documentReducer                from './services/document/documentReducer';
import makenaClientReducer            from './services/makenaClient/makenaClientReducer';
import makenaAssetClassReducer        from './services/makenaAssetClass/makenaAssetClassReducer';
import makenaAssetClassUpdateReducer  from './services/makenaAssetClassUpdate/makenaAssetClassUpdateReducer';
import makenaFundReducer              from './services/makenaFund/makenaFundReducer';
import makenaPeopleReducer              from './services/makenaPeople/makenaPeopleReducer';
import makenaAllManagersReducer       from './services/makenaManagers/makenaManagerReducer';
import appHierarchyReducer            from './services/appHierarchy/appHierarchyReducer';
import adminReducer                   from './services/admin/adminReducer';
import errorReducer                   from './services/errors/errorReducer';
import menuReducer                    from './services/menu/menuReducer';
import settingsReducer                from './services/settings/settingsReducer';
import exploreReducer                 from './services/explore/exploreReducer';
import { ACTIONS }                    from './app/constants/index';


const appReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  account: accountReducer,
  makenaClient: makenaClientReducer,
  makenaAssetClasses: makenaAssetClassReducer,
  makenaAssetClassUpdates: makenaAssetClassUpdateReducer,
  makenaFunds: makenaFundReducer,
  makenaPeople: makenaPeopleReducer,
  makenaAllManagers: makenaAllManagersReducer,
  reporting: reportingReducer,
  appHierarchy: appHierarchyReducer,
  passwordReset: passwordResetReducer,
  errors: errorReducer,
  document: documentReducer,
  adminData: adminReducer,
  menu: menuReducer,
  settings: settingsReducer,
  explore: exploreReducer
});

const reducers = (state, action, ...rest) => {
  if (action.type === ACTIONS.RESET_STATE) {
    state = undefined;
  }

  return appReducer(state, action, ...rest);
};

export default reducers;
