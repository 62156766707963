import { ACTIONS } from '../../app/constants/index';


export const authLoginSuccess = userProfile => ({
  type: ACTIONS.AUTH_LOGIN_SUCCESS,
  userProfile,
});

export const authLogoutAndResetState = () => ({
  type: ACTIONS.RESET_STATE,
});


export const authUpdateUserProfile = userProfile => ({
  type: ACTIONS.AUTH_UPDATE_USER_PROFILE,
  userProfile,
});
