import { ACTIONS } from '../../app/constants/index';
import { getClientInfo } from './makenaClientHelper';

const INITIAL_STATE = getClientInfo();

export default function (state = INITIAL_STATE, action = { makenaClient: {}, type: '' }) {
  switch (action.type) {
    case ACTIONS.MAKENA_CLIENT_BY_ID_FETCH_SUCCESS: {
      return action.makenaClient;
    }
    case ACTIONS.ADMIN_CREATE_CLIENT_SUCCESS: 
    case ACTIONS.ADMIN_UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        newClient: action.makenaClient
      };
    }
    case ACTIONS.MAKENA_CLIENT_RESET: {
      return {};
    }

    default:
      return state;
  }
}
