import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Svg from '../svg/Svg';

class ActionDropdown extends Component {

  onChange = (ev, { value }) => {
    const { onChange = () => {} } = this.props;
    onChange(value);
  }

  render() {
    const {
      key, 
      actionIcon,
      actionText,
      value,
      options = [],
      onAction = () => {}
    } = this.props;
    return (
      <div key={key} className="input-group">
        <Dropdown className="action-right" 
          fluid
          search
          selection
          value={value} options={options} onChange={this.onChange}
        />
        <button className="action right dropdown" type="button" onClick={onAction}>
          { actionIcon? <Svg svg={actionIcon} /> : actionText }
        </button>
      </div>
    )
  }
}

ActionDropdown.propTypes = {
  key         : PropTypes.any,
  actionIcon  : PropTypes.any,
  actionText  : PropTypes.string,
  options     : PropTypes.array,
  value       : PropTypes.any,
  onChange    : PropTypes.func,
  onAction    : PropTypes.func,
}

export default ActionDropdown;