import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import NavItem              from '../navItem/NavItem';
import SideBarManager       from './SideBarManager';
import styles               from './sidebar.local.less';

class SideBarAsset extends Component { 

  state = { 
    isOpen: this.props.activeLevels.activeProductId === this.props.fund.entityId && 
            this.props.activeLevels.activeAssetClassName === this.props.assetClass
  };

  componentDidUpdate(prevProps) {
    const { activeLevels } = prevProps;
    const { activeLevels: { activeProductId, activeAssetClassName }, fund: { entityId }, assetClass } = this.props;
    if (activeLevels.activeProductId === activeProductId && 
      activeLevels.activeAssetClassName === activeAssetClassName) return;
    this.setState({ 
      isOpen: activeProductId === entityId && activeAssetClassName === assetClass
    });
  }

  onToggle = isOpen => this.setState({ isOpen })

  render() {
    const {
      state: { isOpen = false },
      props: {
        activeLevels,
        managers,
        assetClasses,
        assetClass,
        fund
      },
      onToggle
    } = this;
    const { entityId } = fund;
    const makenaAssetClass = assetClasses.find(_assetClass => _assetClass.title === assetClass);
    const { displayName, title } = makenaAssetClass;
    const { activeProductId, activeAssetClassName } = activeLevels;
    const isActive = entityId === activeProductId && title === activeAssetClassName;
    return (
      <div className={styles.assets}>
        <NavItem
          key={entityId}
          isActive={isActive}
          linkName={displayName}
          showArrow={true}
          onToggle={onToggle}
          linkTo={`/dashboard/${entityId}/${encodeURIComponent(assetClass)}/`}
        />
        { 
          isOpen? fund.assetClasses[assetClass].managers.map(manager => {
            return (<SideBarManager
              key={`NAV_${entityId}_${assetClass}_${manager}`}
              fund={fund}
              assetClass={makenaAssetClass}
              manager={manager}
              managers={managers}
              activeLevels={activeLevels}
            />)
          }) : null
        }
      </div>
    )
  }
}

SideBarAsset.propTypes = {
  activeLevels: PropTypes.object,
  managers: PropTypes.array,
  assetClasses: PropTypes.array,
  fund: PropTypes.object,
  assetClass: PropTypes.string
};

SideBarAsset.defaultProps = {
  activeLevels: {},
  managers: [],
  assetClasses: [],
  fund: {},
  assetClass: ''
};

export default SideBarAsset;