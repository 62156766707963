import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';
import Badge                from './../badge/Badge';
import styles               from './datatable.local.less';

const getRandom = () => Math.floor(Math.random() * 1000000) + 1;

class DatatableFilterBadges extends Component {

  onChange = ({filterField, filterOptions}) => (content, value) => {
    const { onFilterOptionClicked = () => {} } = this.props;
    const options = _.filter(filterOptions, ({ filterSelected, name }) => filterSelected && name !== value);
    onFilterOptionClicked({ [filterField]: _.map(options, 'name') })
  }

  onClear = () => {
    const { onFilterOptionClicked = () => {}, headers: { columns = [] } } = this.props;
    let options = {};
    _.forEach(columns, ({filterField}) => filterField? options[filterField] = [] : null);
    onFilterOptionClicked({...options, date: []});
  }

  renderBadge = (keyGroup, optionKey, column, name, title) => {
    const content = column.title? `${column.title}: ${title}` : title;
    return (
      <div key={`${keyGroup}_badge_${optionKey}`} className={styles.badgeSpacing}>
        <Badge value={name.toString()} content={content} onClick={this.onChange(column)}></Badge>
      </div>
    );
  }

  renderBadges = columns => {
    const options = _.flatten(_.map(columns, (column, columnKey) => {
      const _options = _.filter(column.filterOptions, ({ filterSelected }) => filterSelected);
      const keyGroup = `${getRandom()}_badgeGroup_${columnKey}`;
      return _.map(_options, ({title, name}, optionKey) => ({ keyGroup, optionKey, column, name, title }));
    }));
    return _.map(options, badge => this.renderBadge(badge.keyGroup, badge.optionKey, badge.column, badge.name, badge.title));
  }

  render(){ 
    const { headers: { columns = [] } } = this.props;
    const badges = this.renderBadges(columns);
    let showAll = false;
    _.forEach(badges, badge => badge.props.children.props.value? showAll = true : null);
    return (
      <div className={`${styles.badgesContainer} statusBarItem`}>
        <div className={styles.badges}>
          {
            showAll? (
              <div className={styles.badgeSpacing}>
                <Badge content="Clear All" type="dark" onClick={this.onClear}/>
              </div>
            ) : <div className={styles.noBadges}>No Filters Selected</div>
          }
          { badges }
        </div>
      </div>
    );
  
  }
}

DatatableFilterBadges.propTypes = {
  onFilterOptionClicked: PropTypes.func,
  headers: PropTypes.object
}

export default DatatableFilterBadges;