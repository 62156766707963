import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda'
import _ from 'lodash';
import moment from 'moment';

import Card from '../../components/card/Card';
import CardBody from '../../components/card/CardBody';
import CardHeader from '../../components/card/CardHeader';
import SectionLink from  '../sectionLink/SectionLink';
import Svg from '../svg/Svg';

import { rounder } from '../../helpers/formatters/formatters';
import { ROUTES } from '../../app/constants/index';
import { getSettings } from '../../services/auth/authHelper';
import styles from './portfolio.local.less';
import More from '../../assets/svg/icon-arrow-right-blue.svg';

const EnumTags = Object.freeze(
  {
    YEAR_1: '1 Yr. Adjusted TWR (USD)',
    YEAR_5: '5 Yr. Annualized Adjusted TWR (USD)',
    YEAR_10: '10 Yr. Annualized Adjusted TWR (USD)',
    VALUE_TAG: 'Adjusted Value (USD)',
  }
);

const formatSettingsNoDecimals =  {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};
const formatSettingsOneDecimal =  {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
};

const getProductPath = id => ROUTES.DASHBOARD.children.MAKENA_PRODUCT.path.replace(':productId', id);

const getDisplayName = (funds, { entity_id }) => funds.find(({ entityId }) => entityId === entity_id);
class Portfolio extends Component {
  state = {
    isLoading: true,
    lastQuarter: null
  };

  componentDidMount() {
    this.startLoading();
    const lastQuarter = getSettings().reportingQuarterEnd;

    this.finishLoading(lastQuarter);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (lastQuarter) => {
    this.setState({
      lastQuarter: lastQuarter,
      isLoading: false,
    });
  };

  products = () => {
    const { overview, unclickableFunds } = this.props;

    const benchmarkProduct = _.get(overview, 'benchmarks.0', {});
    let benchmark = this.parseProduct({...benchmarkProduct.values, name: benchmarkProduct.name });
    benchmark = { ...benchmark, isBenchmark: true };
    let products = this.getChildren(overview.total.children, unclickableFunds);
    if (products.length === 0) return [benchmark];
    if (products.length === 1) return [ {...products[0], allBold: true}, benchmark];
    const totalRow = this.parseProduct({...overview.total.values, name: 'Total'});
    return [...this.sortProducts(products), {...totalRow, isTotal: true }, benchmark];
  }

  sortProducts = products => {
    products = products.map( product => {
      let sortField = parseFloat(product.value.split(',').join(''));
      return {
        sortField: _.isNumber(sortField) ? sortField : 0,
        ...product
      }
    });
    return _.values(_.orderBy(products, ['sortField'], ['desc']), 'sortField');
  };

  parseValue = (settings, value, multiplier = 1) => {
    if (!value) return ' - ';
    value = rounder(settings, value * multiplier, 1);
    return parseFloat(value)? `${value}` : ' - ';
  };


  parseYear = (value, years, date) => {
    const { lastQuarter } = this.state;
    const parsedValue = this.parseValue(formatSettingsOneDecimal, value, 100);
    switch(years) {
      case 1: return moment.utc(lastQuarter).diff(date, 'days') >= 363 ? parsedValue : ' - ';
      case 5:
      case 10: return moment.utc(lastQuarter).diff(date, 'years') >= years ? parsedValue : ' - ';
      default: return ' - ';
    }
  }

  parseProduct = product => {
    const inceptionDate = product['Inception Date'] ? moment.utc(product['Inception Date']) : moment.utc('1900/1/1');
    return {
      name: product.name,
      path: product.isClickable ? getProductPath(product.id) : null,
      value: this.parseValue(formatSettingsNoDecimals, product[EnumTags.VALUE_TAG]),
      year1: this.parseYear(product[EnumTags.YEAR_1], 1, inceptionDate),
      year5: this.parseYear(product[EnumTags.YEAR_5], 5, inceptionDate),
      year10: this.parseYear(product[EnumTags.YEAR_10], 10, inceptionDate)
    }
  };

  getChildren = (overviewChildren, unclickableFunds) => {
    const children = _.values(_.get(overviewChildren, 'data', {}));

    return children.map(
      child => this.parseProduct({
        ...child.values,
        name: getDisplayName(this.props.funds, child) ? getDisplayName(this.props.funds, child).displayName : child.name,
        id: child.entity_id,
        isClickable: _.has(child, 'entity_id') && !_.find(unclickableFunds, fund => fund == child.entity_id)
      })
    );
  };

  render() {

    if (this.state.isLoading) return null;

    const products = this.products();

    const hasNoValue = R.propEq('value', ' - ');

    const newProducts = R.reject(hasNoValue, products);

    const max = _.maxBy(newProducts, x => x.value.length);

    return (
      <Card
        className={styles.portfolio}
        layoutStyle={this.props.layoutStyle}
        trackEventLabel='Your Portfolio'
        trackEventCategory='Fund'
      >
        <CardHeader>
          Your Portfolio
        </CardHeader>
        <CardBody>
          <div className={styles.portfolio}>
            <div className={`${styles.portfolioRow} ${styles.header}`}>
              <div className={styles.portfolioRowCell}>
                Fund
              </div>
              <div className={`${styles.portfolioRowCell} ${styles.right}`}>
                <div className={styles.hackFlex}>
                  <span>${max.value}</span>
                  <div>NAV</div>
                </div>
              </div>
              <div className={`${styles.portfolioRowCell} ${styles.center}`}>
                1 Year TWR
              </div>
              <div className={`${styles.portfolioRowCell} ${styles.center}`}>
                5 Year Ann. TWR
              </div>
              <div className={`${styles.portfolioRowCell} ${styles.center}`}>
                10 Year Ann. TWR
              </div>
              <div className={`${styles.portfolioRowCell} ${styles.center}`}></div>
            </div>
            {
              newProducts.map( (product, index) => {
                const boldStyle = `${styles.portfolioRowCell} ${styles.bold}`;
                const centerStyle = `${styles.portfolioRowCell} ${styles.center}`;
                const italicStyle = `${styles.portfolioRowCell} ${styles.italic}`;
                const rightStyle = `${styles.portfolioRowCell} ${styles.right}`;
                const boldCenterStyle = `${styles.portfolioRowCell} ${styles.center} ${styles.bold}`;
                const boldRightStyle = `${styles.portfolioRowCell} ${styles.right} ${styles.bold}`;
                const italicCenterStyle = `${styles.portfolioRowCell} ${styles.center} ${styles.italic}`;
                const italicRightStyle = `${styles.portfolioRowCell} ${styles.right} ${styles.italic}`;
                const rowStyle = `${styles.portfolioRow} ${product.isTotal ? styles.total : ''}`;
                const cellStyle = `${product.allBold ? styles.bold : ''}`;

                const nameColumnStyle = product.isBenchmark ? italicStyle : boldStyle;
                const rightColumn = product.isTotal ?
                  boldRightStyle :
                  ( product.isBenchmark ? italicRightStyle : rightStyle );
                const centerColumn = product.isTotal ?
                  boldCenterStyle :
                  ( product.isBenchmark ? italicCenterStyle : centerStyle );

                const lastColumn = `${styles.portfolioRowCell} ${styles.right} ${styles.rightPadding}`;
                return (
                  <SectionLink
                    key={index}
                    className={rowStyle}
                    to={product.path}
                  >
                    <div className={`${cellStyle} ${nameColumnStyle}`}>
                      { product.name }
                    </div>

                    <div className={`${cellStyle} ${rightColumn}`}>
                      { (product.value === ' - ' ? '' : '$') + product.value }
                    </div>

                    <div className={`${cellStyle} ${centerColumn}`}>
                      {
                        product.year1 + (product.year1 === ' - ' ? '' : '%')
                      }
                    </div>

                    <div className={`${cellStyle} ${centerColumn}`}>
                      {
                        product.year5 + (product.year5 === ' - ' ? '' : '%')
                      }
                    </div>

                    <div className={`${cellStyle} ${centerColumn}`}>
                      {
                        product.year10 + (product.year10 === ' - ' ? '' : '%')
                      }
                    </div>
                    {
                      product.path
                        ? (
                          <div className={lastColumn}>
                            <NavLink to={product.path}>
                              <Svg svg={More}/>
                            </NavLink>
                          </div>
                        )
                        : <div/>
                    }
                  </SectionLink>
                );
              })
            }
          </div>
        </CardBody>
      </Card>
    );
  }
}

Portfolio.propTypes = {
  overview: PropTypes.shape({
    total: PropTypes.string
  }),
  layoutStyle: PropTypes.string,
  funds: PropTypes.array,
  unclickableFunds: PropTypes.arrayOf(PropTypes.string),
};

export default Portfolio;
