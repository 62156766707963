import checkmark  from '../../assets/svg/checkmark-white.svg';
import dash       from '../../assets/svg/dash-white.svg';

export const toggleParser = {
  'all':  { icon: checkmark, value: true },
  'some': { icon: dash, value: true },
  'none': { icon: null, value: false }
};

export const switcher = { 'all': 'none', 'none': 'all', 'some': 'none' };
