import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import connect                    from 'react-redux/es/connect/connect';
import TaxElectionFormList        from './taxElectionFormList';
import TaxElectionFormDetails     from './taxElectionFormDetails';

class TaxElectionFormContainer extends Component {
  render() {
    const { match: { params: { uuid } } } = this.props;
    if (uuid) {
      return <TaxElectionFormDetails {...this.props} />;
    }
    return <TaxElectionFormList { ...this.props }/>;
  }
}

TaxElectionFormContainer.propTypes = {
  match: PropTypes.any
};

function mapStateToProps(state) {
  return { sideBarOpen: state.menu.open };
}

export default connect(
  mapStateToProps
)(TaxElectionFormContainer);
