import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import Svg                  from '../svg/Svg';
import _                    from 'lodash';
import moment               from 'moment';

import { yearFilterRange } from '../../helpers/quarterHelpers/quarterHelpers';
import ToggleButton from '../toggleButton/ToggleButton';
import chevronUp    from '../../assets/svg/gray-chevron-up.svg';
import chevronDown  from '../../assets/svg/gray-chevron-down.svg';
import styles       from './quartersAccordion.local.less';


const DEFAULT_DISPLAY_COUNT = 2;
const DISPLAY_MULTIPLE = 4;
const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

class QuartersAccordion extends Component {

  state = {
    collapsed: false,
    data: {},
    increment: 0,
    displayYearCount: DEFAULT_DISPLAY_COUNT,
    yearOptions: []
  };

  componentDidMount = () => {
    const { displayYearCount } = this.state;
    const yearOptions = yearFilterRange(moment.utc().year(), displayYearCount);
    this.setState({ yearOptions });
  }

  componentDidUpdate = prevProps => {
    if (!prevProps.reset && !this.props.reset) return;
    if (prevProps.reset && !_.isEmpty(this.state.data)) return;
    if (prevProps.reset === this.props.reset) return;
    this.setState({ data: {} })
  }

  onChange = (year, quarter) => toggled => {
    const {
      props: { onChange = () => {}, dates }
    } = this;

    const name = `${year}-${quarter}`;
    let newData = {};
    _.forOwn(dates, date => newData = {...newData, [date['name']]: date['filterSelected']})
    newData = { ...newData, [name]: toggled };
    this.setState({ data: newData });

    const output = [];

    _.forEach(newData, (value, key) => {
      if (!value) return;
      output.push(key);
    });

    onChange(output);
  };

  handleMoreYearsClick = () => {
    const { dates } = this.props;
    let totalYearCount = Math.ceil((dates.length / 4) / 2) * 2; // in case we get an odd number we should treat it as a multiple of 2
    let filterDisplayCount = dates.length / 4;


    const yearOptions = yearFilterRange(moment.utc().year(), filterDisplayCount);

    this.setState(prevState => {
      let newIncr = prevState.increment + 1;
      const computed = (newIncr * DISPLAY_MULTIPLE); // 4
      if (computed > totalYearCount) { //
        newIncr = prevState.increment;
      }
      else {
        filterDisplayCount = computed;
      }
      return {
        displayYearCount: filterDisplayCount,
        yearOptions,
        increment: newIncr
      }
    });
  }

  handleFewerYearsClick = () => {
    const { increment } = this.state;
    let newIncr = increment;

    if (newIncr > 0) {
      newIncr--;
    }

    const computed = (newIncr * DISPLAY_MULTIPLE);
    let filterDisplayCount = computed > DEFAULT_DISPLAY_COUNT ? computed : DEFAULT_DISPLAY_COUNT;

    const yearOptions = yearFilterRange(moment.utc().year(), filterDisplayCount);

    this.setState({
      displayYearCount: filterDisplayCount,
      yearOptions,
      increment: newIncr
    });
  }

  handleCollapse = () => this.setState({ ...this.state, collapsed: !this.state.collapsed });

  isToggled = (year, quarter) => {
    const { dates } = this.props;
    const active = _.find(dates, date => date.name == `${year}-${quarter}`) || { name: `${year}-${quarter}`, title: `${quarter} ${year}`, block: true };
    return active.filterSelected;
  }

  hasMoreYears = () => {
    const { dates } = this.props;
    let totalYearCount = Math.ceil((dates.length / 4) / 2) * 2;  // in case we get an odd number we should treat it as a multiple of 4
    const { displayYearCount } = this.state;
    return totalYearCount > displayYearCount;
  }

  hasFewerYears = () => {
    const { increment } = this.state;
    return increment > 0;
  }

  render () {
    const {
      props: { title, reset },
      state: { collapsed, yearOptions }
    } = this;
    const hasMore = this.hasMoreYears();
    const hasFewer = this.hasFewerYears();
    return (
      <div className={styles.accordion}>
        <div className={styles.accordionTopBar}>
          <div className={styles.accordionTitle}>{title}</div>
          <div className={styles.accordionIcon} onClick={this.handleCollapse}><Svg svg={ collapsed? chevronDown : chevronUp }/></div>
        </div>
        <div className={`${styles.accordionBody} ${collapsed? styles.hidden : ''}`}>
          { yearOptions.map(year => (
            <div className={styles.accordionOption} key={year}>
              <span className={styles.year}>{ year }</span>
              <div className={styles.quarters}>
                {
                  quarters.map(quarter => {
                    const disabled = moment.utc(`${year}-${quarter}`, 'YYYY-\QQ').format() > moment.utc().format();
                    return (
                      <ToggleButton key={`${year}-${quarter}`}
                        label={quarter}
                        reset={reset}
                        disabled={disabled}
                        toggled={this.isToggled(year, quarter)}
                        onChange={this.onChange(year, quarter)}/>
                    )
                  })
                }
              </div>
            </div>
          ))
          }
          <div className={styles.accordionActions}>
            { hasFewer && <span className={styles.actionButtons}  onClick={this.handleFewerYearsClick}>Fewer Years</span> }
            { (hasFewer && hasMore) && `|`}
            { hasMore && <span className={styles.actionButtons} onClick={this.handleMoreYearsClick}>More Years</span> }
          </div>
        </div>
      </div>
    );
  }
}

QuartersAccordion.propTypes = {
  title: PropTypes.string,
  reset: PropTypes.any,
  dates: PropTypes.array,
  onChange: PropTypes.func,
}

export default QuartersAccordion;
