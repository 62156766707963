import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SurveyModal from './SurveyModal';
import SurveyTab from './SurveyTab';
import { getStoredSurvey, setStoredSurvey } from '../../services/survey/surveyHelper';
import { getSurvey } from '../../services/admin/survey/surveyService';

class SurveyModule extends Component {

  constructor(props) {
    super(props);
    this.state = {
      completed: true,
      open: false,
      data: {},
      isLoading: true
    };
  }

  async componentDidMount() {
    const data = await getSurvey().catch(() => ({ data: {} }));
    const { completed } = getStoredSurvey(data.uuid);
    this.setState(() => ({ data, isLoading: false, completed, open: !completed }));
  }

  componentDidUpdate(prevProps) {
    if (this.state.completed) return;
    if (prevProps.loggedIn !== this.props.loggedIn && this.props.loggedIn) {
      this.setState(() => ({ completed: false }));
    }
  }

  onTabClicked = () => {
    this.setState(() => ({ open: true }))
  }

  onModalClosed = completed => {
    setStoredSurvey(this.state.data.uuid, completed);
    this.setState(() => ({ completed, open: false }));
  }

  render() {
    const { completed, open, data, isLoading } = this.state;
    if (completed || isLoading) {
      return null;
    }
    
    const {
      tab = `We'd love to hear your feedback!`,
      title = `Important Announcement`,
      action = `Go to Survey`,
      url = ``,
      text = `As Makena Capital continues to strive for the best possible client servicing 
      experience, we want to hear how your feedback on our Investor Portal. 
      Please take a minute to fill out this quick survey and provide us your feedback
      on the Makena Investor Portal.`,
      enabled = false
    } = data || {};

    if (!enabled) {
      return null;
    }

    return <div>
      <SurveyTab onTabClicked={this.onTabClicked} tab={tab}/>
      <SurveyModal open={open} onClose={this.onModalClosed} 
        title={title} action={action} text={text} url={url}
      />
    </div>;
  }
}

SurveyModule.propTypes = {
  loggedIn: PropTypes.bool
}

export default SurveyModule;