import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './tabMenu.local.less';

class TabMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOption: props.activeOption
    }
  }

  handleOnClick = (option) => {
    const { onChange } = this.props;
    this.setState({ activeOption: option });
    if (!onChange) return;
    onChange(option);
  }

  render() {
    const { 
      className, 
      menuOptions, 
      panelStyle 
    } = this.props;

    return (
      <div className={cx(styles.tabMenu, panelStyle, className)}>
        <div className={styles.tabMenuContainer}>
          {
            menuOptions.map( (option, index) => {
              const activeClass = this.state.activeOption === option ? styles.tabMenuItemActive : '';
              return (
                <div
                  key={index}
                  className={`${styles.tabMenuItem} ${activeClass}`}
                  onClick={() => this.handleOnClick(option)}
                >
                  {option}
                </div>
              )}
            )
          }
        </div>
      </div>
    )
  }
}

TabMenu.propTypes = {
  className: PropTypes.string,
  activeOption: PropTypes.string,
  menuOptions: PropTypes.array,
  onChange: PropTypes.func,
  panelStyle: PropTypes.string,
}

export default TabMenu;