import moment from "moment";

export const getQuartersRange = (startDate, endDate) => {
  let year = moment.utc(startDate).year();
  const thisYear = moment(endDate).year();
  const thisQuarter = moment.utc(endDate).quarter();

  const years = [];

  while (year <= thisYear) {
    for (let q = 1; q < 5; q++) {
      if (year === thisYear && q > thisQuarter) continue;
      years.push({
        text: `Q${q} ${year}`,
        key: `Q${q}-${year}`,
        value: `${moment
          .utc(`${year}-${q}`, "YYYY-Q")
          .startOf("quarter")
          .format("YYYY-MM-DD")}/${moment
          .utc(`${year}-${q}`, "YYYY-Q")
          .endOf("quarter")
          .format("YYYY-MM-DD")}`,
      });
    }
    year++;
  }
  return years;
};

export const yearFilterRange = (start, count) => {
  const yearOptions = [];
  let activeYear = start;

  for (let x = count; x > 0; x--) {
    yearOptions.push(activeYear);
    activeYear--;
  }

  return yearOptions;
};
