import React from 'react';
import PropTypes from 'prop-types';
import styles from './loginPageRenderers.local.less';

export const SetupCompleteMessage = ({ primaryMessage, secondaryMessage, handleOkClick = null }) => {
  return(
    <>
      <div className={styles.messageBody}>
        {primaryMessage}
        <br/>
        <p>{secondaryMessage}</p>
      </div>
      <div className={styles.loginMessagePanelButton}>
        <div className="two-column-flex">
          <button 
            className={`formItem ${styles.loginPanelButton} ${styles.loginPanelButtonSolid}`}
            onClick={handleOkClick}
          >
            OK
          </button>
          <button 
            className={`formItem ${styles.loginPanelButton} ${styles.loginPanelButtonOutlineNone}`}
            onClick={handleOkClick}
          >
            Return to Sign In
          </button>
        </div>
      </div>
    </>
  );
}

SetupCompleteMessage.propTypes = {
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
  handleOkClick: PropTypes.func
}

export const SimpleMessage = ({ primaryMessage, secondaryMessage }) => {
  return(
    <>
      <div className={styles.messageBody}>
        {primaryMessage}
        <br/>
        <p>{secondaryMessage}</p>
      </div>
    </>
  );
}


SimpleMessage.propTypes = {
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string
}
