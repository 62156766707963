import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { reduxForm }        from 'redux-form';
import { compose }          from 'redux';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router-dom';
import { Form }             from 'semantic-ui-react';
import { ROUTES }           from './../../../app/constants/index';

import {
  passwordResetRequest,
  passwordReset,
} from '../../../services/passwordReset/passwordResetService';
import { trackEvent } from '../../../helpers/gaHelpers/gaHelpers';

import styles from './passwordResetForm.local.less';
import loginStyles from '../loginForm/loginForm.local.less';
import Card from '../../../components/card/Card';
import CardHeader from '../../../components/card/CardHeader';
import CardBody from '../../../components/card/CardBody';
import SendLinkResetForm from './SendLinkResetForm';
import ConfirmPasswordForm from './ConfirmPasswordForm';

const CODE_INVALID = `Please enter a valid email address.`;

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    const { initialValues: { email = '', token = '' } = {  } } = this.props;
    this.state = {
      isValidForm: false,
      form: null,
      email: email,
      mode: token? 'token' : '',
      token,
      password: '',
      passwordConfirm: ''
    };
  }

  componentDidMount = () => {
    trackEvent('forgot password', {
      event_category: 'Account',
      event_label: 'forgot password'
    });
  }

  handleRequest = async () => {
    const { state: { email, password, token, mode } } = this;
    if (mode !== 'token') {
      try {
        const requestResponse = await passwordResetRequest(email);
        this.setState({ form: requestResponse? 'OK' : 'ERROR', emailError: false });
      } catch (error) {
        this.setState({ form: 'ERROR', emailError: false });
      }
    } else {
      try {
        const requestResponse = await passwordReset({ email, password, token });
        this.setState({ form: requestResponse? 'OK' : 'ERROR' });
        this.setState({ form: 'OK', emailError: false });
      } catch (error) {
        this.setState({ form: 'ERROR', emailError: false });
      }
    }
  }

  validateRequest = () => {
    const { state: { isValidForm, email }, handleRequest } = this; 
    if (isValidForm && email) return handleRequest();
    this.setState({ emailError: true });
  }

  goBack = () => {
    const { props: { history } } = this;
    history.goBack();
  }

  goLogin = () => {
    const { props: { history } } = this;
    history.push(ROUTES.LOGIN.path);
  }

  reset = () => {
    this.setState({ isValidForm: false, email: '', confirmPassword: '', password: '', form: null, emailError: false });
  }

  getForm = () => {
    const { renderOkBody, renderErrorBody, renderForm, state: { form } } = this;
    switch(form) {
      case 'OK': return renderOkBody();
      case 'ERROR': return renderErrorBody();
      default: return renderForm()
    }
  }

  getHeaderTitle = () => {
    const { state: { form, mode } } = this;
    if (form === 'OK') return mode === 'token'? 'Password Changed' : 'Email Sent';
    return 'Reset Password';
  }

  onSendLinkFormChange = value => this.setState({...value, emailError: value.email === ''? false : this.state.emailError });

  onConfirmPasswordChange = value => this.setState(value);

  renderOkBody = () => {
    const { goBack, goLogin, state: { email, mode } } = this; 
    return (
      <CardBody>
        {
          mode !== 'token'? (
            <>
              <div className={styles.message}>
                Thank you. If {email} is associated with a Makena account, we’ve sent instructions so you can create a new password.
              </div>
              <div className={styles.message}>
                If you don’t receive the email shortly, please contact your Makena account manager.
              </div>
              <div className={styles.actionButtonsContainer}>
                <div className={styles.button}>
                  <button className={styles.blue} dtype="button" onClick={goBack}>Ok</button>
                </div>
                <div className={styles.button}>
                  <button className={styles.white} type="button" onClick={goBack}>Return to Sign In</button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                You successfully changed your password. You’re now signed in to the Makena Client Site.
              </div>
              <div className={styles.actionButtonsContainer}>
                <div className={styles.button}>
                  <button className={styles.blue} dtype="button" onClick={goLogin}>Continue</button>
                </div>
                <div className={styles.button}>
                </div>
              </div>
            </>
          )
        }
      </CardBody>
    )
  }

  renderErrorBody = () => {
    const { goBack, reset, state: { email, mode } } = this;
    return (
      <CardBody>
        {
          mode === 'token'? (
            <>
              <div className={styles.message}>Unfortunately Something wrong happened.</div>
              <div className={styles.message}>If you are unable to reset your password, please contact your Makena account manager.</div>
              <div className={styles.actionButtonsContainer}>
                <div className={styles.button}>
                  <button className={styles.blue} dtype="button" onClick={reset}>Try Again</button>
                </div>
                <div className={styles.button}>
                  <button className={styles.white} type="button" onClick={goBack}>Return to Sign In</button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.message}>
                Thank you. If {email} is associated with a Makena account, we’ve sent instructions so you can create a new password.
              </div>
              <div className={styles.message}>
                If you don’t receive the email shortly, please contact your Makena account manager.
              </div>
              <div className={styles.actionButtonsContainer}>
                <div className={styles.button}>
                  <button className={styles.blue} dtype="button" onClick={reset}>Try Again</button>
                </div>
                <div className={styles.button}>
                  <button className={styles.white} type="button" onClick={goBack}>Return to Sign In</button>
                </div>
              </div>
            </>
          )
        }
      </CardBody>
    )
  }

  renderForm = () => {
    const { state: { mode, isValidForm, email, emailError }, goBack, validateRequest, onSendLinkFormChange, onConfirmPasswordChange } = this; 
    return mode !== 'token' ? (
      <CardBody>
        <div className={styles.message}>
          Please enter your email address associated with your Makena account. We’ll email you instructions to reset your password. 
        </div>
        {
          emailError? (
            <div className={loginStyles.errorMessage}>
              <div className={loginStyles.errorIcon}><span>!</span></div>
              <p className={loginStyles.emailErrorMessage}>{ CODE_INVALID }</p>
            </div>
          ) : null
        }
        <SendLinkResetForm onChange={onSendLinkFormChange} email={email}/>
        <div className={styles.actionButtonsContainer}>
          <div className={styles.button}>
            <button className={styles.blue} type="button" onClick={validateRequest}>Send Reset Link</button>
          </div>
          <div className={styles.button}>
            <button className={styles.white} type="button" onClick={goBack}>Return to Sign In</button>
          </div>
        </div>
      </CardBody>
    ) : (
      <CardBody>
        <div className={styles.message}>Please enter a new password below.</div>
        <ConfirmPasswordForm onChange={onConfirmPasswordChange}/>
        <div className={styles.actionButtonsContainer}>
          <div className={styles.button}>
            <button className={styles.blue} disabled={!isValidForm}>Submit</button>
          </div>
          <div className={styles.button}></div>
        </div>
      </CardBody>
    );
  }

  render() {
    const { handleRequest, getForm, getHeaderTitle } = this;
    return (
      <div className="content">
        <div className="dashboardContainer">
          <div className="dashboard">
            <Form onSubmit={handleRequest} className={styles.resetFormWrap}>
              <Card layoutStyle="cardMain">
                <CardHeader>{getHeaderTitle()}</CardHeader>
                { getForm() }
              </Card>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

PasswordResetForm.propTypes = {
  initialValues: PropTypes.any,
  match: PropTypes.shape({}),
  history: PropTypes.object
};

const maybeUnhashEmail = props => {
  try {
    return atob(props.match.params.emailHash);
  } catch (e) {
    return '';
  }
};

function mapStateToProps(state, props) {
  return {
    initialValues: {
      email: maybeUnhashEmail(props),
      token: props.match.params.token
    }
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, {}),
  reduxForm({ form: 'passwordResetForm' }),
)(PasswordResetForm);
