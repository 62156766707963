import React, { 
  Component, 
  createRef, 
  Fragment
} from 'react';
import PropTypes from 'prop-types';

import SliderControls from './SliderControls';
import Slider from '../../components/slider/CustomSlider';

import styles from './leadershipCardRenderer.local.less';

const MAX_DOTS_SHOWN = 6;

class LeadershipSlider extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
      count: PropTypes.number.isRequired
    }

    state = {
      currentDotIndex: 0,
      currentIndex: 0
    };

    slider = createRef()
  
    handleOnPrevSlide = () => {
      const { 
        currentDotIndex,
        currentIndex
      } = this.state;

      const newDotIndex = currentDotIndex
        ? currentDotIndex - 1
        : currentIndex - 1
          ? 1
          : 0;

      this.setState(prevState => ({
        currentDotIndex: newDotIndex,
        currentIndex: prevState.currentIndex - 1
      }), () => {
        this.slider.current.slickPrev();
      });
    }
    
    hadleOnNextSlide = () => {
      const { count } = this.props;

      const { 
        currentDotIndex,
        currentIndex
      } = this.state;

      const newDotIndex = currentDotIndex + 1 < MAX_DOTS_SHOWN
        ? currentDotIndex + 1
        : currentIndex + 2 < count
          ? MAX_DOTS_SHOWN - 2
          : MAX_DOTS_SHOWN - 1;

      this.setState(prevState => ({
        currentDotIndex: newDotIndex,
        currentIndex: prevState.currentIndex + 1
      }), () => {
        this.slider.current.slickNext();
      });
    }  

    handleOnClick = index => {
      const { currentDotIndex } = this.state;

      const diffIndex = index < currentDotIndex
        ? -(currentDotIndex - index)
        : (index - currentDotIndex);

      this.setState(prevState => ({
        currentDotIndex: index,
        currentIndex: prevState.currentIndex + diffIndex
      }), () => {
        this.slider.current.slickGoTo(this.state.currentIndex);
      });
    }

    render() {
      const { count } = this.props;

      const { 
        currentDotIndex,
        currentIndex 
      } = this.state;

      const showControls = count > 1;
      const showPrevControl = !!currentIndex;
      const showNextControl = currentIndex + 1 < count;

      const dotsCount = count > MAX_DOTS_SHOWN
        ? MAX_DOTS_SHOWN
        : count;
      
      return(
        <Fragment>
          <SliderControls 
            currentDotIndex={currentDotIndex}
            dotsCount={dotsCount}
            onClick={this.handleOnClick}
            onNext={this.hadleOnNextSlide}
            onPrev={this.handleOnPrevSlide}
            showControls={showControls}
            showNextControl={showNextControl}
            showPrevControl={showPrevControl}
          />
          <Slider
            ref={this.slider}
            settings={{
              adaptiveHeight: true,
              arrows: false,
              className: styles.leadershipSlider,
              infinite: false,
              variableWidth: false
            }}
          >
            { this.props.children }
          </Slider>
        </Fragment>
      );
    }
}

export default LeadershipSlider;
  