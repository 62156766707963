import * as R from "ramda";
import getApiRootUrl from "../../helpers/apiHelpers/getApiRootUrl";
import requestWrapper from "../../helpers/apiHelpers/requestWrapper";
import {
  setUserDimensions,
  resetUserDimensions,
} from "../../helpers/gaHelpers/gaHelpers";
import { API_URLS, ERRORS, ACCESS_LEVEL } from "../../app/constants/index";
import {
  clearStorage,
  getAuthToken,
  getUserProfile,
  setAuthToken,
  setRefreshToken,
  setUserProfile,
  loginAndReturnUserInfo,
  getDefaultClientId,
  getDefaultSettings,
} from "./authHelper";
import {
  authLoginSuccess,
  authLogoutAndResetState,
  authUpdateUserProfile,
} from "./authActions";
import { getClientList, registerActiveSession } from "./authHelper";
import { makenaClientByIdFetch } from "../makenaClient/makenaClientService";
import { getErrorMessage } from "../errors/errorHelper";
import { setDefaultClient } from "../settings/settingsService";
import getServerStream from "../application/getServerStream";

export const login =
  ({ email, password }, redirectOnSuccess) =>
    async (dispatch) => {
      try {
        const { authToken, refreshToken, userProfile } =
        await loginAndReturnUserInfo(email, password);
        const { clients = [] } = userProfile;

        setAuthToken(authToken);
        setRefreshToken(refreshToken);
        setUserProfile(userProfile);

        if (clients.length && userProfile.level == ACCESS_LEVEL.FULL) {
          await makenaClientByIdFetch(
            getDefaultClientId(clients),
            setUserDimensions(userProfile)
          )(dispatch);
        }

        dispatch(authLoginSuccess(userProfile));

        if (redirectOnSuccess) redirectOnSuccess();
      } catch (err) {
        console.log(err);
        const errorMessage = getErrorMessage(err, ERRORS.AUTH_LOGIN_ERROR);
        throw new Error(errorMessage);
      }
    };

export const generateCode = async (payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.GENERATE_CODE}`;

    return await requestWrapper
      .post(url, payload)
      .then(R.propOr({ ok: false }, "data"));
  } catch (err) {
    console.log(err);
    const errorMessage = getErrorMessage(err, ERRORS.AUTH_LOGIN_ERROR);
    throw new Error(errorMessage);
  }
};

export const verifyCode = async (payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.VERIFY_CODE}`;

    return await requestWrapper
      .post(url, payload)
      .then(R.propOr({ ok: false }, "data"));
  } catch (err) {
    console.log(err);
    const errorMessage = getErrorMessage(err, ERRORS.AUTH_LOGIN_ERROR);
    throw new Error(errorMessage);
  }
};

export const validateAdminUserAuth = () => async (dispatch) => {
  if (!getAuthToken()) {
    clearStorage();
    dispatch(authLogoutAndResetState());
    if (redirectOnLoggedOut) redirectOnLoggedOut();
  }

  try {
    const { uuid } = getUserProfile();
    const url = `${getApiRootUrl()}${API_URLS.VALIDATE_AUTH_TOKEN}/${uuid}`;
    await requestWrapper.get(url);
    const userProfile = getUserProfile();
    dispatch(authLoginSuccess(userProfile));
  } catch (error) {
    clearStorage();
    dispatch(authLogoutAndResetState());
  }
};

export const logout =
  (redirectToDefaultPage = null) =>
    async (dispatch) => {
      const { authContext = null } = getUserProfile();
      const url = `${getApiRootUrl()}${API_URLS.LOGOUT}`;

      if (authContext) {
        await adminRestoreSession(authContext, redirectToDefaultPage, dispatch);
        return;
      }

      try {
        const serverStream = getServerStream();
        if (serverStream) {
          serverStream.close();
        }
        await requestWrapper.get(url);
      } finally {
        clearStorage();
        resetUserDimensions();
        dispatch(authLogoutAndResetState());

        if (redirectToDefaultPage) redirectToDefaultPage();
      }
    };

export const validateUserAuth = (redirectOnLoggedOut) => async (dispatch) => {
  if (!getAuthToken()) {
    clearStorage();
    resetUserDimensions();
    dispatch(authLogoutAndResetState());
    if (redirectOnLoggedOut) redirectOnLoggedOut();
  }

  const hasAcceptedTOS = R.compose(R.not, R.isNil);

  try {
    const { uuid } = getUserProfile();
    const url = `${getApiRootUrl()}${API_URLS.VALIDATE_AUTH_TOKEN}/${uuid}`;

    await requestWrapper.get(url);
    const userProfile = getUserProfile();
    const portalSettings = getDefaultSettings();

    const clients = getClientList(userProfile);

    const { selectedClient } = portalSettings || {};

    if (clients.length && userProfile.level == ACCESS_LEVEL.FULL) {
      if (selectedClient) {
        await makenaClientByIdFetch(
          selectedClient,
          setUserDimensions(userProfile)
        )(dispatch);
        await setDefaultClient(selectedClient)(dispatch);
      } else {
        await makenaClientByIdFetch(
          getDefaultClientId(clients),
          setUserDimensions(userProfile)
        )(dispatch);
        await setDefaultClient(getDefaultClientId(clients))(dispatch);
      }
    }

    if (!hasAcceptedTOS(userProfile.settings.TOSSignatureDate)) {
      throw new Error(ERRORS.ACCOUNT_TOS_ERROR);
    }

    dispatch(authUpdateUserProfile(userProfile));
  } catch (error) {
    clearStorage();
    resetUserDimensions();
    dispatch(authLogoutAndResetState());
  }
};

export const validateUserByEmail = async (email, onSuccess) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.VALIDATE_USER_EMAIL}`;
    const response = await requestWrapper.post(url, email);

    if (onSuccess) onSuccess();
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNT_EMAIL_ERROR);
    throw new Error(errorMessage);
  }
};

export const validateInvitationToken = async (token, onSuccess) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.VALIDATE_ACCOUNT_INVITE(token)}`;
    const response = await requestWrapper.get(url);
    const account = response.data.data;

    if (onSuccess) onSuccess();
    return account;
  } catch (err) {
    let errorMessage = R.pathOr(
      ERRORS.TOKEN_VALIDATION_ERROR,
      ["response", "data", "data", "err", "message"],
      err
    );

    throw new Error(errorMessage);
  }
};

export const validateUserCredentials = async (email, password) => {
  try {
    const { authToken, userProfile } = await loginAndReturnUserInfo(
      email,
      password
    );
    setAuthToken(authToken);
    setUserProfile(userProfile);

    return userProfile;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.AUTH_LOGIN_ERROR);
    throw new Error(errorMessage);
  }
};

export const loadInitialState = async () => {
  if (!getAuthToken()) {
    clearStorage();
    resetUserDimensions();
    return Promise.resolve({});
  }

  try {
    const { uuid } = getUserProfile();
    const url = `${getApiRootUrl()}${API_URLS.VALIDATE_AUTH_TOKEN}/${uuid}`;
    await requestWrapper.get(url);
    const userProfile = getUserProfile();
    const portalSettings = getDefaultSettings();
    const { selectedClient = null } = portalSettings || {};

    const initialState = {
      auth: { ...userProfile, isLoggedIn: true },
      settings: { selectedClient },
    };
    return Promise.resolve(initialState);
  } catch (error) {
    clearStorage();
    resetUserDimensions();
    return Promise.resolve({});
  }
};

export const adminImpersonateUser =
  (email, id, onSuccess) => async (dispatch) => {
    try {
      const url = `${getApiRootUrl()}${API_URLS.ADMIN_IMPERSONATE_USER}`;
      const response = await requestWrapper.post(url, { email, adminId: id });
      await registerActiveSession(response, dispatch);

      if (onSuccess) onSuccess();
    } catch (err) {
      const errorMessage = getErrorMessage(
        err,
        ERRORS.ACCOUNT_IMPERSONATE_ERROR
      );
      throw new Error(errorMessage);
    }
  };

export const adminRestoreSession = async (
  { id, token },
  redirect,
  dispatch
) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ADMIN_RESTORE_SESSION}`;
    const response = await requestWrapper.post(url, { id, token });
    await registerActiveSession(response, dispatch);

    if (redirect) redirect();
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNT_IMPERSONATE_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const setAuthProfile = (userProfile) => async (dispatch) => {
  const { clients = [] } = userProfile;
  setUserProfile(userProfile);
  dispatch(authUpdateUserProfile(userProfile));
  if (clients.length && userProfile.level == ACCESS_LEVEL.FULL) {
    await makenaClientByIdFetch(getDefaultClientId(clients))(dispatch);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const refreshAuthProfile = () => async (dispatch) => {
  try {
    const { uuid } = getUserProfile();
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_FETCH_BY_ID(uuid)}`;

    const response = await requestWrapper.get(url);
    const userProfile = response.data.data;
    const { clients = [] } = userProfile;

    if (clients.length && userProfile.level == ACCESS_LEVEL.FULL) {
      await makenaClientByIdFetch(getDefaultClientId(clients))(dispatch);
    }

    dispatch(authUpdateUserProfile(userProfile));
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNTS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const register = async (payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.REGISTER}`;

    return await requestWrapper.post(url, payload);
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNTS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

export const updateUser = async (id, payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_UPDATE(id)}`;

    return await requestWrapper.put(url, payload);
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNTS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

export const getUser = async (id) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.ACCOUNT_UPDATE(id)}`;

    return await requestWrapper.get(url);
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.ACCOUNTS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};
