import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { NextArrow, PrevArrow } from './SliderArrows';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const DEFAULT_SETTINGS = {
  className: 'slider variable-width',
  dots: false,
  centerMode: false,
  variableWidth: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const CustomSlider = forwardRef(({
  settings,
  children
}, ref) => {
  const sliderSettings = {
    ...DEFAULT_SETTINGS,
    ...settings
  };

  return (
    <Slider 
      ref={ref}
      {...sliderSettings}
    >
      {children}
    </Slider>
  );
});

CustomSlider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object
};

CustomSlider.defaultProps = {
  settings: {}
};

export default CustomSlider;
