//https://www.abeautifulsite.net/parsing-urls-in-javascript

const parseURL = (url) => {
  var parser = document.createElement('a'),
    searchObject = {},
    queries, split, i;
  // Let the browser do the work
  parser.href = url;
  // Convert query string to object
  queries = parser.search.replace(/^\?/, '').split('&');
  for( i = 0; i < queries.length; i++ ) {
    split = queries[i].split('=');
    searchObject[split[0]] = split[1];
  }

  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash
  };
}

const getRootUrl = reqUrl => {
  const parsed = parseURL(reqUrl);
  const host = parsed.host || 'localhost';
  const protocol = parsed.protocol ? parsed.protocol : 'http:';
  return `${protocol}//${host}/`;
};


module.exports = {
  parseURL,
  getRootUrl
}