export default {
  LOGIN: "/auth/login",
  VERIFY_CODE: "/auth/verify",
  GENERATE_CODE: "/auth/generate",
  LOGOUT: "/auth/logout",
  AUTH_REFRESH: "/auth/refresh",
  AUTH_STATUS: "/auth/status",
  ADMIN_IMPERSONATE_USER: "/auth/impersonate",
  ADMIN_RESTORE_SESSION: "/auth/admin-restore",
  VALIDATE_AUTH_TOKEN: "/api/v1/cloudUser/check-session",
  VALIDATE_USER_EMAIL: "/api/v1/cloudUser/email",
  VALIDATE_ACCOUNT_INVITE: (token) => `/api/v1/cloudUser/invitation/${token}`,
  ACCOUNT_CREATE: "/api/v1/cloudUser",
  ACCOUNT_LOG: "/api/v1/cloudUser/history",
  ACCOUNT_RETRIEVE_LOG: (uuid) => `/api/v1/cloudUser/history/${uuid}`,
  ACCOUNT_INVITE_USER: (uuid) => `/api/v1/cloudUser/invite/${uuid}`,
  ACCOUNT_DELETE: (uuid) => `/api/v1/cloudUser/id/${uuid}`,
  ACCOUNT_FETCH_BY_ID: (uuid) => `/api/v1/cloudUser/id/${uuid}`,
  ACCOUNT_INVITE_BY_ID: (uuid) => `/api/v1/cloudUser/activate/${uuid}`,
  ACCOUNT_UPDATE: (uuid) => `/api/v1/cloudUser/id/${uuid}`,
  ACCOUNT_UPDATE_PASSWORD: (uuid) => `/api/v1/cloudUser/updatePassword/${uuid}`,
  ACCOUNT_PERMISSIONS_UPDATE: `/api/v1/sscIdsFunds/bulkUpdate`,
  ACCOUNT_CLIENTS_UPDATE: (uuid) => `/api/v1/cloudUserMakenaClient/${uuid}`,
  ACCOUNT_SETUP: (token) => `/api/v1/cloudUser/token/${token}`,
  ACCOUNT_SETUP_BY_ID: (uuid) => `/api/v1/cloudUser/setupById/${uuid}`,
  ACCOUNT_CONFIRM_USER: (uuid) => `/api/v1/cloudUser/confirm/${uuid}`,
  ACCOUNT_PERMISSIONS_FETCH_BY_CONTACT_ID: (contactId) =>
    `/api/v1/cloudUser/permissionsByContactId/${contactId}`,
  ACCOUNTS_FETCH: "/api/v1/cloudUser",
  ACCOUNT_BULK_INVITE: "/api/v1/cloudUser/bulk-invite",
  ACCOUNTS_FETCH_DATATABLE: "/api/v1/cloudUser/datatable",
  APPLICATION_SETTINGS_UPDATE: "/api/v1/setting",
  AUDIT_LOG: "/api/v1/auditLog",
  GLOBAL_SETTINGS: "/api/v1/setting/global",
  GLOBAL_SETTINGS_UPDATE: "/api/v1/setting/global",
  MAKENA_ADDEPAR_INFO: "/api/v1/addepar",
  MAKENA_ADDEPAR_REFRESH: "/api/v1/addepar/refresh",
  MAKENA_CLIENTS_FETCH: "/api/v1/makenaClient",
  MAKENA_CLIENT_BY_ID_FETCH: (id) => `/api/v1/makenaClient/id/${id}`,
  MAKENA_PRODUCTS_FETCH: "/api/v1/makenaProduct",
  MAKENA_PRODUCTS_BY_CLIENT_ID_FETCH: (makenaClientId) =>
    `/api/v1/makenaProduct/makenaClientId/${makenaClientId}`,
  MAKENA_ASSETS_FETCH: "/api/v1/makenaAsset",
  MAKENA_MANAGERS_FETCH: "/api/v1/makenaManager",
  MAKENA_MANAGERS_VISITED_FETCH: (title) =>
    `/api/v1/makenaManager/${encodeURIComponent(title)}/visit-status`,
  MAKENA_MANAGERS_VISITED_FETCH_ALL: "/api/v1/makenaManager/visit-status",
  MAKENA_MANAGER_BY_ID_FETCH: (id) => `/api/v1/makenaManager/id/${id}`,
  MAKENA_ASSET_CLASSES_FETCH: "/api/v1/makenaAssetClass",
  MAKENA_ASSET_CLASSES_FETCH_BY_ID: (id) => `/api/v1/makenaAssetClass/id/${id}`,
  MAKENA_ASSET_CLASSES_CREATE: "/api/v1/makenaAssetClass",
  MAKENA_ASSET_CLASSES_UPDATE: (id) => `/api/v1/makenaAssetClass/id/${id}`,
  MAKENA_ASSET_CLASSES_DELETE: (id) => `/api/v1/makenaAssetClass/id/${id}`,
  INVESTOR_DOCUMENTS_FETCH: "/api/v1/investorDocuments",
  INVESTOR_DOCUMENTS_NAME_BY_UUID: (uuid) =>
    `/api/v1/investorDocuments/name/${uuid}`,
  INVESTOR_DOCUMENTS_ADMIN_FETCH: "/api/v1/investorDocuments/admin",
  DOCUMENT_INVESTOR_COUNT_FETCH_BY_FIELD_ADMIN: (field) =>
    `/api/v1/investorDocuments/admin/count/${field}`,
  INVESTOR_DOCUMENTS_FETCH_BY_CLIENT_ID: (id) =>
    `/api/v1/investorDocuments/client/${id}`,
  INVESTOR_DOCUMENTS_FETCH_BY_UUID: (uuid) =>
    `/api/v1/investorDocuments/uuid/${uuid}`,
  INVESTOR_DOCUMENTS_CREATE: "/api/v1/investorDocuments",
  INVESTOR_DOCUMENTS_UPDATE: (uuid) => `/api/v1/document/uuid/${uuid}`,
  MAKENA_FUNDS_FETCH: "/api/v1/makenaFund",
  MAKENA_FUND_BY_ID_FETCH: (id) => `/api/v1/makenaFund/id/${id}`,
  MAKENA_ASSET_CLASSES_UPDATE_FETCH: "/api/v1/makenaAssetClassUpdate",
  MAKENA_ASSET_CLASSES_UPDATE_BY_ID_FETCH: (id) =>
    `/api/v1/makenaAssetClassUpdate/id/${id}`,
  MAKENA_ASSET_CLASSES_UPDATE_CREATE: `/api/v1/makenaAssetClassUpdate`,
  MAKENA_ASSET_CLASSES_UPDATE_UPDATE: (id) =>
    `/api/v1/makenaAssetClassUpdate/id/${id}`,
  MAKENA_ASSET_CLASSES_UPDATE_DELETE: (id) =>
    `/api/v1/makenaAssetClassUpdate/id/${id}`,
  PASSWORD_RESET_REQUEST_FETCH: (email) =>
    `/api/v1/passwordReset/email/${encodeURIComponent(email)}`,
  PASSWORD_RESET_ADMIN_REQUEST_FETCH: (email, omitSendEmail) =>
    `/api/v1/passwordReset/admin/email/${encodeURIComponent(email)}${
      omitSendEmail ? "?omitSendEmail=true" : ""
    }`,
  PASSWORD_RESET_FETCH: `/api/v1/passwordReset`,
  APP_HIERARCHY_FETCH: (clientId, startDate, endDate) =>
    `/api/v1/reporting/hierarchy?entityId=${clientId}&startDate=${startDate}&endDate=${endDate}`,
  REPORTING_FETCH_BENCHMARKS: `/api/v1/reporting/benchmarks`,
  MAKENA_SSC_IDS_FETCH: `/api/v1/makenaSscIds`,
  MAKENA_SSC_IDS_FETCH_BY_SSC_ID: (sscId) => `/api/v1/makenaSscIds/id/${sscId}`,
  MAKENA_SSC_IDS_UPDATE_BY_SSC_ID: (sscId) =>
    `/api/v1/makenaSscIds/id/${sscId}`,
  USAGE_LOGIN: (startDate, endDate) =>
    `/api/v1/usage/login?startDate=${startDate}&endDate=${endDate}`,
  USAGE_CLIENTS: `/api/v1/usage/clients/datatable`,
  USAGE_USERS: `/api/v1/usage/users/datatable`,
  NOTIFICATION_SETTINGS_ALL: `/api/v1/survey`,
  NOTIFICATION_SETTINGS_ENTITY: (id) => `/api/v1/survey/id/${id}`,
  USAGE_CLIENTS_COUNT_FETCH_BY_FIELD: (field) =>
    `/api/v1/usage/clients/${field}/count`,
  USAGE_USERS_COUNT_FETCH_BY_FIELD: (field) =>
    `/api/v1/usage/users/${field}/count`,
  USAGE_PAGES: (startDate, endDate) =>
    `/api/v1/usage/navigation?startDate=${startDate}&endDate=${endDate}`,
  USAGE_DOCUMENTS: (startDate, endDate, type) =>
    `/api/v1/usage/documents?startDate=${startDate}&endDate=${endDate}&type=${type}`,
  REPORTING_DEFAULT_FETCH: ({
    reportType,
    portfolioId,
    reportingQuarterStart,
    reportingQuarterEnd,
  }) =>
    `/api/v1/reporting/type/${reportType}/${portfolioId}?startDate=${encodeURIComponent(
      reportingQuarterStart
    )}&endDate=${encodeURIComponent(reportingQuarterEnd)}`,
  REPORTING_FETCH: ({
    reportType,
    entityId = "",
    transformer = "raw",
    reportingQuarterStart = "",
    reportingQuarterEnd = "",
    assetClassTitle = "",
    managerTitle = "",
  }) =>
    `/api/v1/reporting/type/${encodeURIComponent(
      reportType
    )}?entityId=${encodeURIComponent(
      entityId
    )}&assetClassTitle=${encodeURIComponent(
      assetClassTitle
    )}&managerTitle=${encodeURIComponent(
      managerTitle
    )}&transformer=${encodeURIComponent(
      transformer
    )}&startDate=${encodeURIComponent(
      reportingQuarterStart
    )}&endDate=${encodeURIComponent(reportingQuarterEnd)}`,
  REPORTING_SP_FETCH: ({
    reportType,
    entityId = "",
    transformer = "raw",
    portfolioId = "",
    reportingQuarterStart = "",
    reportingQuarterEnd = "",
    assetClassTitle = "",
    managerTitle = "",
  }) =>
    `/api/v1/reporting/type/${encodeURIComponent(
      reportType
    )}/${portfolioId}?entityId=${encodeURIComponent(
      entityId
    )}&assetClassTitle=${encodeURIComponent(
      assetClassTitle
    )}&managerTitle=${encodeURIComponent(
      managerTitle
    )}&transformer=${encodeURIComponent(
      transformer
    )}&startDate=${encodeURIComponent(
      reportingQuarterStart
    )}&endDate=${encodeURIComponent(reportingQuarterEnd)}`,
  EXPORT_FETCH: ({
    reportType,
    reportingQuarterStart = "",
    reportingQuarterEnd = "",
    type = "",
  }) =>
    `/api/v1/reporting/${encodeURIComponent(
      reportType
    )}?startDate=${encodeURIComponent(
      reportingQuarterStart
    )}&endDate=${encodeURIComponent(
      reportingQuarterEnd
    )}&type=${encodeURIComponent(type)}`,
  DOCUMENT_CREATE: "/api/v1/document",
  DOCUMENT_UPDATE_BY_UUID: (uuid) => `/api/v1/document/uuid/${uuid}`,
  DOCUMENT_FETCH_BY_UUID: (uuid) => `/api/v1/document/uuid/${uuid}`,
  DOCUMENT_FETCH_ALL: "/api/v1/document",
  DOCUMENT_MARK_READ: "/api/v1/document/read",
  DOCUMENT_MARK_UNREAD: "/api/v1/document/unread",
  DOCUMENT_GET_ZIP: "/api/v1/document/zip",
  DOCUMENT_GET_LIST: "/api/v1/investorDocuments/admin",
  DOCUMENT_CLIENT_FETCH_ALL_BY_CLIENT_ID: (clientId) =>
    `/api/v1/clientDocuments/id/${clientId}`,
  DOCUMENT_CLIENT_FETCH_ALL: "/api/v1/clientDocuments",
  DOCUMENT_CLIENT_CREATE: "/api/v1/clientDocuments",
  DOCUMENT_DOWNLOAD: (fileName) =>
    `/api/v1/document/fetch/uuid/${encodeURIComponent(
      fileName.split("/").join("-")
    )}`,
  DOCUMENT_GLOBAL_FETCH_ALL: "/api/v1/globalDocuments",
  DOCUMENT_GLOBAL_CREATE: "/api/v1/globalDocuments",
  DOCUMENT_INVESTOR_FETCH_DATATABLE: "/api/v1/investorDocuments/datatable",
  DOCUMENT_INVESTOR_CREATE: "/api/v1/investorDocuments",
  DOCUMENT_INVESTOR_COUNT_FETCH_BY_FIELD: (field) =>
    `/api/v1/investorDocuments/count/${field}`,
  DOCUMENT_INVESTOR_EARLIEST_DATE: `/api/v1/investorDocuments/earliest`,
  UPLOAD_FILE: "/api/v1/upload",
  UPLOAD_ASSET: "/assets",
  MAKENA_CLIENT_CREATE: "/api/v1/makenaClient",
  MAKENA_CLIENT_UPDATE: (clientId) => `/api/v1/makenaClient/id/${clientId}`,
  MAKENA_PEOPLE_FETCH_ALL: "/api/v1/makenaStaff",
  MAKENA_PEOPLE_FETCH_BY_ID: (id) => `/api/v1/makenaStaff/id/${id}`,
  MAKENA_PEOPLE_CREATE: "/api/v1/makenaStaff",
  MAKENA_PEOPLE_UPDATE: (id) => `/api/v1/makenaStaff/id/${id}`,
  MAKENA_PEOPLE_DELETE: (id) => `/api/v1/makenaStaff/id/${id}`,
  MAKENA_MANAGER_CREATE: "/api/v1/makenaManager",
  MAKENA_MANAGER_UPDATE: (id) => `/api/v1/makenaManager/id/${id}`,
  MAKENA_MANAGER_DELETE: (id) => `/api/v1/makenaManager/id/${id}`,
  DOCUMENT_MANAGER_FETCH_DATATABLE: "/api/v1/makenaManager/datatable",
  DOCUMENT_MANAGER_COUNT_FETCH_BY_FIELD: (field) =>
    `/api/v1/makenaManager/count/${field}`,
  MAKENA_FUND_CREATE: "/api/v1/makenaFund",
  MAKENA_FUND_UPDATE: (id) => `/api/v1/makenaFund/id/${id}`,
  MAKENA_FUND_DELETE: (id) => `/api/v1/makenaFund/id/${id}`,
  TAX_ELECTION_FORMS_FETCH: `/api/v1/taxElectionForms`,
  TAX_ELECTION_FORMS_FETCH_UUID: (uuid) =>
    `/api/v1/taxElectionForms/uuid/${uuid}`,
  TAX_ELECTION_FORMS_EXPORT: (year) =>
    `/api/v1/taxElectionForms/export/${year}`,
  TAX_ELECTION_FORMS_DATATABLE: `/api/v1/taxElectionForms/datatable`,
  TAX_ELECTION_FORMS_COUNT_FETCH_BY_FIELD: (field) =>
    `/api/v1/taxElectionForms/${field}/count`,
  SCEF_DOWNLOAD: (fileName) =>
    `/api/v1/taxElectionForms/fetch/name/${encodeURIComponent(
      fileName.split("/").join("-")
    )}`,
};
