import React from 'react';
import PropTypes from 'prop-types';
import Card from '../card/Card';
import CardHeader from '../card/CardHeader';
import CardBody from '../card/CardBody';
import ContentRenderer from '../contentRenderer/ContentRenderer';

const renderHeaderContent = (headerContent, headerRenderer = null, headerStyle = '') => {
  let renderHeader = ContentRenderer(headerRenderer);
  return (
    <CardHeader layoutStyle={headerStyle}>
      {renderHeader(headerContent)}
    </CardHeader>
  )
}

const renderBodyContent = (bodyContent, bodyRenderer = null, bodyStyle = '', children = null) => {
  let renderBody = ContentRenderer(bodyRenderer);
  return (
    <CardBody layoutStyle={bodyStyle}>
      {renderBody(bodyContent)}
      { children }
    </CardBody>
  )
}

const ContentCardView = ({
  bodyContent,
  BodyRenderer,
  bodyStyle,
  headerContent,
  HeaderRenderer,
  headerStyle,
  layoutStyle,
  children,
  trackEventLabel,
  trackEventCategory,
}) => (
  <Card
    layoutStyle={layoutStyle}
    trackEventLabel={trackEventLabel}
    trackEventCategory={trackEventCategory}
  >
    {renderHeaderContent(headerContent, HeaderRenderer, headerStyle)}
    {renderBodyContent(bodyContent, BodyRenderer, bodyStyle, children)}
  </Card>
);

ContentCardView.propTypes = {
  bodyContent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  BodyRenderer: PropTypes.func,
  bodyStyle: PropTypes.string,
  headerContent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  HeaderRenderer: PropTypes.func,
  headerStyle : PropTypes.string,
  layoutStyle: PropTypes.string,
  trackEventLabel: PropTypes.string,
  trackEventCategory: PropTypes.string
};

ContentCardView.defaultProps = {
  BodyRenderer: null,
  bodyStyle: "",
  HeaderRenderer: null,
  headerStyle: "",
  layoutStyle: "",
  trackEventLabel: "",
  trackEventCategory: ""
};

export default ContentCardView;
