export const setClientInfo = clientInfo => window.sessionStorage.setItem('clientInfo', JSON.stringify(clientInfo));

export const getClientInfo = () => {
  try {
    const clientInfo = window.sessionStorage.getItem('clientInfo');

    if (!clientInfo) return {};
    return JSON.parse(clientInfo);
  } catch (e) {
    return {};
  }
};

