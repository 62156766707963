import React        from 'react';
import PropTypes    from 'prop-types';
import Card         from '../../components/card/Card';
import CardHeader   from '../../components/card/CardHeader';
import CardBody     from '../../components/card/CardBody';
import DynamicImage from '../image/DynamicImage';
import {
  ADDEPAR_ASSET_CLASS_PROP,
  DATE_PROP,
  ROUTES,
  MANAGER_PROP
} from '../../app/constants';

import styles from './recentInvestmentsCard.local.less';

const RecentInvestmentsCard = ({ recentInvestments, inlineStyles, layoutStyle }) => {
  const investments = recentInvestments.map(({ name, values }) => ({ name, ...values }));
  return (
    <Card
      layoutStyle={layoutStyle}
      trackEventLabel='Recent Investments'
      trackEventCategory='Fund'
    >
      <CardHeader>
        <section className={styles.recentInvestmentsCard}>
          <div className={styles.leftHeader}>
            Recent Investments
          </div>
        </section>
      </CardHeader>
      <CardBody>
        <div className={styles.cardBodyInner} style={inlineStyles}>
          { investments.map((investment, i) => (
            <a
              key={`recent-investment-${i}`}
              href={`${ROUTES.EXPLORE.path}/${investment['MCM Manager Name']}`}
            >
              <section className={styles.recentInvestmentsCard}>
                <div className={styles.leftBody} title={investment[MANAGER_PROP]}>
                  <DynamicImage
                    path={ investment.logo? `managers/${investment.logo}` : 'common/manager-no-logo.png' }
                    isAsset={true}
                    className={styles.avatar}
                  />
                </div>
                <div className={styles.middleBody}>
                  <span className={styles.subtitle} style={{ marginBottom: '4px' }}>{investment[MANAGER_PROP]}</span>
                  <span className={styles.assetClass}>{investment[ADDEPAR_ASSET_CLASS_PROP]}</span>
                </div>
                <div className={styles.rightBody}>
                  <span>{investment[DATE_PROP]}</span>
                </div>
              </section>
            </a>
          ))
          }
        </div>
      </CardBody>
    </Card>
  );
};

RecentInvestmentsCard.propTypes = {
  recentInvestments: PropTypes.arrayOf(PropTypes.shape({})),
  inlineStyles: PropTypes.shape({}),
  layoutStyle: PropTypes.string,
};

export default RecentInvestmentsCard;
