export const requiredField = value => (value ? undefined : 'Required');

export const minChars = (value, fields, {length = 8} = {}) => {
  return value.length >= length;
}

export const hasOneUpperCase = (value) => {
  const expr = /(?=.*[A-Z])/;
  return expr.test(value);
}

export const numberOrSpecialChar = (value) => {
  const expr = /(?=.*\d)|(?=.*\W])/
  return expr.test(value);
}

export const exactMatch = (value, fields, {compare = 'newPassword'} = {}) => value === fields[compare]
