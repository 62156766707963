import { ACTIONS } from '../../app/constants/index';

const INITIAL_STATE = {
  open: false,
  selectedFund: null
};

export default function (state = INITIAL_STATE, action = { toggleMenu: false, selectedFund: null, type: '' }) {
  switch (action.type) {
    case ACTIONS.MAKENA_MENU_TOGGLE: {
      return { ...state, ...action.toggleMenu, open: !state.open };
    }
    case ACTIONS.MAKENA_MENU_SELECTED_FUND: {
      return { ...state, selectedFund: action.selectedFund };
    }
    default:
      return state;
  }
}