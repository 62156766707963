import { get, orderBy, forEach } from 'lodash';
import { Helper } from '../datatableCommons';

export const nonDownloadableDocumentsByFundCode = ['mcm ip lp', 'mcm llc', 'mcmllc'];

export const TransformFundsBySscKey = funds => {
  const keys = {};
  forEach(funds, ({ displayName, sscKey }) => sscKey.split(',').forEach(key => keys[key] = displayName));
  return keys;
}

const TransformPublicInvestorDocuments = (styles, checkedDocuments, sscKeys) => document => {
  document.isChecked = checkedDocuments[document.uuid];
  document.rowClass = document.read === 'unread'? styles.bold : '';
  document.fundCode = sscKeys[document.fundCode] || document.fundCode;
  return document;
}

const TransformCMSInvestorDocuments = (styles, checkedDocuments, sscKeys) => document => {
  document.isChecked = checkedDocuments[document.uuid];
  document.visible = document.status;
  document.rowClass = !document.visible? styles.bold : '';
  document.fundCode = sscKeys[document.fundCode] || document.fundCode;
  return document;
}

const TransformParser = {
  'CMS': TransformCMSInvestorDocuments,
  'Public': TransformPublicInvestorDocuments
}

export const InvestorDocumentsTransform = (styles, datatable) => (investorDocuments = { data: {} }, checkedDocuments = {}, sscKeys = {}) => ({
  ...investorDocuments,
  data: Object.values(get(investorDocuments, 'data', {})).map(TransformParser[datatable](styles, checkedDocuments, sscKeys))
});

export const AssignFiltersToHeaders = (_headers, filters, appliedFilters, sortObject) => {
  let newHeaders = Helper.AssignFiltersToHeaders(_headers, filters, appliedFilters, sortObject);
  newHeaders.columns.forEach(column => {
    if (column.isFilterable) {
      column.isFilterContainerWider = column.selector === 'releaseDate';
      if (column.selector !== 'releaseDate' || column.selector !== 'visible') 
        column.filterOptions = orderBy(column.filterOptions, ['title'], ['asc']);
    }
  });
  return newHeaders;
};

export const filterFields = {
  CMS: {
    accounts: 'account',
    categories: 'category',
    funds: 'fund',
    visible: 'visible',
    releaseDate: 'date'
  },
  Public: {
    accounts: 'account',
    categories: 'category',
    funds: 'fund',
    readUnread: 'unread',
    releaseDate: 'date'  
  }
};

export const countFunctions = {
  CMS: {
    accounts: 'investorDocumentsCountByAccounts',
    categories: 'investorDocumentsCountByCategories',
    funds: 'investorDocumentsCountByFunds',
    visible: 'investorDocumentsCountByVisible',
    releaseDate: 'investorDocumentsCountByReleaseDate'
  },
  Public: {
    accounts: 'investorDocumentsCountByAccounts',
    categories: 'investorDocumentsCountByCategories',
    funds: 'investorDocumentsCountByFunds',
    readUnread: 'investorDocumentsCountByReadUnread',
    releaseDate: 'investorDocumentsCountByReleaseDate'  
  }
}
