import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import connect                    from 'react-redux/es/connect/connect';
import CardBody                   from '../../components/card/CardBody';
import Card                       from '../../components/card/Card';
import CardHeader                 from '../../components/card/CardHeader';
import Svg                        from '../../components/svg/Svg';
import * as moment                from 'moment';
import ChevronRight               from '../../assets/svg/icon-arrow-right-blue.svg';
import { fetchTaxElectionForms }  from '../../services/taxElectionForms/taxElectionFormsService';
import API_URLS                   from '../../app/constants/apiUrls';
import getApiRootUrl              from '../../helpers/apiHelpers/getApiRootUrl';
import { getAuthToken }           from '../../services/auth/authHelper';
import styles                     from './taxElectionFormContainer.local.less';
import { getGlobalSettings }      from '../../services/admin/settings/settingsService';

class TaxElectionFormList extends Component {
  state = { 
    isLoading: true,
    settings: {
      taxFormsYear: parseInt(moment().format('YYYY'), 10),
      taxFormsDate: moment().format('YYYY-MM-DD')
    }
  };

  componentDidMount() {
    this.startLoading();
    getGlobalSettings()
      .then(settings => {
        const year = settings.taxFormsYear || parseInt(moment().format('YYYY'), 10);
        fetchTaxElectionForms(year)
          .then(data => {
            fetchTaxElectionForms(year - 1)
              .then(lastYearData => this.finishLoading({ data, settings, lastYearData }))
          })
      })
      .catch(this.finishLoading);
  }

  startLoading = () => {
    this.setState({ isLoading: true });
  };

  finishLoading = ({ data = [], lastYearData = [], settings = {} })  => {
    const hasLastYearData = !!lastYearData.filter(({ uuid }) => uuid).length;
    this.setState({
      data,
      lastYearData: hasLastYearData? lastYearData : [],
      settings,
      isLoading: false,
    });
  };
  
  GoToForm = (investorId, legalName, uuid) => () => {
    const { props: { history } } = this;
    if (uuid && uuid !== 'new') {
      history.push(`/tax-election-forms/${uuid}?investorId=${investorId}`);
      return;
    }
    history.push(`/tax-election-forms/new?investorId=${investorId}&legalName=${legalName}`);
  }


  showFile = ({ year = moment().format('YYYY'), uuid, investorId, }) => async () => {
    const filename = `Makena ${year} State Composite Election Form (${investorId} ${moment().format('YYYY-MM-DD HH:mm:ss')})`
    const documentUrl = API_URLS.SCEF_DOWNLOAD(filename);
    const apiUrl = getApiRootUrl();
    window.open(`${apiUrl}${documentUrl}?attachment=false&token=${getAuthToken()}&uuid=${uuid}`, "_blank");
  }

  render() {
    const { state: { isLoading, data = [], lastYearData = [], settings = {} } } = this;
    if (isLoading) return <></>;
    const {
      taxFormsYear = parseInt(moment().format('YYYY'), 10),
      taxFormsDate = moment().format('YYYY-MM-DD')
    } = settings;
    
    return <div className="dashboardTopPadding">
      <div className='contentRowSplitTwoThirds'>

        <Card 
          contentStyle='cardLightGrey'
          layoutStyle='cardMain jsWelcomeCard' 
        >
          <CardHeader>
          Your State Composite Return Election Forms
          </CardHeader>
          <CardBody>
            <div className="mb-16px">
            As a result of your investment(s) with Makena, 
            you may have {taxFormsYear - 1} state income tax return filing 
            obligations. In an effort to minimize your state 
            filing responsibilities, Makena would like to offer 
            you the opportunity to participate in Makena’s {taxFormsYear - 1} state composite tax filings.
            </div>
            <div className={styles.title}>CURRENT YEAR</div>
            <div className="mb-8px"></div>
            <div className={styles.table}>
              <div className={styles.headerCell}>Investor #</div>
              <div className={styles.headerCell}>Current Year Election</div>
              <div></div>
              {(data && data.length)? data.map(({ investorId, legalName, submittedBy, uuid, year, status }, index) => {
                return <>
                  <div key={'investorId'+index} className={`${styles.investorCell} ${index % 2? '' : styles.isYellow}`}>
                    { status === 1? <div onClick={this.showFile({ investorId, uuid, legalName, year })}>{ investorId }</div> : <div onClick={this.GoToForm(investorId, legalName, uuid)}>{ investorId }</div>}
                  </div>
                  <div key={'submittedBy'+index} className={`${styles.statusCell} ${index % 2? '' : styles.isYellow}`}>
                    { status === 1? <div onClick={this.showFile({ investorId, uuid, legalName, year })}> Submitted by { submittedBy } </div>: null }
                    { status === 0? <div onClick={this.GoToForm(investorId, legalName, uuid)}> In Progress </div>: null }
                    { status === null || status === "Not Started"? <div onClick={this.GoToForm(investorId, legalName, uuid)}> Not Started </div>: null }
                  </div>
                  <div key={'action'+index} className={`${styles.linkCell} ${index % 2? '' : styles.isYellow}`}>
                    { status === 1? <div className={styles.link} onClick={this.showFile({ investorId, uuid, legalName, year })}> View </div>: null }
                    { status === 0? <div onClick={this.GoToForm(investorId, legalName, uuid)}> <Svg svg={ChevronRight}></Svg> </div>: null }
                    { status === null || status === "Not Started"? <div onClick={this.GoToForm(investorId, legalName, 'new')}> <Svg svg={ChevronRight}></Svg> </div>: null }
                  </div>
                </>
              }) : null}
            </div>
            {
              (!data || !data.length)? (
                <p className={styles.noRecords}>
                    You don’t have any forms to fill out for the current year. 
                    If you have any questions, please contact Makena Investor Relations at 
                  <a href="mailto:clientoperations@makenacap.com">clientoperations@makenacap.com</a> or contact Dan Drury at 
                  <a href="mailto:tax@makenacap.com">tax@makenacap.com</a> or <a>(650) 926-0510</a>.
                </p>
              ) : null

            }
            <div className="mb-32px"></div>

            <div className={styles.title}>LAST YEAR</div>
            <div className="mb-8px"></div>
            <div className={styles.table}>
              <div className={styles.headerCell}>Investor #</div>
              <div className={styles.headerCell}>Last Year Election</div>
              <div></div>
              {(lastYearData && lastYearData.length)? lastYearData.map(({ investorId, legalName, submittedBy, uuid, year, status }, index) => {
                return <>
                  <div key={'lastInvestorId'+index} onClick={this.showFile({ investorId, uuid, legalName, year })} className={`${styles.investorCell} ${index % 2? '' : styles.isYellow}`}>
                    <div>{ investorId }</div>
                  </div>
                  <div key={'lastSubmittedBy'+index} onClick={this.showFile({ investorId, uuid, legalName, year })} className={`${styles.statusCell} ${index % 2? '' : styles.isYellow}`}>
                    { status === 1? <div> Submitted by { submittedBy } </div>: 'Not Filled' }
                  </div>
                  <div key={'lastAction'+index} className={`${styles.linkCell} ${index % 2? '' : styles.isYellow}`}>
                    { status === 1? <div className={styles.link} onClick={this.showFile({ investorId, uuid, legalName, year })}> View </div>: null }
                  </div>
                </>
              }) : null}
            </div>
            {
              (!lastYearData || !lastYearData.length)? (
                <p className={styles.noRecords}>
                    You didn’t need to fill out any forms last year.
                    If you have any questions, please contact Makena Investor Relations at 
                  <a href="mailto:clientoperations@makenacap.com">clientoperations@makenacap.com</a> or contact Dan Drury at 
                  <a href="mailto:tax@makenacap.com">tax@makenacap.com</a> or <a>(650) 926-0510</a>.
                </p>
              ) : null

            }
            <div className="mb-32px"></div>
            <div className={styles.text}>
            The purpose of a state composite tax return is to relieve you from filing your own state tax return and remitting tax payments to a state. The composite tax returns indicate the income allocated to each participating nonresident partner. Every state filing requirement is different so please note the following:
            </div>
            <ul className={styles.text}>
              <li>You cannot be included in a composite return for a state where you are a resident</li>
              <li>You cannot be included in a composite return for a state where you receive income from more than one source</li>
              <li>You may be required to file a tax return in states where you are not eligible to be included in a compositereturn or where you elect to be excluded</li>
              <li>If you chose not to participate in the composite return filing in a state, Makena may still be required to withhold tax on your behalf</li>
            </ul>
            <div className={styles.text}>
            It is important to note that the tax reported on each composite return is expected to be computed at the highest rate without any deductions, exemptions or credits. Therefore, your tax liability as a member of the composite return may be greater than if you were to file your own tax return. Also, it is likely that any state sourced loss incurred by Makena would not be deductible by you in the future.
            </div>
            <div className={styles.text}>
            Makena will inform you of any composite returns filed on your behalf as well as any nonresident state withholding tax paid by Makena on your behalf in conjunction with your {taxFormsYear - 1} Schedule K-1. As a reminder, {taxFormsYear - 1} Schedule K-1s are expected to be released { moment(taxFormsDate).format('MMM Do, YYYY') }.
            </div>
            <div className={`${styles.text} ${styles.remark}`}>We recommend that you consult with your tax advisor to determine whether or not you should elect to participate in Makena’s composite returns.</div>
            <div className={styles.text}>
            If you choose to participate, please complete the State Composite Return Election Form and 
            return it no later than { moment(taxFormsDate).format('MMM Do, YYYY') } to the contacts noted in the form. For new investors, failure to 
            respond will result in exclusion from participating in Makena’s {taxFormsYear - 1} state composite filings. 
            For those investors who participated in Makena’s {taxFormsYear - 2} composite filings, we will default with 
            those same elections and apply them to {taxFormsYear - 1} even if we don’t hear from you this year. 
            You can access your election from prior years within the General Tax Documents category of our 
            secure investor portal 
              <a href="https://investments.makenacap.com/investor-documents" className="ml-4px mr-4px" target="_blank">
              (https://investments.makenacap.com/investor-documents).
              </a>
            If you have any questions, please contact Makena Investor Relations at 
              <a href="mailto:clientoperations@makenacap.com" target="_blank" className="ml-4px mr-4px" >
            clientoperations@makenacap.com
              </a> 
            or contact Dan Drury at 
              <a href="mailto:clientoperations@makenacap.com" target="_blank" className="ml-4px mr-4px" >tax@makenacap.com</a>
            or (650) 926-0510.
            </div>
            <div className={styles.text}>
            Kind regards,
            </div>
            <div className={styles.text}>
            Makena Capital Management, LLC
            </div>


          </CardBody>
        </Card>
      </div>
    </div>;
  }
}

TaxElectionFormList.propTypes = {
  history: PropTypes.any
};

function mapStateToProps(state) {
  return { sideBarOpen: state.menu.open };
}

export default connect(
  mapStateToProps
)(TaxElectionFormList);
