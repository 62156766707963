import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { withRouter }           from 'react-router-dom';
import { CSSTransition }        from 'react-transition-group';
import { compose }              from 'redux';
import moment                   from 'moment';
import connect                  from 'react-redux/es/connect/connect';
import { Message }              from 'semantic-ui-react';
import SideBar                  from '../../components/sideBar/SideBar';
import { onToggleMenu }         from '../../services/menu/menuService';
import { MergeAll }             from '../../helpers/globalHelpers';
import ActiveScreen             from './ActiveScreen';
import styles                   from './dashboard.local.less';
import { onSelectedFund }       from '../../services/menu/menuService';
import { ROUTES }               from '../../app/constants/index';

import {
  clientPortalOverviewFetch,
  clientPortalRecentInvestmentsFetch
}                               from '../../services/reporting/reportingService';

import { 
  getInvestorDocumentsForCard 
}                               from '../../services/document/documentService';

const LEFT_NAV_TRANSITION_TIMEOUT = 600;

const parseRecentInvestments = (recentInvestments = []) => {
  const newRecentInvestments = {};
  recentInvestments = Array.isArray(recentInvestments) ? recentInvestments : [recentInvestments];
  recentInvestments.forEach(recentInvestment => {
    const {
      values = {}
    } = recentInvestment
    const managerName = values['MCM Manager Name'];
    if (!newRecentInvestments[managerName]) return newRecentInvestments[managerName] = recentInvestment;
    const storedNewRecentInvestment = newRecentInvestments[managerName];
    const recentInvestmentDate = moment.utc(values['MCM Initial Closing Date'], 'MM/YYYY').format('YYYY/MM/DD');
    const storedNewRecentInvestmentDate = moment.utc(storedNewRecentInvestment.values['MCM Initial Closing Date'], 'MM/YYYY').format('YYYY/MM/DD');
    if (recentInvestmentDate > storedNewRecentInvestmentDate) return newRecentInvestments[managerName] = recentInvestment;
    return;
  });

  return Object.values(newRecentInvestments);
}

class Dashboard extends Component {
  static propTypes = {
    hierarchy: PropTypes.any,
    makenaClient: PropTypes.shape({
      latest: PropTypes.shape({
        totalAssets: PropTypes.number,
        products: PropTypes.shape({}),
      }),
      all: PropTypes.shape({}),
    }),
    funds: PropTypes.array,
    allFunds: PropTypes.array,
    assetClasses: PropTypes.array,
    managers: PropTypes.array,
    auth: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        productId: PropTypes.string,
        assetKey: PropTypes.string,
      }),
    }),
    openMenu: PropTypes.bool,
    toggle: PropTypes.func,
    selectFund: PropTypes.func,
    selectedClient: PropTypes.any
  }

  state = {
    isLoading: true,
    data: {},
    errors: {
      makenaProductsFetchError: null,
    }
  };

  async componentDidMount() {
    const { finishLoading, refresh, props: { toggle, openMenu, match: { params: { productId = '' } = { } } } } = this;
    productId && !openMenu && toggle();
    await refresh().then(finishLoading);
  }

  async componentDidUpdate({ selectedClient }) {
    const { refresh, finishLoading } = this;
    if (selectedClient === this.props.selectedClient) return;
    await refresh().then(finishLoading);
  }

  refresh = () => {    
    const { props: { makenaClient: { entityId } } } = this;
    const promises = [ 
      clientPortalRecentInvestmentsFetch(entityId).then(parseRecentInvestments).then(recentInvestments => ({ recentInvestments })),
      getInvestorDocumentsForCard().then(investorDocuments => ({ investorDocuments })),
      clientPortalOverviewFetch(entityId).then(overview => ({ overview })).catch(() => ({ overview: {} })),
    ];
    return Promise.all(promises)
      .then(MergeAll);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = data => {
    this.setState({
      data,
      isLoading: false,
    });
  };

  renderSideBar = ({
    settings,
    hierarchy,
    overview,
    assetClassName,
    funds,
    assetClasses,
    managers,
    makenaClient,
    managerTitle,
    openMenu,
    productId,
    sidebarIsAbsolute
  }) => (
    <CSSTransition  
      mountOnEnter
      unmountOnExit
      in={openMenu} 
      timeout={LEFT_NAV_TRANSITION_TIMEOUT}
      classNames={{
        enter: styles.sidebarEnter,
        enterActive: styles.sidebarEnterActive,
        exit: styles.sidebarExit,
        exitActive: styles.sidebarExitActive
      }}
    >
      <SideBar
        sidebarIsAbsolute={sidebarIsAbsolute}
        activeAssetClassName={assetClassName}
        activeManagerTitle={managerTitle}
        activeProductId={productId? parseInt(productId, 10) : null}
        hierarchy={hierarchy}
        settings={settings}
        overview={overview}
        funds={funds}
        assetClasses={assetClasses}
        managers={managers}
        makenaClient={makenaClient}
      />
    </CSSTransition>
  );

  render() {
    const {
      isLoading,
      data: { overview, recentInvestments, investorDocuments = {} },
      errors: { makenaProductsFetchError },
    } = this.state;
    
    const {
      hierarchy = {},
      funds = [],
      assetClasses = [],
      allFunds = [],
      managers = [],
      makenaClient = {},
      openMenu,
      auth,
      match = {},
      history,
      selectFund
    } = this.props;    
    if (isLoading) return null;
    const { params } = match;
    const sidebarIsAbsolute = ROUTES.INVESTOR_DOCUMENTS.path === match.path;
    const { settings = {} } = auth;
    const hasHierarchy = Object.keys(hierarchy).length > 1;
    const hasClient = Object.keys(makenaClient).length > 0;
    const { assetClassName, managerTitle, productId } = params;
    const fund = productId? funds.find(_fund => _fund.entityId.toString() === productId): null;
    const assetClass = assetClassName? assetClasses.find(_assetClass => _assetClass.title === decodeURIComponent(assetClassName)): null;
    const manager = managerTitle? managers.find(_manager => _manager.title === decodeURIComponent(managerTitle)): null;
    const withSideBar = hasHierarchy && openMenu;
    const showSideBar = hasHierarchy && hasClient;
    const sideBarParams = {
      overview,
      settings,
      hierarchy,
      assetClassName : decodeURIComponent(assetClassName),
      assetClasses,
      funds,
      managers,
      makenaClient,
      managerTitle: decodeURIComponent(managerTitle),
      openMenu,
      productId,
      sidebarIsAbsolute
    };
    selectFund(productId);
    return (
      <>
        {showSideBar && this.renderSideBar(sideBarParams)}
        <Message negative hidden={!makenaProductsFetchError} content={makenaProductsFetchError} />
        <div className={`dashboardContainer ${openMenu? 'openSidebar' : ''}`}>
          <div 
            data-id='Dashboardwrap'
            className={`dashboard ${ openMenu? 'openSidebar' : ''}`} 
          >
            <ActiveScreen 
              match={match} 
              hierarchy={hierarchy} 
              fund={fund} 
              assetClass={assetClass} 
              manager={manager} 
              funds={funds} 
              assetClasses={assetClasses} 
              managers={managers} 
              allFunds={allFunds}
              overview={overview} 
              makenaClient={makenaClient}
              recentInvestments={recentInvestments}
              investorDocuments={investorDocuments}
              history={history}
            />
          </div>
        </div>
        { withSideBar? <div className="openSidebar"></div>: null }
      </>
    );
  }
}

Dashboard.propTypes = {
  hierarchy: PropTypes.any,
  makenaClient: PropTypes.shape({
    latest: PropTypes.shape({
      totalAssets: PropTypes.number,
      products: PropTypes.shape({}),
    }),
    all: PropTypes.shape({}),
    entityId: PropTypes.string
  }),
  funds: PropTypes.array,
  allFunds: PropTypes.array,
  assetClasses: PropTypes.array,
  managers: PropTypes.array,
  auth: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
      assetKey: PropTypes.string,
    }),
  }),
  openMenu: PropTypes.bool,
  toggle: PropTypes.func,
  selectFund: PropTypes.func
}

const mapStateToProps = state => ({
  makenaClient: state.makenaClient,
  openMenu: state.menu.open,
  selectedClient: state.settings && state.settings.selectedClient
});

const mapDispatchToProps = ({ 
  toggle: onToggleMenu,
  selectFund: onSelectedFund,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Dashboard);
