import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';

class DatatableRowColumnString extends Component {
  render() { 
    const { column: { selector, format = value => value }, data } = this.props;
    return (<div>{format(_.get(data, selector))}</div>);
  }
}

DatatableRowColumnString.propTypes = {
  column: PropTypes.object,
  data: PropTypes.object
}

export default DatatableRowColumnString;