import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';
import { getErrorMessage } from '../errors/errorHelper';

// eslint-disable-next-line import/prefer-default-export
export const makenaAssetClassesFetch = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.MAKENA_ASSET_CLASSES_FETCH}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.MAKENA_ASSET_CLASSES_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};
