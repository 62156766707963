import React from 'react';
import PropTypes from 'prop-types';

const FormField = ({
  input, label, type, fieldClass="", meta: { touched, error },
}) => (
  <div className={fieldClass}>
    {label && <label>{label}</label>}
    <div>
      <input {...input} placeholder={label} type={type} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

FormField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  fieldClass: PropTypes.string,
  meta: PropTypes.object
}

export default FormField;
