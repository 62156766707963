import { ACTIONS } from '../../app/constants/index';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action = { type: '' }) {
  switch (action.type) {

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_SECTOR_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerSector: action.clientPortalManagerSector };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_RE_PROPERTY_TYPE_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerREPropertyType: action.clientPortalManagerREPropertyType };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_NR_INDUSTRY_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerNRIndustry: action.clientPortalManagerNRIndustry };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_GROWTH_OF_A_DOLLAR_STATIC_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerGrowthOfADollarStatic: action.clientPortalManagerGrowthOfADollarStatic };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_GEOGRAPHY_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerGeography: action.clientPortalManagerGeography };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_VINTAGE_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerVintage: action.clientPortalManagerVintage };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_RISK_FACTOR_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerRiskFactor: action.clientPortalManagerRiskFactor };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_FUND_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerFund: action.clientPortalManagerFund };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_MAKENA_FUNDS_FETCH_SUCCESS: {
      return { ...state, clientPortalManagerMakenaFunds: action.clientPortalManagerMakenaFunds };
    }

    case ACTIONS.REPORTING_CLIENT_PORTAL_CLEAR_PRODUCT_DATA: {
      return { ...state, ...action.clear };
    }

    default:
      return state;
  }
}
