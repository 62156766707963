import React from 'react';
import PropTypes from 'prop-types';

import ManagerGalleryCard from '../managerGalleryCard/ManagerGalleryCard';
import Slider from '../slider/CustomSlider';
import TabMenu from '../tabMenu/TabMenu';
import Svg from '../svg/Svg';
import {
  MANAGER_EXPLORE_CAROUSEL_FILTER_TABS,
  MANAGER_PROP,
  SPREADSHEET_ASSET_CLASS_PROP,
} from '../../app/constants'
import AddManagerButton from '../../assets/svg/add-manager-button.svg';

import styles from './managerGallery.local.less';

const mapPropsClientInvestment = ({ 
  logo,
  ...props 
}) => ({
  date: props['MCM Initial Closing Date'],
  logo,
  strategy: props.strategyBrief,
  assetClass: props[SPREADSHEET_ASSET_CLASS_PROP],
  title: props[MANAGER_PROP]
})

const orderByManagerPropNames = 
  (clientInvestments, managersWithFunds) => clientInvestments.reduce((acc, { entity_id, name, values }) => {
    const clientInvestment = { entity_id, name, ...values, ...managersWithFunds.find(manager => manager.title === values['MCM Manager Name']) };
    const clientInvestmentFormatted = mapPropsClientInvestment(clientInvestment);
    if(clientInvestmentFormatted.title.includes('Dragoneer')) {
      return [
        clientInvestmentFormatted,
        ...acc
      ];
    } else if(clientInvestmentFormatted.title.includes('ABRY')) {
      return [
        acc[0],
        clientInvestmentFormatted,
        ...acc.slice(1)
      ];
    } else {
      return [
        ...acc,
        clientInvestmentFormatted
      ];
    }
  }, []);

const renderAddBtn = (
  isAddBtnVisible, 
  addBtnCallback
) => isAddBtnVisible
  ? (
    <a onClick={addBtnCallback} role='button'>
      <div className={styles.card}>
        <div className={styles.logo}>
          <Svg svg={AddManagerButton} />
          <p className={styles.addManagerLink}>add a manager</p>
        </div>
      </div>
    </a>
  )
  : null;

const ManagerGallery = ({
  addBtnCallback,
  recentInvestments, 
  isAddBtnVisible,
  onClickHandler, 
  managersWithFunds,
  size,
  title 
}) => (
  <div key={title} className={styles.managerGallery}>
    <TabMenu 
      activeOption={MANAGER_EXPLORE_CAROUSEL_FILTER_TABS[0]}
      className={styles.tabMenu}
      menuOptions={MANAGER_EXPLORE_CAROUSEL_FILTER_TABS} 
    />
    <Slider 
      settings={{
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 4
      }}
    >
      {
        orderByManagerPropNames(recentInvestments, managersWithFunds)
          .map(
            clientInvestment => (
              <ManagerGalleryCard
                key={clientInvestment.title}
                onClick={onClickHandler}
                size={size}
                {...clientInvestment}
              />
            )
          )
      }
      {renderAddBtn(isAddBtnVisible, addBtnCallback)}
    </Slider>
  </div>
);


ManagerGallery.propTypes = {
  addBtnCallback: PropTypes.func,
  isAddBtnVisible: PropTypes.bool,
  recentInvestments: PropTypes.arrayOf(PropTypes.object),
  managersWithFunds: PropTypes.arrayOf(PropTypes.object),
  onClickHandler: PropTypes.func,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  title: PropTypes.string,
};

ManagerGallery.defaultProps = {
  size: 'default'
};

export default ManagerGallery;
