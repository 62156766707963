import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './managerPreview.local.less';
import DynamicImage from '../image/DynamicImage';
import Currency from '../currency/Currency';
import ManagerLabel from '../managerLabel/ManagerLabel';
import CardPanel from '../cardPanel/CardPanel';

class ManagerPreview extends Component {
  render() {
    const {
      fundSize,
      inception,
      location,
      logo,
      title,
      labelX,
      labelY,
      strategyGridX1,
      strategyGridX2,
      strategyGridX3,
      strategyGridY1,
      strategyGridY2,
      strategyGridY3,
      makenaInvestmentDate,
      panelStyle
    } = this.props;
    return (
      <CardPanel panelStyle={`${panelStyle} ${styles.container}`}>
        <div className={styles.managerPreview}>
          <div className={styles.managerPreviewLogo}>
            { logo ? <DynamicImage path={`managers/${logo}`} isAsset={true} alt={title}/> : title }
          </div>
          <div className={styles.managerPreviewContent}>
            <div className={styles.managerPreviewContentGrid}>
              <div className={`${styles.info} ${styles.grid11}`}>
                <div className={styles.title}>Most Recent Fund</div>
                <div><Currency value={fundSize} format={`$0.00 a`} /></div>
              </div>
              <div className={`${styles.info} ${styles.grid21}`}>
                <div className={styles.title}>Firm Inception</div>
                <div>{inception}</div>
              </div>
              <div className={`${styles.info} ${styles.grid12}`}>
                <div className={styles.title}>Location</div>
                <div>{location}</div>
              </div>
              <div className={`${styles.info} ${styles.grid22}`}>
                <div className={styles.title}>Makena Investment</div>
                <div>{moment.utc(makenaInvestmentDate).format('MMM. YYYY')}</div>
              </div>
            </div>
          </div>
          <div className={styles.managerSubstrategy}>
            <div className={`${styles.info}`}>
              <div className={`${styles.title}`}>Sub-strategy</div>
              <ManagerLabel
                labelX={labelX}
                labelY={labelY}
                strategyGridX1={strategyGridX1}
                strategyGridX2={strategyGridX2}
                strategyGridX3={strategyGridX3}
                strategyGridY1={strategyGridY1}
                strategyGridY2={strategyGridY2}
                strategyGridY3={strategyGridY3}
                layoutStyle={styles.center}
              />
            </div>
          </div>
        </div>
      </CardPanel>
    )
  }
}

ManagerPreview.propTypes = {
  fundSize: PropTypes.string,
  inception: PropTypes.string,
  location: PropTypes.string,
  logo: PropTypes.string,
  title: PropTypes.string,
  panelStyle: PropTypes.string,
  labelX: PropTypes.string,
  labelY: PropTypes.string,
  makenaInvestmentDate: PropTypes.string,
  strategyGridX1: PropTypes.string,
  strategyGridX2: PropTypes.string,
  strategyGridX3: PropTypes.string,
  strategyGridY1: PropTypes.string,
  strategyGridY2: PropTypes.string,
  strategyGridY3: PropTypes.string,
};

export default ManagerPreview;
