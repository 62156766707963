export const gtag = (...args) => {
  window.gtag && window.gtag(...args);
};

export const trackEvent = (name, params) => {
  gtag('event', name, JSON.parse(JSON.stringify(params)));
}

export const setUserDimensions = userProfile => clientInfo => {

  if(userProfile.firstName && userProfile.lastName && userProfile.email && userProfile.permissionsJson && userProfile.level && clientInfo.title && clientInfo.accountManagerFirstName && clientInfo.accountManagerLastName) {

    const fullName = [userProfile.firstName, userProfile.lastName].join(' ');
    const accountManagerFullName = [clientInfo.accountManagerFirstName, clientInfo.accountManagerLastName].join(' ');

    gtag('set', {
      client: clientInfo.title,
      user: fullName,
      email: userProfile.email,
      role: userProfile.permissionsJson.ROLE,
      accessLevel: userProfile.level,
      accountManager: accountManagerFullName,
    });
  }
}

export const resetUserDimensions = () => {
  gtag('set', {
    client: '',
    user: '',
    email: '',
    role: '',
    accessLevel: '',
    accountManager: '',
  });
}
