import React from 'react';
import PropTypes from 'prop-types';
import styles from './cardHeader.local.less';

const CardHeader  = ({ children, layoutStyle = ""}) => (
  <div className={`${styles.cardHeader} ${layoutStyle}`}>
    {children}
  </div>
);

CardHeader.propTypes = {
  layoutStyle: PropTypes.string
}
export default CardHeader;
