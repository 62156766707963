import React                    from 'react';
import PropTypes                from 'prop-types';
import _                        from 'lodash';
import HeaderNav                from '../headerNav/HeaderNav';
import HeaderMenu               from '../headerMenu/HeaderMenu';
import Logo                     from '../../assets/svg/logo.svg';
import Svg                      from '../svg/Svg';
import { NavLink }              from 'react-router-dom';
import { ROUTES, ACCESS_LEVEL } from '../../app/constants/index';
import { getUserProfile }       from '../../services/auth/authHelper';


const Header = (props) => {
  const { clientInfo, id, funds, auth, config, layoutStyles = '' } = props;
  const userProfile = getUserProfile();
  const level = userProfile && userProfile.level;
  const isClientVisible = !_.isEmpty(clientInfo);
  const { authContext, email } = auth;
  
  return (
    <div className={`header unprintableHeader ${layoutStyles} ${authContext? 'impersonatedHeader' : ''}`} id={id}>
      { authContext && <div className="impersonatedHeaderBar"> You&apos;re signed in as: &nbsp;<span className="strong"> {email} </span></div>}
      <header className="headerBar">
        <div className="headerBarContainer">
          <NavLink to={level == ACCESS_LEVEL.FULL ? ROUTES.DASHBOARD.path : ROUTES.INVESTOR_DOCUMENTS.path } className="headerLogo">
            <Svg svg={Logo} />
          </NavLink>

          <div className="headerNav">
            {
              isClientVisible
              && (
                <div className="headerNavClient">{userProfile.email}</div>
              )
            }
            {
              isClientVisible
              && (
                <HeaderNav {...props} className="headerNavClientMenu unprintable"/>
              )
            }
            {
              !isClientVisible && level == ACCESS_LEVEL.DOCUMENT_ONLY
              && (
                <div className="headerNavClient">{userProfile.email}</div>
              )
            }
            {
              !isClientVisible && level == ACCESS_LEVEL.DOCUMENT_ONLY
              && (
                <HeaderNav {...props} className="headerNavClientMenu unprintable"/>
              )
            }
          </div>
        </div>
      </header>
      
      <HeaderMenu id="headerMenu" 
        clientInfo={clientInfo}
        funds={funds}
        config={config}
      />
    </div>
  );
};

Header.propTypes = {
  layoutStyles: PropTypes.string,
  config: PropTypes.shape({}),
  clientInfo: PropTypes.object,
  id: PropTypes.string,
  funds: PropTypes.array,
  auth: PropTypes.object
};

export default Header;