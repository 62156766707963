import React from 'react';
import PropTypes from 'prop-types';
import Svg from '../svg/Svg';
import ArrowLeft from '../../assets/svg/icon-arrow-left-blue.svg';
import ArrowRight from '../../assets/svg/icon-arrow-right-blue.svg';

import styles from './slider.local.less';


const NextArrow = ({ className, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    role="button"
  >
    <Svg svg={ArrowRight} className={styles.iconArrow} />
  </div>
);

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const PrevArrow = ({ className, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    role="button"
  >
    <Svg svg={ArrowLeft} className={styles.iconArrow} />
  </div>
);

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export {
  NextArrow,
  PrevArrow,
};
