import React from 'react';
import PropTypes from 'prop-types';
import Card from '../card/Card';
import CardHeader from '../card/CardHeader';
import CardBody from '../card/CardBody';
import styles from './textDisplayCard.local.less'

export const renderBodyText = (text, htmlText) => {
  if (htmlText) {
    const cardText = {__html: text };

    return (
      <div dangerouslySetInnerHTML={cardText} className={styles.textDisplayCardContent}></div>
    )
  }

  return (
    <CardBody>
      {text}
    </CardBody>
  )
}

const TextDisplayCard = ({
  bodyText,
  headerText,
  isHtmlText,
  layoutStyle,
  trackEventCategory
}) => (
  <Card
    layoutStyle={layoutStyle}
    trackEventLabel={headerText}
    trackEventCategory={trackEventCategory}
  >
    <CardHeader>
      {headerText}
    </CardHeader>
    {renderBodyText(bodyText, isHtmlText)}
  </Card>
);

TextDisplayCard.propTypes = {
  bodyText: PropTypes.string,
  headerText: PropTypes.string,
  isHtmlText: PropTypes.bool,
  layoutStyle: PropTypes.string,
  trackEventCategory: PropTypes.string,
};

TextDisplayCard.defaultProps = {
  isHtmlText: false,
  layoutStyle: ""
};

export default TextDisplayCard;
