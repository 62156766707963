import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { trackEvent } from '../../helpers/gaHelpers/gaHelpers';
import { getAuthToken } from '../../services/auth/authHelper';

class DownloadLink extends Component {
    static propTypes = {
      children: PropTypes.node,
      className: PropTypes.string,
      download: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
      ]),
      href: PropTypes.string.isRequired,
      style: PropTypes.object,
      trackEventLabel: PropTypes.string,
    }

    static defaultProps = {
      download: false,
      trackEventLabel: '',
    }

    showFile = (blob) => {
      var newBlob = new Blob([blob], { type: "application/pdf" })
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob, { oneTimeOnly: true });
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.download= 'File.pdf';
      link.click();
      setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    }


    handleClickTrackEvent = async () => {
      const {
        href,
        trackEventLabel
      } = this.props;

      trackEvent('download', {
        event_category: 'Files',
        event_label: trackEventLabel,
        location: href
      });
      const authToken = getAuthToken();

      const fetchOptions = {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-store, no-cache, must-revalidate',
          'Accept': 'application/pdf'
        }
      };

      if (authToken) {
        fetchOptions.headers.Authorization = `Bearer ${authToken}`
      }

      const response = await fetch(href, fetchOptions)
      const blob = await response.blob();
      this.showFile (blob);
    }

    render() {
      const {
        children,
        className,
        download,
        style
      } = this.props;

      return (
        <a
          className={className}
          download={download}
          style={style}
          target="_blank"
          onClick={this.handleClickTrackEvent}
        >
          {children}
        </a>
      );
    }
}

export default DownloadLink;
