import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import times from 'lodash/times';

import { PrevArrow, NextArrow } from '../../components/slider/SliderArrows';

import styles from './leadershipCardRenderer.local.less';

const handleOnClickCurrentDot = 
  (currentDotIndex, callback) => ({ target: { value }}) => {
    const index = +value;

    currentDotIndex !== index && callback(index);
  };

const SliderControls = ({
  currentDotIndex,
  dotsCount,
  onClick,
  onNext,
  onPrev,
  showControls,
  showNextControl,
  showPrevControl
}) => showControls && (
  <div className={styles.sliderPagination}>
    <PrevArrow
      className={cx(styles.prevSlide, { 
        [styles.slideShowArrowControl]: showPrevControl 
      })} 
      onClick={onPrev} 
    /> 
    <ul className='slick-dots'>
      {
        times(dotsCount)
          .map(index => (
            <li key={index}>
              <button 
                type='button'
                className={cx({
                  [styles.slideActive]: currentDotIndex === index
                })}
                onClick={handleOnClickCurrentDot(currentDotIndex, onClick)}
                value={index}
              />
            </li>
          ))
      }
    </ul>
    <NextArrow 
      className={cx(styles.nextSlide, { 
        [styles.slideShowArrowControl]: showNextControl 
      })} 
      onClick={onNext} 
    />
  </div>
);

SliderControls.propTypes = {
  currentDotIndex: PropTypes.number.isRequired,
  dotsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  showControls: PropTypes.bool.isRequired,
  showNextControl: PropTypes.bool.isRequired,
  showPrevControl: PropTypes.bool.isRequired
};

export default SliderControls;
