import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './managerGalleryCard.local.less';

const ManagerGalleryCardTextContent = ({ 
  className, 
  heading, 
  text 
}) => (
  <div className={cx(styles.textContent, className)}>
    <h5>{heading}</h5>
    <p>{text}</p>
  </div>
);

ManagerGalleryCardTextContent.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default ManagerGalleryCardTextContent;