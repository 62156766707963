import { toggleMenu, selectedFund } from "./menuActions";

// eslint-disable-next-line import/prefer-default-export
export const onToggleMenu = () => async (dispatch) => {
  dispatch(toggleMenu());
};

// eslint-disable-next-line import/prefer-default-export
export const onSelectedFund = fund => async (dispatch) => {
  dispatch(selectedFund(fund));
};
  