import React from 'react';
import * as R from 'ramda';
import getApiRootUrl    from '../../helpers/apiHelpers/getApiRootUrl';
import API_URLS         from '../../app/constants/apiUrls';
import { getAuthToken } from '../../services/auth/authHelper';

const DynamicImage = (props) => {
  const { alt, path, className, isAsset = false } = props;
  const picture = R.compose(R.last, R.split('/'))(path);
  const src = !isAsset ?
    `${getApiRootUrl()}${API_URLS.DOCUMENT_DOWNLOAD(picture)}?attachment=false&token=${getAuthToken()}&uuid=${picture}` :
    `${getApiRootUrl()}/assets/${path}`;

  try {
    const image = (
      <img
        src={src}
        alt={alt}
        title={alt}
        onError={(e) => {
          e.target.onError = null;
          if (e.target.parent) {
            e.target.outerHTML = alt;
          }
        }}
      />
    );

    if (!image) return null;

    return (
      <div className={className}>
        {image}
      </div>
    );
  } catch (e) {
    return null;
  }
};

export default DynamicImage;
