import React, { Component }  from 'react';
import PropTypes  from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import Card from '../../components/card/Card';
import CardHeader from '../../components/card/CardHeader';
import CardBody from '../../components/card/CardBody';
import {
  AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid,
} from 'recharts';
import styles from './performanceChartCard.local.less';
import PerformanceChartCardTooltip from './PerformanceChartCardTooltip';
import { COLORS, MAKENA_COLORS } from '../../app/constants/index';
import { areaStyles, areaCategories, benchmarkTitle, totalTitle, areaChartSettings } from './PerformanceChartCard.const';
import { trackEvent } from '../../helpers/gaHelpers/gaHelpers';

const formatSettings = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

class PerformanceChartCard extends Component {
  static propTypes = {
    ignoreBenchmarks: PropTypes.bool,
    labels: PropTypes.object,
    layoutStyle: PropTypes.string,
    performance: PropTypes.object,
    subTitle: PropTypes.string,
    title: PropTypes.string
  }

  static defaultProps = {
    title: 'Performance'
  }

  constructor(props) {
    super(props);

    this.state = {
      added: false,
      defaultBenchmark: null,
      'Your Portfolio': null
    };
  }

  componentDidMount() {

    const { chartData } = this.getChartDataSettings();

    this.setState(() => ({
      'Your Portfolio': this.getLast(chartData, totalTitle),
      defaultBenchmark: this.getLast(chartData, benchmarkTitle)
    }));
  }

  componentDidUpdate() {
    if(!(this.state['Your Portfolio'] || this.state.defaultBenchmark)) {

      const { chartData } = this.getChartDataSettings();

      this.setState(() => ({
        'Your Portfolio': this.getLast(chartData, totalTitle),
        defaultBenchmark: this.getLast(chartData, benchmarkTitle)
      }));
    }
  }

  getChartDataSettings () {
    const {
      performance,
      ignoreBenchmarks
    } = this.props;

    return areaChartSettings(performance, ignoreBenchmarks);
  }

  onAreaMove = async event => {
    const { chartData } = this.getChartDataSettings();
    const objectPath = `activePayload.0.payload.`;
    let portfolioValue = _.get(event, `${objectPath}${totalTitle}`, this.getLast(chartData, totalTitle));
    let benchmarkValue = _.get(event, `${objectPath}${benchmarkTitle}`, this.getLast(chartData, benchmarkTitle));

    portfolioValue = new Intl.NumberFormat('en-US', formatSettings).format(portfolioValue);
    benchmarkValue = new Intl.NumberFormat('en-US', formatSettings).format(benchmarkValue);

    if (this.state[benchmarkTitle] === benchmarkValue && this.state[totalTitle] === portfolioValue) {
      return;
    }

    trackEvent('hover', {
      event_category: 'Fund',
      event_label: portfolioValue,
    });

    this.setState({
      ...this.state,
      'Your Portfolio': portfolioValue,
      'defaultBenchmark': benchmarkValue
    });
  }

  getLast = (chartData, tag) => {
    const value = _.last(chartData.map(data => _.isNumber(data[tag]) ? data[tag] : 0 ) );

    return new Intl.NumberFormat('en-US', formatSettings).format(value);
  }

  render() {
    const {
      labels,
      subTitle,
      ignoreBenchmarks,
      title
    } = this.props;
    const {
      chartData,
      xAxisParams : { dateRange, domain },
      yAxisParams
    } = this.getChartDataSettings();
    const areaMargins = { top: 20, right: 20, left: -14, bottom: 20 };
    const newAreaCategories = ignoreBenchmarks? [ areaCategories[0] ] : [ ...areaCategories ];

    return (
      <Card
        layoutStyle={this.props.layoutStyle}
        trackEventLabel={title}
        trackEventCategory='Fund'
      >
        <CardHeader>
          <div className={styles.performanceHeader}>
            <div className={styles.performanceHeaderTitle}>
              {title}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className={styles.tooltipContainer}>
            <div className={styles.tooltipTitle}>
              {subTitle}
            </div>
            <div className={styles.tooltipResume}>
              {
                newAreaCategories.map((category, index) => {
                  const isLast = index === newAreaCategories.length - 1;

                  return (
                    <div key={index} className={`${styles.tooltip} ${isLast? styles.italic : ''}`}>
                      <div className={styles.tooltipInfo}>
                        <div className={styles.tooltipSquare} style={{backgroundColor: areaStyles[category].color}}></div>
                        <div className={styles.tooltipDescription}>{labels? labels[category] : category}</div>
                      </div>
                      <div className={styles.tooltipValue}>${this.state[category]}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <ResponsiveContainer className={styles.performanceContainer}>
            <AreaChart
              data={chartData}
              margin={areaMargins}
              onMouseMove={this.onAreaMove}
            >
              <Tooltip
                cursor={{ stroke: areaStyles['Your Portfolio'].color, strokeDasharray: '2', strokeWidth: 1, opacity: 1 }}
                isAnimationActive={false}
                opacity={1}
                content={<PerformanceChartCardTooltip/>}
              />
              <CartesianGrid
                vertical={false}
                verticalFill={[COLORS.white]}
                fillOpacity={0.5}
                horizontal
              />
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                dataKey="name"
                scale="time"
                domain={domain}
                ticks={dateRange}
                tick={{ fill: MAKENA_COLORS.textColor }}
                tickFormatter={tick => moment.utc(tick).format('YYYY')}
                margin={{left: 170, right: 30}}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                type="number"
                tickFormatter={value => numeral(value).format('$0.00')}
                domain={[yAxisParams.min, yAxisParams.max]}
                stroke={MAKENA_COLORS.white}
                tick={{ fill: MAKENA_COLORS.textColor }}
                ticks={yAxisParams.ticks}
              />
              {
                newAreaCategories.map(label =>
                  <Area
                    key={label}
                    type="linear"
                    dataKey={label}
                    stroke={areaStyles[label].color}
                    fill={areaStyles[label].color}
                    strokeDasharray={areaStyles[label].strokeDasharray}
                    strokeWidth={2}
                    activeDot={{ stroke: areaStyles[label].color, strokeWidth: 2, r: 4.5, fillOpacity: 0 }}
                    fillOpacity={0}
                  />
                )
              }
            </AreaChart>
          </ResponsiveContainer>
        </CardBody>
      </Card>
    );
  }
}

export default PerformanceChartCard;
