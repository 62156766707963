import { ACTIONS } from '../../app/constants/index';

export default function (state = {}, action = { makenaPerson: {}, type: '' }) {
  switch (action.type) {
    case ACTIONS.ADMIN_FETCH_ALL_MAKENA_PEOPLE: {
      return action.makenaPeople;
    }
    case ACTIONS.ADMIN_CREATE_PERSON_SUCCESS:
    case ACTIONS.ADMIN_UPDATE_PERSON_SUCCESS:
    case ACTIONS.ADMIN_UPDATE_PERSON_SUCCESS: {
      return {
        ...state, 
        makenaPerson: action.makenaPerson 
      }
    }
    default:
      return state;
  }
}
