import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Card from '../../components/card/Card';
import CardHeader from '../../components/card/CardHeader';
import CardBody from '../../components/card/CardBody';
import DynamicImage from '../../components/image/DynamicImage';

import styles from '../../components/welcomeCard/welcomeCard.local.less';
import assetStyles from './makenaAssetClassCard.local.less'

const MakenaAssetClassCard = ({
  cardInfo,
  imagePath,
  imageAlt,
  layoutStyle,
  contentStyle,
  isHtmlText=false
}) => {
  const renderBodyText = (text, htmlText) => {
    if (htmlText) {
      const cardText = {__html: text };
      return (
        <div dangerouslySetInnerHTML={cardText}></div>
      )
    }
    return text;
  }

  const authorName = `${cardInfo.authorFirstName} ${cardInfo.authorLastName}`
  const date = moment.utc(cardInfo.date).format('MMMM YYYY');
  return (
    <Card
      layoutStyle={layoutStyle}
      contentStyle={contentStyle}
      trackEventLabel='Recent Updates'
      trackEventCategory='Managers'
    >
      <CardHeader>
        <section className={styles.welcomeCard}>
          <div className={styles.leftHeader}>
            Recent Updates
          </div>
        </section>
      </CardHeader>
      <CardBody>
        <section className={styles.welcomeCard}>
          <div className={styles.leftBody} title={authorName}>
            <DynamicImage
              path={imagePath}
              alt={imageAlt || authorName}
              isAsset={true}
              className={assetStyles.avatar}
            />
            <span className={styles.subtitle} style={{ marginBottom: '4px' }}>{authorName}</span>
            <span className={styles.repRole}>{cardInfo.authorTitle}</span>
          </div>
          <div className={styles.rightBody}>
            <span><i>{date}</i></span>
            <div className={`marginBottom ${assetStyles.bodyText}`}>
              {renderBodyText(cardInfo.body, isHtmlText)}
            </div>
          </div>
        </section>
      </CardBody>
    </Card>
  );
};

MakenaAssetClassCard.propTypes = {
  cardInfo: PropTypes.object,
  imagePath: PropTypes.string,
  imageAlt: PropTypes.string,
  layoutStyle: PropTypes.string,
  contentStyle: PropTypes.string,
  isHtmlText: PropTypes.bool
};

export default MakenaAssetClassCard;
