import React from 'react';
import PropTypes from 'prop-types';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import reducers from '../reducers';


const ReduxProviderWrapper = ({ children, initialState = {} }) => {
  // const store = createStore(
  //   reducers,
  //   initialState,
  //   window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : a => a,
  //   applyMiddleware(reduxThunk),
  // );

  const composeEnhancers = typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(reduxThunk),
    // other store enhancers if any
  );
  const store = createStore(reducers, initialState, enhancer);

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
};

ReduxProviderWrapper.propTypes = {
  initialState: PropTypes.shape({
    auth: PropTypes.object,
  }),
};

export default ReduxProviderWrapper;
