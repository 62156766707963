import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import Card from "../../../components/card/Card";
import CardPanel from "../../../components/cardPanel/CardPanel";
import CardHeader from "../../../components/card/CardHeader";
import { trackEvent } from "../../../helpers/gaHelpers/gaHelpers";
import { ROUTES } from "../../../app/constants/index";
import styles from "./loginForm.local.less";

const requiredField = (value) => value.trim() !== "";

class TOTPForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formControls: {
        code: {
          value: "",
          valid: true,
          validationRules: {
            isRequired: true,
            minLength: 6,
            maxLength: 6,
          },
        },
      },
    };

    this.codeFieldRef = createRef();
  }

  componentDidMount() {
    trackEvent("login", {
      event_category: "Account",
      event_label: "verify",
    });

    const codeField = this.codeFieldRef.current.getRenderedComponent();
    codeField.focus();
  }

  validate = (value, rules) => {
    let isValid = true;

    for (let rule in rules) {
      switch (rule) {
        case "isRequired":
          isValid = isValid && requiredField(value);
          break;
        case "maxLength":
        case "minLength":
          isValid = isValid && value.toString().length === 6;
          break;
        default:
          isValid = true;
      }
    }

    return isValid;
  };

  handleInputChange = (event) => {
    const { formControls } = this.state;
    const target = event.target;
    const name = target.name;
    const formState = {
      ...formControls,
    };

    const updatedControl = {
      ...formState[name],
    };

    updatedControl.value = target.value;

    formState[name] = updatedControl;

    this.setState({
      formControls: formState,
    });
  };

  errorHandler = (error) => {
    if (error)
      return (
        <div className={styles.bigErrorMessage}>
          <div className={styles.errorIcon}>
            <span>!</span>
          </div>
          <p>{error}</p>
        </div>
      );
  };

  getSessionMessage = () => {
    const { history } = this.props;
    const {
      location: { state: locationState = {} },
    } = history;
    const { flashMessage = null } = locationState;

    const signInMessage = "Please sign in to access your account";

    if (flashMessage) {
      history.replace(ROUTES.LOGIN.path, {
        ...locationState,
        flashMessage: null,
      });
    }

    return signInMessage;
  };

  render() {
    const { handleSubmit, handleLogin, submitting, error } = this.props;

    return (
      <Fragment>
        <Card
          layoutStyle="cardMain"
          trackEventLabel="Makena Investor Site"
          trackEventCategory="Authentication"
        >
          <CardHeader>Makena Investor Site</CardHeader>
          <CardPanel panelStyle="cardPanel noMargin">
            <p className={styles.signInText}>{this.getSessionMessage()}</p>
            {this.errorHandler(error)}
            <form
              autoComplete="off"
              onSubmit={handleSubmit(handleLogin)}
              className={`attached fluid segment ${styles.loginFormWrap}`}
            >
              <div className={styles.formField}>
                <label className={styles.formLabels}>Verification Code</label>
                <Field
                  component="input"
                  name="code"
                  tabIndex="1"
                  type="number"
                  className={styles.formInput}
                  onChange={this.handleInputChange}
                  ref={this.codeFieldRef}
                  forwardRef={true}
                />
              </div>

              <div className="two-column-flex">
                <div />
                <input
                  type="submit"
                  className={`${styles.submitVerifyBtn} formItem`}
                  disabled={submitting}
                  value="Verify"
                />
              </div>
            </form>
          </CardPanel>
        </Card>
      </Fragment>
    );
  }
}

TOTPForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleLogin: PropTypes.func,
  submitting: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object,
    replace: PropTypes.func,
  }),
};

export default compose(
  connect(null, null),
  reduxForm({ form: "totpForm" })
)(TOTPForm);
