export const objectiveCardText = {
  header: 'Our Objective',
  body: `<p>
  The Makena Endowment Portfolio’s objective is to preserve and grow
  multigenerational capital by generating a long-term return exceeding
  inflation (CPI) plus payout (500bps), while maximizing risk-adjusted returns
  through diversification and active management. Additionally, the
  Makena Endowment Portfolio offers investors access to elite,
  capacity-constrained investment opportunities across asset classes
  and access to a mature portfolio in order to bypass the
  portfolio construction phase.
</p>`
};

export const strategyCardText = {
  header: 'Our Strategy',
  body: `<p>
  The strategy behind the Makena Endowment Portfolio is to build a balanced
  portfolio that is strategically diversified across asset class, strategy,
  and geography.
  The Makena Endowment Portfolio allocates capital to top-tier managers across
  asset classes with an emphasis on alternatives, selectively granting
  access to emerging markets with local managers who have long-term track records.
  Our strategy centers around diligently monitoring and managing portfolio
  risks such as equity risk, currency and liquidity.
</p>`
};
