import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import Svg                    from '../svg/Svg';
import styles                 from './datatable.local.less';
import { retrieveSortObject } from './SortingHelper';
import AscIcon                from '../../assets/svg/button-sort-descending.svg';
import AscIconWhite           from '../../assets/svg/button-sort-descending-white.svg';
import DescIcon               from '../../assets/svg/button-sort-ascending.svg';
import DescIconWhite          from '../../assets/svg/button-sort-ascending-white.svg';
import Filter                 from '../../assets/svg/button-filter.svg';
import FilterWhite            from '../../assets/svg/button-filter-white.svg';
import DatatableFilterOptions from './DatatableFilterOptions';



class DatatableHeaderColumnString extends Component {
  state = { openFilter: false };

  componentDidUpdate() {
    const {
      state: { openFilter }
    } = this;
    if (!openFilter) return;
    const element = document.querySelector(`.${styles.headerCell}`);
    if (!element) return
    const elementBounds = element.getBoundingClientRect();
    element.style.marginTop = `-${elementBounds.height - 32}px`;
  }

  handleOnBlur = ({ target }) => {
    if(!document.getElementById("InvestorDocumentsFilterBox") || !document.getElementById("InvestorDocumentsFilterBox").contains(target)) {
      this.setState({
        openFilter: null,
      }, () => {
        document.removeEventListener('click', this.handleOnBlur);
      });
    }
  };

  openFilterColumn = (event, title) => {
    event.stopPropagation();
    event.preventDefault();
    
    document.addEventListener('click', this.handleOnBlur);

    this.setState(prevState => ({ openFilter: prevState.openFilter !== title  ? title  : null }));
  };

  onFilterOptionClicked = filterField => selectedOptions => {
    const { onFilterOptionClicked = () => {} } = this.props;
    onFilterOptionClicked({ [filterField]: selectedOptions });
  }

  onSortChange = () =>  {    
    const { column: { filterField, sortDirection = 'DESC', isSortable }, onSortChange = () => {} } = this.props;
    if (!isSortable) return;
    onSortChange(retrieveSortObject(filterField, sortDirection));
  }

  onToggle = () => this.setState({...this.setState, openFilter: !this.state.openFilter });

  render() { 
    const {
      props: { column: { isSortable, title, isFilterable, customSortFilter, filterOptions = [], filterField = '', sortDirection, isFilterContainerWider }, id, rowContainerClasses },
      state: { openFilter }
    } = this;
    
    return (
      <div className={`${styles.headerCellButton} ${openFilter? styles.active : null}`}>
        <div className={`${styles.headerCell} ${openFilter? styles.active : null}`} id={id}>
          { 
            !isSortable || !sortDirection? null :
              <div onClick={this.onSortChange} className={styles.sortable}>
                { openFilter? <Svg svg={sortDirection === 'ASC'? AscIconWhite : DescIconWhite}/> : <Svg svg={sortDirection === 'ASC'? AscIcon : DescIcon}/>}
              </div>
          }
          <div onClick={this.onSortChange}>{title}</div>
          { 
            !isFilterable? null :
              <div className={styles.filters} onClick={event => this.openFilterColumn(event, title)}>
                <Svg svg={openFilter? FilterWhite : Filter}/>
              </div>
          }
        </div>
        {
          !openFilter? null : 
            <DatatableFilterOptions customSortFilter={customSortFilter} isFilterContainerWider={isFilterContainerWider} rowContainerClasses={rowContainerClasses} idColumn={id} options={filterOptions} onFilterOptionClicked={this.onFilterOptionClicked(filterField)}/>
        }
      </div>
    ); 
  }
}

DatatableHeaderColumnString.propTypes = {
  onFilterOptionClicked: PropTypes.func,
  onSortChange: PropTypes.func,
  column: PropTypes.object,
  id: PropTypes.string,
  rowContainerClasses: PropTypes.string
}

export default DatatableHeaderColumnString;