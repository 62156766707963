import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Svg from '../svg/Svg';
import _ from 'lodash';
import chevronUp from '../../assets/svg/gray-chevron-up.svg';
import chevronDown from '../../assets/svg/gray-chevron-down.svg';
import styles from './checkboxAccordion.local.less';
import CheckboxAccordionItem from './CheckboxAccordionItem';

class CheckboxAccordion extends Component {

  state = {
    collapsed: false,
    data: {},
  };

  componentDidUpdate = prevProps => {
    if (!prevProps.reset && !this.props.reset) return;
    if (prevProps.reset && !_.isEmpty(this.state.data)) return;
    if (prevProps.reset === this.props.reset) return;
    this.setState({ data: {} })
  }

  getSelectedOptions = () => {
    const { 
      props: { options = []}
    } = this;
    let mergedObject = {};
    options.forEach(({ name, filterSelected }) => filterSelected? mergedObject[name] = true : null);
    return mergedObject;
  }

  handleFormValuesChange = name => toggled => {
    const { 
      getSelectedOptions,
      props: { onChange = () => {} }
    } = this;
    
    const newData = { ...getSelectedOptions(), [name]: toggled };
    this.setState({ data: newData });
    
    const output = [];
    _.forEach(newData, (value, key) => { 
      if (!value) return;
      output.push(key); 
    });
    
    onChange(output);
  };

  handleCollapse = () => this.setState({ ...this.state, collapsed: !this.state.collapsed });

  render () {
    const {
      props: { title, options },
      state: { collapsed }
    } = this;
    return (
      <div className={styles.accordion}>
        <div className={styles.accordionTopBar}>
          <div className={styles.accordionTitle}>{title}</div>
          <div className={styles.accordionIcon} onClick={this.handleCollapse}><Svg svg={ collapsed? chevronDown : chevronUp }/></div>
        </div>
        <div className={`${styles.accordionBody} ${collapsed? styles.hidden : ''}`}>
          { options.map(
            (option, index) => (
              <div className={styles.accordionOption} key={index}>
                <CheckboxAccordionItem
                  mark={option.mark}
                  selected={option.filterSelected}
                  label={`${option.title} (${option.count})`}
                  onChange={this.handleFormValuesChange(option.name)}/>
              </div>
            )
          ) 
          }
        </div>
      </div>
    );
  }
}

CheckboxAccordion.propTypes = {
  title: PropTypes.string,
  reset: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
}

export default CheckboxAccordion;