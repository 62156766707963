import { ACTIONS } from '../../app/constants/index';

// eslint-disable-next-line import/prefer-default-export
export const clientDocumentsFetchSuccess = clientDocuments => ({
  type: ACTIONS.CLIENT_DOCUMENTS_FETCH_SUCCESS,
  clientDocuments,
});

// eslint-disable-next-line import/prefer-default-export
export const insightsDocumentsFetchSuccess = insightsDocuments => ({
  type: ACTIONS.INSIGHTS_DOCUMENTS_FETCH_SUCCESS,
  insightsDocuments,
});

// eslint-disable-next-line import/prefer-default-export
export const investorDocumentsFetchSuccess = investorDocuments => ({
  type: ACTIONS.INVESTOR_DOCUMENTS_FETCH_SUCCESS,
  investorDocuments,
});

// eslint-disable-next-line import/prefer-default-export
export const investorDocumentsDatatableFetchSuccess = investorDocumentsDatatable => ({
  type: ACTIONS.INVESTOR_DOCUMENTS_DATATABLE_FETCH_SUCCESS,
  investorDocumentsDatatable,
});

// eslint-disable-next-line import/prefer-default-export
export const investorDocumentsCountByFieldFetchSuccess = investorDocumentsCountByField => ({
  type: ACTIONS.INVESTOR_DOCUMENTS_COUNT_BY_FIELD_FETCH_SUCCESS,
  investorDocumentsCountByField,
});
