import React from 'react';

const ContentRenderer = (Renderer) => {
  if (!Renderer) {
    return value => value;
  }

  if (React.isValidElement(Renderer)) {
    return value => (
      <Renderer value={value} />
    );
  }
  else {
    return value => Renderer(value)
  }

};

export default ContentRenderer;