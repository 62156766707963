import * as R from 'ramda';
import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';

export const passwordResetRequest = async email => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.PASSWORD_RESET_REQUEST_FETCH(email)}`;
    const response = await requestWrapper.get(url);
    return response.status === 200? true : false;
  } catch (err) {
    return; // Always show success on this form.
  }
};

export const passwordResetRequestAdmin = async (email, omitSendEmail) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.PASSWORD_RESET_ADMIN_REQUEST_FETCH(email, omitSendEmail)}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    throw err;
  }
};

export const passwordReset = async ({ email, password, token }) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.PASSWORD_RESET_FETCH}`;
    const response = await requestWrapper.post(url, { email, password, token });
    return response.status === 200? true : false;
  } catch (err) {

    let errorMessage = R.pathOr(
      ERRORS.PASSWORD_RESET_ERROR(email),
      ['response', 'data', 'data', 'err', 'message'],
      err
    );

    throw new Error(errorMessage);
  }
};
