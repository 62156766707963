import getApiRootUrl from '../../../helpers/apiHelpers/getApiRootUrl';
import API_URLS from '../../../app/constants/apiUrls';
import requestWrapper from '../../../helpers/apiHelpers/requestWrapper';
import ERRORS from '../../../app/constants/errors';
import { getErrorMessage } from '../../errors/errorHelper';

export const getSurvey = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.NOTIFICATION_SETTINGS_ALL}`;
    const response = await requestWrapper.get(url);
    return response.data.data;

  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.NOTIFICATION_SETTINGS_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};

export const updateSurvey = async (id, payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.NOTIFICATION_SETTINGS_ENTITY(id)}`;
    const response = await requestWrapper.put(url, payload);
    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.NOTIFICATION_SETTINGS_UPDATE_ERROR);
    throw new Error(errorMessage);
  }
};

export const createSurvey = async payload => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.NOTIFICATION_SETTINGS_ALL}`;
    const response = await requestWrapper.post(url, payload);
    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.NOTIFICATION_SETTINGS_CREATE_ERROR);
    throw new Error(errorMessage);
  }
};
