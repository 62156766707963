// eslint-disable-next-line import/prefer-default-export
export const getErrorMessage = (error, defaultErrorMessage) => {
  if (error.statusCode === 403) {
    return 'Permission denied';
  } 
  
  if (error.statusCode >= 400) {
    return 'Bad response from server';
  }

  return defaultErrorMessage;
};
