import React from 'react';
import PropTypes from 'prop-types';

import DynamicImage from '../image/DynamicImage';

import styles from './managerGalleryCard.local.less';

const ManagerGalleryCardImage = props => (
  <div className={styles.cardImageContainer} >
    { 
      props.logo 
        ? <DynamicImage {...props} path={`managers/${props.logo}`} isAsset={true} /> 
        : props.alt     
    }
  </div>
)
ManagerGalleryCardImage.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
};

export default ManagerGalleryCardImage;
