import React, { Component }                     from 'react';
import PropTypes                                from 'prop-types';

import CardHeader                               from '../../components/card/CardHeader';
import ExposureChartList                        from '../../components/exposureChartList/ExposureChartList';
import ExposuresCard                            from '../../components/exposuresCard/ExposuresCard';
import Footer                                   from '../../components/footer/Footer';
import FundPerformanceChartCard                 from '../../components/fundPerformanceChartCard/FundPerformanceChartCard';
import FundClosedEndPerformanceCard             from '../../components/fundClosedEndPerformanceCard/FundClosedEndPerformanceCard';
import TextDisplayCard                          from '../../components/textDisplayCard/TextDisplayCard';

import { objectiveCardText, strategyCardText }  from './MakenaFundDescription';
import { jCurveTransform }                      from '../../helpers/fundsHelpers/charts';

class ClosedEndMakenaFund extends Component {

  render() {
    const {
      asOfDate,
      fund,
      overview,
      growthOfADollar,
      exposures,
      header
    } = this.props;

    const {
      displayName,
      inceptionDate,
      objective,
      strategy,
      sidepocket
    } = fund;
    
    return (
      <div className="dashboard-content">
        { header }
        <div className='contentRowSplitTwo unprintable'>
          <TextDisplayCard 
            layoutStyle='cardRowItem cardContentLeft' 
            headerText={objectiveCardText.header}
            bodyText={objective}
            isHtmlText={true}
          />
          <TextDisplayCard 
            layoutStyle='cardRowItem cardContentRight'
            headerText={strategyCardText.header}
            bodyText={strategy}
            isHtmlText={true}
          />
        </div>
        <div className='contentRowSplitTwo makenaProduct'>
          <FundPerformanceChartCard
            performance={jCurveTransform(inceptionDate, asOfDate, growthOfADollar)}
            subTitle=''
            ignoreBenchmarks={true}
            layoutStyle='cardRowItem unprintable makenaProductPerformance'
            labels={{'Your Portfolio': displayName}}
            title='Since Inception IRR'
          />
          <FundClosedEndPerformanceCard 
            fund={fund}
            overview={overview}
            layoutStyle='cardRowItem unprintable makenaProductAssets'
            title='Fund Summary'
          />
          <ExposuresCard 
            layoutStyle={`cardRowItem unprintable printableCard makenaProductExposures ${sidepocket? 'sidepocket' : ''}`}
            trackEventLabel='Fund Exposures'
          >
            <CardHeader>
              Fund Exposures
            </CardHeader>
            <div className="printableCardBody">
              <ExposureChartList charts={exposures} />
            </div>
          </ExposuresCard>
        </div>
        <Footer/>
      </div>
    );
  }
}


ClosedEndMakenaFund.propTypes = {
  asOfDate        : PropTypes.string,
  header          : PropTypes.any,
  exposures       : PropTypes.array,
  fund            : PropTypes.object ,
  growthOfADollar : PropTypes.shape({}) ,
  overview        : PropTypes.shape({})
};

export default ClosedEndMakenaFund;
