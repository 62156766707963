import React, { Component } from 'react';
import { connect }          from 'react-redux';
import PropTypes            from 'prop-types';
import _                    from 'lodash';
import cx                   from 'classnames';
import HeaderMenuDropdown   from '../headerMenuDropdown/HeaderMenuDropdown';
import AsOfBadge            from '../asOfBadge/AsOfBadge';

import { CSSTransition } from 'react-transition-group';
import { ROUTES } from '../../app/constants/index';
import { onToggleMenu } from '../../services/menu/menuService';
import { auditLog } from '../../services/logging/loggingService';
import { LOG_EVENTS } from '../../app/constants/clientLogs';

import styles from './headerMenu.local.less';

const managersMenu = [
  {
    name: 'All Managers',
    path: ROUTES.EXPLORE.path,
  }
];

const investorDocumentsMenu = [
  {
    name: 'Investor Documents',
    path: ROUTES.INVESTOR_DOCUMENTS.path,
  }
];

const taxElectionFormsMenu = [
  {
    name: 'Forms',
    path: ROUTES.TAX_ELECTION_FORMS.path,
  }
];

class HeaderMenu extends Component {
  get products () {
    return [
      {
        name: 'Home',
        path: ROUTES.DASHBOARD.path,
      },
      ..._.map(this.props.funds, product => { 
        return {
          name: product.displayName, 
          path: ROUTES.DASHBOARD.children.MAKENA_PRODUCT.path.replace(':productId', product.entityId),
          child: true,
        }; 
      }),
    ]
  }

  handleOnToggle(open, onToggle) {
    if (!open) {
      auditLog(LOG_EVENTS.USER_ACTIONS.MENU);
    }
    onToggle();
  }
  
  render() {
    const { isLoggedIn, open, clientInfo, settings, onToggle } = this.props;
    const { taxFormsEnabled } = settings || {};
    const isClientVisible = !_.isEmpty(clientInfo);
    const { config: {leftNav, fundsMenu, managerMenu, investorDocsMenu, asOfDate, taxElectionForms } } = this.props; 
    
    return isLoggedIn
      ? (
        <div className="headerMenu">
          { isClientVisible ?
            (
              <div className="headerMenuContainer">
                {leftNav && <div
                  className={`headerMenuContainerIcon ${open? 'openSidebar' : ''}`}
                  onClick={() => this.handleOnToggle(open, onToggle)}
                >
                  <div className={styles.iconMenuWrapper}>
                    <div
                      className={cx(
                        styles.line,
                        { [styles.arrowTopLine]: open }
                      )}
                    />
                    <div
                      className={cx(
                        styles.line,
                        { [styles.middleLine]: open }
                      )}
                    />
                    <div
                      className={cx(
                        styles.line,
                        { [styles.arrowBottomLine]: open }
                      )}
                    />
                  </div>
                  <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={open}
                    timeout={{
                      enter: 300,
                      exit: 0
                    }}
                    classNames={{
                      enter: styles.iconTextEnter,
                      enterActive: styles.iconTextEnterActive
                    }}
                  >
                    <div className={styles.openMenuText}>
                        close
                    </div>
                  </CSSTransition>
                </div>}
                <div className={`headerMenuContainerOptions ${ open? 'openSidebar' : '' }`}>
                  {fundsMenu && <HeaderMenuDropdown
                    menuItemClass="headerMenuContainerOption"
                    items={this.products}
                    name="Funds"
                  />}
                  {managerMenu && <HeaderMenuDropdown
                    items={managersMenu}
                    menuItemClass="headerMenuContainerOption"
                    name="Managers"
                  />}
                  {investorDocsMenu && <HeaderMenuDropdown
                    items={investorDocumentsMenu}
                    menuItemClass="headerMenuContainerOption"
                    name="Investor Documents"
                  />}
                  {taxElectionForms && taxFormsEnabled && <HeaderMenuDropdown
                    items={taxElectionFormsMenu}
                    menuItemClass="headerMenuContainerOption"
                    name="Forms"
                  />}
                  <div className="headerMenuContainerOptionFiller"></div>
                  <div className={styles.asOfBadge}>
                    {asOfDate && <AsOfBadge/>}
                  </div>
                </div>
                <div className={`headerMenuContainerFiller ${open? 'openSidebar' : null}`}>
                  <div className="mockMenuIcon"></div>
                </div>
              </div>
            ) : (
              <div className="headerMenuContainer">
                <div>
                  <div className={`headerMenuContainerOptions ${ open? 'openSidebar' : '' }`}>
                    {investorDocsMenu && <HeaderMenuDropdown
                      items={investorDocumentsMenu}
                      menuItemClass="headerMenuContainerOption"
                      name="Investor Documents"
                    />}
                    {taxElectionForms && taxFormsEnabled && <HeaderMenuDropdown
                      items={taxElectionFormsMenu}
                      menuItemClass="headerMenuContainerOption"
                      name="Forms"
                    />}
                  </div>
                  <div className="headerMenuContainerOptionFiller"></div>
                </div>
              </div>
            )
          }
        </div>
      )
      : <div className={styles.headerMenuContainer}></div>;
  }
}

HeaderMenu.propTypes = {
  onToggle: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  open: PropTypes.bool,
  funds: PropTypes.array,
  config: PropTypes.object,
  settings: PropTypes.any,
  clientInfo: PropTypes.shape({ 
    pathname: PropTypes.string,
    title: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    open: state.menu.open,
    settings: state.auth.settings
  };
}

export default connect(
  mapStateToProps,
  {onToggle: onToggleMenu}
)(HeaderMenu);
