import * as R from 'ramda';
import React from 'react';
import moment from 'moment';
import { titleCase } from 'title-case';
import { upperCase } from 'upper-case';
import PropTypes from 'prop-types';

import Card from '../card/Card';
import CardBody from '../card/CardBody';
import CardHeader from '../card/CardHeader';
import DownloadLink from '../downloadDocumentLink/DownloadDocumentLink';

import getApiRootUrl    from '../../helpers/apiHelpers/getApiRootUrl';
import API_URLS         from '../../app/constants/apiUrls';
import { ROUTES } from '../../app/constants/index';
import { CALENDAR_WORDS } from '../../app/constants/miscellaneous';

import styles from './investorDocumentsCard.local.less';

const formatTitle = title => {
  return _.filter(title.split(' '), word => {
    if (_.find(CALENDAR_WORDS, calendarWord => word.toUpperCase() === calendarWord)) return;
    if (!_.isNaN(parseInt(word, 10))) return;
    return word;
  }).join(' ');
};

const excludeInternal = R.compose(
  R.not,
  R.includes(R.__, ['internal', 'internal crm only', 'internal - crm only']),
  cat => cat.toLowerCase().trim(),
  R.prop('category')
)

const renderNoDocuments = () => {
  return (<div className="noContent">
    <div className="noContentContainer">
      <div className="marginBottom">Your investor documents will appear here.</div>
      <div>
        If you have any questions, please contact your Makena account manager or write to us at
        <a href="mailto:ClientOperations@makenacap.com"> ClientOperations@makenacap.com</a>.
      </div>
    </div>
  </div>)
}

const InvestorDocumentsCard = ({ documents=[], layoutStyle, inlineStyles={}, parentPage }) => {
  const apiUrl = getApiRootUrl();
  const filteredDocuments = _.filter(documents, excludeInternal);

  return (
    <Card
      layoutStyle={layoutStyle}
      trackEventLabel='Investor Documents Card'
      trackEventCategory='Documents'
    >
      <CardHeader>
        <section className={styles.investorDocumentsCard}>
          <div className={styles.investorDocumentsCardTitle}>
            Recent Investor Documents
          </div>
          { (parentPage !== "settings") &&
          <div className={styles.rightHeader}>
            <a href={ROUTES.INVESTOR_DOCUMENTS.path}>See more</a>
          </div>
          }
        </section>
      </CardHeader>
      <CardBody>
        <div className={styles.cardBodyInner} style={inlineStyles}>
          {
            filteredDocuments.length ?
              filteredDocuments.map((document, index) => {
                const documentUrl = API_URLS.DOCUMENT_DOWNLOAD(document.title);

                return (
                  <DownloadLink
                    key={index}
                    className={styles.documentLink}
                    href={`${apiUrl}${documentUrl}?attachment=false&uuid=${document.uuid}`}
                    trackEventLabel={document.title}
                  >
                    <div className={styles.titleContainer}>
                      <span className={styles.title}>
                        {titleCase(formatTitle(document.title))}
                      </span>
                      <span className={styles.fund}>
                        {upperCase(formatTitle(document.fundCode))}
                      </span>
                    </div>
                    <div className={styles.documentDate}>
                      {moment.utc(document.releaseDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                    </div>
                  </DownloadLink>
                )
              })
              : renderNoDocuments()
          }
        </div>
      </CardBody>
      { (parentPage == "settings") &&
      <div className={styles.rightFooter}>
        <a href={ROUTES.INVESTOR_DOCUMENTS.path}>See All Investor Documents</a>
      </div>
      }
    </Card>
  );
};

InvestorDocumentsCard.propTypes = {
  documents: PropTypes.object,
  makenaFunds: PropTypes.shape({}),
  layoutStyle: PropTypes.string,
  inlineStyles: PropTypes.object,
  parentPage: PropTypes.string
};

export default InvestorDocumentsCard;
