import getApiRootUrl      from '../../helpers/apiHelpers/getApiRootUrl';
import API_URLS           from '../../app/constants/apiUrls';
import requestWrapper     from '../../helpers/apiHelpers/requestWrapper';
import { getSettings }    from '../auth/authHelper';
import { getClientInfo }  from '../makenaClient/makenaClientHelper';

const getClientInfoForHierarchy = () => {
  try {
    const settings = getSettings();
    const clientProfile = getClientInfo();

    return {
      clientId: clientProfile.entityId,
      startDate: settings.reportingQuarterStart,
      endDate: settings.reportingQuarterEnd,
    }
  } catch (e) {
    throw e;
  }
};

export const getAppHierarchy = async () => {
  try {
    const {clientId, startDate, endDate: reportingQuarterEnd } = getClientInfoForHierarchy();
    const url = `${getApiRootUrl()}${API_URLS.APP_HIERARCHY_FETCH(clientId, startDate, reportingQuarterEnd)}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    throw err;
  }
};
