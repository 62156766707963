import React from 'react';
import PropTypes from 'prop-types';
import CollapsiblePanel from '../../../components/collapsiblePanel/CollapsiblePanel';
import Card from '../../../components/card/Card';
import CardPanel from '../../../components/cardPanel/CardPanel';
import CardHeader from '../../../components/card/CardHeader';

import styles from './loginTOSPanel.local.less';

const nda = (
  <div>
    The information available on this site relates to Makena and its affiliated investment funds. This information is highly confidential. You hereby agree to maintain such information in strict confidence and acknowledge that such information is proprietary and confidential, that Makena derives independent economic value from such information not being generally known, and that such information is subject to reasonable efforts by Makena to maintain its secrecy. You hereby agree that information available on the site constitutes trade secrets and that the disclosure of any such information will cause substantial and irreparable competitive harm, as well as financial and strategic damage, to Makena and/or the portfolio companies of an investment fund managed by Makena (each a 'Fund'). Any reports, memoranda, or summaries available on this site are for use by investors or their authorized designees, and you hereby agree that this information shall only be used by you in connection with your evaluation of an investment in such Fund. In addition, you agree that any information on this website shall not be disclosed to any other person or entity or reproduced in any manner (by print, by electronic means or otherwise) without the express written authorization of Makena; provided, that such information may be disclosed on a need-to-know basis to other employees of your organization and to your investment advisers (so long as (i) they are bound by an obligation to maintain the confidentiality of such information that is at least as protective as your obligations hereunder and (ii) such other employees and investment advisers are not given your password to access this site) or as explicitly required by applicable law.
  </div>
);

const advisoryServices = (
  <div>
    <p>The information on this Site is provided for informational purposes only. This Site and the information contained herein do not constitute and should not be construed as an offer to sell or a solicitation of any offer to buy the securities or services of Makena or any other issuer. Offers can only be made where lawful under, and in compliance with, applicable law. Responses to any inquiry that may involve the rendering of personalized investment advice or effecting or attempting to effect transactions in securities will not be made absent compliance with applicable laws or regulations (including broker dealer, investment adviser or applicable agent or representative registration requirements), or applicable exemptions or exclusions therefrom.</p>
    <p>Makena makes no representations that transactions, products or services discussed on the Site are available or appropriate for sale or use in all jurisdictions or by all investors. Those who access the Site do so at their own initiative and are responsible for compliance with local laws or regulations.</p>
    <p>Makena is not utilizing the Site to provide investment or other advice, and no information or material at the Site is to be deemed a recommendation to buy or sell any securities or is to be relied upon for the purpose of making or communicating investment or other decisions. In addition, no information, content or other materials contained on the Site should be construed or relied upon as investment, legal, accounting, tax or other professional advice or in connection with any offer or sale of securities. Any transactions listed on the Site are included as representative transactions and are not necessarily reflective of overall performance.</p>
    <p>Makena does not advise on the tax consequences of any investment.</p>
  </div>
);

const LoginTOSPanel = ({handleTOSAccept, handleTOSDecline}) => {
  return (
    <Card layoutStyle={`cardMain ${styles.loginFormPanel}`}>
      <CardHeader>Makena Investor Site</CardHeader>
      <CardPanel panelStyle="cardPanel noMargin">
        <p className={styles.signInText}>Thanks for signing in.</p>
        <p className={styles.tosIntro}>Please agree to our Terms of Service below before you enter the site.</p>

        <div className={styles.termServiceContainer}>
          <h5 className={styles.termsTitle}>Terms of Service</h5>
          <CollapsiblePanel
            serviceTitle={'Non-Disclosure Agreement'}
            serviceContent={nda}
          />
          <CollapsiblePanel
            serviceTitle={'Not an Offer of Advisory Services'}
            serviceContent={advisoryServices}
          />

          <p className={styles.termsClosing}>Once you’ve read the material above, click the I Agree button below to agree to these Terms of Service.</p>
        </div>
        <div className={styles.termsBtns}>
          <button className={styles.agreeBtn} onClick={handleTOSAccept} value={true}>I Agree</button>
          <button className={styles.disagreeBtn} onClick={handleTOSDecline} value={false}>I Don&apos;t Agree</button>
        </div>
      </CardPanel>
    </Card>
  );
};

LoginTOSPanel.propTypes = {
  handleTOSAccept: PropTypes.func,
  handleTOSDecline: PropTypes.func
};


const LoginTOSDeclinePanel = ({handleLoginFlowResetClick}) => {
  return (
    <Card layoutStyle={`cardMain ${styles.loginFormPanel}`}>
      <CardHeader>Makena Investor Site</CardHeader>
      <CardPanel panelStyle="cardPanel noMargin">
        <div className={styles.tosCancelContent}>
          <p className={styles.signInText}>Thanks for signing in.</p>
          <p className={styles.tosIntro}>Please contact your Makena account manager if you need further assistance.</p>
        </div>
        <button className={`${styles.loginPanelButton} ${styles.btnWide}`} onClick={handleLoginFlowResetClick} value={false}>Return to Sign In</button>
      </CardPanel>
    </Card>
  )
}

LoginTOSDeclinePanel.propTypes = {
  handleLoginFlowResetClick: PropTypes.func
}

export {
  LoginTOSPanel,
  LoginTOSDeclinePanel
};
