import React, { Component }     from 'react';
import moment                   from 'moment';

import Card                     from '../../components/card/Card';
import CardBody                 from '../../components/card/CardBody';
import CardHeader               from '../../components/card/CardHeader';
import DownloadLink             from '../../components/downloadDocumentLink/DownloadDocumentLink';
import SectionLink              from '../../components/sectionLink/SectionLink';
import Svg                      from '../../components/svg/Svg';
import DynamicImage             from '../../components/image/DynamicImage';

import getApiRootUrl            from '../../helpers/apiHelpers/getApiRootUrl';
import API_URLS                 from '../../app/constants/apiUrls';

import DownloadIcon             from '../../assets/svg/download-button.svg';
import styles                   from './insightsPage.local.less';
import icon                     from './../../assets/svg/m-large.svg';
import Footer                   from '../../components/footer/Footer';
import { getInsightsDocuments } from '../../services/document/documentService';
import { MergeAll }             from '../../helpers/globalHelpers';

class InsightsPage extends Component {
  state = {
    isLoading: true,
    data: { }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    await this.refresh().then(this.finishLoading);
  }

  refresh = () => {
    const promises = [
      getInsightsDocuments().then(insightDocuments => ({ insightDocuments })),
    ];
    return Promise.all(promises)
      .then(MergeAll);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = data => {
    this.setState({
      data,
      isLoading: false,
    });
  };

  setError = (errorName, errorMessage) => {
    this.setState({
      ...this.state,
      errors: {
        [errorName]: errorMessage,
      },
    });
  };

  clearError = (errorName) => {
    this.setState({
      ...this.state,
      errors: {
        [errorName]: null,
      },
    });
  };

  render() {
    const { isLoading, data: { insightDocuments } } = this.state;
    if (isLoading || !insightDocuments) return null;
    const apiUrl = getApiRootUrl();
    return (
      <div className="dashboardTopPadding">
        <Card
          layoutStyle="contentRowFull cardMain"
          trackEventLabel='Makena Insights Page'
          trackEventCategory='Makena Insights'
        >
          <CardHeader>Makena Insights</CardHeader>
          <CardBody>
            <div className="table">
              {
                insightDocuments.map((row, index) => {
                  const documentUrl = API_URLS.DOCUMENT_DOWNLOAD(row.title);
                  return (
                    <SectionLink
                      key={index}
                      className={`tableRow`}
                    >
                      <div className="tableRowCell big">
                        <div className="tableRowCellContent">
                          <div className={styles.content}>
                            {
                              row.logo? <DynamicImage
                                path={`managers/${row.logo}`}
                                isAsset={true}
                                className={styles.avatar}
                              /> : <Svg className={styles.avatar} svg={ icon }/>
                            }
                            <div className={styles.description}>
                              <div className={styles.title}>{row.title}</div>
                              { row.author ? <div className={styles.subtitle}>{row.author}</div> : null }
                              { row.metaJson && row.metaJson.description ? <div className={styles.summary}>{row.metaJson.description}</div> : null }
                              <DownloadLink
                                className={`row ${styles.clickable}`}
                                href={`${apiUrl}${documentUrl}?attachment=false&uuid=${row.uuid}`}
                                trackEventLabel={row.title}
                                trackEventCategory='Makena Insights'
                              >
                                <Svg svg={DownloadIcon}/> <span className={styles.leftSpaced}>Download</span>
                              </DownloadLink>
                            </div>
                            <div>{moment.utc(row.releaseDate).format('MMMM DD, YYYY')}</div>
                          </div>
                        </div>
                      </div>
                    </SectionLink>
                  )
                })
              }
            </div>
          </CardBody>
        </Card>
        <div style={{ marginTop: '20px' }}>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default InsightsPage;
