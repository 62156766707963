import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import FieldValidationChecklist from './FieldValidationChecklist'; 

import styles from './annotatedPasswordField.local.less';

const RenderMode = {
  PASSWORD: 'password',
  TEXT: 'text'
};

class AnnotatedPasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      displayMode: RenderMode.PASSWORD,
      isEditing: false
    }
    this.fieldRef = React.createRef();
  }

  componentDidMount() { }

  handleFieldFocus = () => {
    this.setState(() => ({
      isEditing: true
    }));
  }

  handleFieldBlur = () => {
    this.setState(() => ({
      isEditing: false
    }));
  }

  renderPopupContent= () => {
    const { validators } = this.props.validationState;
    const items = validators.map(validator => {
      return { isValid: validator.isValid, error: validator.error };
    })
  
    return (
      <FieldValidationChecklist validators={items} />
    )
  }

  showToolTip() {
    return this.state.isEditing && this.props.toolTip;
  }

  handleModeToggle = () => {
    const mode = this.state.displayMode == RenderMode.PASSWORD ? RenderMode.TEXT : RenderMode.PASSWORD;
    this.setState(() => ({
      displayMode: mode
    }));
  }

  render() {
    //let renderProps = Object.assign({}, this.props, {type: this.state.dis, label: undefined, fieldClass: styles.fieldWrap});
    const {label, input, position = 'bottom left' } = this.props;
    
    return (
      <Fragment>
        <label>{label}</label>
        <div className={styles.annotatedField}>
          <input 
            {...input} 
            placeholder={label} 
            type={this.state.displayMode} 
            ref={this.fieldRef} 
            onFocus={this.handleFieldFocus} 
            onBlur={this.handleFieldBlur}
            autoComplete="no_thanK_you"
          />
          <span className={styles.validStatus}>{this.state.isValid ?<> &#10003; </>: ''} </span>
          <span className={styles.inputModeLabel} onClick={this.handleModeToggle}> 
            {this.state.displayMode == RenderMode.PASSWORD ? "SHOW" : "HIDE"}
          </span>
        </div>
        <Popup position={position} context={this.fieldRef.current} open={this.showToolTip()} wide='very'>
          <Popup.Header className={styles.toolTipHeader}>Your password must have at least:</Popup.Header>
          <Popup.Content className={styles.toolTipContent}>
            {this.renderPopupContent()}
          </Popup.Content>
        </Popup>
      </Fragment>
    );
  }
}
  
AnnotatedPasswordField.propTypes = {
  toolTip: PropTypes.bool,
  validationState: PropTypes.object,
  label: PropTypes.string,
  input: PropTypes.Component,
  position: PropTypes.string
}

export default AnnotatedPasswordField;


