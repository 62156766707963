import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import styles from './footer.local.less';
import { FOOTER_MANAGER_DISCLAIMER, FOOTER_COPYRIGHTS } from '../../app/constants/site';

const appFooterTextContent = `${FOOTER_MANAGER_DISCLAIMER}`;
const defaultConfig = {
  textContent: true,
  copyRights: true,
  links: true
};

const Footer = ({config = {}}) => {
  const footerConfig = {...defaultConfig, ...config};
  const {textContent, copyRights, links} = footerConfig;

  return (
    <div className={cx('appFooter unprintable', styles.footer)}>
      <div className={styles.disclaimer}>
        <p>
          {textContent && appFooterTextContent}
        </p>
      </div>
      <div className={styles.footerCopy}>
        {copyRights && <span
          className={styles.copyRight}
          dangerouslySetInnerHTML={{__html:FOOTER_COPYRIGHTS}}
        />}
        <div className={styles.terms}>
          {links &&
            <ul className={styles.footerLinks}>
              <li>
                <NavLink
                  className='darkShade'
                  to='/terms-and-conditions'
                >
                      Terms and Conditions
                </NavLink>
              </li>
              <li>
                <NavLink
                  className='darkShade'
                  to='/privacy'
                >
                      Privacy
                </NavLink>
              </li>
            </ul>
          }
        </div>
      </div>
    </div>
  );
}


Footer.propTypes = {
  config: PropTypes.shape({})
}

export default Footer;
