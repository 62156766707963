import * as R from 'ramda';
import moment from 'moment';  

const getEOQ = date => {
  const subtractedDay = moment.utc(date).subtract(1, 'd'),
    subtractedQuarter = moment.utc(date).subtract(1, 'q');
  
  return (subtractedDay.diff(subtractedQuarter, 'q') === 0? subtractedQuarter : subtractedDay).endOf('quarter').startOf('day');
};

const sort = R.compose(
  R.sortBy(R.prop('name')),
  R.map(
    R.over(
      R.lensProp('name'),
      Number
    )
  )
);

const changeLabel = label => R.map(
  R.compose(
    R.unless(item => !R.isNil(item.value), R.assoc('value', 0)),
    item => R.zipObj(['name', 'value'], [item.name, item[label]]),
  )
);

const filterByNonValue = R.filter(R.has('value'));

const filterByDate = (inceptionDate, asOfDate) => R.filter(item => item.name >= inceptionDate && item.name <= asOfDate);

const parseJCurve = (label, inceptionDate, asOfDate) => R.compose(
  R.objOf('data'),
  R.when(
    R.compose(R.not, R.propEq('value', 0), R.head),
    values => R.compose(
      value => [value, ...values],
      R.over(R.lensProp('value'), R.always(0)),
      R.over(R.lensProp('name'), quarterUnix => moment.utc(quarterUnix).subtract(1, 'quarter').unix() * 1000),
      R.head,
    )(values)
  ),
  R.map(R.over(R.lensProp('value'), value => value? value * 100 : 0)),
  filterByDate(inceptionDate, asOfDate),
  filterByNonValue,
  changeLabel(label),
  sort
);

const fill21 = items => R.compose(
  ({ range, lastDate, data }) => 
    R.compose(
      value => [...value, ...data],
      R.reverse,
      R.map(
        R.compose(
          R.objOf('name'),
          () => lastDate.subtract(1, 'quarters').unix() * 1000
        )
      )
    )(R.range(0, range)
    ),
  _items => ({ range: 21 - _items.length, lastDate: moment.utc(_items[0].name), data: _items })
)(items);

const parseGrowthOfADollar = (label, inceptionDate, asOfDate) => R.compose(
  R.unless(R.propEq('length', 21), fill21),
  R.takeLast(21),
  filterByDate(inceptionDate, asOfDate),
  filterByNonValue,
  changeLabel(label),
  sort,
  R.defaultTo([])
);

export const jCurveTransform = (inceptionDate, asOfDate, { total: { categories = [''], data = [] } }) => {
  const _inceptionDate  = moment.utc(inceptionDate).unix() * 1000,
    _asOfDate           = moment.utc(asOfDate).unix() * 1000,
    _category           = categories[0];

  return parseJCurve(_category, _inceptionDate, _asOfDate)(data);
};

export const growthOfADollarFundTransform = (fund, asOfDate, { total, chart, benchmarks }) => {
  const { inceptionDate, symbol, sidepocket } = fund;
  
  const _inceptionDate  = getEOQ(inceptionDate).unix() * 1000,
    _asOfDate           = getEOQ(asOfDate).unix() * 1000,
    _fundCategory       = sidepocket? total.categories[0] : symbol,
    _fundData           = (sidepocket? total : chart).data,
    _benchmarksCategory = fund.benchmarks[0],
    _benchmarksData     = benchmarks.data;
    
  return {
    data: parseGrowthOfADollar(_fundCategory, _inceptionDate, _asOfDate)(_fundData),
    benchmarks: parseGrowthOfADollar(_benchmarksCategory, _inceptionDate, _asOfDate)(_benchmarksData)
  }
};

export const growthOfADollarAssetClassTransform = (fund, assetClass, asOfDate, { total, benchmarks }) => {
  const { inceptionDate } = fund;
  
  const _inceptionDate  = getEOQ(inceptionDate).unix() * 1000,
    _asOfDate           = getEOQ(asOfDate).unix() * 1000,
    _fundCategory       = total.categories[0],
    _fundData           = total.data,
    _benchmarksCategory = assetClass.benchmarks[0],
    _benchmarksData     = benchmarks.data;
    
  return {
    data: parseGrowthOfADollar(_fundCategory, _inceptionDate, _asOfDate)(_fundData),
    benchmarks: parseGrowthOfADollar(_benchmarksCategory, _inceptionDate, _asOfDate)(_benchmarksData)
  }
};

export const growthOfADollarClientTransform = (inceptionDate, asOfDate, { total, benchmarks }) => {
  const _inceptionDate  = getEOQ(inceptionDate).unix() * 1000,
    _asOfDate           = getEOQ(asOfDate).unix() * 1000,
    _category           = total.categories[0],
    _data               = total.data,
    _benchmarksCategory = benchmarks.categories[0],
    _benchmarksData     = benchmarks.data;
  
  return {
    data: parseGrowthOfADollar(_category, _inceptionDate, _asOfDate)(_data),
    benchmarks: parseGrowthOfADollar(_benchmarksCategory, _inceptionDate, _asOfDate)(_benchmarksData)
  }
};