import moment               from 'moment';
import fileDownload         from 'js-file-download';
import getApiRootUrl        from '../../helpers/apiHelpers/getApiRootUrl';
import downloadWrapper      from '../../helpers/apiHelpers/downloadWrapper';
import API_URLS             from '../../app/constants/apiUrls';
import requestWrapper       from '../../helpers/apiHelpers/requestWrapper';
import { uploadFile }       from '../uploadFiles/uploadFiles';
import { getErrorMessage }  from '../errors/errorHelper';
import {
  clientDocumentsFetchSuccess
}                           from '../document/documentActions';

const sortByReleaseDateDescAndTitleAsc = (a, b) => {
  const releaseA = moment(a.releaseDate).format('YYYY-MM-DD'),
    releaseB = moment(b.releaseDate).format('YYYY-MM-DD');

  if (releaseA < releaseB) {
    return 1;
  } else if (releaseA > releaseB) {
    return -1;
  } else {
    return a.title < b.title ? 1 : -1;
  }
};

// this is deprecated and needs to be replaced with service that can create a global, client, or sscid document
export const createDocument = async ({ clientId, managerId, title, file, releaseDate }, onSuccess) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_CREATE}`;

    const fileInfo = await uploadFile(file);

    const documentData = {
      title: title || fileInfo.title,
      uuid: fileInfo.uuid,
      type: fileInfo.type,
      url: fileInfo.url,
      status: fileInfo.status
    };

    if (releaseDate) documentData.releaseDate = releaseDate;
    if (clientId) documentData.clientId = clientId;
    if (managerId) documentData.managerId = managerId;

    const response = await requestWrapper.post(url, documentData);

    if (response.data.data.err) {
      throw new Error(response.data.data.err.displayMessage);
    }

    if (onSuccess) onSuccess();
    return response;

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getAllDocuments = async (onSuccess) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_FETCH_ALL}`;
    const response = await requestWrapper.get(url);

    if (response.data.data.err) {
      throw new Error(response.data.data.err.displayMessage);
    }

    if (onSuccess) onSuccess();
    return response.data.data.sort(sortByReleaseDateDescAndTitleAsc);

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getClientDocuments = (clientId, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_CLIENT_FETCH_ALL_BY_CLIENT_ID(clientId)}`;
    const response = await requestWrapper.get(url);

    if (response.data.data.err) {
      throw new Error(response.data.data.err.displayMessage);
    }

    dispatch(clientDocumentsFetchSuccess(response.data.data));
    if (onSuccess) onSuccess();
    return response.data.data.sort(sortByReleaseDateDescAndTitleAsc);

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getDocumentsEarliestDate = async (onSuccess) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_INVESTOR_EARLIEST_DATE}`;
    const response = await requestWrapper.get(url);

    if (response.data.data.err) {
      throw new Error(response.data.data.err.displayMessage);
    }

    // dispatch(clientDocumentsFetchSuccess(response.data.data));
    if (onSuccess) onSuccess();
    return response.data.data;

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};


export const getInsightsDocuments = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_GLOBAL_FETCH_ALL}`;
    const response = await requestWrapper.get(url);
    if (response.data.data.err) {
      throw new Error(response.data.data.err.displayMessage);
    }
    return response.data.data.sort(sortByReleaseDateDescAndTitleAsc);
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getInvestorDocumentsForCard = async () => {
  try {
    const payload = {
      "limit": [4],
      "sort": [{"field": "date", "direction": "DESC"}]
    };
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_INVESTOR_FETCH_DATATABLE}`;
    const response = await requestWrapper.post(url, payload);
    if (response.data.data.err) throw new Error(data.err.displayMessage);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const markDocumentsAsRead = async body => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_MARK_READ}`;
    await requestWrapper.post(url, body);
    return true;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getFileInfo = async uuid => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.INVESTOR_DOCUMENTS_NAME_BY_UUID(uuid)}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const markDocumentsAsUnread = async body => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_MARK_UNREAD}`;
    await requestWrapper.post(url, body);
    return true;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const markDocumentsAsVisible = async body => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_MARK_VISIBLE}`;
    await requestWrapper.post(url, body);
    return true;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const markDocumentsAsNotVisible = async body => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_MARK_NOT_VISIBLE}`;
    await requestWrapper.post(url, body);
    return true;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const downloadPDFsZip = async body => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_GET_ZIP}`;
    downloadWrapper.post(url, body)
      .then(res => {
        fileDownload(res.data, 'investor-documents.zip');
      });
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const deleteDocuments = async params => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.INVESTOR_DOCUMENTS_CREATE}`;
    await requestWrapper.delete(url, { params });
    return true;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const downloadList = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_GET_LIST}?fields[]=account&fields[]=releaseDate&fields[]=title&fields[]=fund&fields[]=category&fields[]=modifyTime&fields[]=status&fields[]=name&download=true`;
    return downloadWrapper.get(url)
      .then(res => {
        return fileDownload(res.data, 'investor-documents.xlsx');
      });
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getDatatableInvestorDocuments = async (payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_INVESTOR_FETCH_DATATABLE}`;
    const data = (await requestWrapper.post(url, payload)).data.data;
    
    if (data.err) throw new Error(data.err.displayMessage);

    return data;

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const getInvestorDocumentsCountByField = async (field, payload) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_INVESTOR_COUNT_FETCH_BY_FIELD(field)}`;
    const data = (await requestWrapper.post(url, payload)).data.data;

    if (data.err) throw new Error(data.err.displayMessage);

    return data;

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const deleteDocument = async (uuid) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_UPDATE_BY_UUID(uuid)}`;
    const response = await requestWrapper.put(url, { status : 0 });

    if (response.data.data.err) {
      throw new Error(response.data.data.err.displayMessage);
    }
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const updateDocument = async (fileInfo, onSuccess) => {
  try {
    let newFileInfo = {};

    if (fileInfo.file) {
      newFileInfo = await uploadFile(fileInfo.file);
    }

    const documentData = {
      title: fileInfo.title,
      type: newFileInfo.type || fileInfo.type,
      url: newFileInfo.url || fileInfo.url,
      status: newFileInfo.status || fileInfo.status
    };

    const url = `${getApiRootUrl()}${API_URLS.DOCUMENT_UPDATE_BY_UUID(fileInfo.uuid)}`;

    if (fileInfo.releaseDate) documentData.releaseDate = fileInfo.releaseDate;

    const response = await requestWrapper.put(url, documentData);

    if (response.data.data.err) {
      throw new Error(response.data.data.err.displayMessage);
    }

    if (onSuccess) onSuccess();
    return response;

  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};
