import { ACTIONS } from '../../app/constants/index';

const INITIAL_STATE = {
  error: '',
  filters: [],
  isLoading: false
};

const exploreReducer = (state = INITIAL_STATE, action = { type: '', filters: [], message: '' }) => {
  switch(action.type) {
    case ACTIONS.EXPLORE_ALL_MANAGERS_CLEAR_ERROR: 
      return {
        ...state,
        error: null
      };
    case ACTIONS.EXPLORE_ALL_MANAGERS_FINISH_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case ACTIONS.EXPLORE_ALL_MANAGERS_GET_SEARCH_FILTERS:
      return{
        ...state,
        filters: action.filters
      }; 
    case ACTIONS.EXPLORE_ALL_MANAGERS_SET_ERROR:
      return {
        ...state,
        error: action.message
      };
    case ACTIONS.EXPLORE_ALL_MANAGERS_START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};

export default exploreReducer;