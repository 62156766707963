import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import connect              from 'react-redux/es/connect/connect';
import MakenaManager        from './MakenaManager';
import MakenaManagerPublic  from './MakenaManagerPublic';
import { isMakenaManagerVisited } from '../../services/makenaManagers/makenaManagerService';

class MakenaManagerContainer extends Component {
  state = {
    isLoading: true,
    data: { assetClass: {}, manager: {} },
    errors: {
      managerFetchError: null
    },
  };

  componentDidMount() {
    this.startLoading();
    isMakenaManagerVisited("AllManagers")
      .then(({ isVisited }) => this.finishLoading(isVisited))
      .catch(() => this.finishLoading(false));
  }

  componentDidUpdate({ manager }) {
    const { manager: { title } } = this.props;
    if (manager.title === title) return;
    this.startLoading();
    isMakenaManagerVisited("AllManagers")
      .then(({ isVisited }) => this.finishLoading(isVisited))
      .catch(() => this.finishLoading(false));
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = hasBeenVisited => {
    this.setState({
      hasBeenVisited,
      isLoading: false,
    });
  };

  render() {
    const { props: { openModal, sideBarOpen, assetClass, manager }, state: { hasBeenVisited, isLoading } } = this;
    
    if (isLoading) return <></>;
    
    const { trackRecordPrivate } = manager;
    return trackRecordPrivate ? (
      <MakenaManager
        openModal={openModal}
        manager={manager}
        assetClass={assetClass}
        sideBarOpen={sideBarOpen}
        hasBeenVisited={hasBeenVisited}
      />
    ) : (
      <MakenaManagerPublic
        openModal={openModal}
        manager={manager}
        assetClass={assetClass}
        sideBarOpen={sideBarOpen}
        hasBeenVisited={hasBeenVisited}
      />
    );
  }
}

MakenaManagerContainer.propTypes = {
  openModal: PropTypes.func,
  assetClass: PropTypes.object,
  manager: PropTypes.object,
  sideBarOpen: PropTypes.bool,
  history: PropTypes.shape({})
};

function mapStateToProps(state) {
  return { sideBarOpen: state.menu.open };
}

export default connect(
  mapStateToProps
)(MakenaManagerContainer);
