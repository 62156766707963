import React      from 'react';
import PropTypes  from 'prop-types';

import Card       from '../card/Card';
import CardBody   from '../card/CardBody';
import CardHeader from '../card/CardHeader';

import MakenaAssetPublicPerformanceTable from './MakenaAssetPublicPerformanceTable';


const MakenaAssetPublicPerformanceCard = ({assetClass, fund, overview, layoutStyle=""}) => {
  const { displayName } = assetClass;
  const cardHeaderContent = `${displayName} Performance (TWR)`;

  return (
    <Card
      layoutStyle={layoutStyle}
      trackEventLabel={cardHeaderContent}
      trackEventCategory='Fund'
    >
      <CardHeader>
        {cardHeaderContent}
      </CardHeader>
      <CardBody>
        <MakenaAssetPublicPerformanceTable
          assetClass={assetClass}
          fund={fund}
          overview={overview}
        />
      </CardBody>
    </Card>
  );
};


MakenaAssetPublicPerformanceCard.propTypes = {
  assetClass  : PropTypes.object,
  fund        : PropTypes.shape({}),
  layoutStyle : PropTypes.string,
  overview    : PropTypes.shape({})
};

MakenaAssetPublicPerformanceCard.defaultProps = {
  layoutStyle: ''
};

export default MakenaAssetPublicPerformanceCard;


