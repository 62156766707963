import {
  getAuthToken,
} from '../auth/authHelper';

import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import API_URLS from '../../app/constants/apiUrls';



const onServerEventError = () => {};

let evtSource;

const getServerStream = () => {
  const loadAuthToken = () => {
    let token = getAuthToken();
    if (!token) {
      setTimeout(() => loadAuthToken, 5000);
    }
    return token;
  };
  
  let authToken = loadAuthToken();

  if (!evtSource) {
    const eventUrl = `${getApiRootUrl()}${API_URLS.AUTH_STATUS}?token=${authToken}`; 
    evtSource = new EventSource(eventUrl);
    evtSource.onerror = onServerEventError;
  }

  return evtSource;
}

export default getServerStream;


