import axios from 'axios';
import { getAuthToken } from '../../services/auth/authHelper';
import getApiRootUrl from './getApiRootUrl';

const config = {
  baseURL: getApiRootUrl(),
  timeout: 300000,
  headers: {
    'Cache-Control': 'no-store, no-cache, must-revalidate',
  },
  method:'POST',
  responseType: 'blob',
};

const downloadWrapper = axios.create(config);

// before the request is sent to the server, add jwt to the Authorization header
downloadWrapper.interceptors.request.use((reqConfig) => {
  const authToken = getAuthToken();

  if (authToken) {
    const updatedReqConfig = Object.assign({}, reqConfig);

    updatedReqConfig.headers.Authorization = `Bearer ${authToken}`;

    return updatedReqConfig;
  }

  return reqConfig;
});

// whenever a response is received from the node layer
downloadWrapper.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export default downloadWrapper;
