import { ACTIONS } from '../../app/constants/index';

export const toggleMenu = () => ({
  type: ACTIONS.MAKENA_MENU_TOGGLE
});

export const selectedFund = fund => ({
  type: ACTIONS.MAKENA_MENU_SELECTED_FUND,
  selectedFund: fund
});

