import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from './headerMenuDropdown.local.less';
import Svg from '../svg/Svg';
import ArrowDown from '../../assets/svg/icon-arrow-white-down.svg';
import ArrowUp from '../../assets/svg/icon-arrow-white-up.svg';
import { auditLog } from '../../services/logging/loggingService';
import { LOG_EVENTS } from '../../app/constants/clientLogs';

class HeaderMenuDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false};
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }
  
  handleOnClick(event) {
    event.preventDefault();
    switch (this.props.name) {
      case 'Funds':
        auditLog(LOG_EVENTS.USER_ACTIONS.FUNDS);
        break;
      case 'Managers':
        auditLog(LOG_EVENTS.USER_ACTIONS.MANAGERS);
        break;
      case 'Investor Documents':
        auditLog(LOG_EVENTS.USER_ACTIONS.INVESTOR_DOCUMENTS);
        break;
      case 'Forms':
        auditLog(LOG_EVENTS.USER_ACTIONS.FORMS);
        break;
      default:
        break;
    }


    this.setState({ open: true}, () => {
      document.addEventListener('click', this.handleOnBlur);
    });
  }
  handleOnBlur() {
    this.setState({ open: false }, () => {
      document.removeEventListener('click', this.handleOnBlur);
    });  
  }

  renderMany = () => {
    const { items, menuItemClass, name, selectedFund } = this.props;
    return (
      <div className={menuItemClass}>
        <button className={styles.headerMenuItemDropdown} onClick={this.handleOnClick}>
          <span className={styles.headerMenuItemDropdownTitle}>
            {name} <Svg svg={this.state.open ? ArrowUp : ArrowDown} className={!items ? styles.hidden : ''}/>
          </span>
        </button>
        {
          this.state.open ? 
            <ul className={`${styles.headerMenuDropdownContent}`}>
              {
                items 
                  ? items.map(
                    (item, index) => { 
                      const expectedItemPath = `/dashboard${selectedFund? `/${selectedFund}` : ``}`;
                      const activeStyle = expectedItemPath === item.path ? styles.active : '';
                      const itemStyle = `${styles.headerMenuDropdownContentItem} ${styles.link} ${activeStyle} ${item.child ? styles.child : ''}`;
                      return (
                        <li key={index} className={itemStyle}>
                          <NavLink to={item.path}>{item.name} </NavLink>
                        </li> 
                      )
                    })
                  : null
              }
            </ul> 
            : null
        }
      </div>
    )
  }

  renderOne = () => {
    const { items, menuItemClass, name } = this.props;
    const item = items[0];
    return (
      <div className={menuItemClass}>
        <button className={styles.headerMenuItemDropdown} onClick={this.handleOnClick}>
          <span className={styles.headerMenuItemDropdownTitle}>
            <NavLink to={item.path}>{name}</NavLink>
          </span>
        </button>
      </div>
    )
  }

  render() {
    const { items } = this.props;
    if (!items || !items.length) return;
    return items.length > 1 ? this.renderMany() : this.renderOne();
  }
}

HeaderMenuDropdown.propTypes = {
  name: PropTypes.string,
  selectedFund: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
  menuItemClass: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    selectedFund: parseInt(state.menu.selectedFund, 10)
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, {}),
)(HeaderMenuDropdown);
