import docStyles        from './documentsPageView.local.less';
import { IsMobile }     from '../../helpers/globalHelpers';

import {
  InvestorDocumentsTransform,
  AssignFiltersToHeaders,
  TransformFundsBySscKey,
  filterFields,
  countFunctions,
  nonDownloadableDocumentsByFundCode
}                       from '../../helpers/datatableHelpers/investorDocuments/datatableCommons';

import {
  getReadColumn,
  getCheckboxReadColumn,
  getEffectiveDateColumn,
  getAccountColumn,
  getTitleColumn,
  getFundColumn,
  getCategoryColumn,
  getPostedColumn,
  getDownloadColumn
}                       from '../../helpers/datatableHelpers/investorDocuments/datatableHeaders';

const columns = [
  getReadColumn,
  getCheckboxReadColumn,
  getEffectiveDateColumn,
  getAccountColumn,
  getTitleColumn,
  getFundColumn,
  getCategoryColumn,
  getPostedColumn,
  getDownloadColumn
];

const headers = {
  headersClasses: `${docStyles.documentsHeader} ${ IsMobile()? docStyles.documentsHeaderMobile : ''}`,
  rowsClasses: `${docStyles.documentsRow} ${ IsMobile()? docStyles.documentsRowMobile : ''}`,
  rowContainerClasses: docStyles.documentsRowContainer,
  columns: columns.map(getColumn => getColumn(docStyles))
};

const GetClasses = isImpersonated => {
  let classes = {
    ivDocsPage: docStyles.ivDocsPage,
    ivDocsContainer: docStyles.ivDocsContainer,
    ivDocsTitle: docStyles.ivDocsTitle,
    ivSearchBox: docStyles.ivSearchBox,
    ivDocsFilters: docStyles.ivDocsFilters,
    ivDocsActions: docStyles.ivDocsActions,
    ivDocsActionsSeparator: docStyles.ivDocsActionsSeparator,
    ivDocsDatatableContainer: docStyles.ivDocsDatatableContainer,
    ivDocsActionsHorizontalBottomSeparator: docStyles.ivDocsActionsHorizontalBottomSeparator,
    ivDocsActionsHorizontalTopSeparator: docStyles.ivDocsActionsHorizontalTopSeparator,
  }
  if (isImpersonated) classes.ivDocsPage += ` ${docStyles.ivDocsPageImpersonated}`;
  return classes;
}

export const Helper = {
  InvestorDocumentsTransform: InvestorDocumentsTransform(docStyles, 'Public'),
  AssignFiltersToHeaders,
  TransformFundsBySscKey,
  nonDownloadableDocumentsByFundCode,
  headers,
  filterFields: filterFields.Public,
  countFunctions: countFunctions.Public,
  GetClasses
}
