import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import styles               from './managerDisclaimer.local.less';
import CloseIcon            from '../../assets/svg/icon-black-close.svg';
import Svg                  from '../svg/Svg';

class ManagerDisclaimerPopUp extends Component {
  
  OnClick = () => {
    const { onDismiss = () => {} } = this.props;
    onDismiss();
  }

  render() {   
    const { props: { position }, OnClick } = this;
    let positionStyle = '';
    if (position === 'right') { 
      positionStyle = styles[position]; 
    } else {
      positionStyle = styles['center'];
    }
    
    return (
      <div className={styles.managerDisclaimerPopUp} onClick={event => event.stopPropagation()}>
        <div className={styles.managerDisclaimerPopUpContainerPointer}></div>
        <div className={`cardMain cardMain--fit mt-16px ${styles.managerDisclaimerPopUpContainer} ${positionStyle || ''}`}>
          <div className={styles.managerDisclaimerPopUpContainerClose} onClick={OnClick}><Svg svg={CloseIcon}/></div>
          <div className={styles.managerDisclaimerPopUpContainerTitle}><strong>Manager Disclaimer: </strong></div>
          <div className={styles.managerDisclaimerPopUpContainerContent}>Managers presented are for illustrative purposes only and are meant to represent the strategies and objectives of the Makena Funds invested in them. These managers are subject to change and are not indicative of the remainder of the managers in the portfolio.</div>
        </div>
      </div>
    );
  }
}

ManagerDisclaimerPopUp.propTypes = { onDismiss: PropTypes.func, position: PropTypes.string }

export default ManagerDisclaimerPopUp;
