import isNumber from  'lodash/isNumber';
import isString from  'lodash/isString';

export const floatFixer = (value = 0, pow = 0) => parseFloat(value).toFixed(pow);

const round = (value = 0, pow = 0) => Math.round(value * Math.pow(10, pow)) / Math.pow(10, pow);

export const formatTextToAbbr = (title) => {
  if (!title) return null;

  if (title.length < 12) return title;

  const words = title.split(',');
  const abbr = words[0].match(/[A-Z]/g).join('');

  return words.length > 1 ? abbr.concat(` ${words[1]}`) : abbr;
};

export const splitAndCapitalizeString = text => (text
  ? text.split(' ').map(t => t && t.charAt(0).toUpperCase() + t.slice(1)) : null);

export const defaultCurrencyFormat = '$0,0';
export const defaultCurrencyFormatMln = '$0.000 a';

export const decimalToFloat = v => (Math.abs(v) < 1 ? ((v * 100).toFixed(1)) : v);

export const padDate = ({
  date,
  pad
}) => {
  const [ month, year ] = date.split('/');
  
  return `${pad(month, 2, 0)}/${year}`;
};

export const percentageFormatter = (formatter, value) => {
  const numberValue = isString(value) ? parseFloat(value) : value;
  
  return isNumber(numberValue)
    ? `${formatter.format(numberValue * 100)}%`
    : ' - ';
};

export const format = (settings, value) => `${new Intl.NumberFormat('en-US', settings).format(value)}`;

export const rounder = (settings, value, pow) => {
  const roundedValue = round(value, pow); 
  const fixedRoundedValue = floatFixer(roundedValue, pow);
  return format(settings, fixedRoundedValue);
}