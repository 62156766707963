import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import styles from './taxElectionFormContainer.local.less';
import Close from '../../assets/svg/close-black.svg'
import Svg from '../../components/svg/Svg';

class TaxElectionFormModal extends Component {

  state = { disabled: null }

  Close = () => {
    const { onClose = () => {} } = this.props;
    onClose(false);
  }

  Confirm = () => {
    this.setState({ disabled: true });
    const { onClose = () => {} } = this.props;
    onClose(true);
  }

  render() {
    return <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderTitle}>
            Ready to Submit?
          </div>
          <div className={styles.modalHeaderClose} onClick={!this.state.disabled? this.Close : () => {}}>
            <Svg svg={Close}/>
          </div>
        </div>
        <div className={styles.modalDivider}></div>
        <div className={styles.modalBody}>
          <div className={styles.modalContent}>
            <p className="mb-24px">Are you sure you want to submit this form? Please note you will not be able to edit further after submitting. If you are ready, please click the “Yes, Submit” button below. If you need to make any changes after submitting your form, please reach out to <a mailto="clientoperations@makenacap.com">clientoperations@makenacap.com</a>.</p>
            <p className="mb-24px"><strong>It will take a few moments for our system to process your selections.</strong> Once your form has been processed, you will be taken to the previous page displaying your other State Composite Elections.</p>
          </div>
          <div className={styles.modalActions}>
            <Button className="mr-24px" onClick={this.Confirm} disabled={this.state.disabled}>Yes, Submit</Button>
            <Button basic color="blue" onClick={this.Close} disabled={this.state.disabled}>No, Continue Editing</Button>
          </div>
        </div>
      </div>
    </div>;
  }
}

TaxElectionFormModal.propTypes = {
  onClose : PropTypes.func
};

export default TaxElectionFormModal;