import React from 'react';
import PropTypes from 'prop-types';
import styles from './svg.local.less';

const Svg = ({ svg, className, isDisabled }) => {
  let cssClass = '';
  if (isDisabled) cssClass = cssClass.concat(' ', styles.isDisabled);
  if (className) cssClass = cssClass.concat(' ', className);

  return (
    <div className={cssClass}>
      <img src={svg} alt="" />
    </div>
  );
};

Svg.propTypes = {
  svg: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};


export default Svg;
