import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import Card from '../../components/card/Card';
import CardBody from '../../components/card/CardBody';
import CardHeader from '../../components/card/CardHeader';
import Svg from '../../components/svg/Svg';

import LeftIcon from '../../assets/svg/icon-chevron-left-blue.svg';
import styles from './infoContentPage.local.less';

class InfoContentPage extends Component {
  static propTypes = {
    content: PropTypes.object,
    history: PropTypes.object,
    sections: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
  }

  static contextTypes = {
    router: PropTypes.object
  }

  static defaultProps = {
    content: {},
    sections: [],
    title: ''
  }

  state = {
    selectedSection: _.first(this.props.sections)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  infoContentContainerElem = createRef()
  infoListContainerElem = createRef()
  listItemsElem = createRef()

  onClick = (event, selectedSection) => {
    event.stopPropagation();
    event.preventDefault();

    const element = document.getElementById(selectedSection);

    element.scrollIntoView(true);
    this.setState({ selectedSection });
  }

  onScroll = _.throttle(() => {
    const gutter = 14;
    const offset = 224;
    const { current: infoContentContainer } = this.infoContentContainerElem;
    const { current: infoListContainer } = this.infoListContainerElem;
    const { current: listItems } = this.listItemsElem;
    const { sections } = this.props;
    const containerBounds = document.body.getBoundingClientRect();
    const infoContentContainerBounds = infoContentContainer.getBoundingClientRect();
    const listItemsBounds = listItems.getBoundingClientRect();

    let [selectedSection] = sections;
    if((containerBounds.top + gutter + offset) < -1) {
      infoListContainer.setAttribute('style', `position: fixed; max-width: 277px; padding-right: 27px; top: 0`);

      if(-(infoContentContainerBounds.top - listItemsBounds.height - listItems.getBoundingClientRect().top - gutter) >= infoContentContainerBounds.height) {
        infoListContainer.setAttribute('style', 'position: absolute; max-width: 277px; padding-right: 27px; bottom: -4px');
      }

      const currentSection = sections.find(section => {
        const elementBounds = document.getElementById(`${section}_container`).getBoundingClientRect();

        if(elementBounds.top > 0) {
          return section;
        }
      });

      selectedSection = currentSection;

    } else {
      infoListContainer.setAttribute('style', 'position: static');
    }

    this.setState({ selectedSection });
  }, 50)

  goBack = () => {
    const { props: { history } } = this;
    history.goBack();
  }

  render() {
    const {
      content,
      sections,
      title
    } = this.props;

    const {
      selectedSection
    } = this.state;

    return (
      <div className='dashboardTopPadding'>
        <div
          className={styles.backIcon}
          onClick={this.goBack}
        >
          <Svg svg={LeftIcon} />
          <span className={styles.backIconText}>
            Back
          </span>
        </div>
        <Card
          layoutStyle='contentRowFull cardMain'
          trackEventLabel={title}
        >
          <CardHeader>{title}</CardHeader>
          <CardBody>
            <div className={styles.infoContainer}>
              <div
                className={styles.infoListContainer}
              >
                <div ref={this.infoListContainerElem}>
                  <ul
                    className={styles.infoList}
                    id='listItems'
                    ref={this.listItemsElem}
                  >
                    {
                      sections.map((section, index) => (
                        <li
                          key={index}
                          className={styles.infoListItem}
                          id={`listItem_${section}`}
                        >
                          <a
                            onClick={event => this.onClick(event, section) }
                            className={cx({ [styles.bold]: section === selectedSection })}
                          >
                            {section}
                          </a>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div
                className={styles.infoContentContainer}
                id='infoContainer'
                ref={this.infoContentContainerElem}
              >
                {
                  Object.keys(content)
                    .map((section, index) => {
                      const paragraphs = content[section];

                      return (
                        <div
                          key={index} id={`${section}_container`}
                          className={styles.infoContent}
                        >
                          <div
                            id={section}
                            className={styles.infoContentTitleAnchor}
                          />
                          <div className={styles.infoContentTitle}>
                            <a>{section}</a>
                          </div>
                          {
                            paragraphs.map((paragraph, idx) => (
                              <p
                                key={idx}
                                className={styles.infoContentParagraph}
                              >
                                {paragraph}
                              </p>
                            ))
                          }
                        </div>
                      )
                    })
                }
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default InfoContentPage;
