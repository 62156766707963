import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import styles               from './datatableHeaderColumnCheckbox.local.less';

class DatatableHeaderColumnHTML extends Component {
  render() { 
    const { column : { headerHtml = () => null }} = this.props;
    return (<div className={styles.datatableHeaderColumnCustom}>{headerHtml()}</div>); 
  }
}

DatatableHeaderColumnHTML.propTypes = {
  column: PropTypes.object
}

export default DatatableHeaderColumnHTML;