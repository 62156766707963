import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import cx                   from 'classnames';
import NavItem              from '../navItem/NavItem';
import SideBarAsset         from './SideBarAsset';
import SideBarManager       from './SideBarManager';
import styles               from './sidebar.local.less';

const HasChildren = (fund, perpetualAssetClass) => {
  const { assetClasses = {}, type } = fund || {};
  switch(type.toUpperCase()) {
    case 'PERPETUAL': {
      const managers = assetClasses[perpetualAssetClass.title]? assetClasses[perpetualAssetClass.title].managers : [];
      return managers.length > 0;
    }
    case 'OPEN': return Object.keys(assetClasses).length > 0;
    default: return false;
  }
}

class SideBarNode extends Component { 

  state = { isOpen: this.props.activeLevels.activeProductId === this.props.fund.entityId };

  componentDidUpdate(prevProps) {
    const { activeLevels } = prevProps;
    const { activeLevels: { activeProductId }, fund: { entityId } = { } } = this.props;
    if (activeLevels.activeProductId === activeProductId) return;
    this.setState({ isOpen: this.props.activeLevels.activeProductId === entityId });
  }

  onToggle = isOpen => this.setState({ isOpen })

  SortAssetClasses = (assetClassNames = []) => {
    const { assetClasses = [] } = this.props;
    const assetClassDisplayNames = assetClassNames.map(_title => {
      return assetClasses.find(({ title }) => title === _title) || { displayName: _title, title: _title };
    }).sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);
    return assetClassDisplayNames;
  }

  render() {
    const {
      state: { isOpen },
      props: {
        activeLevels,
        managers,
        assetClasses,
        fund
      },
      onToggle
    } = this;
    const {
      displayName,
      entityId,
      type
    } = fund;
    const { activeProductId } = activeLevels;
    const isActive = activeProductId ===  entityId;
    const resetNavItem = !isOpen && !isActive;
    const perpetualAssetClass = type === 'PERPETUAL'? assetClasses.find(({ id }) => fund.perpetualAssetClassId === id ): null;
    return (
      <div 
        className={cx(
          styles.product,
          { [styles.isProductGroupActive]: isOpen }
        )}
      >
        <NavItem
          key={entityId}
          reset={resetNavItem}
          isActive={isActive}
          linkName={displayName}
          showArrow={HasChildren(fund, perpetualAssetClass)}
          keepPadding={true}
          onToggle={onToggle}
          linkTo={`/dashboard/${entityId}`}
        />
        {
          isOpen? (
            perpetualAssetClass? (
              fund.assetClasses[perpetualAssetClass.title].managers.map(manager => {
                return (<SideBarManager
                  key={`NAV_${entityId}_${perpetualAssetClass.title}_${manager}`}
                  fund={fund}
                  assetClass={perpetualAssetClass}
                  manager={manager}
                  managers={managers}
                  activeLevels={activeLevels}
                />)
              })
            ) : (
              this.SortAssetClasses(Object.keys(fund.assetClasses)).map(assetClass => {
                return (<SideBarAsset
                  key={`NAV_${entityId}_${assetClass.title}`}
                  fund={fund}
                  activeLevels={activeLevels}
                  assetClass={assetClass.title}
                  assetClasses={assetClasses}
                  managers={managers}
                />)
              })
            )
          ) : null
        }
      </div>
    );
  }
}

SideBarNode.propTypes = {
  activeLevels: PropTypes.object,
  managers: PropTypes.array,
  assetClasses: PropTypes.array,
  fund: PropTypes.object
};

SideBarNode.defaultProps = {
  activeLevels: {},
  managers: [],
  assetClasses: [],
  fund: {}
};

export default SideBarNode;
