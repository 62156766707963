import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './cardBody.local.less';

const CardBody = ({ 
  children, 
  layoutStyle,
  type
}) => (
  <div className={cx(styles.cardBody, styles[type], layoutStyle)}>
    {children}
  </div>
);

CardBody.propTypes = {
  type: PropTypes.string,
  layoutStyle: PropTypes.string
};

CardBody.defaultProps = {
  type: '',
  layoutStyle: ''
};

export default CardBody;