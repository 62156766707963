import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import connect                    from 'react-redux/es/connect/connect';
import ExploreManager             from './ExploreManager';
import { isMakenaManagerVisited } from '../../services/makenaManagers/makenaManagerService';

class ExploreManagerContainer extends Component {
  state = { isLoading: true };

  componentDidMount() {
    this.startLoading();
    isMakenaManagerVisited("AllManagers")
      .then(({ isVisited }) => this.finishLoading(isVisited))
      .catch(() => this.finishLoading(false));
  }

  startLoading = () => {
    this.setState({ isLoading: true });
  };

  finishLoading = hasBeenVisited => {
    this.setState({
      hasBeenVisited,
      isLoading: false,
    });
  };

  render() {
    const { props: { manager, assetClass, sideBarOpen }, state: { isLoading, hasBeenVisited } } = this;
    if (isLoading) return <></>;
    return <ExploreManager hasBeenVisited={hasBeenVisited} manager={manager} assetClass={assetClass} sideBarOpen={sideBarOpen}/>
  }
}

ExploreManagerContainer.propTypes = {
  assetClass: PropTypes.object,
  manager: PropTypes.object,
  sideBarOpen: PropTypes.bool
};

function mapStateToProps(state) {
  return { sideBarOpen: state.menu.open };
}

export default connect(
  mapStateToProps
)(ExploreManagerContainer);
