
import { calculateClientGrowth } from '../../helpers/chartHelpers/chartHelpers';
import moment from 'moment';

const generateDateRange = (from, to) => {
  const range = [];
  const fromNumber = Number(from);
  const toNumber = Number(to);
  for (let i = fromNumber; i <= toNumber; i++) {
    let dateStr = `${i}-02-03`; //account for date handling in every browser but chrome
    range.push(moment.utc(dateStr).startOf('year'));
  }
  return range;
};

const calculateUpperBound = x => x % 0.25 !== 0 ? (parseInt((x/0.25).toString(), 10) + 1) * 0.25 : x + 0.25;
const calculateLowerBound = x => {
  let result = parseInt(x / 0.25, 10) * 0.25;
  if (x % 0.25 === 0) return result;
  if (x >= 0) return result;
  return result - 0.25;
}
const calculateTicks = data => {
  let unnamedRows = _.map(data, row => _.omit(row, ['name']));
  unnamedRows = _.uniq(_.flatten(_.map(unnamedRows, row => _.values(row))));
  const max = calculateUpperBound(_.max(unnamedRows));
  const min = calculateLowerBound(_.min(unnamedRows));
  let ticks = [min];
  let acum = min;
  while (acum < max) {
    ticks.push(acum += 0.25);
  }
  ticks.push(max);
  return { ticks, domain: [min, max] };
}

export const totalTitle = 'Your Portfolio';
export const benchmarkTitle = 'defaultBenchmark';
export const areaCategories = [totalTitle, benchmarkTitle];
export const areaStyles = {
  'Your Portfolio': {
    color: '#194069',
    strokeDasharray: '0'
  },
  'defaultBenchmark': {
    color: '#8fa9cc',
    strokeDasharray: '5'
  }
}

const mergeData = (data, benchmark) => ({
  name            : data.name,
  [totalTitle]    : data.value,
  [benchmarkTitle]: benchmark.value
});

export const areaChartSettings = ({ data, benchmarks }, ignoreBenchmarks=false) => {
  const _dates  = data.filter(item => !item.hasOwnProperty('value')); 
  const _data   = calculateClientGrowth(data.filter(item => item.hasOwnProperty('value')));
  const _bData = calculateClientGrowth(_.takeRight(benchmarks, _data.length || 21));
  let chartData = _.map(_data, (item, index) => mergeData(item, _bData[index]));

  if (ignoreBenchmarks) chartData = _.map(chartData, obj => _.omit(obj, 'defaultBenchmark'));
  chartData = [..._dates, ...chartData];
  const startDate = moment.utc(chartData[0].name).format('YYYY');
  const endDate = moment.utc(chartData[chartData.length - 1].name).format('YYYY');
  const xAxisParams = {
    dateRange: generateDateRange(startDate, endDate),
    domain: [startDate, endDate]
  };
  const yAxisParams = calculateTicks(chartData);
  
  return {
    chartData,
    xAxisParams,
    yAxisParams
  };
}

