import { ACTIONS } from '../../app/constants/index';


export default function (state = {}, action = { type: '', errorMessage: {} }) {
  const { type, errorMessage } = action;

  if (errorMessage && type !== ACTIONS.ERROR_DELETE) {
    return [...state, errorMessage];
  }

  switch (type) {
    case ACTIONS.ERRORS_CLEAR:
      return [];
    case ACTIONS.ERROR_DELETE:
      return state.filter(err => err !== errorMessage);
    default:
      return state;
  }
}
