import React, { Component } from 'react';
import PropTypes                from 'prop-types';
import { ROUTES }               from '../../app/constants/index';
import UserDashboardContainer   from '../userDashboard/UserDashboardContainer';
import ExploreContainer         from '../explore/ExploreContainer';
import Settings                 from '../../components/settings/Settings';
import MakenaFundContainer      from '../makenaFund/MakenaFundContainer';
import MakenaAssetContainer     from '../makenaAsset/MakenaAssetContainer';
import MakenaManagerContainer   from '../makenaManager/MakenaManagerContainer';
import InfoContentPage          from '../infoContentPage/InfoContentPage';

import {
  accountLog
}                               from '../../services/account/accountService';

import { 
  TAC_CONTENT, 
  TAC_SECTIONS, 
  TAC_TITLE 
}                               from '../../app/constants/terms-and-conditions';
import { 
  PRIVACY_TITLE, 
  PRIVACY_SECTIONS, 
  PRIVACY_CONTENT 
}                               from '../../app/constants/privacy';
import InsightsPage             from '../insightsPage/InsightsPage';
import ExploreManagerContainer  from '../exploreManager/ExploreManagerContainer';
import TaxElectionFormContainer from '../taxElectionForm/taxElectionFormContainer';

class ActiveScreen extends Component {

  state = { changedLog: true };

  componentDidUpdate(prevProps, prevState) {
    const { match: { url } } = this.props;
    const changedLog = prevProps.match.url !== url;
    if (prevState.changedLog === this.state.changedLog) return;
    return this.setState(() => ({ changedLog }));
  }

  renderExplorePage = () => {
    const { 
      recentInvestments, 
      hierarchy, 
      makenaClient: { entityId }, 
      funds, 
      allFunds,
      history
    } = this.props;

    if (this.state.changedLog) accountLog(`Explore`);
    return (
      <ExploreContainer
        recentInvestments={recentInvestments}
        entityId={entityId}
        funds={funds}
        allFunds={allFunds}
        history={history}
        hierarchy={hierarchy}
      />
    );
  }

  renderExploreManagerPage = () => {
    const { match: { params: { managerTitle = '' } = { } } = {}, managers, assetClasses  } = this.props;
    const manager = managers.find(({ title }) => title === managerTitle);
    const assetClass = assetClasses.find(({ id }) => id === manager.assetClassId);
    if (this.state.changedLog) accountLog(`Explore | Manager: ${manager? manager.title : 'Undefined'}`);
    return (<ExploreManagerContainer manager={manager} assetClass={assetClass}/>);
  }

  renderExplorePath = () => {
    const { match: { params: { managerTitle = '' } = { } } = {}  } = this.props;

    if (managerTitle) return this.renderExploreManagerPage();
    return this.renderExplorePage();
  }

  renderManagerContainer = () => {
    const { fund = {}, assetClass = {}, manager = {}, history = {} } = this.props;
    if (this.state.changedLog) accountLog(`Fund ${fund.displayName} | Asset Class: ${assetClass.displayName} | Manager: ${manager.title}`);
    return <MakenaManagerContainer assetClass={assetClass} fund={fund} manager={manager} history={history} />
  }

  renderAssetClassContainer = () => {
    const { fund = {}, assetClass = {}, history = {} } = this.props;
    if (this.state.changedLog) accountLog(`Fund ${fund.displayName} | Asset Class: ${assetClass.displayName}`);
    return <MakenaAssetContainer assetClass={assetClass} fund={fund} history={history} />
  }

  renderFundContainer = () => {
    const { fund = {}, history = {}, overview = {} } = this.props;
    if (this.state.changedLog) accountLog(`Fund ${fund.displayName}`);
    return <MakenaFundContainer fund={fund} history={history} overview={overview} />
  }

  renderDashboardPath = () => {
    const {
      fund = { },
      match: { params: { productId = '', assetClassName = '', managerTitle = '' } = { } } = {}
    } = this.props;
    const { entityId = ''} = fund || {};
    if (!productId) {
      if (this.state.changedLog) accountLog('Dashboard');
      return <UserDashboardContainer {...this.props} />;
    }
    if (entityId.toString() !== productId) return this.noMatch();
    if (!assetClassName) {
      return this.renderFundContainer();
    }
    if (!managerTitle) {
      return this.renderAssetClassContainer();
    }
    return this.renderManagerContainer();
  }

  renderSettings = () => {
    const { history, investorDocuments } = this.props;
    if (this.state.changedLog) accountLog('Settings');
    return <Settings investorDocuments={investorDocuments} history={history}/>
  }

  renderTaxElectionForms = () => {
    if (this.state.changedLog) accountLog('Tax Election Forms');
    return <TaxElectionFormContainer {...this.props}/>
  }

  renderTandC = () => {
    if (this.state.changedLog) accountLog(`Terms and Conditions`);
    return <InfoContentPage
      sections={TAC_SECTIONS}
      content={TAC_CONTENT}
      title={TAC_TITLE}
      {...this.props}
    />
  }
  renderPrivacy = () => {
    if (this.state.changedLog) accountLog(`Privacy`);
    return <InfoContentPage
      sections={PRIVACY_SECTIONS}
      content={PRIVACY_CONTENT}
      title={PRIVACY_TITLE}
      {...this.props}
    />
  }
  renderInsights = () => {
    if (this.state.changedLog) accountLog(`Insights`);
    return <InsightsPage
      sections={PRIVACY_SECTIONS}
      content={PRIVACY_CONTENT}
      title={PRIVACY_TITLE}
      {...this.props}
    />
  }

  noMatch = () => this.props.history.push('/nomatch');

  render (){
    const { match: { path } = {} } = this.props;
    switch(path) {
      case ROUTES.EXPLORE.routes: return this.renderExplorePath();
      case ROUTES.TAX_ELECTION_FORMS.routes: return this.renderTaxElectionForms();
      case ROUTES.SETTINGS.path: return this.renderSettings();
      case ROUTES.TERMS_AND_CONDITIONS.path: return this.renderTandC();
      case ROUTES.PRIVACY_POLICY.path: return this.renderPrivacy();
      case ROUTES.INSIGHTS.path: return this.renderInsights();
      default: return this.renderDashboardPath();
    }
  }
}

ActiveScreen.propTypes = {
  makenaClient: PropTypes.object,
  makenaFunds: PropTypes.object,
  makenaProducts: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
      assetKey: PropTypes.string,
    }),
    url: PropTypes.string
  }),
  openModal: PropTypes.func,
  recentInvestments: PropTypes.array,
  hierarchy: PropTypes.object,
  funds: PropTypes.array,
  fund: PropTypes.object,
  history: PropTypes.object,
  investorDocuments: PropTypes.array,
  allFunds: PropTypes.array,
  managers: PropTypes.array,
  manager: PropTypes.object,
  assetClass: PropTypes.object,
  assetClasses: PropTypes.object,
  overview: PropTypes.any
};

export default ActiveScreen;