import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import moment               from 'moment';
import SideBarNode          from './SideBarNode';
import SideBarTopInfo       from './SideBarTopInfo';
import styles               from './sidebar.local.less';

class SideBar extends Component {

  getActiveLevels = () => {
    const {
      activeAssetClassName,
      activeManagerTitle,
      activeProductId
    } =  this.props;
    return { activeAssetClassName, activeManagerTitle, activeProductId };
  }

  getTotal = () => {
    const { overview: { total: { values } } } = this.props;
    return values['Adjusted Value (USD)'];
  }

  formatDate = () => {
    const { settings: { reportingQuarterEnd } } = this.props;
    return reportingQuarterEnd && moment.utc(reportingQuarterEnd).format('MM/DD/YYYY');
  }

  render() {
    const {
      getTotal,
      formatDate,
      getActiveLevels,
      props: {
        managers,
        assetClasses,
        funds,
        sidebarIsAbsolute
      }
    } = this;
    const activeLevels = getActiveLevels();
    return (
      <div 
        className={`sidebar unprintable ${sidebarIsAbsolute ? 'absoluteSidebar' : '' }`}
      >
        <SideBarTopInfo
          reportingQuarterEnd={formatDate()}
          total={getTotal()}
        />

        <div className={styles.nav}>
          {
            funds.map((fund, i) => (
              <SideBarNode
                key={`product-${i}`}
                activeLevels={activeLevels}
                managers={managers}
                assetClasses={assetClasses}
                fund={fund}
                setActiveLevel={this.setActiveLevel}
              />
            ))
          }
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  activeAssetClassName: PropTypes.string,
  activeManagerTitle: PropTypes.string,
  activeProductId: PropTypes.number,
  sidebarIsAbsolute: PropTypes.any,
  overview: PropTypes.any,
  settings: PropTypes.any,
  managers: PropTypes.array,
  funds: PropTypes.array,
  assetClasses: PropTypes.array,
};

export default SideBar;