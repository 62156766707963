import _ from 'lodash';

const uniqField = 'MCM Manager Name';

export const areTheSame = (a, b) => a[uniqField] === b[uniqField];

export const fabricReportingUrl = apiUrls => 
  ({ portfolioId, ...props }) => {
    const hasPortfolioId = !_.isNil(portfolioId);

    return apiUrls[`REPORTING_${hasPortfolioId ? 'SP_': ''}FETCH`]({
      ...props,
      ...(hasPortfolioId && { portfolioId })
    });
  };