import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, SubmissionError } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  login as loginAction,
  generateCode,
  verifyCode,
} from "../../../services/auth/authService";
import { getUserProfile } from "../../../services/auth/authHelper";
import { updateApplicationSettings as updateApplicationSettingsAction } from "../../../services/application/applicationSettingsService";

import AuthCanvas from "../../../components/auth/AuthCanvas";
import LoginFormV2 from "../loginForm/LoginFormV2";
import TOTPForm from "../loginForm/TOTPForm";
import { ACCESS_LEVEL, ROUTES } from "../../../app/constants";

class LoginPageV2 extends Component {
  state = {
    data: {},
    isTotp: false,
  };

  handleLoginSuccess = () => {
    const userProfile = getUserProfile();

    const userSettings = userProfile.settings;
    const newState = {
      isActiveUser: true,
      isSuccessfulLogin: true,
      isFirstLogin: !userSettings.TOSSignatureDate,
    };

    this.setState(() => newState);
    const { clients = [] } = userProfile;
    const { history } = this.props;

    if (clients.length && userProfile.level == ACCESS_LEVEL.FULL) {
      history.push(ROUTES.DASHBOARD.path);
    } else {
      console.log(2, ROUTES.INVESTOR_DOCUMENTS.path);
      history.push(ROUTES.INVESTOR_DOCUMENTS.path);
    }
  };

  handleLogin = async ({ email, password }) => {
    try {
      const { ok, mfaEnabled } = await generateCode({ email, password });
      if (!mfaEnabled) {
        const { login } = this.props;
        await login({ email, password }, () => {
          this.handleLoginSuccess();
        });
        return;
      }
      this.setState({ data: { email, password }, isTotp: ok });
    } catch (error) {
      throw new SubmissionError({ _error: error.message });
    }
  };

  handleMFA = async ({ code }) => {
    try {
      const { email, password } = this.state.data || {};
      const { ok } = await verifyCode({ code, email });
      if (!ok) {
        throw new Error("Invalid Code");
      }
      const { login } = this.props;
      await login({ email, password }, () => {
        this.handleLoginSuccess();
      });
    } catch (error) {
      console.log(error);
      throw new SubmissionError({ _error: error.message });
    }
  };

  render() {
    const { history } = this.props;

    return (
      <AuthCanvas>
        {this.state.isTotp ? (
          <TOTPForm handleLogin={this.handleMFA} history={history} />
        ) : (
          <LoginFormV2 handleLogin={this.handleLogin} history={history} />
        )}
      </AuthCanvas>
    );
  }
}

LoginPageV2.propTypes = {
  match: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  login: PropTypes.func,
};

export default compose(
  connect(null, {
    login: loginAction,
    updateApplicationSettings: updateApplicationSettingsAction,
  }),
  reduxForm({ form: "loginPageV2" })
)(LoginPageV2);
