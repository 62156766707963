import React            from 'react';
import { titleCase }    from 'title-case';
import moment           from 'moment';
import _                from 'lodash';
import Svg              from '../../../components/svg/Svg';
import DownloadLink     from '../../../components/downloadDocumentLink/DownloadDocumentLink';
import downloadButton   from '../../../assets/svg/download-button.svg';
import getApiRootUrl    from '../../apiHelpers/getApiRootUrl';
import API_URLS         from '../../../app/constants/apiUrls';
import { IsMobile }     from '../../globalHelpers';

const apiUrl = getApiRootUrl();


const RenderReadMark = (styles, radius) => () => (<svg className={styles} viewBox={`0 0 ${radius*2} ${radius*2}`}>
  <circle cx={radius} cy={radius} r={radius} fill="#077aff" strokeWidth="0"></circle>
</svg>);


const RenderDownload = (styles, value) => {
  const documentUrl = API_URLS.DOCUMENT_DOWNLOAD(document.title);
  if (IsMobile()) return <></>;
  return (
    <div className={styles.downloadButton}>
      <DownloadLink
        href={`${apiUrl}${documentUrl}?attachment=true&uuid=${value}`}
        trackEventLabel={document.title}
      >
        <Svg svg={downloadButton}/>
      </DownloadLink>
    </div>
  );
}

export const getCheckboxColumn =  styles =>  ({
  type: 'checkbox',
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`,
  show: value => {
    return !['mcm ip lp', 'mcm llc', 'mcmllc'].some(fundCode => fundCode.toUpperCase() === value.fundCode.toUpperCase())
  }
});

export const getForcedCheckboxColumn =  styles =>  ({
  type: 'checkbox',
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getEffectiveDateColumn = styles => ({
  type: 'string',
  title: 'Effective Date',
  format: date => moment.utc(date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
  selector: 'releaseDate',
  filterField: 'date',
  isFilterable: true,
  customSortFilter: values => _.orderBy(values, ['name'], ['desc']),
  isSortable: true,
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getAccountColumn = styles => ({
  type: 'string',
  title: 'Account',
  selector: 'legalName',
  isSortable: true,
  isFilterable: true,
  filterField: 'account',
  format: name => name ? name.toString() : '',
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getTitleColumn = styles => ({
  type: 'string',
  title: 'Document Name',
  selector: 'title',
  isSortable: true,
  format: name => titleCase(name),
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getFundColumn = styles => ({
  type: 'string',
  title: 'Fund',
  selector: 'fundCode',
  isSortable: true,
  isFilterable: true,
  filterField: 'fund',
  format: name => name.toUpperCase(),
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getCategoryColumn = styles => ({
  type: 'string',
  title: 'Category',
  selector: 'category',
  isSortable: true,
  isFilterable: true,
  filterField: 'category',
  format: name => titleCase(name),
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getPostedTimeColumn = styles => ({
  type: 'string',
  title: 'Posted',
  selector: 'modifyTime',
  isSortable: true,
  filterField: 'modifyTime',
  format: value => moment(value).format('MM/DD/YYYY HH:mm:ss'),
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getPostedColumn = styles => ({
  type: 'string',
  title: 'Posted',
  selector: 'modifyTime',
  isSortable: true,
  filterField: 'modifyTime',
  format: value => moment(value).format('MM/DD/YYYY'),
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getVisibleColumn = styles => ({
  type: 'string',
  title: 'Visible',
  selector: 'visible',
  isSortable: true,
  isFilterable: true,
  filterField: 'visible',
  format: name => name ? 'Yes' : 'No',
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});

export const getReadColumn = styles => ({
  type: 'render',
  selector: 'read',
  headerHtml: RenderReadMark(styles.svgCircleHeader, 4),
  isFilterable: true,
  filterField: 'read',
  format: value => value === 'unread'? RenderReadMark(styles.svgCircleRow, 3)() : null,
  headerColumnClass: `${styles.documentHeaderCustomColumn}`,
  columnClass: styles.documentReadCell
});

export const getCheckboxReadColumn = styles => ({
  type: 'checkbox',
  selector: 'isUnread',
  columnClass: `${styles.documentCell}`
});

export const getDownloadColumn = styles => ({
  type: 'render',
  title: '',
  selector: 'uuid',
  format: value => RenderDownload(styles, value),
  headerColumnClass: `${styles.documentHeaderColumn}`,
  columnClass: `${styles.documentCell}`
});
