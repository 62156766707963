import React, { Component }                     from 'react';
import PropTypes                                from 'prop-types';

import FundPerpetualPerformanceCard             from '../../components/fundPerpetualPerformanceCard/FundPerpetualPerformanceCard';
import CardHeader                               from '../../components/card/CardHeader';
import ExposureChartList                        from '../../components/exposureChartList/ExposureChartList';
import ExposuresCard                            from '../../components/exposuresCard/ExposuresCard';
import Footer                                   from '../../components/footer/Footer';
import PerformanceChartCard                     from '../../components/performanceChartCard/PerformanceChartCard';
import TextDisplayCard                          from '../../components/textDisplayCard/TextDisplayCard';

import { objectiveCardText, strategyCardText }  from './MakenaFundDescription';
import { growthOfADollarFundTransform }         from '../../helpers/fundsHelpers/charts';

class PerpetualMakenaFund extends Component {
  
  render() {
    const {
      asOfDate,
      fund,
      overview,
      growthOfADollar,
      exposures,
      header
    } = this.props;

    const {
      benchmarks,
      displayName,
      objective,
      strategy,
      sidepocket
    } = fund;
    
    return (
      <div className="dashboard-content">
        { header }
        <div className='contentRowSplitTwo unprintable'>
          <TextDisplayCard 
            layoutStyle='cardRowItem cardContentLeft' 
            headerText={objectiveCardText.header}
            bodyText={objective}
            isHtmlText={true}
          />
          <TextDisplayCard 
            layoutStyle='cardRowItem cardContentRight'
            headerText={strategyCardText.header}
            bodyText={strategy}
            isHtmlText={true}
          />
        </div>
        <div className='contentRowSplitTwo makenaProduct'>
          <PerformanceChartCard
            performance={growthOfADollarFundTransform(fund, asOfDate, growthOfADollar)}
            subTitle='Trailing 5 Year'
            layoutStyle='cardRowItem unprintable makenaProductPerformance'
            labels={{'Your Portfolio': displayName, defaultBenchmark: benchmarks[0]}}
            title='Growth of a Dollar'
          />
          <FundPerpetualPerformanceCard 
            fund={fund}
            overview={overview}
            layoutStyle='cardRowItem unprintable makenaProductAssets'
            title='Fund Performance (TWR)'
          />
          <ExposuresCard 
            layoutStyle={`cardRowItem unprintable printableCard makenaProductExposures ${sidepocket? 'sidepocket' : ''}`}
            trackEventLabel='Fund Exposures'
          >
            <CardHeader>
                Fund Exposures
            </CardHeader>
            <div className="printableCardBody">
              <ExposureChartList charts={exposures} />
            </div>
          </ExposuresCard>
        </div>
        <Footer/>
      </div>
    );
  }
}


PerpetualMakenaFund.propTypes = {
  asOfDate        : PropTypes.string,
  header          : PropTypes.any,
  exposures       : PropTypes.array,
  fund            : PropTypes.object,
  growthOfADollar : PropTypes.shape({}),
  overview        : PropTypes.shape({})
};

export default PerpetualMakenaFund;
