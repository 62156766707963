import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';
import {
  makenaClientByIdFetchSuccess,
} from './makenaClientActions';
import { setClientInfo } from './makenaClientHelper';
import { getErrorMessage } from '../errors/errorHelper';


export const makenaClientByIdFetch = (id, onSuccess) => async (dispatch) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.MAKENA_CLIENT_BY_ID_FETCH(id)}`;
    const response = await requestWrapper.get(url);
    const makenaClient = response.data.data;

    setClientInfo(makenaClient);

    dispatch(makenaClientByIdFetchSuccess(makenaClient));
    if (onSuccess) onSuccess(makenaClient);
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.MAKENA_CLIENT_BY_ID_FETCH_ERROR);
    throw new Error(errorMessage);
  }
};
