import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './sectionLink.local.less';

const SectionLink = ({
  className,
  to,
  wrapper: Wrapper = 'div',
  children
}, {
  router: {
    history
  } = {}
}) => (
  <Wrapper 
    className={cx(
      className, 
      styles.sectionLink, 
      {[styles.hoverSection]: to }
    )} 
    onClick={() => to && history.push(to)}
  >
    { children }
  </Wrapper>
);

SectionLink.contextTypes = {
  router: PropTypes.object
};

SectionLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  wrapper: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default SectionLink;