import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form'
import { Form, Input } from 'semantic-ui-react';
import FormField from '../../../components/formField/FormField';

class SendLinkResetForm extends Component {

  onChange = (e, email) => {
    const { onChange = () => {} } = this.props;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    onChange({ isValidForm: re.test(String(email).toLowerCase()), email });
  }

  render() {
    const { props: { email }, onChange } = this;
    return (
      <Form.Field >
        <label>Email</label>
        <Field
          name="email"
          component={FormField}
          value={email}
          type="email"
          onChange={onChange}
          as={Input}
        />
      </Form.Field>
    );
  }
}

SendLinkResetForm.propTypes = {
  onChange: PropTypes.func,
  email: PropTypes.string,
};

export default SendLinkResetForm;