import React, { Component } from "react";

import Svg from "../svg/Svg";
import Icon from "../../assets/svg/m-large.svg";
import helpWithSignUpPdf from "../../assets/help_with_your_account__makena.pdf";
import styles from "./authCanvas.local.less";

class AuthCanvas extends Component {
  render() {
    return (
      <div className={styles.loginContainer}>
        <div className={styles.loginFormPanel}>
          <div className={styles.loginFormContent}>
            <Svg svg={Icon} className={styles.icon} />
          </div>
          <div className={styles.loginFormContent}>{this.props.children}</div>
          <div className={styles.loginFormTrouble}>
            <a href={helpWithSignUpPdf} target="_blank">
              Having trouble with your account?
            </a>
          </div>
        </div>
        <div className={styles.loginQuotePanel}>
          <div className={styles.quoteWrapper}>
            <p>
              &ldquo;Know what you own, and know why you own it.&rdquo;
              <br />
              <br />— Peter Lynch
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthCanvas;
