import { ACTIONS } from '../../app/constants/index';

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerSectorFetchSuccess = clientPortalManagerSector => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_SECTOR_FETCH_SUCCESS,
  clientPortalManagerSector,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerREPropertyTypeFetchSuccess = clientPortalManagerREPropertyType => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_RE_PROPERTY_TYPE_FETCH_SUCCESS,
  clientPortalManagerREPropertyType,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerNRIndustryFetchSuccess = clientPortalManagerNRIndustry => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_NR_INDUSTRY_FETCH_SUCCESS,
  clientPortalManagerNRIndustry,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerGrowthOfADollarStaticFetchSuccess = clientPortalManagerGrowthOfADollarStatic => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_GROWTH_OF_A_DOLLAR_STATIC_FETCH_SUCCESS,
  clientPortalManagerGrowthOfADollarStatic,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerGeographyFetchSuccess = clientPortalManagerGeography => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_GEOGRAPHY_FETCH_SUCCESS,
  clientPortalManagerGeography,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerVintageFetchSuccess = clientPortalManagerVintage => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_VINTAGE_FETCH_SUCCESS,
  clientPortalManagerVintage,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerRiskFactorFetchSuccess = clientPortalManagerRiskFactor => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_RISK_FACTOR_FETCH_SUCCESS,
  clientPortalManagerRiskFactor,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerFundFetchSuccess = clientPortalManagerFund => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_FUND_FETCH_SUCCESS,
  clientPortalManagerFund,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalManagerMakenaFundsFetchSuccess = clientPortalManagerMakenaFunds => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_MANAGER_MAKENA_FUNDS_FETCH_SUCCESS,
  clientPortalManagerMakenaFunds,
});

// eslint-disable-next-line import/prefer-default-export
export const clientPortalClearProductDataAction = () => ({
  type: ACTIONS.REPORTING_CLIENT_PORTAL_CLEAR_PRODUCT_DATA,
  clear: {
    clientPortalProductOverview: {},
    clientPortalProductAssetAllocation: {},
    clientPortalProductGeography: {},
    clientPortalProductRiskFactor: {}
  },
});
