import React                        from 'react';
import PropTypes                    from 'prop-types';
import Card                         from '../card/Card';
import CardHeader                   from '../card/CardHeader';
import CardBody                     from '../card/CardBody';

import FundPerpetualPerformanceTable from './FundPerpetualPerformanceTable';

const FundPerpetualPerformanceCard = ({
  fund,
  overview,
  layoutStyle,
  title
}) => (
  <Card
    layoutStyle={layoutStyle}
    trackEventLabel={title}
    trackEventCategory='Fund'
  >
    <CardHeader>
      {title}
    </CardHeader>
    <CardBody>
      <FundPerpetualPerformanceTable fund={fund} overview={overview}/>
    </CardBody>
  </Card>
);


FundPerpetualPerformanceCard.propTypes = {
  fund: PropTypes.object,
  overview: PropTypes.object,
  layoutStyle: PropTypes.string,
  title: PropTypes.string
}

FundPerpetualPerformanceCard.defaultProps = {
  layoutStyle: '',
  title: 'Asset Class Performance'
}

export default FundPerpetualPerformanceCard;


