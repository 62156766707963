import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import Svg                        from '../svg/Svg';
import styles                     from './datatableHeaderColumnCheckbox.local.less';
import { switcher, toggleParser } from './CheckboxHelper';
class DatatableHeaderColumnCheckbox extends Component {

  onClick = () => {
    const { onChange = () => {}, column: { toggleStatus = 'none' } } = this.props;
    const { value } = toggleParser[switcher[toggleStatus]];
    onChange(value);
  }

  render() {
    const { props: { column: { toggleStatus = 'none' } } } = this;
    const { icon, value } = toggleParser[toggleStatus];
    return (
      <div className={`${styles.datatableHeaderColumnCustom} ${styles.header}`} onClick={this.onClick}>
        <div className={`${styles.box} ${value? styles.active : ''}`}>
          { value? <Svg svg={icon}/> : null }
        </div>
      </div>
    )
  }
}

DatatableHeaderColumnCheckbox.propTypes = {
  column: PropTypes.object,
  onChange: PropTypes.func,
};

export default DatatableHeaderColumnCheckbox;