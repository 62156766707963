import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Slider from '../slider/CustomSlider';
import defaultImage from './assets/latest-news-default@2x.png';

import styles from './newsGallery.local.less';
import DynamicImage from '../image/DynamicImage';

const NewsGallery = ({ news, onClickHandler }) => {
  if (_.isEmpty(news)) return null;

  return (
    <Slider>
      {
        news.map((n) => {
          const { id, title, image } = n;

          return (
            <a key={id} onClick={onClickHandler} role="button">
              <div className={styles.card}>
                <div className={styles.cardImage}>
                  {
                    image ? (
                      <DynamicImage path={`news/${image}`} alt={title} />
                    ) : (
                      <img src={defaultImage} alt={title} />
                    )
                  }
                </div>

                <div className={styles.title}>
                  {title}
                </div>
              </div>
            </a>
          );
        })
      }
    </Slider>
  );
};


NewsGallery.propTypes = {
  news: PropTypes.arrayOf(PropTypes.shape({})),
  onClickHandler: PropTypes.func,
};

export default NewsGallery;
