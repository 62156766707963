import React from 'react';

import DynamicImage from '../../components/image/DynamicImage';
import LeadershipSlider from './LeadershipSlider';

import styles from './leadershipCardRenderer.local.less';

const renderSlides = 
  managers => managers.map(
    manager => {
      const itemKey = manager.name.split(" ").join("-");
    
      return (
        <div className={styles.leadershipSlide} key={itemKey}>
          { manager.image
            ? (
              <div className={styles.leaderWithImage}>
                <div className={styles.leaderInfo}>
                  <DynamicImage
                    alt={manager.name}
                    path={`managerLeaders/${manager.image}`}
                    isAsset={true}
                  />
                  <div>{manager.name}</div>
                </div>
                <div className={styles.leaderBio}>
                  {manager.bio}
                </div>
              </div>
            ) 
            : (
              <div className={styles.leaderWithoutImage}>
                <div className={styles.leaderName}>{manager.name}</div>
                <div className={styles.leaderBio}>{manager.bio}</div>
              </div>
            )
          }        
        </div>
      )
    }
  );


const LeadershipCardRenderer = leadership => (
  <LeadershipSlider count={leadership.length}>
    { renderSlides(leadership) }
  </LeadershipSlider>
);

export default LeadershipCardRenderer;