import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import DatatableHeaders         from './DatatableHeaders';
import DatatableRows            from './DatatableRows';
import DatatablePaginator       from './DatatablePaginator';
import styles                   from './datatable.local.less';

import { isIOS }                from '../../helpers/globalHelpers/index';


class Datatable extends Component {

  componentDidUpdate({ content: { currentPage = null } } = { content: { currentPage: null } } ) {
    if (currentPage === this.props.content.currentPage) return;
    this.ScrollToTop();
  }

  rowContainerRef = React.createRef();

  onPageChange = value => {
    const { onPageChange = () => {}} = this.props;
    onPageChange(value);
  }

  onRowClick = (value, event) => {
    if (isIOS() && event.type !== 'click') return;
    const { onRowClick = () => {}} = this.props;
    onRowClick(value, event);
  }

  onColumnClick = value => {
    const { onColumnClick = () => {} } = this.props;
    onColumnClick(value);
  }

  onSortChange = value => {
    const { onSortChange = () => {} } = this.props;
    onSortChange([value]);
  }

  onDataChange = value => {
    const { onDataChange = () => {} } = this.props;
    onDataChange(value);
  }

  ScrollToTop = () => this.rowContainerRef.current.scrollTo(0,0);

  render(){
    const { headers, content: { data, count, totalPages, currentPage, pageSize }, onFilterOptionClicked } = this.props;
    return (
      <div className={styles.dataTable}>
        <DatatableHeaders data={headers} onColumnClick={this.onColumnClick} onSortChange={this.onSortChange} onFilterOptionClicked={onFilterOptionClicked}/>
        <div className={headers.rowContainerClasses} ref={this.rowContainerRef}>
          <DatatableRows data={data} headers={headers} onDataChange={this.onDataChange} onRowClick={this.onRowClick}/>
          <div className={styles.separator}></div>
          <DatatablePaginator count={count} currentPage={currentPage} pageSize={pageSize} totalPages={totalPages} onPageChange={this.onPageChange}/>
        </div>
      </div>
    );
  }
}

Datatable.propTypes = {
  headers: PropTypes.object,
  content: PropTypes.object,
  onDataChange: PropTypes.func,
  onColumnClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onFilterOptionClicked: PropTypes.func,
  onSortChange: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default Datatable;
