import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Card from '../../components/card/Card';
import CardBody from '../../components/card/CardBody';
import CardHeader from '../../components/card/CardHeader';
import Svg from '../../components/svg/Svg';

import Close from '../../assets/svg/icon-menu-black-close.svg';

import ContactProfile from './ContactProfile';
import styles from './contactCard.local.less';

const ContactCard = ({
  contact,
  investmentContact,
  onClose
}) => (
  <Card
    layoutStyle={styles.contactCard}
    trackEventLabel='Contact'
    trackEventCategory='Managers'
  >
    <CardHeader>
      <div className={styles.contactCardHeader}>
        <div className={styles.contactCardHeaderContent}>
          Contact
        </div>
        <div className={styles.contactCardHeaderClose}>
          <button onClick={onClose}>
            <Svg svg={Close}/>
          </button>
        </div>
      </div>
    </CardHeader>
    <CardBody>
      <div className={styles.wrapperContactCardBody}>
        <ContactProfile
          role='Your Account Manager'
          {...contact}
        />
        {
          !_.some(
            _.values(investmentContact),
            _.isEmpty
          ) && (
            <ContactProfile
              role='Your Investment Contact'
              {...investmentContact}
            />
          )
        }
      </div>
    </CardBody>
  </Card>
);

ContactCard.propTypes = {
  contact: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    picture: PropTypes.string
  }).isRequired,
  investmentContact: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    picture: PropTypes.string
  }),
  onClose: PropTypes.func,
};

ContactCard.defaultProps = {
  investmentContact: {
    email: '',
    name: '',
    phone: '',
    picture: ''
  },
  onClose() {}
};

export default ContactCard;
