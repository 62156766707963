export default {
  ADMIN_FETCH_ALL_CLIENTS: 'ADMIN_FETCH_ALL_CLIENTS',
  ADMIN_MAKENA_CLIENT_CREATE: 'ADMIN_MAKENA_CLIENT_CREATE',
  ADMIN_MAKENA_CLIENT_UPDATE: 'ADMIN_MAKENA_CLIENT_UPDATE',
  ADMIN_CREATE_CLIENT_SUCCESS: 'ADMIN_CREATE_CLIENT_SUCCESS',
  ADMIN_UPDATE_CLIENT_SUCCESS: 'ADMIN_UPDATE_CLIENT_SUCCESS',
  ADMIN_FETCH_ALL_MAKENA_PEOPLE: 'ADMIN_FETCH_ALL_MAKENA_PEOPLE',
  ADMIN_MAKENA_PERSON_CREATE: 'ADMIN_MAKENA_PERSON_CREATE',
  ADMIN_MAKENA_PERSON_UPDATE: 'ADMIN_MAKENA_PERSON_UPDATE',
  ADMIN_CREATE_PERSON_SUCCESS: 'ADMIN_CREATE_PERSON_SUCCESS',
  ADMIN_UPDATE_PERSON_SUCCESS: 'ADMIN_UPDATE_PERSON_SUCCESS',
  ADMIN_DELETE_PERSON_SUCCESS: 'ADMIN_UPDATE_PERSON_SUCCESS',
  ADMIN_USER_FETCH_SUCCESS: 'ADMIN_USER_FETCH_SUCCESS',
  ADMIN_USER_UPDATE_SUCCESS: 'ADMIN_USER_UPDATE_SUCCESS',
  ADMIN_IMPERSONATE_USER_SUCCESS: 'ADMIN_IMPERSONATE_USER_SUCCESS',
  ERROR_DELETE: 'ERROR_DELETE',
  ERRORS_CLEAR: 'ERRORS_CLEAR',
  AUTH_IN_PROGRESS: 'AUTH_IN_PROGRESS',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  RESET_STATE: 'RESET_STATE',
  AUTH_UPDATE_USER_PROFILE: 'AUTH_UPDATE_USER_PROFILE',
  ACCOUNT_CREATE_IN_PROGRESS: 'ACCOUNT_CREATE_IN_PROGRESS',
  ACCOUNT_CREATE_SUCCESS: 'ACCOUNT_CREATE_SUCCESS',
  ACCOUNT_DISPATCH_INVITE_SUCCESS: 'ACCOUNT_DISPATCH_INVITE_SUCCESS',
  ACCOUNT_DELETE_SUCCESS: 'ACCOUNT_DELETE_SUCCESS',
  ACCOUNT_UPDATE_SUCCESS: 'ACCOUNT_UPDATE_SUCCESS',
  ACCOUNTS_FETCH_SUCCESS: 'ACCOUNTS_FETCH_SUCCESS',
  APPLICATION_SETTINGS_UPDATE: 'APPLICATION_SETTINGS_UPDATE',
  MAKENA_MENU_TOGGLE: 'MAKENA_MENU_TOGGLE',
  MAKENA_MENU_SELECTED_FUND: 'MAKENA_MENU_SELECTED_FUND',
  MAKENA_CLIENT_BY_ID_FETCH_SUCCESS: 'MAKENA_CLIENT_BY_ID_FETCH_SUCCESS',
  MAKENA_CLIENT_RESET: 'MAKENA_CLIENT_RESET',
  CLIENT_DOCUMENTS_FETCH_SUCCESS: 'CLIENT_DOCUMENTS_FETCH_SUCCESS',
  INSIGHTS_DOCUMENTS_FETCH_SUCCESS: 'INSIGHTS_DOCUMENTS_FETCH_SUCCESS',
  INVESTOR_DOCUMENTS_DATATABLE_FETCH_SUCCESS: 'INVESTOR_DOCUMENTS_DATATABLE_FETCH_SUCCESS',
  INVESTOR_DOCUMENTS_COUNT_BY_FIELD_FETCH_SUCCESS: 'INVESTOR_DOCUMENTS_COUNT_BY_FIELD_FETCH_SUCCESS',
  INVESTOR_DOCUMENTS_FETCH_SUCCESS: 'INVESTOR_DOCUMENTS_FETCH_SUCCESS',
  MAKENA_SETTINGS_SET_DEFAULT_CLIENT: 'MAKENA_SETTINGS_SET_DEFAULT_CLIENT',
  MAKENA_PRODUCTS_FETCH_SUCCESS: 'MAKENA_PRODUCTS_FETCH_SUCCESS',
  MAKENA_PRODUCT_BY_ID_FETCH_SUCCESS: 'MAKENA_PRODUCT_BY_ID_FETCH_SUCCESS',
  MAKENA_ASSETS_FETCH_SUCCESS: 'MAKENA_ASSETS_FETCH_SUCCESS',
  MAKENA_ALL_MANAGERS_FETCH_SUCCESS: 'MAKENA_ALL_MANAGERS_FETCH_SUCCESS',
  APP_HIERARCHY: 'APP_HIERARCHY',
  PASSWORD_RESET_REQUEST_SUCCESS: 'PASSWORD_RESET_REQUEST_SUCCESS',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  MAKENA_ASSET_CLASSES_FETCH_SUCCESS: 'MAKENA_ASSET_CLASSES_FETCH_SUCCESS',
  MAKENA_ASSET_CLASS_UPDATES_FETCH_SUCCESS: 'MAKENA_ASSET_CLASS_UPDATES_FETCH_SUCCESS',
  MAKENA_FUNDS_FETCH_SUCCESS: 'MAKENA_FUNDS_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_SECTOR_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_SECTOR_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_RE_PROPERTY_TYPE_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_RE_PROPERTY_TYPE_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_NR_INDUSTRY_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_NR_INDUSTRY_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_GEOGRAPHY_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_GEOGRAPHY_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_VINTAGE_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_VINTAGE_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_RISK_FACTOR_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_RISK_FACTOR_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_FUND_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_FUND_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_MAKENA_FUNDS_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_MAKENA_FUNDS_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_MANAGER_GROWTH_OF_A_DOLLAR_STATIC_FETCH_SUCCESS: 'REPORTING_CLIENT_PORTAL_MANAGER_GROWTH_OF_A_DOLLAR_STATIC_FETCH_SUCCESS',
  REPORTING_CLIENT_PORTAL_CLEAR_PRODUCT_DATA: 'REPORTING_CLIENT_PORTAL_CLEAR_PRODUCT_DATA',
  EXPLORE_ALL_MANAGERS_CLEAR_ERROR: 'EXPLORE_ALL_MANAGERS_CLEAR_ERROR',
  EXPLORE_ALL_MANAGERS_FINISH_LOADING: 'EXPLORE_ALL_MANAGERS_FINISH_LOADING',
  EXPLORE_ALL_MANAGERS_GET_SEARCH_FILTERS: 'EXPLORE_ALL_MANAGERS_GET_SEARCH_FILTERS',
  EXPLORE_ALL_MANAGERS_SET_ERROR: 'EXPLORE_ALL_MANAGERS_SET_ERROR',
  EXPLORE_ALL_MANAGERS_START_LOADING: 'EXPLORE_ALL_MANAGERS_START_LOADING',
  WELCOME_EMAIL_SUCCESS: 'WELCOME_EMAIL_SUCCESS'
};
