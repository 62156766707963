import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../components/card/Card';
import CardPanel from '../../components/cardPanel/CardPanel';
import CardHeader from '../../components/card/CardHeader';
import Svg from '../../components/svg/Svg';
import Icon from '../../assets/svg/m-large.svg';
import Chrome from '../../assets/svg/chrome-button.svg';
import Firefox from '../../assets/svg/firefox-button.svg';
import Safari from '../../assets/svg/safari-button.svg';
import styles from './updateBrowser.local.less';

const UpdateBrowser = props => {
  return (
    <>
      <div className={styles.browserContainer}>
        <div className={styles.browserPanel}>
          <div className={styles.browserContent}>
            <Svg svg={Icon} className={styles.icon} />
          </div>
          <div className={styles.browserContent}>
            <Card
              layoutStyle="cardMain"
              trackEventLabel="Please Updtate Your Web Browser"
              trackEventCategory='Browser Error'
            >
              <CardHeader>Please Update Your Web Browser</CardHeader>
              <CardPanel panelStyle="cardPanel noMargin">
                <p>
                  We noticed you're using Internet Explorer, an unsupported browser.
                </p>
                <br />
                <p>
                  We recommend using one of the web browsers listed below so
                  all of the websites you visit will display correctly,
                  faster, and more securely.
                </p>
                <br />
                <p>
                  If you have questions, please contact your Makena account
                  manager.
                </p>
                <br />
                <a href="https://www.google.com/chrome/" target="_blank">
                  <Svg svg={Chrome} className={styles.icon} />
                </a>
                <a
                  href="https://www.mozilla.org/en-US/firefox/new/"
                  target="_blank"
                >
                  <Svg svg={Firefox} className={styles.icon} />
                </a>
                <a
                  href="https://support.apple.com/en-us/HT204416"
                  target="_blank"
                >
                  <Svg svg={Safari} className={styles.icon} />
                </a>
                <div className={styles.browserLink}>
                  <a type="button" onClick={props.bypassUnsupportedBrowser} href="">Continue With Current Browser</a>
                </div>
              </CardPanel>
            </Card>
          </div>
        </div>
        <div className={styles.browserQuotePanel}>
          <div className={styles.quoteWrapper}>
            <p>
              &ldquo;Know what you own, and know why you own it.&rdquo;
              <br />
              <br />— Peter Lynch
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

UpdateBrowser.propTypes = {
  bypassUnsupportedBrowser:PropTypes.func 
};

export default UpdateBrowser;
