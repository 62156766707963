import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import cx                             from 'classnames';
import * as R                         from 'ramda';

import { MANAGER_NAV }        from '../../app/constants/index';
import More                   from '../../assets/svg/icon-arrow-left-blue.svg';
import InfoIcon               from '../../assets/svg/icon-info.svg';

import { renderBodyText }     from '../../components/textDisplayCard/TextDisplayCard';
import Card                   from '../../components/card/Card';
import CardHeader             from '../../components/card/CardHeader';
import ContentCardView        from '../../components/contentCardView/ContentCardView';
import Footer                 from '../../components/footer/Footer';
import ManagerPreview         from '../../components/managerPreview/ManagerPreview';
import ManagerDisclaimer      from '../../components/managerDisclaimer/ManagerDisclaimer';
import Svg                    from '../../components/svg/Svg';

import { trackEvent }         from '../../helpers/gaHelpers/gaHelpers';

import LeadershipCardRenderer from '../../renderers/leadershipCardRenderer/LeadershipCardRenderer';

import styles                 from './exploreManager.local.less';
import ManagerDisclaimerPopUp from '../../components/managerDisclaimer/ManagerDisclaimerPopUp';

class ExploreManager extends Component {
  state = {
    showDisclaimer: !this.props.hasBeenVisited,
    showPopUpDisclaimer: false,
    activeItem: MANAGER_NAV[0],
    accordionList: [
      'Investment Merits'
    ]
  };

  dismissDisclaimerCard = () => {
    trackEvent('click', {
      event_cagegory: 'Managers',
      event_label: 'dismiss disclaimer',
    });
    this.setState({ showDisclaimer: false })
  };

  toggleDisclaimer = () => {
    trackEvent('click', {
      event_cagegory: 'Managers',
      event_label: 'toggle disclaimer',
    });
    this.setState({ showPopUpDisclaimer: !this.state.showPopUpDisclaimer });
  }

  handleItemClick = (name) => {
    trackEvent('click', {
      event_cagegory: 'Managers',
      event_label: name
    });
    this.setState({ activeItem: name });
  }

  handleAccordionClick = e => {
    const { accordionList } = this.state;
    const selection = e.currentTarget.dataset.tag;
    trackEvent('click', {
      event_cagegory: 'Managers',
      event_label: selection
    });

    if (R.contains(selection, accordionList)) {
      const newAccordionList = accordionList.filter(x => x !== selection);

      this.setState({
        accordionList: newAccordionList
      });
    } else {
      const newAccordionList = [
        selection,
        ...accordionList
      ]
      this.setState({
        accordionList: newAccordionList
      });
    }
  }

  renderAccordionContent = (sideBarOpen, accordionList, headline, content) => (
    <div className={cx(styles.accordionContainer, {
      [styles.sideBarOpen]: sideBarOpen
    })} onClick={this.handleAccordionClick} data-tag={headline}>
      <h4 className={cx(styles.headline, {
        [styles.isOpen]: !R.contains(headline, accordionList)
      })}>{headline} <span className={cx(styles.overviewIcon, {
          [styles.isOpen]: !R.contains(headline, accordionList)
        })}>
          <Svg svg={More}/>
        </span></h4>
      {R.contains(headline, accordionList) && (
        <div className={cx({
          [styles.contentContainer]: R.contains(headline, accordionList)
        })}>
          {renderBodyText(content)}
        </div>
      )}
    </div>
  )

  renderDisclaimerInfoIcon = (showPopUpDisclaimer, toggleDisclaimer) => (
    <div className={styles.reviewCardDisclaimerIcon} onClick={toggleDisclaimer}>
      <Svg svg={InfoIcon}></Svg>
      { showPopUpDisclaimer? <ManagerDisclaimerPopUp onDismiss={toggleDisclaimer}/> : <></> }
    </div>
  );

  renderAccordion = () => {
    const {
      props: {
        sideBarOpen,
        manager: {
          review,
          overview,
          strategy
        }
      },
      state: {
        accordionList,
        showDisclaimer,
        showPopUpDisclaimer
      },
      toggleDisclaimer,
      renderDisclaimerInfoIcon
    } = this;

    return (
      <Fragment>
        <Card layoutStyle="cardRowFlexItem managerFlexReview">
          <CardHeader>
            <div className={styles.reviewCardHeader}>
              <div className={styles.reviewCardTitle}>Our Review</div>
              { showDisclaimer? <></> : renderDisclaimerInfoIcon(showPopUpDisclaimer, toggleDisclaimer) }
            </div>
          </CardHeader>
          {this.renderAccordionContent(sideBarOpen, accordionList, 'Investment Merits', review)}
          {this.renderAccordionContent(sideBarOpen, accordionList, 'Firm Background', overview)}
          {this.renderAccordionContent(sideBarOpen, accordionList, 'Fund Strategy', strategy)}
        </Card>
      </Fragment>
    );
  }


  renderOverviewTab = () => {
    const { manager: { leadership } } = this.props;
    return (
      <div className='contentRowSplitTwo makenaManagerFlex'>
        <div className="flexColumnLeft">
          {this.renderAccordion()}
        </div>
        <div className="flexColumnRight">
          <ContentCardView
            layoutStyle={`${styles.managerSlides} cardRowFlexItem managerFlexLeadership`}
            headerContent="Leadership"
            bodyContent={leadership}
            BodyRenderer={LeadershipCardRenderer}
            trackEventLabel="Leadership"
          />
        </div>
        <Footer/>
      </div>
    )
  };

  render() {

    const {
      props: {
        assetClass,
        manager
      },
      state: { showDisclaimer },
      dismissDisclaimerCard
    } = this;

    const {
      strategyLabelX1,
      strategyLabelX2,
      strategyLabelX3,
      strategyLabelY1,
      strategyLabelY2,
      strategyLabelY3,
    } = assetClass;

    const {
      firmInception,
      labelX,
      labelY,
      location,
      logo,
      makenaInvestmentDate,
      mostRecentFundSize,
      title
    } = manager;

    return (
      <div className='dashboardFullBleed fullBleed--top'>
        <ManagerPreview
          logo={logo}
          title={title}
          fundSize={mostRecentFundSize}
          inception={firmInception}
          location={location}
          labelX={labelX}
          labelY={labelY}
          makenaInvestmentDate={makenaInvestmentDate}
          strategyGridX1={strategyLabelX1}
          strategyGridX2={strategyLabelX2}
          strategyGridX3={strategyLabelX3}
          strategyGridY1={strategyLabelY1}
          strategyGridY2={strategyLabelY2}
          strategyGridY3={strategyLabelY3}
          panelStyle="contentRowFullWidth--masthead-fixed"
        />
        { showDisclaimer? <ManagerDisclaimer onDismiss={dismissDisclaimerCard}/> : null }
        { this.renderOverviewTab()}
      </div>
    );
  }
}

ExploreManager.propTypes = {
  assetClass    : PropTypes.object,
  manager       : PropTypes.object,
  sideBarOpen   : PropTypes.bool,
  hasBeenVisited: PropTypes.bool
};

export default ExploreManager;
