export const CALENDAR_WORDS = [
  'JANUARY',
  'JAN',
  'FEBRUARY',
  'FEB',
  'MARCH',
  'MAR',
  'APRIL',
  'APR',
  'MAY',
  'MAY',
  'JUNE',
  'JUN',
  'JULY',
  'JUL',
  'AUGUST',
  'AUG',
  'SEPTEMBER',
  'SEP',
  'OCTOBER',
  'OCT',
  'NOVEMBER',
  'NOV',
  'DECEMBER',
  'DEC'
]
