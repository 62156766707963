import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { trackEvent } from '../../helpers/gaHelpers/gaHelpers';

class Card extends Component {
  static propTypes = {
    colorBg: PropTypes.bool,
    contentStyle: PropTypes.string,
    forwardedRef: PropTypes.object,
    inlineStyles: PropTypes.object,
    layoutStyle: PropTypes.string,
    trackEventLabel: PropTypes.string,
    trackEventCategory: PropTypes.string,
  }

  static contextTypes = {
    router: PropTypes.object
  }

  static defaultProps = {
    contentStyle: '',
    inlineStyles: {},
    trackEventLabel: ''
  }

  handleOnClick = () => {
    const { trackEventLabel, trackEventCategory } = this.props;
    const {
      router : {
        history: {
          location
        } = {}
      } = {}
    } = this.context;

    if (!location) {
      return;
    }

    trackEvent('click', {
      event_category: trackEventCategory,
      event_label: trackEventLabel,
      location: location.pathname
    });
  }

  render() {
    const {
      children,
      colorBg,
      contentStyle,
      forwardedRef,
      inlineStyles,
      layoutStyle
    } = this.props;

    const background = { background: colorBg ? '#fafafa' : '#fff'};
    const elementStyles = {...background, ...inlineStyles};

    return (
      <div
        style={elementStyles}
        className={cx(layoutStyle, contentStyle)}
        onClick={this.handleOnClick}
        ref={forwardedRef}
      >
        {children}
      </div>
    );
  }
}

export default Card;
