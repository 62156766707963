import { ACTIONS } from '../../app/constants/index';

const INITIAL_STATE = {
  didPasswordResetRequest: false,
  didPasswordReset: false,
};

export default function (state = INITIAL_STATE, action = { type: '' }) {
  switch (action.type) {
    case ACTIONS.PASSWORD_RESET_REQUEST_SUCCESS: {
      return { ...state, didPasswordResetRequest: true };
    }
    case ACTIONS.PASSWORD_RESET_SUCCESS:
      return { ...state, didPasswordReset: true };
    default:
      return state;
  }
}
