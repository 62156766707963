import React            from 'react';
import PropTypes        from 'prop-types';
import moment           from 'moment';
import _                from 'lodash';
import docStyles        from './allManagersCard.local.less';
import DynamicImage     from '../image/DynamicImage';


const fundsRenderer = funds => (<div className={docStyles.fundsContainer}>{ funds.split(',').map((fund, idx) => (<div key={`fund-${fund}-${idx}`}>{ fund.trim() }</div>)) }</div>);

const managerRenderer = ({ image, value, title }) => image? (
  <div className={docStyles.titleContainer} title={title}>
    <DynamicImage path={`managers/${value}`} isAsset={true} alt={title}/>
  </div>
)
  : (<div className={docStyles.titleContainer}>{ title }</div>);

managerRenderer.propTypes = { image: PropTypes.boolean, value: PropTypes.string, title: PropTypes.string };

export const headers = {
  headersClasses: docStyles.documentsHeader,
  rowsClasses: docStyles.documentsRow,
  rowContainerClasses: docStyles.documentsRowContainer,
  columns: [
    {
      type: 'string',
      title: 'Manager',
      selector: 'logo',
      format: value => managerRenderer(value),
      headerColumnClass: `${docStyles.documentHeaderColumn}`,
      columnClass: `${docStyles.documentCell}`
    },
    {
      type: 'string',
      title: 'Funds',
      selector: 'funds',
      format: value => fundsRenderer(value),
      filterField: 'funds',
      isFilterable: true,
      headerColumnClass: `${docStyles.documentHeaderColumn}`,
      columnClass: `${docStyles.documentCell}`
    },
    {
      type: 'string',
      title: 'Asset Class',
      selector: 'assetClass',
      isSortable: true,
      isFilterable: true,
      filterField: 'assetClass',
      format: name => name ? name.toString() : '',
      headerColumnClass: `${docStyles.documentHeaderColumn}`,
      columnClass: `${docStyles.documentCell}`
    },
    {
      type: 'string',
      title: 'Our Initial Investment',
      selector: 'makenaInvestmentDate',
      filterField: 'makenaInvestmentDate',
      isSortable: true,
      isFilterable: false,
      format: value => moment.utc(value, 'MM/DD/YY').format('MM/YYYY'),
      headerColumnClass: `${docStyles.documentHeaderColumn}`,
      columnClass: `${docStyles.documentCell}`
    },
    {
      type: 'string',
      title: 'Location',
      selector: 'location',
      isSortable: true,
      isFilterable: true,
      filterField: 'location',
      headerColumnClass: `${docStyles.documentHeaderColumn}`,
      columnClass: `${docStyles.documentCell}`
    },
    {
      type: 'string',
      title: 'Strategy',
      selector: 'strategy',
      format: value => `${value.substring(0, 65)}...`,
      headerColumnClass: `${docStyles.documentHeaderColumn}`,
      columnClass: `${docStyles.documentCell} ${docStyles.strategyContainer}`
    }
  ]
};

export const transform = response => ({
  ...response,
  data: (response.data && Array.isArray(response.data)? response.data : []).map(row => ({
    ...row,
    logo: row.logo? { image: true, value: row.logo, title: row.managerTitle } : { image: false, title: row.managerTitle }
  }))
});

export const assignFiltersToHeaders = (_headers, filters, appliedFilters, sortObject) => {
  let newHeaders = _.clone(_headers);
  _.forEach(newHeaders.columns, column => {
    if (column.isFilterable) {
      column.filterOptions = filters[column.selector];
      const filteredFilters = _.get(appliedFilters, column.filterField, []);
      _.forEach(column.filterOptions, filterOption => {
        filterOption.filterSelected = _.find(filteredFilters, filterValue => filterValue === filterOption.name)? true : false;
      });

      column.filterOptions = _.orderBy(column.filterOptions, ['title'], ['asc']);
    }

    if (column.isSortable) {
      if (sortObject.field !== column.filterField)
        delete column.sortDirection;
      else
        column.sortDirection = sortObject.direction;
    }
  });
  return newHeaders;
};
