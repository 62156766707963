import { ACTIONS } from '../../app/constants/index';

const INITIAL_STATE = {
  selectedClient: null
};

export default function (state = INITIAL_STATE, action = { selectedClient: null, type: '' }) {
  switch (action.type) {
    case ACTIONS.MAKENA_SETTINGS_SET_DEFAULT_CLIENT: {
      return { ...state, selectedClient: action.selectedClient };
    }
    default:
      return state;
  }
}