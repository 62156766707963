import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import get                              from 'lodash/get';
import { compose }                      from 'redux';
import { withRouter }                   from 'react-router-dom';
import connect                          from 'react-redux/es/connect/connect';

import { onToggleMenu }                 from '../../services/menu/menuService';
import { makenaAssetClassesFetch }      from '../../services/makenaAssetClass/makenaAssetClassService';
import OpenEndMakenaFund                from './OpenEndMakenaFund';
import ClosedEndMakenaFund              from './ClosedEndMakenaFund';
import PerpetualMakenaFund              from './PerpetualMakenaFund';

import {
  clientPortalGrowthOfADollarByProductFetch,
  clientPortalProductJCurveFetch,
  clientPortalProductAssetAllocationFetch,
  clientPortalProductClosedAssetAllocationFetch,
  clientPortalProductGeographyFetch,
  clientPortalProductCurrencyFetch,
  clientPortalProductClosedGeographyFetch,
  clientPortalProductOverviewFetch,
  clientPortalProductOverviewClosedEndFetch,
  clientPortalProductRiskFactorFetch,
  clientPortalProductClosedRiskFactorFetch,
  clientPortalAssetClassSectorFetch,
  clientPortalAssetClassGeographyFetch,
  clientPortalAssetClassStrategyFetch,
  clientPortalAssetClassREPropertyTypeFetch,
  clientPortalAssetClassNRIndustryFetch,
  clientPortalAssetClassPESectorFetch
} from '../../services/reporting/reportingService';
import MakenaFundHeader from './MakenaFundHeader';

const reportsFetchFunctions = {
  'clientPortalAssetClassSector'          : {
    fn: clientPortalAssetClassSectorFetch,
    applyAssetName: false 
  },
  'clientPortalAssetClassGeography'       : {
    fn: clientPortalAssetClassGeographyFetch,
    applyAssetName: false 
  },
  'clientPortalAssetClassStrategy'        : {
    fn: clientPortalAssetClassStrategyFetch,
    applyAssetName: false 
  },
  'clientPortalAssetClassREPropertyType'  : {
    fn: clientPortalAssetClassREPropertyTypeFetch,
    applyAssetName: true 
  },
  'clientPortalAssetClassNRIndustry'      : {
    fn: clientPortalAssetClassNRIndustryFetch,
    applyAssetName: true 
  },
  'clientPortalAssetClassPESector'        : {
    fn: clientPortalAssetClassPESectorFetch,
    applyAssetName: true 
  }
};

const getAssetClass = (perpetualAssetClassId, assetClasses) => assetClasses.find(({ id }) => id === perpetualAssetClassId);

const transformExposuresReports = ({ title, exposuresReports }) => object => {
  return Object.keys(exposuresReports).map(
    key => {
      const value = exposuresReports[key],
        _data = get(object[value.report], 'children.data', {}),
        _title = key,
        id    = value.index,
        data  = _data[title]? _data[title] : _data;
      return { data, title: _title, id: id.toString() };
    }
  ).sort((a, b)=> a.id - b.id);
}

const parseAssetAllocation = (assetClasses, assetAllocation) => {
  const {
    children: {
      data = []
    } = {}
  } = assetAllocation;
  const newAssetAllocationData = {};
  Object.keys(data).forEach(_title => {
    const {
      displayName = _title
    } = assetClasses.find(({ title }) => _title === title) || {};
    newAssetAllocationData[displayName] = data[_title];
  });
  return {
    ...assetAllocation,
    children: {
      ...assetAllocation.children,
      data: newAssetAllocationData
    }
  }
}

const parseOverview = (assetClasses, overview) => {
  const {
    total: {
      children: {
        data: {
          assetClasses: {
            data = {}
          } = {}
        } = {}
      } = {}
    }
  } = overview;
  
  const newOverviewData = {};
  Object.keys(data).forEach(_title => {
    const {
      displayName = _title
    } = assetClasses.find(({ title }) => _title === title) || {};
    newOverviewData[displayName] = data[_title];
    newOverviewData[displayName].name = displayName;
  });
  return {
    ...overview,
    total: {
      ...overview.total,
      children: {
        ...overview.total.children,
        data: {
          ...overview.total.children.data,
          assetClasses: {
            ...overview.total.children.data.assetClasses,
            data: newOverviewData
          }
        }
      }
    }
  }
}

const mergeAll = arrayData => {
  let object = {};
  arrayData.forEach(data => object = { ...object, ...data });
  return object;
}

class MakenaFundContainer extends Component {
  static propTypes = {
    asOfDate        : PropTypes.string,
    history         : PropTypes.shape({ push: PropTypes.func }),
    makenaClient    : PropTypes.object,
    openModal       : PropTypes.func,
    fund            : PropTypes.shape({}),
    toggle          : PropTypes.func
  }

  state = {
    isLoading: true,
    data: {
      overview: {},
      closedEndOverview: {},
      growthOfADollar: {},
      exposures: { 
        assetAllocation: {}, 
        geography: {}, 
        riskFactor: {},
        currency: {} 
      },
      jCurve : {},
      fund: {}
    },
    errors: {
      productFetchError: null
    },
  };

  async componentDidMount() {
    const {
      initReset,
      props: { openMenu, toggle },
    } = this;

    if (!openMenu) toggle();

    await initReset();
  }

  async componentDidUpdate(prevProps) {
    const {
      initReset,
      props: { fund: { entityId } }
    } = this;
    if (entityId === prevProps.fund.entityId) return;
    
    await initReset();
  }

  initReset = async () => {
    const {
      startLoading,
      updateState, 
      finishLoading, 
      noMatch,
    } = this;

    try { await startLoading(); await updateState(); }
    catch(e) { noMatch(); }
    finally { await finishLoading(); }
  }

  updateState = () => {
    const {
      mergeFundData,
      props: {
        makenaClient: { entityId },
        fund,
      },
      retrieveExposures
    } = this;

    const productId = fund.entityId;
    const portfolioId = fund.sidepocket? fund.portfolioId : null;

    const {
      getAssetAllocation,
      getGeography,
      getRiskFactor,
      getCurrency
    } = {
      getAssetAllocation: fund.type === 'CLOSED'? clientPortalProductClosedAssetAllocationFetch : clientPortalProductAssetAllocationFetch,
      getGeography: fund.type === 'CLOSED'? clientPortalProductClosedGeographyFetch : clientPortalProductGeographyFetch,
      getRiskFactor: fund.type === 'CLOSED'? clientPortalProductClosedRiskFactorFetch : clientPortalProductRiskFactorFetch,
      getCurrency: clientPortalProductCurrencyFetch
    }
    
    let promises = [
      makenaAssetClassesFetch().then(assetClasses => ({ assetClasses })),
      clientPortalGrowthOfADollarByProductFetch(entityId, portfolioId).then(growthOfADollar => ({ growthOfADollar })),
      clientPortalProductOverviewFetch(productId, portfolioId).then(overview => ({ overview })),
      clientPortalProductOverviewClosedEndFetch(productId, portfolioId).then(closedEndOverview => ({ closedEndOverview })),
      getAssetAllocation(productId, portfolioId).then(assetAllocation => ({ assetAllocation })),
      getGeography(productId, portfolioId).then(geography => ({ geography })),
      getRiskFactor(productId, portfolioId).then(riskFactor => ({ riskFactor }))
    ];

    if (fund.type === 'CLOSED') promises.push(clientPortalProductJCurveFetch(productId).then(jCurve => ({ jCurve })));

    if (fund.type === 'OPEN' && fund.sidepocket) promises.push(getCurrency(productId, portfolioId).then(currency => ({ currency })));

    return Promise.all(promises)
      .then(mergeFundData)
      .then(data => ({ data: { ...data, fund }}))
      .then(retrieveExposures)
      .then(data => this.setState(data));
  }

  retrieveExposures = async obj => {
    const { 
      data: { 
        assetClasses,
        fund: { type = '', perpetualAssetClassId = 0 }
      } 
    } = obj;
    if (type !== 'PERPETUAL') return obj;
    const { fund: { entityId, portfolioId, sidepocket } } = this.props;
    const assetClass = getAssetClass(perpetualAssetClassId, assetClasses);
    const { exposuresReports, title } = assetClass;
    const promises = Object.keys(exposuresReports)
      .map(report => exposuresReports[report].report)
      .map(reportName => {
        const { applyAssetName, fn } = reportsFetchFunctions[reportName];
        return (applyAssetName? fn(entityId, sidepocket? portfolioId : '', title) : fn(entityId, sidepocket? portfolioId : ''))
          .then(value => ({ [reportName]: value }))
      });
    
    return await Promise.all(promises)
      .then(mergeAll)
      .then(transformExposuresReports(assetClass))
      .then(exposures => ({ data: { ...obj.data, exposures} }));
  }

  mergeFundData = arrayData => {
    const { 
      assetClasses = [],
      overview = {}, 
      closedEndOverview = {}, 
      growthOfADollar = {}, 
      assetAllocation = {}, 
      geography = {}, 
      currency = {}, 
      riskFactor = {}, 
      jCurve = {}
    } = mergeAll(arrayData);

    const { fund: { sidepocket } } = this.props

    const _assetAllocation = parseAssetAllocation(assetClasses, assetAllocation);
    
    let exposures = [
      { title: 'Asset Allocation' , data: get(_assetAllocation , 'children.data', []), id: 'asset' },
      { title: 'Risk Factor'      , data: get(riskFactor      , 'children.data', []), id: 'risk'  },
      { title: 'Geography'        , data: get(geography       , 'children.data', []), id: 'geo'   },
    ];
    
    if (sidepocket) {
      exposures.push({ title: 'Currency', id: 'cur', data: get(currency, 'children.data', []) });
    }
    
    return {
      assetClasses,
      closedEndOverview,
      exposures,
      growthOfADollar,
      jCurve,
      overview: parseOverview(assetClasses, overview),
    };
  }

  noMatch = () => this.props.history.push('/nomatch');

  startLoading = async () => {
    return this.setState({
      isLoading: true,
    });
  };

  finishLoading = () => {
    return this.setState({
      isLoading: false
    });
  };

  getMakenaFundHeader = () => {
    return <MakenaFundHeader fund={this.state.data.fund} />
  }

  renderClosedEndFund = () => {
    const {
      state: { data: { closedEndOverview, exposures, jCurve, fund } },
      props: { asOfDate, openModal = () => {} }
    } = this;
    
    return <ClosedEndMakenaFund
      asOfDate={asOfDate}
      header={this.getMakenaFundHeader()}
      overview={closedEndOverview}
      growthOfADollar={jCurve}
      openModal={openModal}
      fund={fund}
      exposures={exposures}
    />;
  }
  renderOpenEndFund = () => {
    const {
      state: { data: { overview, growthOfADollar, exposures, fund, assetClasses = [] } },
      props: { asOfDate, openModal = () => {} }
    } = this;
    
    return <OpenEndMakenaFund
      asOfDate={asOfDate}
      overview={overview}
      header={this.getMakenaFundHeader()}
      assetClasses={assetClasses}
      growthOfADollar={growthOfADollar}
      openModal={openModal}
      fund={fund}
      exposures={exposures}
    />
  }
  renderPerpetualFund = () => {
    const {
      state: { data: { overview, growthOfADollar, exposures, fund } },
      props: { asOfDate, openModal = () => {} }
    } = this;
    return <PerpetualMakenaFund
      asOfDate={asOfDate}
      overview={overview}
      header={this.getMakenaFundHeader()}
      growthOfADollar={growthOfADollar}
      openModal={openModal}
      fund={fund}
      exposures={exposures}
    />
  }

  renderFund = () => {
    const {
      state: { data: { fund: { type } } },
      renderClosedEndFund,
      renderOpenEndFund,
      renderPerpetualFund
    } = this;

    switch(type.toUpperCase()) {
      case 'CLOSED'     : return renderClosedEndFund();
      case 'OPEN'       : return renderOpenEndFund()  ;
      case 'PERPETUAL'  : return renderPerpetualFund();
    }
  }

  render() {
    const { 
      renderFund,
      state: { isLoading } 
    } = this;
    return isLoading? null : renderFund();
  }
}

MakenaFundContainer.propTypes = {
  fund: PropTypes.object,
  openMenu: PropTypes.bool
};

const mapStateToProps = state => ({
  asOfDate: state.auth.settings.reportingQuarterEnd,
  makenaClient: state.makenaClient,
  openMenu: state.menu.open,
});

const mapDispatchToProps = ({
  toggle: onToggleMenu
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MakenaFundContainer);
