import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cx from "classnames";
import { SubmissionError } from "redux-form";
import isEmpty from "lodash/isEmpty";
import * as R from "ramda";
import { Button, Modal, Radio } from "semantic-ui-react";

import Card from "../card/Card";
import CardBody from "../card/CardBody";
import CardHeader from "../card/CardHeader";
import ChangePasswordForm from "../changePasswordForm/ChangePasswordForm";
import DynamicImage from "../image/DynamicImage";
import InvestorDocumentsCard from "../investorDocumentsCard/InvestorDocumentsCard";

import SplitHeaderRenderer from "../../renderers/shared/splitHeaderRenderer/SplitHeaderRenderer";
import { accountUpdatePassword as accountUpdateAction } from "../../services/account/accountService";
import ERRORS from "../../app/constants/errors";

import styles from "./settings.local.less";
import Footer from "../footer/Footer";
import {
  login as loginAction,
  refreshAuthProfile as getProfile,
  refreshAuthProfile,
  updateUser,
} from "../../services/auth/authService";
import ActionDropdown from "../actionDropdown/ActionDropdown";
import { getDefaultClient } from "../../services/auth/authHelper";
import { setDefaultClient } from "../../services/settings/settingsService";

const transformClientsList = R.map(({ id, title }) =>
  R.zipObj(["text", "key", "value"], [title, `${title}-${id}`, id])
);

class Settings extends Component {
  static propTypes = {
    investorDocuments: PropTypes.any,
    clientProfile: PropTypes.object,
    userProfile: PropTypes.object,
    login: PropTypes.func,
    refresh: PropTypes.func,
    setDefaultClient: PropTypes.func,
    selectedClient: PropTypes.any,
    mfaEnabled: PropTypes.bool,
    getUserProfile: PropTypes.func,
  };

  state = {
    error: "",
    isModalVisible: false,
    isPasswordUpdateComplete: false,
    selectedClient: null,
    mfaEnabled: null,
  };

  async componentDidMount() {
    const {
      investorDocumentsFetchAction,
      clientProfile,
      selectedClient,
      userProfile,
    } = this.props;

    try {
      this.startLoading();
      try {
        await this.props.getUserProfile();
        await investorDocumentsFetchAction(clientProfile.entityId);
        this.clearError("clientPortalFetchError");
      } catch (error) {
        this.setError("clientPortalFetchError", error.message);
      }
    } finally {
      this.finishLoading({
        selectedClient,
        mfaEnabled: userProfile.mfaEnabled,
      });
    }
  }

  setError = (errorName, errorMessage) => {
    this.setState({
      errors: {
        [errorName]: errorMessage,
      },
    });
  };

  clearError = (errorName) => {
    this.setError(errorName, null);
  };

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (data = {}) => {
    this.setState({
      ...data,
      isLoading: false,
    });
  };

  handlePasswordUpdate = async ({ newPassword, currentPassword }) => {
    const {
      accountPasswordUpdate,
      userProfile: { email, uuid },
      login,
    } = this.props;
    try {
      await accountPasswordUpdate(
        uuid,
        { password: newPassword, currentPassword },
        async () => {
          await login({ email, password: newPassword });
          this.setState({
            isPasswordUpdateComplete: true,
          });
        }
      );
    } catch (error) {
      throw new SubmissionError({
        _error: ERRORS.ACCOUNT_UPDATE_PASSWORD_ERROR,
      });
    }
  };

  handleChangePasswordClick = () => {
    this.setState({
      isModalVisible: true,
      isPasswordUpdateComplete: false,
    });
  };

  handlePasswordOkClick = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleModalClose = () => {
    console.log("Handle Modal Close");
    this.setState({
      isModalVisible: false,
    });
  };

  handleCancelClick = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handleNewsletterCheck = () => {
    console.log("Newsletter Change");
  };

  handleClientAction = () => {
    if (!this.state.selectedClient) return;
    this.props.setDefaultClient(this.state.selectedClient);
  };

  handleClientChange = (value) => {
    const { userProfile: { clients = [] } = {} } = this.props;
    const selectedClient = R.compose(
      R.path([value, "id"]),
      R.indexBy(R.prop("id"))
    )(clients);
    this.setState((state) => ({ ...state, selectedClient }));
  };

  handleFormValuesChange = (e, { name, value }) => {
    const { userProfile: { uuid } = {}, refresh } = this.props;
    updateUser(uuid, { [name]: value }).then(() => {
      this.setState({ mfaEnabled: value });
      refresh();
    });
  };

  renderModalContent = () => {
    const cardHeader = {
      left: {
        value: "Change Password",
      },
      right: {
        value: (
          <span className={styles.cardCloseBtn} onClick={this.handleModalClose}>
            X
          </span>
        ),
        styles: styles.cardHeaderRight,
      },
    };

    if (this.state.isPasswordUpdateComplete) {
      return (
        <>
          <Modal.Header>Password Changed</Modal.Header>
          <Modal.Content>
            <div className={styles.confirmPanel}>
              You successfully changed your password.
            </div>
            <Button
              className={styles.actionButton}
              onClick={this.handlePasswordOkClick}
            >
              OK
            </Button>
          </Modal.Content>
        </>
      );
    } else {
      return (
        <>
          <Modal.Header>{SplitHeaderRenderer(cardHeader)}</Modal.Header>
          <Modal.Content>
            <ChangePasswordForm
              handleCancelClick={this.handleCancelClick}
              handleFormSubmit={this.handlePasswordUpdate}
            />
          </Modal.Content>
        </>
      );
    }
  };

  renderContactCards() {
    const { clientProfile } = this.props;
    let contacts = [
      {
        title: "Your Account Manager",
        name: `${clientProfile.accountManagerFirstName} ${clientProfile.accountManagerLastName}`,
        phone: clientProfile.accountManagerPhone,
        picture: clientProfile.accountManagerPicture,
        email: clientProfile.accountManagerEmail,
      },
    ];

    if (clientProfile.investmentContactEmail) {
      contacts.push({
        email: clientProfile.investmentContactEmail,
        name: `${clientProfile.investmentContactFirstName} ${clientProfile.investmentContactLastName}`,
        phone: clientProfile.investmentContactPhone,
        picture: clientProfile.investmentContactPicture,
        title: "Your Investment Contact",
      });
    }

    return (
      <Card
        layoutStyle={cx("contactCardView", "sViewCard", styles.sCard)}
        trackEventLabel="Contact Us"
        trackEventCategory="Settings"
      >
        <CardHeader>Contact Us</CardHeader>
        <CardBody>
          {" "}
          {isEmpty(clientProfile) ? (
            <div className={cx(styles.contentRow, styles.contactContent)}>
              <div className={styles.contactText}>
                <div className={styles.contactLabel}>
                  Need help with your account?
                </div>
                <div className={styles.contactLabel}>
                  Please don’t hesitate to contact us.
                </div>
              </div>
              <span className={styles.contactLabel}>
                <a
                  className={styles.linkButton}
                  href="mailto:portalhelp@makenacap.com"
                >
                  portalhelp@makenacap.com
                </a>
              </span>
              <span className={styles.contactLabel}>650-926-0510</span>
            </div>
          ) : (
            contacts.map((contact, ind) => (
              <div
                className={cx(styles.contentRow, styles.contactContent)}
                key={ind}
              >
                <div className={styles.placeholderImage}>
                  <DynamicImage
                    path={`people/${contact.picture}`}
                    isAsset={true}
                    alt={contact.name}
                  />
                </div>
                <span className={cx(styles.contactLabel, styles.contactName)}>
                  {contact.name}
                </span>
                <span className={styles.contactLabel}>{contact.title}</span>
                <span className={styles.contactLabel}>
                  <a
                    className={styles.linkButton}
                    href={`mailto:${contact.email}`}
                  >
                    {contact.email}
                  </a>
                </span>
                <span className={styles.contactLabel}>{contact.phone}</span>
              </div>
            ))
          )}
        </CardBody>
      </Card>
    );
  }

  renderInvestorDocumentsCard = () => {
    const { investorDocuments } = this.props;

    return !investorDocuments.data ||
      !Object.keys(investorDocuments.data).length ? (
        <Card layoutStyle={`${styles.sCard} sViewCard`}>
          <CardHeader>Recent Investor Documents</CardHeader>
          <CardBody>
            <div className="noContent">
              <div className="noContentContainer">
                <div className="marginBottom">
                Your investor documents will appear here.
                </div>
                <div>
                If you have any questions, please contact your Makena account
                manager or write to us at
                  <a href="mailto:ClientOperations@makenacap.com">
                    {" "}
                  ClientOperations@makenacap.com
                  </a>
                .
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      ) : (
        <InvestorDocumentsCard
          documents={investorDocuments.data}
          layoutStyle={cx("docsCard", styles.sCard)}
          parentPage="settings"
        />
      );
  };

  renderProfileCard = () => {
    const { userProfile: { email = "", clients = [] } = {} } = this.props;
    const defaultClient = getDefaultClient(clients);
    const selectedClient =
      this.state.selectedClient || (defaultClient ? defaultClient.id : null);
    const { handleClientAction, handleClientChange } = this;

    return (
      <Card
        layoutStyle={cx(
          "settingsCard",
          "sViewCard",
          styles.sCard,
          styles.topCard
        )}
        trackEventLabel="Account Settings"
      >
        <CardHeader>Account Settings</CardHeader>
        <CardBody>
          <div className={styles.contentRow}>
            <div className={styles.contentRowLabelColumn}>
              Investor Account:
            </div>
            <div className={styles.contentRowDataColumn}>
              {selectedClient && (
                <ActionDropdown
                  options={transformClientsList(clients)}
                  value={selectedClient}
                  actionText="Save"
                  onChange={handleClientChange}
                  onAction={handleClientAction}
                />
              )}
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.contentRowLabelColumn}>Email:</div>
            <div className={styles.contentRowDataColumn}>{email}</div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.contentRowLabelColumn}>Password:</div>
            <div className={styles.contentRowDataColumn}>
              <span
                className={styles.linkButton}
                onClick={this.handleChangePasswordClick}
              >
                Change Password
              </span>
            </div>
          </div>
          <div className={styles.contentRow}>
            <div className={styles.contentRowLabelColumn}>
              Enable Two-Factor Authentication:
            </div>
            <div className={styles.contentRowDataColumn}>
              <Radio
                checked={Boolean(!this.state.mfaEnabled)}
                className="mr-16px"
                value={0}
                label="No"
                name="mfaEnabled"
                onChange={this.handleFormValuesChange}
              />
              <Radio
                checked={Boolean(this.state.mfaEnabled)}
                value={1}
                label="Yes"
                name="mfaEnabled"
                onChange={this.handleFormValuesChange}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };
  render() {
    return this.state.isLoading ? null : (
      <div className="dashboardTopPadding">
        <div className="settingsContent">
          {this.renderProfileCard()}
          {this.renderInvestorDocumentsCard()}
          {this.renderContactCards()}
          <Modal
            open={this.state.isModalVisible}
            onClose={this.handleModalClose}
            size="mini"
          >
            {this.renderModalContent()}
          </Modal>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientProfile: state.makenaClient,
  userProfile: state.auth,
  selectedClient: (state.settings && state.settings.selectedClient) || null,
});

Settings.propTypes = {
  investorDocumentsFetchAction: PropTypes.func,
  accountPasswordUpdate: PropTypes.func,
};

const mapDispatchToProps = {
  setDefaultClient,
  accountPasswordUpdate: accountUpdateAction,
  getUserProfile: getProfile,
  login: loginAction,
  refresh: refreshAuthProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
