import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Card from '../../../components/card/Card';
import CardPanel from '../../../components/cardPanel/CardPanel';
import CardHeader from '../../../components/card/CardHeader';
import { trackEvent } from '../../../helpers/gaHelpers/gaHelpers';
import { ROUTES } from '../../../app/constants/index';
import styles from './loginForm.local.less';

const requiredField = value => value.trim() !== '';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailValidator =  email => email.match(emailRegex);

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInputAsText: false,
      formControls: {
        password: {
          value: '',
          valid: true,
          validationRules: {
            isRequired: true
          }
        }
      }
    }

    this.passwordFieldRef = createRef();

  }

  componentDidMount() {
    trackEvent('login', {
      event_category: 'Account',
      event_label: 'login'
    });

    const passwordField = this.passwordFieldRef.current.getRenderedComponent();
    passwordField.focus();
  }

  validate = (value, rules) => {
    let isValid = true;

    for (let rule in rules) {
      switch (rule) {
        case 'isRequired': isValid = isValid && requiredField(value); break;
        case 'isEmail': isValid = isValid && emailValidator(value); break;
        default: isValid = true;
      }
    }

    return isValid;
  }

  handleInputChange = event => {
    const { formControls } = this.state;
    const target = event.target;
    const name = target.name;
    const formState = {
      ...formControls
    }

    const updatedControl = {
      ...formState[name]
    }

    updatedControl.value = target.value;

    formState[name] = updatedControl;

    this.setState({
      formControls: formState,
    });
  }

  errorHandler = error => {
    if (error) return (
      <div className={styles.bigErrorMessage}>
        <div className={styles.errorIcon}><span>!</span></div>
        <p>{error}</p>
      </div>
    );
  }

  getSessionMessage = () => {
    const { history } = this.props;
    const { location : { state: locationState = {}} } = history;
    const { flashMessage = null } = locationState;

    const signInMessage = "Please sign in to access your account";

    if (flashMessage) {
      history.replace(ROUTES.LOGIN.path, {...locationState, flashMessage: null});
    }

    return signInMessage;   
  }
  
  toggleShowInputAsText = () => this.setState(state => ({ showInputAsText: !state.showInputAsText }));

  render() {
    const { showInputAsText } = this.state;
    const { handleSubmit, handleLogin, handleBackButtonClick, submitting, error, email } = this.props;

    return (
      <Fragment>
        <Card
          layoutStyle="cardMain"
          trackEventLabel='Makena Investor Site'
          trackEventCategory='Authentication'
        >
          <CardHeader>Makena Investor Site</CardHeader>
          <CardPanel panelStyle="cardPanel noMargin">
            <p className={styles.signInText}>{this.getSessionMessage()}</p>
            { this.errorHandler(error) }
            <form
              autoComplete="off"
              onSubmit={handleSubmit(handleLogin)}
              className={`attached fluid segment ${styles.loginFormWrap}`}
            >
              <div className={styles.formField}>
                <label className={styles.formLabels}>{ email }</label>
                <Field component="input" type="hidden" name="email" value={email} />
              </div>
              <div className={styles.formField}>
                <div>
                  <label className={styles.formLabels}>Password</label>
                  <a className={styles.forgotPassword} href={ROUTES.PASSWORD_RESET.path} tabIndex="3">Forgot password?</a>
                </div>
                <div className={styles.formInputGroup}>
                  <Field
                    component="input"
                    type={showInputAsText? "text" : "password"}
                    name="password"
                    autoComplete="new-password"
                    className={styles.formInput}
                    onChange={this.handleInputChange}
                    tabIndex="2"
                    ref={this.passwordFieldRef}
                    forwardRef={true}
                  />

                  <button
                    type="button"
                    onClick={this.toggleShowInputAsText}
                    className={styles.formInputButton}
                  >{showInputAsText? 'Hide' : 'Show'}</button>
                </div>
              </div>

              <div className="two-column-flex">
                <input type="submit" className={`${styles.submitBtn} formItem`} disabled={submitting}  value="Sign In" />
                <span className="formItem formButton--clear" onClick={handleBackButtonClick}>Back</span>
              </div>
            </form>
          </CardPanel>
        </Card>
      </Fragment>
    );
  }

}

LoginForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleLogin: PropTypes.func,
  handleBackButtonClick: PropTypes.func,
  submitting: PropTypes.bool,
  email: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object,
    replace: PropTypes.func
  }),
};

export default compose(
  connect(
    null,
    null
  ),
  reduxForm({ form: 'loginForm' }),
)(LoginForm);
