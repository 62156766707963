import React, { Component } from 'react';
import { withRouter }       from 'react-router-dom';
import moment               from 'moment';
import styles               from './asOfBadge.local.less';
import { getSettings }      from '../../services/auth/authHelper';
class AsOfBadge extends Component {

  state = {
    isLoading: true,
    date: null,
  };

  componentDidMount() {
    this.startLoading();
    const date = getSettings().reportingQuarterEnd;
    this.finishLoading(date);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (date) => {
    this.setState({
      date,
      isLoading: false,
    });
  };

  render() { 
    const { date, isLoading } = this.state;

    if (isLoading) return null;
    
    return (
      <div className={styles.asOfBadge} id="asOfBadge">
        <div className={styles.staticContent}>{`As of `}</div>
        <div className={styles.bold}>{moment.utc(date).format('MM/DD/YYYY')}</div>
      </div>
    )
  }
}

export default withRouter(AsOfBadge);
