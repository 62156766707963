import getApiRootUrl        from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper       from '../../helpers/apiHelpers/requestWrapper';
import { API_URLS, ERRORS } from '../../app/constants/index';
import { getErrorMessage }  from '../errors/errorHelper';

// eslint-disable-next-line import/prefer-default-export
export const makenaAssetClassUpdatesFetch = async () => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.MAKENA_ASSET_CLASSES_UPDATE_FETCH}`;
    const response = await requestWrapper.get(url);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, ERRORS.REPORTING_FETCH_ERROR(reportType));
    throw new Error(errorMessage);
  }
};