export default {
  HOME: {
    path: '/admin',
    routes: '/admin',
    title: 'AdminLayout',
    isPublic: false,
  },
  DASHBOARD_LOGINS_AND_DOCS: {
    path: '/admin/dashboard/logins-and-docs',
    routes: '/admin/dashboard/logins-and-docs',
    title: 'Logins and Docs',
    isPublic: false,
  },
  DASHBOARD_CLIENTS: {
    path: '/admin/dashboard/clients',
    routes: '/admin/dashboard/clients',
    title: 'Clients',
    isPublic: false,
  },
  DASHBOARD_USERS: {
    path: '/admin/dashboard/users',
    routes: '/admin/dashboard/users',
    title: 'Users',
    isPublic: false,
  },
  USERS: {
    path: '/admin/users',
    routes: '/admin/users',
    title: 'Manage Users',
    isPublic: false,
  },
  USER_CREATE: {
    path: '/admin/users/create',
    routes: '/admin/users/create',
    title: 'Create account',
    isPublic: false,
  },
  USER_EDIT: {
    path: '/admin/users/edit',
    routes: '/admin/users/edit/:userId',
    title: 'Edit Users',
    isPublic: false,
  },
  ACCOUNT_NAME: {
    path: '/admin/account-names',
    routes: '/admin/account-names',
    title: 'Manage Account Names',
    isPublic: false,
  },
  ACCOUNT_NAME_EDIT: {
    path: '/admin/account-names/edit',
    routes: '/admin/account-names/edit/:sscId',
    title: 'Edit Account Name',
    isPublic: false,
  },
  SETTINGS: {
    path: '/admin/settings',
    routes: '/admin/settings',
    title: 'Settings',
    isPublic: false,
  },
  ADDEPAR: {
    path: '/admin/addepar',
    routes: '/admin/addepar',
    title: 'Addepar',
    isPublic: false,
  },
  NOTIFICATION_SETTINGS: {
    path: '/admin/notification-settings',
    routes: '/admin/notification-settings',
    title: 'Notification Settings',
    isPublic: false,
  },
  CLIENTS: {
    path: '/admin/clients',
    routes: '/admin/clients',
    title: 'Clients',
    isPublic: false,
  },
  CLIENT_CREATE: {
    path: '/admin/clients/create',
    routes: '/admin/clients/create',
    title: 'Create Client',
    isPublic: false,
  },
  CLIENT_EDIT: {
    path: '/admin/clients/edit/:id',
    routes: '/admin/clients/edit/:id',
    title: 'Edit Client',
    isPublic: false,
  },
  FUNDS: {
    path: '/admin/funds',
    routes: '/admin/funds',
    title: 'Funds',
    isPublic: false,
  },
  FUND_CREATE: {
    path: '/admin/funds/create',
    routes: '/admin/funds/create',
    title: 'Create Fund',
    isPublic: false,
  },
  FUND_EDIT: {
    path: '/admin/funds/edit/:id',
    routes: '/admin/funds/edit/:id',
    title: 'Edit Fund',
    isPublic: false,
  },
  ASSETS: {
    path: '/admin/asset-classes',
    routes: '/admin/asset-classes',
    title: 'Asset Classes',
    isPublic: false,
  },
  ASSETS_CREATE: {
    path: '/admin/asset-classes/create',
    routes: '/admin/asset-classes/create',
    title: 'Create Asset Class',
    isPublic: false,
  },
  ASSETS_EDIT: {
    path: '/admin/asset-classes/edit/:id',
    routes: '/admin/asset-classes/edit/:id',
    title: 'Edit Asset Class',
    isPublic: false,
  },
  UPDATES: {
    path: '/admin/updates',
    routes: '/admin/updates',
    title: 'Updates',
    isPublic: false,
  },
  UDPATE_CREATE: {
    path: '/admin/updates/create',
    routes: '/admin/updates/create',
    title: 'Create Update',
    isPublic: false,
  },
  UDPATE_EDIT: {
    path: '/admin/updates/edit/:id',
    routes: '/admin/updates/edit/:id',
    title: 'Edit Update',
    isPublic: false,
  },
  PEOPLE: {
    path: '/admin/people',
    routes: '/admin/people',
    title: 'Makena People',
    isPublic: false,
  },
  PEOPLE_CREATE: {
    path: '/admin/people/create',
    routes: '/admin/people/create',
    title: 'Create Person',
    isPublic: false,
  },
  PEOPLE_EDIT: {
    path: '/admin/people/edit/:id',
    routes: '/admin/people/edit/:id',
    title: 'Edit Person',
    isPublic: false,
  },
  MANAGER: {
    path: '/admin/managers',
    routes: '/admin/managers',
    title: 'Makena Managers',
    isPublic: false,
  },
  MANAGER_CREATE: {
    path: '/admin/managers/create',
    routes: '/admin/managers/create',
    title: 'Create Manager',
    isPublic: false,
  },
  MANAGER_EDIT: {
    path: '/admin/managers/edit/:id',
    routes: '/admin/managers/edit/:id',
    title: 'Edit Manager',
    isPublic: false,
  },
  GLOBAL_DOCS: {
    path: '/admin/global-docs',
    routes: '/admin/global-docs',
    title: 'Global Documents',
    isPublic: false,
  },
  GLOBAL_DOCS_CREATE: {
    path: '/admin/global-docs/create',
    routes: '/admin/global-docs/create',
    title: 'Create Global Documents',
    isPublic: false,
  },
  GLOBAL_DOCS_EDIT: {
    path: '/admin/global-docs/edit/:uuid',
    routes: '/admin/global-docs/edit/:uuid',
    title: 'Edit Global Documents',
    isPublic: false,
  },
  TAX_FORMS: {
    path: '/admin/tax-forms',
    routes: '/admin/tax-forms',
    title: 'Tax Forms',
    isPublic: false,
  },
  TAX_FORMS_DETAILS: {
    path: '/admin/tax-forms/:uuid',
    routes: '/admin/tax-forms/:uuid',
    title: 'Edit Tax Form',
    isPublic: false,
  },
  INVESTOR_DOCS: {
    path: '/admin/investor-docs',
    routes: '/admin/investor-docs',
    title: 'Investor Documents',
    isPublic: false,
  },
  INVESTOR_DOCS_CLIENTS: {
    path: '/admin/investor-docs/clients/:clientId',
    routes: '/admin/investor-docs/clients/:clientId',
    title: 'Create Investor Documents',
    isPublic: false,
  },
  INVESTOR_DOCS_EDIT: {
    path: '/admin/investor-docs/clients/:clientId/edit/:uuid',
    routes: '/admin/investor-docs/clients/:clientId/edit/:uuid',
    title: 'Edit Investor Documents',
    isPublic: false,
  },
  CLIENT_DOCS: {
    path: '/admin/client-docs',
    routes: '/admin/client-docs',
    title: 'Client Documents',
    isPublic: false,
  },
  CLIENT_DOCS_CLIENTS: {
    path: '/admin/client-docs/clients/:clientId',
    routes: '/admin/client-docs/clients/:clientId',
    title: 'Clients',
    isPublic: false,
  },
  CLIENT_DOCS_CREATE: {
    path: '/admin/client-docs/clients/:id/create',
    routes: '/admin/client-docs/clients/:id/create',
    title: 'Create Client Documents',
    isPublic: false,
  },
  CLIENT_DOCS_EDIT: {
    path: '/admin/client-docs/clients/:clientId/edit/:uuid',
    routes: '/admin/client-docs/clients/:clientId/edit/:uuid',
    title: 'Edit Client Documents',
    isPublic: false,
  },
};
