export const states = [
  'Alaska',
  'Louisiana',
  'Ohio',
  'Alabama',
  'Massachusetts',
  'Oklahoma',
  'Arkansas',
  'Maryland',
  'Oregon',
  'Arizona',
  'Maine',
  'Pennsylvania',
  'California',
  'Michigan',
  'Rhode Island',
  'Colorado',
  'Minnesota',
  'South Carolina',
  'Connecticut',
  'Missouri',
  'South Dakota',
  'Delaware',
  'Mississippi',
  'Tennessee',
  'Florida',
  'Montana',
  'Texas',
  'Georgia',
  'North Carolina',
  'Utah',
  'Hawaii',
  'North Dakota',
  'Virginia',
  'Iowa',
  'Nebraska',
  'Vermont',
  'Idaho',
  'New Hampshire',
  'Washington',
  'Illinois',
  'New Jersey',
  'Wisconsin',
  'Indiana',
  'New Mexico',
  'West Virginia',
  'Kansas',
  'Nevada',
  'Wyoming',
  'Kentucky',
  'New York'
];
export const otherStates = [
  'District of Columbia',
  'Puerto Rico'
];
export const statesSelectOptions = [...states, ...otherStates].map(value => ({
  value,
  key: value,
  text: value
})).sort((a,b) => {
  if (a.text > b.text)
    return 1;
  if (a.text < b.text)
    return -1;
  return 0;
});

export const setAllStates = value => {
  const objectStates = {};
  statesSelectOptions.forEach(state => {
    objectStates[state.value] = value;
  });
  return objectStates;
}