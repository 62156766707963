import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import {
  defaultCurrencyFormat,
  defaultCurrencyFormatMln
} from '../../helpers/formatters/formatters';

numeral.register('locale', 'en-us', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'million',
    billion: 'billion',
    trillion: 'trillion'
  },
  ordinal: function (number) {
    var b = number % 10;
    return (~~ (number % 100 / 10) === 1) ? 'th' :
      (b === 1) ? 'st' :
        (b === 2) ? 'nd' :
          (b === 3) ? 'rd' : 'th';
  },
  currency: {
    symbol: '$'
  }
});

const Currency = (props) => {
  if (!props) return null;

  const {
    value, cssClass, format, noFormat, isMln, withCents,
  } = props;

  if (noFormat) {
    return (
      <span className={cssClass}>
      $ {value}
      </span>
    );
  }

  let formatValue = format || defaultCurrencyFormat;
  if (isMln) formatValue = defaultCurrencyFormatMln;
  if (withCents) formatValue = formatValue.concat('.00');

  numeral.locale('en-us');

  return (
    <span className={cssClass}>
      {numeral(+value).format(formatValue)}
    </span>
  );
};

Currency.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cssClass: PropTypes.string,
  format: PropTypes.string,
  noFormat: PropTypes.bool,
  isMln: PropTypes.bool,
  withCents: PropTypes.bool,
};

export default Currency;
