import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'

import ManagerGalleryCardContent from './ManagerGalleryCardContent';
import ManagerGalleryCardImage from './ManagerGalleryCardImage';
import { getManagerPageUrl } from '../../helpers/urlHelpers/urlHelpers';
import { trackEvent } from '../../helpers/gaHelpers/gaHelpers';

import styles from './managerGalleryCard.local.less'

const handleOnClick = ({ 
  location,
  trackEventLabel
}, callback) => 
  e => {
    trackEvent('click', {
      location,
      event_category: 'Cards',
      event_label: trackEventLabel
    });
    
    callback(e);
  }; 

const ManagerGalleryCard = ({
  assetClass,
  date,
  history,
  logo,
  onClick,
  size,
  strategy,
  title
}) => (
  <a 
    className={styles[size]} 
    onClick={handleOnClick({
      location: history.location.pathname,
      trackEventLabel: title 
    }, 
    onClick
    )} 
    role='button'
    href={getManagerPageUrl({ 
      assetClass, 
      managerPage: title 
    })}
  >
    <div className={styles.card}>
      <ManagerGalleryCardImage 
        alt={title}
        className={styles.logo} 
        logo={logo}
      />
      <ManagerGalleryCardContent 
        texts={{
          assetClass, 
          date, 
          strategy
        }}
      />
    </div>
  </a>
);

ManagerGalleryCard.propTypes = {
  assetClass: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  history: PropTypes.object,
  logo: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string.isRequired,
  strategy: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

ManagerGalleryCard.defaultProps = {
  onClick(){}
};

export default withRouter(ManagerGalleryCard);
