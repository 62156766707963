import React, { Component }  from 'react';
import PropTypes  from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import Card from '../card/Card';
import CardHeader from '../card/CardHeader';
import CardBody from '../card/CardBody';
import {
  AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid,
} from 'recharts';
import styles from './fundPerformanceChartCard.local.less';
import FundPerformanceChartCardTooltip from './FundPerformanceChartCardTooltip';
import { COLORS, MAKENA_COLORS } from '../../app/constants/index';
import { areaStyles, areaCategories, totalTitle, areaChartSettings } from './FundPerformanceChartCard.const';
import { trackEvent } from '../../helpers/gaHelpers/gaHelpers';


const formatSettings = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

const getTooltipValue = value => (Math.round(parseFloat(value.toString()) * 10) / 10).toFixed(1);

class FundPerformanceChartCard extends Component {
  static propTypes = {
    labels: PropTypes.object,
    layoutStyle: PropTypes.string,
    namespace: PropTypes.string,
    performance: PropTypes.object,
    subTitle: PropTypes.string,
    title: PropTypes.string
  }

  static defaultProps = {
    title: 'Performance'
  }

  constructor(props) {
    super(props);
    const {
      performance,
      namespace
    } = this.props;
    const { chartData } = areaChartSettings(performance, namespace);
    this.state = {
      'Your Portfolio': this.getLast(chartData, totalTitle),
      'added': false
    };
  }
  onAreaMove = async event => {
    const {
      performance,
      namespace
    } = this.props;
    const { chartData } = areaChartSettings(performance, namespace);
    const objectPath = `activePayload.0.payload.`;
    let portfolioValue = _.get(event, `${objectPath}${totalTitle}`, this.getLast(chartData, totalTitle));

    portfolioValue = new Intl.NumberFormat('en-US', formatSettings).format(portfolioValue);

    if (this.state[totalTitle] === portfolioValue)
      return;
    this.setState({
      ...this.state,
      'Your Portfolio': portfolioValue
    });
  }

  trackAreaOnEnter = area => {
    trackEvent('hover', {
      event_category: 'Fund',
      event_label: area,
    })
  }

  getLast = (chartData, tag) => {
    let value = _.last( chartData.map( data => _.isNumber(data[tag]) ? data[tag] : 0 ) );
    return new Intl.NumberFormat('en-US', formatSettings).format(value);
  }

  render() {
    const {
      labels,
      namespace,
      performance,
      subTitle,
      title
    } = this.props;
    const {
      chartData,
      xAxisParams : { dateRange, domain },
      yAxisParams
    } = areaChartSettings(performance, namespace);
    const areaMargins = { top: 20, right: 20, left: -14, bottom: 20 };

    return (
      <Card
        layoutStyle={this.props.layoutStyle}
        trackEventLabel={title}
        trackEventCategory='Fund'
      >
        <CardHeader>
          <div className={styles.performanceHeader}>
            <div className={styles.performanceHeaderTitle}>
              {title}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className={styles.tooltipContainer}>
            <div className={styles.tooltipTitle}>
              {subTitle}
            </div>
            <div className={styles.tooltipResume}>
              {
                areaCategories.map( (category, index) => {
                  return (
                    <div key={index} className={`${styles.tooltip}`}>
                      <div className={styles.tooltipInfo}>
                        <div className={styles.tooltipSquare} style={{backgroundColor: areaStyles[category].color}}></div>
                        <div className={styles.tooltipDescription}>{labels? labels[category] : category}</div>
                      </div>
                      <div className={styles.tooltipValue}>{getTooltipValue(this.state[category])}%</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <ResponsiveContainer className={styles.performanceContainer}>
            <AreaChart
              data={chartData}
              margin={areaMargins}
              onMouseMove={this.onAreaMove}
            >
              <Tooltip
                cursor={{ stroke: areaStyles['Your Portfolio'].color, strokeDasharray: '2', strokeWidth: 1, opacity: 1 }}
                isAnimationActive={false}
                opacity={1}
                content={<FundPerformanceChartCardTooltip/>}
              />
              <CartesianGrid
                vertical={false}
                verticalFill={[COLORS.white]}
                fillOpacity={0.5}
                horizontal
              />
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                dataKey="name"
                scale="time"
                domain={domain}
                ticks={dateRange}
                tick={{ fill: MAKENA_COLORS.textColor }}
                tickFormatter={tick => moment.utc(tick).format('YYYY')}
                margin={{left: 170, right: 30}}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                type="number"
                tickFormatter={value => `${numeral(value).format('0')}%`}
                domain={[yAxisParams.min, yAxisParams.max]}
                stroke={MAKENA_COLORS.axisColor}
                tick={{ fill: MAKENA_COLORS.textColor }}
                ticks={yAxisParams.ticks}
              />
              {
                areaCategories.map(label =>
                  <Area
                    key={label}
                    type="linear"
                    dataKey={label}
                    stroke={areaStyles[label].color}
                    fill={areaStyles[label].color}
                    strokeDasharray={areaStyles[label].strokeDasharray}
                    strokeWidth={2}
                    activeDot={{ stroke: areaStyles[label].color, strokeWidth: 2, r: 4.5, fillOpacity: 0 }}
                    fillOpacity={0}
                  />
                )
              }
            </AreaChart>
          </ResponsiveContainer>
        </CardBody>
      </Card>
    );
  }
}

export default FundPerformanceChartCard;
