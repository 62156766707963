import getApiRootUrl from '../../helpers/apiHelpers/getApiRootUrl';
import requestWrapper from '../../helpers/apiHelpers/requestWrapper';
import { getErrorMessage } from '../errors/errorHelper';
import { API_URLS } from '../../app/constants/index';


export const uploadFile = async (file) => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.UPLOAD_FILE}`;

    const data = new FormData();
    data.append('file', file);

    const response = await requestWrapper.post(url, data);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const upload = async data => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.UPLOAD_ASSET}`;    
    const response = await requestWrapper.post(url, data);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};

export const uploadDocument = async data => {
  try {
    const url = `${getApiRootUrl()}${API_URLS.UPLOAD_FILE}`;    
    const response = await requestWrapper.post(url, data);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err, err.message);
    throw new Error(errorMessage);
  }
};
