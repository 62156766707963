import { ACTIONS } from '../../app/constants/index';

export default function (state = {}, action = { clients: [], settings: {}, type: '' }) {
  switch (action.type) {
    case ACTIONS.ADMIN_FETCH_ALL_CLIENTS: {
      return {
        ...state,
        clients: action.clients
      };
    }
    case ACTIONS.APPLICATION_SETTINGS_UPDATE: {
      return {
        ...state,
        settings: action.settings,
      };
    }
    default:
      return state;
  }
}
