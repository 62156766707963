import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './survey.local.less';
import Close from '../../assets/svg/close-black.svg'
import Svg from '../svg/Svg';

class SurveyModal extends Component {

  Close = () => {
    const { onClose = () => {} } = this.props;
    onClose(false);
  }

  GoToSurvey = () => {
    const { onClose = () => {} } = this.props;
    onClose(true);
    window.open(this.props.url, '_blank');
  }

  render() {

    const { open } = this.props;

    if (!open) return null;

    return <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderTitle}>
            {this.props.title}
          </div>
          <div className={styles.modalHeaderClose} onClick={this.Close}>
            <Svg svg={Close}/>
          </div>
        </div>
        <div className={styles.modalDivider}></div>
        <div className={styles.modalBody}>
          <div className={styles.modalContent}>
            {this.props.text}
          </div>
          <div className={styles.modalActions}>
            <button onClick={this.GoToSurvey}>{this.props.action}</button>
          </div>
        </div>
      </div>
    </div>;
  }
}

SurveyModal.propTypes = {
  title : PropTypes.string,
  action : PropTypes.string,
  url : PropTypes.url,
  text : PropTypes.string,
  open : PropTypes.bool,
  onClose : PropTypes.func
};

export default SurveyModal;