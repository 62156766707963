import React from 'react';
import PropTypes from 'prop-types';
import Svg from '../svg/Svg';
import iconArrow from '../../assets/svg/icon-arrow-black-right.svg';

import styles from './collapsiblePanel.local.less';

class CollapsiblePanel extends React.Component {
  constructor(){
    super();

    this.state = {
      displayMenu: false,
    };
  }

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu = () => {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  render() {
    const { serviceContent, serviceTitle } = this.props;
    return (
      <div  className={styles.collapsiblePanel}>
        <div className={styles.collapsiblePanelBtn} onClick={this.showDropdownMenu}>
          <Svg svg={iconArrow} />
          {serviceTitle}
        </div>
        { this.state.displayMenu ? (serviceContent) : (null) }
      </div>
    );
  }
}

CollapsiblePanel.propTypes = {
  serviceTitle: PropTypes.string,
  serviceContent: PropTypes.object
};

export default CollapsiblePanel;
