import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import _                          from 'lodash';
import DatatableRowColumnString   from './DatatableRowColumnString';
import DatatableRowColumnHTML     from './DatatableRowColumnHTML';
import DatatableRowColumnCheckbox from './DatatableRowColumnCheckbox';

const getRandom = () => Math.floor(Math.random() * 1000000) + 1;

class DatatableRows extends Component {

  onDataChange = value => {
    const { onDataChange = () => {} } = this.props;
    onDataChange(value);
  }

  onRowClick = value => event => {
    const { onRowClick = () => {} } = this.props;
    onRowClick(value, event);
  }

  columnRenderer = (value, column) => {
    switch(column.type) {
      case 'checkbox': return <DatatableRowColumnCheckbox data={value} column={column} onChange={this.onDataChange}/>
      case 'render': return <DatatableRowColumnHTML data={value} column={column}/>
      default: return <DatatableRowColumnString data={value} column={column}/>
    }
  }

  rowRenderer = (key, value, columns) => {
    const { headers: { rowsClasses } } = this.props;
    return (
      <div key={key} className={rowsClasses}> 
        { 
          _.map(columns, column => {
            const columnKey = `${key}__${column.selector? column.selector : getRandom()}`
            const rowClass = `${column.columnClass} ${value.rowClass}`;
            return (<div key={columnKey} className={rowClass} onTouchEnd={this.onRowClick(value)} onClick={this.onRowClick(value)}>{ this.columnRenderer(value, column) }</div>)
          }) 
        } 
      </div>
    );
  }

  render(){ 
    const { data, headers: { columns } } = this.props
    return (
      <div>
        { _.isEmpty(data) && 'No Results' }
        { 
          _.map(data, (row, key) => {
            const DatatableRowKey = `${getRandom()}__${key}`;
            return this.rowRenderer(DatatableRowKey, row, columns);
          }) 
        }
      </div>
    );
  
  }
}

DatatableRows.propTypes = {
  onDataChange: PropTypes.func,
  onRowClick: PropTypes.func,
  headers: PropTypes.object,
  data: PropTypes.array
}

export default DatatableRows;