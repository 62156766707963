import {
  DATA_COLORS, DATA_NEGATIVE_COLORS, COLORS, MAKENA_COLORS, BENCHMARK_COLORS,
} from './colors';
import ROUTES from './routes';
import ADMIN_ROUTES from '../routes/admin';
import API_URLS from './apiUrls';
import ACTIONS from './actions';
import ERRORS from './errors';
import FUNDS from './funds';

const ADDEPAR_ASSET_CLASS_PROP = 'MCM Portal Asset Class';
const DATE_PROP = 'MCM Initial Closing Date';
const MANAGER_EXPLORE_CAROUSEL_FILTER_TABS = [ 'recent investments' ];
const MANAGER_NAV = [ 'overview' ];
const MANAGER_PROP = 'MCM Manager Name';
const SPREADSHEET_ASSET_CLASS_PROP = 'assetClass';
const SESSION_TIMEOUT_MESSAGE = "Your session has expired. Please enter your email address to sign in.";

const ACCOUNT_STATUS = {
  ACTIVE       : 1,
  PENDING         : 2,
  REGISTERED      : 3
};

const TokenStatus = {
  VALID   : 'VALID',
  INVALID : 'INVALID',
  EXPIRED : 'EXPIRED'
};

const INVESTOR_DOCUMENTS_COUNT_FIELDS = {
  ACCOUNTS: 'accounts',
  CATEGORIES: 'categories',
  FUNDS: 'funds',
  READ_UNREAD: 'readUnread',
  RELEASE_DATE: 'releaseDate'
};

const MAKENA_MANAGERS_COUNT_FIELDS = {
  FUNDS: 'funds',
  LOCATIONS: 'locations',
  ASSET_CLASSES: 'assetClasses'
};

const ADMIN_INVESTOR_DOCUMENTS_COUNT_FIELDS = {
  ACCOUNTS: 'accounts',
  CATEGORIES: 'categories',
  FUNDS: 'funds',
  VISIBLE: 'visible',
  RELEASE_DATE: 'releaseDate'
};

const ADMIN_USAGE_CLIENTS_COUNT_FIELDS = {
  ACCOUNT_MANAGERS: 'accountManagers',
  CLIENTS: 'clients'
};

const ADMIN_TAX_ELECTION_FORMS_COUNT_FIELDS = {
  LEGAL_NAME: 'legalName',
  STATUS: 'status'
};

const ACCESS_LEVEL = {
  FULL: 'full',
  DOCUMENT_ONLY: 'document'
};

export {
  ACTIONS,
  ADMIN_INVESTOR_DOCUMENTS_COUNT_FIELDS,
  ADMIN_USAGE_CLIENTS_COUNT_FIELDS,
  ADMIN_TAX_ELECTION_FORMS_COUNT_FIELDS,
  ADDEPAR_ASSET_CLASS_PROP,
  API_URLS,
  BENCHMARK_COLORS,
  COLORS,
  DATA_COLORS,
  DATA_NEGATIVE_COLORS,
  DATE_PROP,
  ERRORS,
  FUNDS,
  MAKENA_COLORS,
  MANAGER_EXPLORE_CAROUSEL_FILTER_TABS,
  MANAGER_NAV,
  MANAGER_PROP,
  ROUTES,
  ADMIN_ROUTES,
  SPREADSHEET_ASSET_CLASS_PROP,
  ACCOUNT_STATUS,
  ACCESS_LEVEL,
  INVESTOR_DOCUMENTS_COUNT_FIELDS,
  MAKENA_MANAGERS_COUNT_FIELDS,
  SESSION_TIMEOUT_MESSAGE,
  TokenStatus
};
