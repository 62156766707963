import React from 'react';
import PropTypes from 'prop-types';
import { MAKENA_COLORS } from '../../app/constants';

import styles from './section.local.less';

const Section = ({
  children, className, withMargin, withArrows, isFullWidthLayout, bgColor, paddingSize,
}) => {
  let cssClass = isFullWidthLayout ? styles.fullWidth : styles.layoutWithSideNav;
  if (className) cssClass = cssClass.concat(' ', className);
  if (withMargin) cssClass = cssClass.concat(' ', styles.withMargin);
  if (withArrows) cssClass = cssClass.concat(' ', styles.withArrows);
  if (paddingSize) cssClass = cssClass.concat(' ', styles[`padding_${paddingSize}`]);

  return (
    <section className={cssClass} style={{ backgroundColor: bgColor }}>
      <div className={styles.content}>
        {children}
      </div>
    </section>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  withMargin: PropTypes.bool,
  withArrows: PropTypes.bool,
  isFullWidthLayout: PropTypes.bool,
  paddingSize: PropTypes.oneOf(['default', 'none', 'small']),
  bgColor: PropTypes.string,
};

Section.defaultProps = {
  isFullWidthLayout: false,
  bgColor: MAKENA_COLORS.sectionBackground,
  paddingSize: 'default',
};


export default Section;
