import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import * as moment              from 'moment';
import { pathOr }               from 'ramda';
import connect                  from 'react-redux/es/connect/connect';
import CardBody                 from '../../components/card/CardBody';
import Card                     from '../../components/card/Card';
import CardHeader               from '../../components/card/CardHeader';
import { 
  fetchTaxElectionForm,
  saveTaxElectionForm,
  updateTaxElectionForm,
  fetchTaxElectionFormBySSCAndYear
}                               from '../../services/taxElectionForms/taxElectionFormsService';
import { getGlobalSettings }    from '../../services/admin/settings/settingsService';
import {
  Button, 
  Form, 
  Radio, 
  Checkbox
} from 'semantic-ui-react';
import { 
  states as usStates, 
  otherStates, 
  setAllStates,
  statesSelectOptions }         from './taxElectionForm.const';
import styles                   from './taxElectionFormContainer.local.less';
import TaxElectionFormModal from './taxElectionFormModal';

class TaxElectionFormDetails extends Component {
  state = { 
    isLoading: true,
    openModal: false,
    data: {
      form: {
        comments: '',
        states: setAllStates(false),
        participate: false,
        primary_residency: null,
        secondary_residency: null,
      },
      status: 0,
      sscId: null,
      uuid: null,
    },
    saving: false,
    checkAllStates: false,
  };

  componentDidMount() {
    this.startLoading();
    const {
      props: { 
        history: { location: { search } }, 
        isAdmin
      },
    } = this;
    const uuid = pathOr(
      pathOr(
        null,
        ['computedMatch', 'params', 'uuid'], 
        this.props
      ),
      ['match', 'params', 'uuid'],
      this.props
    )
    let data = {
      uuid: !uuid || uuid === 'new'? null : uuid,
      sscId: new URLSearchParams(search).get("investorId"),
      legalName: new URLSearchParams(search).get("legalName")
    };
    

    getGlobalSettings()
      .then(async settings => {
        data.year = settings.taxFormsYear;
        let _data;
        if (data.uuid) {
          _data = await fetchTaxElectionForm(isAdmin, uuid);
          data.status = _data.status;
        } else {
          _data = await fetchTaxElectionFormBySSCAndYear(isAdmin, data.sscId, data.year - 1);
        }
        const { form = { states: [] } } = _data || { };
        let objectStates = {};
        (form.states || []).forEach(state => objectStates[state] = true);
        data.form = {
          comments: '',
          states: setAllStates(false),
          participate: false,
          primary_residency: null,
          secondary_residency: null,
          ...form
        };
        data.form.states = objectStates;
        this.finishLoading({ _data: data, settings })
      });
  }

  startLoading = () => {
    this.setState({ isLoading: true });
  };

  finishLoading = ({ _data, settings }) => {
    this.setState(({ data }) => ({
      data: {
        ...data,
        ..._data
      },
      settings,
      isLoading: false,
    }));
  };

  handleFormValuesChange = (e, { name, value }) => {
    const { data } = this.state;
    const form = {
      ...data.form,
      [name]: value
    }

    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        form
      }
    }));
  };

  handleFormValuesChange = (e, { name, value }) => {
    const { data } = this.state;
    const form = {
      ...data.form,
      [name]: value
    }

    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        form
      }
    }));
  };

  checkAllStates = (e, { checked }) => {
    const { data } = this.state;

    const form = {
      ...data.form,
      states: setAllStates(checked)
    }

    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        form
      }
    }));
  };

  checkState = (e, { name, checked }) => {
    const { data } = this.state;

    const form = {
      ...data.form,
      states: {
        ...data.form.states,
        [name]: checked
      }
    }
    
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        form
      }
    }));
  };

  goBack = () => {
    const { history, isAdmin } = this.props;
    
    history.push(isAdmin ? `/admin/tax-forms` : `/tax-election-forms`);
  }

  store = status => () => {
    const { state: { data: { uuid, form } }, props: { isAdmin } } = this;
    const data = {
      ...this.state.data,
      status,
      form: {
        ...form,
        states: Object.keys(form.states).filter(state => !!form.states[state])
      }
    }

    this.setState((state) => ({ ...state, saving: true }));

    if (uuid && uuid !== 'new') {
      updateTaxElectionForm(isAdmin, uuid, data)
        .then(this.goBack)
        .catch(() => 
          this.setState((state) => ({ ...state, saving: false })));
    } else  {
      saveTaxElectionForm(isAdmin, data)
        .then(this.goBack)
        .catch(() => 
          this.setState((state) => ({ ...state, saving: false })));
    }
  }

  onOpenModal = () => {
    this.setState(state => ({
      ...state,
      openModal: true
    }))
  }

  onCloseModal = value => {
    if (value) {
      return this.store(1)();
    }

    return this.setState(state => ({
      ...state,
      openModal: false
    }))
  }

  render() {
    
    const { state: { isLoading, data = {}, openModal = false, saving = false, settings = {} } } = this;
    const {
      sscId,
      legalName,
      form = {},
    } = data;
    const {
      taxFormsYear = parseInt(moment().format('YYYY'), 10),
      taxFormsDate = moment().format('YYYY-MM-DD')
    } = settings;
    const {
      participate,
      comments,
      primary_residency,
      secondary_residency
    } = form;
    
    if (isLoading) return <></>;
    
    const allStatesChecked = Object.values(form.states).filter(state => !!state).length === statesSelectOptions.length;

    return <div className="dashboardTopPadding">
      <div className='contentRowSplitTwoThirds'>
        <Card 
          contentStyle='cardLightGrey'
          layoutStyle='cardMain jsWelcomeCard' 
        >
          <CardHeader>
            Investor # { sscId } State Composite Election Form
          </CardHeader>
          <CardBody>
            <Form>
              <div className={`mb-8px ${styles.text}`}>The completed form should be submitted no later than { moment(taxFormsDate).format('MMM Do, YYYY') }. If you have multiple investments with Makena, you only need to complete the election form once for each legal investor. This means, if the same legal investor holds an interest in multiple Makena funds we will apply your response to each fund, unless directed otherwise below:</div>
              <div className="mb-24px">
                <Form.TextArea  onChange={this.handleFormValuesChange} name="comments" value={comments} rows="3"></Form.TextArea>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.title}>
                SECTION I: Investor Information
              </div>
              <div className="m-16px">
                <div className="mb-16px flex">
                  <div className={`mr-4px ${styles.title}`}>
                    Investor name: 
                  </div>
                  <div className={styles.text}> { legalName }</div>
                </div>
                <div className="mb-16px flex">
                  <div className={`mr-4px ${styles.title}`}>
                    Investor #: 
                  </div>
                  <div className={styles.text}> { sscId }</div>
                </div>
                <div className="mb-16px flex">
                  <div className={`mr-4px ${styles.title}`}>
                    Contact name: 
                  </div>
                  <div className={styles.text}>Makena Investor Relations</div>
                </div>
                <div className="mb-16px flex">
                  <div className={`mr-4px ${styles.title}`}>
                    Contact email: 
                  </div>
                  <div className="flex">
                    <a className={styles.link} href="mailto:ClientOperations@makenacap.com">
                      ClientOperations@makenacap.com
                    </a>
                    <div className="mr-4px ml-4px">and</div>
                    <a className={styles.link} href="mailto:Makena.IR@sscinc.com">
                      Makena.IR@sscinc.com
                    </a>
                  </div>
                </div>
                <div className={`mb-16px ${styles.text}`}>
                  Please indicate if you wish to participate in the composite tax return. By checking "No", you will not be included in Makena's composite return and no state residency or election is required below. Should you wish to participate in Makena’s composite return please check "Yes" below and continue with the required fields.
                </div>
                <Form.Field>
                  <Radio checked={!participate} value={false} label="No, I do not wish to participate." name="participate" onChange={this.handleFormValuesChange} />
                </Form.Field>
                <Form.Field>
                  <Radio checked={!!participate} value={true} label="Yes, I do wish to participate." name="participate" onChange={this.handleFormValuesChange} />
                </Form.Field>
              </div>
              <div className={styles.divider}></div>
              { participate && (
                <div className="mt-32px mb-16px">
                  <div className={styles.title}>
                    SECTION II: State(s) of Residency for { taxFormsYear - 1 }
                  </div>
                  <div className="m-16px">
                    <Form.Field>
                      <div className={`${styles.text} mb-4px`}>Your state of residency for { taxFormsYear - 1 }:</div>
                      <div className={styles.fieldContainer}>
                        <Form.Select value={primary_residency} name="primary_residency" onChange={this.handleFormValuesChange} options={statesSelectOptions}></Form.Select>
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <div className={`${styles.text} mb-4px`}>If you were the resident of another state in { taxFormsYear - 1 }, choose that state below.</div>
                      <div className={styles.fieldContainer}>
                        <Form.Select 
                          value={secondary_residency} 
                          name="secondary_residency" 
                          onChange={this.handleFormValuesChange}  
                          options={[{text: 'None', value: null, key: 'None'}, ...statesSelectOptions]}></Form.Select>
                      </div>
                    </Form.Field>
                    <div className={styles.text}>
                      You will be excluded from the composite return for the state(s) of residency you list above. 
                    </div>
                  </div>
                </div>
              ) 
              }
              <div className={styles.divider}></div>

              { participate && (
                <div className="mt-32px mb-16px">
                  <div className={styles.title}>
                    SECTION III: Composite Return Election
                  </div>
                  <div className="m-16px">
                    <div className={styles.text}>
                      Please check the states where you wish to be included in Makena’s composite tax return filings. Please consult with your tax advisor if you have questions regarding your composite return election.
                    </div>
                    <div className="mt-24px mb-24px">
                      <Checkbox 
                        checked={allStatesChecked} 
                        onChange={this.checkAllStates}
                        label="Check all states"
                      />
                    </div>
                    <div className={`mb-8px ${styles.title}`}>
                      States
                    </div>
                    <div className={styles.statesTable}>
                      { usStates.map(state => (
                        <Checkbox 
                          key={`state-${state}`}
                          className={styles.checkbox} 
                          checked={form.states[state]} 
                          name={state} 
                          label={state}
                          onChange={this.checkState}
                        />
                      )) 
                      }
                    </div>
                    <div className={`mt-24px mb-8px ${styles.title}`}>
                      Other
                    </div>
                    <div className={styles.statesTable}>
                      { otherStates.map(state => (
                        <Checkbox 
                          key={`state-${state}`}
                          className={styles.checkbox} 
                          checked={form.states[state]} 
                          name={state} 
                          label={state}
                          onChange={this.checkState}
                        />
                      )) 
                      }
                    </div>
                  </div>
                </div>
              )
              }
              <div className="ui semantic mt-24px mb-24px">
                <Button className="mr-24px" onClick={this.onOpenModal} disabled={saving? true : null}>Submit</Button>
                <Button basic color="blue" onClick={this.store(0)} className="mr-24px" disabled={saving? true : null}>Save</Button>
                <Button basic color="blue" onClick={this.goBack} disabled={saving? true : null}>Cancel</Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      { openModal? <TaxElectionFormModal onClose={this.onCloseModal}></TaxElectionFormModal> : null }
    </div>
  }
}

TaxElectionFormDetails.propTypes = {
  history: PropTypes.any,
  isAdmin: PropTypes.boolean
};

function mapStateToProps(state) {
  return { sideBarOpen: state.menu.open };
}

export default connect(
  mapStateToProps
)(TaxElectionFormDetails);
