import React, { Component }                     from 'react';
import PropTypes                                from 'prop-types';

const formatSettings = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

class MakenaFundHeader extends Component {

  getAum = () => {
    const { fund: { size = 0 } = { }, } = this.props;
    const millions = 1000000;
    if (!size) {
      return {
        unit: '',
        value: new Intl.NumberFormat('en-US', formatSettings).format(0)
      }
    }
    if (size > millions ) {
      return {
        unit: 'Million',
        value: new Intl.NumberFormat('en-US', formatSettings).format(size / millions)
      }
    } 
    return {
      unit: '',
      value: size.toFixed(2)
    }
  }

  render() {
    const {
      fund: { title } = { },
    } = this.props;
    const { unit, value } = this.getAum();
    return (
      <div className='contentRowFullHeader unprintableTitle'>
        <h1 className='title'>{title}</h1>
        <div className="aum">
          <div className="mr-4px">AUM</div>
          <strong>{ `$${value}` }</strong>
          <div className="ml-4px">{unit}*</div>
        </div>
      </div>
    );
  }
}


MakenaFundHeader.propTypes = {
  fund : PropTypes.object,
};

export default MakenaFundHeader;
