/* KEEP IN SYNC WITH client/assets/theme/site/globals/site.variables */

export const DATA_COLORS = {
  0: {
    0: '#104580',
    1: '#56769d',
    2: '#8aaaba',
    3: '#51686f',
    4: '#1e2c2d'
  },
  1: {
    0: '#104580',
    1: '#32578b',
    2: '#4b6b97',
    3: '#617fa3',
    4: '#7695ae',
    5: '#8aaaba',
    6: '#728e9b',
    7: '#5c747e',
    8: '#465b61',
    9: '#314346',
    10: '#1e2c2d'
  },
  2: {
    0: '#104580',
    1: '#2b5388',
    2: '#3d6091',
    3: '#4e6f99',
    4: '#5d7da1',
    5: '#6d8ba9',
    6: '#7b9ab2',
    7: '#8aaaba',
    8: '#7996a4',
    9: '#69848f',
    10: '#597179',
    11: '#495e65',
    12: '#3a4d52',
    13: '#2c3d3f',
    14: '#1e2c2d'
  }
};

export const DATA_NEGATIVE_COLORS = [
  '#e0c7a9',
  '#e3cdb2',
  '#e6d2ba',
  '#e9d8c3',
  '#ecddcb',
  '#f0e3d4',
  '#f3e9dd',
  '#f6eee5',
  '#f9f4ee',
  '#fcf9f6',
];


export const COLORS = {
  candyRed: '#fa0000',
  defaultThemeBlue: '#0e6eb8',
  veryLightPink: '#ececec',
  battleshipGrey: '#737c81',
  marineBlue: '#04396c',
  greyBlue: '#6497b1',
  whiteTwo: '#fafafa',
  melon: '#ff8d5b',
  navy: '#031f4b',
  peacockBlue: '#035b96',
  lightBlueGrey: '#b3cde0',
  ultraLightPink: '#eaeaea',
  blueGrey: '#b6bbbe',
  backgroundColor: '#f7f7f7',
  mainBackground: '#fbfbfb',
  white: '#ffffff',
  midBlue: '#2572ad',
  veryLightGrey: '#e2e2e2',
  darkGreyBlue: '#acafb2',
  duskyBlue: '#426491',
  offWhite: '#f9f8f5',
  offWhiteBeforeOpacity: 'rgb(245, 243, 237)',
  cobalt: '#1a5199',
  seafoamBlue: '#57dfba',
};

export const MAKENA_COLORS = {
  primaryColor: COLORS.cobalt,
  textColor: COLORS.battleshipGrey,
  dataColor: COLORS.lightBlueGrey,
  axisColor: COLORS.ultraLightPink,
  sectionBackground: COLORS.white,
};

export const BENCHMARK_COLORS = {
  0: COLORS.seafoamBlue,
  1: COLORS.seafoamBlue,
};
