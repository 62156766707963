import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import styles                 from './managerDisclaimer.local.less';
import { visitMakenaManager } from '../../services/makenaManagers/makenaManagerService';

class ManagerDisclaimer extends Component {
  
  state = { omit: false };

  OnClick = () => {
    const { onDismiss = () => {} } = this.props;
    this.setState({ omit: true });
    onDismiss();
    visitMakenaManager("AllManagers").catch(() => {});
  }

  render() {   
    if (this.state.omit) return <></>;
    return (
      <div className={`cardMain cardMain--fit mt-16px ${styles.managerDisclaimerContainer}`}>
        <div className={styles.managerDisclaimerContainerTitle}><strong>Manager Disclaimer: </strong></div>
        <div className={styles.managerDisclaimerContainerContent}>Managers presented are for illustrative purposes only and are meant to represent the strategies and objectives of the Makena Funds invested in them. These managers are subject to change and are not indicative of the remainder of the managers in the portfolio.</div>
        <div className={styles.managerDisclaimerContainerDismiss} onClick={this.OnClick}>Dismiss</div>
      </div>
    );
  }
}

ManagerDisclaimer.propTypes = { onDismiss: PropTypes.func }

export default ManagerDisclaimer;
