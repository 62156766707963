import * as R from 'ramda';

import { COLORS, DATA_COLORS, DATA_NEGATIVE_COLORS, BENCHMARK_COLORS } from '../../app/constants';

const between = (x, min, max) => x >= min && x <= max;
// eslint-disable-next-line no-bitwise
export const getRandomColor = () => `#${(Math.random() * (1 << 24) | 0).toString(16)}`;

export const getChartColors = (chart, benchmarks) => {
  const result = {};

  const chartCategories = chart && chart.categories;
  const benchmarkCategories = benchmarks && benchmarks.categories;

  if (chartCategories) {
    chartCategories.forEach((cat, index) => {
      result[cat] = DATA_COLORS[index] || getRandomColor();
    });
  }

  if (benchmarkCategories) {
    benchmarkCategories.forEach((cat, index) => {
      result[cat] = BENCHMARK_COLORS[index] || getRandomColor();
    });
  }

  return result;
};

export const getChartColorForIndex = (index, sectorCount, isNegative) => R.cond([
  [R.always(isNegative), R.always(DATA_NEGATIVE_COLORS[index])],
  [R.partialRight(between, [11, 15]), R.always(DATA_COLORS[2][index])],
  [R.partialRight(between, [5, 10]), R.always(DATA_COLORS[1][index])],
  [R.T, R.always(DATA_COLORS[0][index])]
])(sectorCount);

export const getChartCellColorForIndex = (index, sectorCount, isColorForLegend) => {
  const isNegative = R.lt(sectorCount, index);

  const localIndex = R.cond([
    [R.always(isNegative), R.compose(R.dec, R.subtract(R.__, sectorCount))],
    [R.always(isColorForLegend), R.identity],
    [R.T, R.compose(R.subtract(sectorCount, R.__))],
  ])(index);

  return getChartColorForIndex(localIndex, sectorCount, isNegative);
}

export const CHART_DOTS_STYLE = {
  stroke: COLORS.melon,
  strokeWidth: 2,
  fill: COLORS.white,
  fillOpacity: 1,
};

export const calculateGrowth = data => {
  const dict = R.compose(
    R.map(R.always(1)),
    R.omit(['name']),
    R.head,
  )(data);
  
  return R.clone(data).map((o, i) => {
    const copy = { ...{ name: o.name } };
    for (const k in o) {
      if (k !== 'name' && o.hasOwnProperty(k)) {
        const currentQValue = 1 + o[k];
        const f = i > 0 ? dict[k] * currentQValue : 1;
        dict[k] = f;
        copy[k] = f;
      }
    }
    return copy;
  });
};

export const calculateClientGrowth = data => {
  const dict = R.compose(
    R.map(R.always(1)),
    R.omit(['name']),
    R.head,
  )(data);
  
  return R.clone(data).map((o, i) => {
    const copy = { ...{ name: o.name } };
    for (const k in o) {
      if (k !== 'name' && o.hasOwnProperty(k)) {
        const currentQValue = 1 + o[k];
        const prevQValue = i === 0 ? 1 : 1 + data[i-1][k];
        const f = i > 0 ? dict[k] * (currentQValue / prevQValue) : 1;
        dict[k] = f;
        copy[k] = f;
      }
    }
    return copy;
  });
};
