import React        from 'react';
import { NavLink }  from 'react-router-dom';
import PropTypes    from 'prop-types';
import Currency     from '../currency/Currency';
import styles       from './sidebar.local.less';

const matchDashboardBaseUrl = (match, location) => /^\/dashboard$/.test(location.pathname);

const SideBarTopInfo = ({ reportingQuarterEnd, total }) => (
  <div className={styles.productOverview}>
    <NavLink
      className={styles.title}
      activeClassName={styles.isActive}
      isActive={matchDashboardBaseUrl}
      to='/dashboard'
    >
      HOME
    </NavLink>
    { total && <Currency value={total} cssClass={styles.value}/> }
    { reportingQuarterEnd && <p className={styles.subTitle}>{`100% reported through ${reportingQuarterEnd}`}</p> }
  </div>
);

SideBarTopInfo.propTypes = {
  total: PropTypes.string.isRequired,
  reportingQuarterEnd: PropTypes.string
};

export default SideBarTopInfo;
