import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './datatable.local.less';
import Svg from '../svg/Svg';
import CheckMark from './../../assets/svg/checkmark-blue.svg'

class DatatableFilterOptions extends Component {
  state = { loaded: false };

  componentDidMount() {
    this.setState({loaded: true});
  }

  onFilterOptionClicked(event, index, option){
    event.stopPropagation();
    event.preventDefault();
    const { onFilterOptionClicked = () => {}, options } = this.props;
    if (index <= 0) return onFilterOptionClicked(['All']);
    
    let newOptions = _.clone(this.sortOptions(options));
    newOptions[index - 1].filterSelected = !option.filterSelected;
    const filteredOptions = _.filter(newOptions, ({filterSelected}) => filterSelected);
    return onFilterOptionClicked(_.map(filteredOptions, 'name'));
  }

  getOptions = () => {
    const { options = [] } = this.props;
    const defaultOption = {
      title: 'All',
      name: 'all',
      filterSelected: true
    };
    if (!options.length) return [ defaultOption ];
    if (options.length === 1) {
      options[0] = { ...options[0], ...defaultOption }
      return options;
    }
    const isThereAFilterSelected = _.find(options, 'filterSelected');
    return [ { title: 'All', name: 'all', count: _.sumBy(options, 'count'), filterSelected: !isThereAFilterSelected }, ...this.sortOptions() ];
  }

  sortOptions = () => {
    const { options = [], customSortFilter } = this.props;
    return !customSortFilter? options : customSortFilter(options);
  }

  optionRenderer = (option = { title: '' }, index = 0) => {
    const count = _.get(option, 'count');
    const content = isNaN(count)? option.title : `${option.title} (${count})`;
    return (
      <div
        key={index}
        onClick={(event) => this.onFilterOptionClicked(event, index, option)}
        className={`${styles.filterContainerItem} ${option.filterSelected? styles.selected : ''}`}
      >
        <div className={styles.icon}> { option.filterSelected ? <Svg svg={CheckMark}/> : null } </div>
        <span>{ content }</span>
      </div>
    );
  }

  render() {
    if (!this.state.loaded) return null;
    const newOptions = this.getOptions();
    const { isFilterContainerWider } = this.props;
    return (
      <div className={`${styles.filterContainer} ${isFilterContainerWider? styles.filterContainerWider : ''}`}>
        { newOptions.length <= 2? this.optionRenderer(newOptions[0], 0) : _.map(newOptions, this.optionRenderer) }
      </div>
    )
  }
}

DatatableFilterOptions.propTypes = {
  customSortFilter: PropTypes.func,
  onFilterOptionClicked: PropTypes.func,
  options: PropTypes.array.isRequired,
  isFilterContainerWider: PropTypes.bool
};

export default DatatableFilterOptions;