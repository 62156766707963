import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../components/card/Card';


const ExposuresCard = ({
  children,
  layoutStyle,
  trackEventLabel,
  trackEventCategory,
}) => (
  <Card
    layoutStyle={layoutStyle}
    trackEventLabel={trackEventLabel}
    trackEventCategory={trackEventCategory}
  >
    {children}
  </Card>
);

ExposuresCard.propTypes = {
  children: PropTypes.node,
  layoutStyle: PropTypes.string,
  trackEventLabel: PropTypes.string,
  trackEventCategory: PropTypes.string
};

ExposuresCard.defaultProps = {
  layoutStyle: '',
  trackEventLabel: '',
  trackEventCategory: ''
};


export default ExposuresCard;
