import React, { Component }       from 'react';
import PropTypes                  from 'prop-types';
import { get, pick, tail, take }  from 'lodash';
import moment                     from 'moment';

import SectionLink                from '../sectionLink/SectionLink';
import { rounder }                from '../../helpers/formatters/formatters';
import { getSettings }            from '../../services/auth/authHelper';
import styles                     from './makenaAssetPerformanceTable.local.less';

const parseColumnField = {
  'QTD': 'Adjusted IRR (Current Quarter, USD, Deannualized)',
  '1 Year': '1 Yr. Adjusted IRR (USD)',
  '3 Year Ann.' : '3 Yr. Adjusted IRR (USD)',
  '5 Year Ann.': '5 Yr. Adjusted IRR (USD)',
};

const columns = [
  '',
  'QTD',
  '1 Year',
  '3 Year Ann.',
  '5 Year Ann.'
];

const formatSettings = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
};

const printTotal = (column, total) => {
  return column === 'Allocation'
    ? '100%'
    : total[column];
};

const getValue = (asset, field, isInvalid) => {
  if (isInvalid) return ' - ';
  let value = get(asset, `${parseColumnField[field]}`) * 100;
  if (!value) return ' - ';
  value = rounder(formatSettings, value, 1);
  return parseFloat(value)? `${value}%` : ' - ';
};

class MakenaAssetPerformanceTable extends Component {

  state = {
    isLoading: true,
    lastQuarter: null
  };

  componentDidMount() {
    this.startLoading();
    const lastQuarter = getSettings().reportingQuarterEnd;
    this.finishLoading(lastQuarter);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (lastQuarter) => {
    this.setState({
      lastQuarter: lastQuarter,
      isLoading: false,
    });
  };

  transformValues = item => {
    const { 
      props: { fund: { inceptionDate } },
      state: { lastQuarter } 
    } = this;

    const _inceptionDate = moment.utc(inceptionDate);
    const quarterDate = moment.utc(lastQuarter);
    const diff = quarterDate.diff(_inceptionDate, 'years');
    
    return {
      'Sub-Strategy': item.name,
      'QTD': getValue(item, 'QTD'),
      '1 Year': getValue(item, '1 Year', diff < 1),
      '3 Year Ann.': getValue(item, '3 Year Ann.', diff < 3),
      '5 Year Ann.': getValue(item, '5 Year Ann.', diff < 5)
    }
  };

  getTotal = () => {
    const {
      transformValues,
      props: { overview: { total: { children : { data: { assetClasses: { data } } } } }, assetClass: { title } }
    } = this;
    
    const { values } = data[title];
    
    return transformValues({ ...values, name: `Total` });
  };

  getBenchmarks = (dashQTD) => {
    const {
      props: {
        assetClass: { benchmarks },
        overview: { benchmarks: { data } }
      },
      transformValues
    } = this;

    return Object.values(pick(data, benchmarks))
      .map(({ name, values }) => ({ name, ...values }))
      .map(transformValues)
      .map(benchmark => {
        if (dashQTD) benchmark.QTD = ' - ';
        return benchmark;
      });
  };


  render() {
    const {
      getBenchmarks,
      getTotal,
      props: { assetClass: { displayName } },
      state: { lastQuarter }
    } = this;
    
    if (!lastQuarter) return null;
    
    const dataColumns = take(tail(columns), columns.length - 1);
    
    const total = getTotal();
    
    const benchmarks = getBenchmarks(!total || !total.QTD || total.QTD === ' - ');
    
    return (
      <div className='table'>
        <div className={`tableRow tableRowHeader ${styles.assetRow} ${styles.private}`}>
          {
            columns.map((column, index) => (
              <div
                key={index}
                className='tableRowCell'
              >
                {column}
              </div>
            ))
          }
        </div>
        <SectionLink className={`tableRow total ${styles.assetRow} ${styles.private}`}>
          <div className='tableRowCell bold big first'>
            {`${displayName} ${total['Sub-Strategy']}`}
          </div>
          {
            dataColumns.map((column, index) => (
              <div
                key={index}
                className='tableRowCell bold big'
              >
                { printTotal(column, total) }
              </div>
            ))
          }
        </SectionLink>
        {
          benchmarks.map((row, index) => (
            <SectionLink
              key={index}
              className={`tableRow ${styles.assetRow} ${styles.private}`}
            >
              <div className='tableRowCell italic first'>
                {row['Sub-Strategy']}
              </div>
              {
                dataColumns.map((column, idx) => (
                  <div
                    key={idx}
                    className='tableRowCell italic'
                  >
                    {row[column]}
                  </div>
                ))
              }
            </SectionLink>
          ))
        }
      </div>
    )

  }
}

MakenaAssetPerformanceTable.propTypes = {
  assetClass  : PropTypes.shape({
    title: PropTypes.string,
    displayName: PropTypes.string,
    benchmarks: PropTypes.array
  }),
  fund        : PropTypes.shape({
    inceptionDate: PropTypes.string
  }),
  overview    : PropTypes.shape({
    total: PropTypes.object,
    benchmarks: PropTypes.object
  })
};

export default MakenaAssetPerformanceTable;
