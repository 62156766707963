import { setSettingsDefaultClient } from "./settingsActions";
import { makenaClientByIdFetch } from '../makenaClient/makenaClientService';
import { getDefaultSettings, setDefaultSettings } from "../auth/authHelper";

// eslint-disable-next-line import/prefer-default-export
export const setDefaultClient = selectedClient => async (dispatch) => {
  if (!selectedClient) return;

  await makenaClientByIdFetch(selectedClient)(dispatch);
  const settings = getDefaultSettings() || { };
  setDefaultSettings({ ...settings, selectedClient });
  dispatch(setSettingsDefaultClient(selectedClient));
};

