import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import cx                    from 'classnames';
import moment                from 'moment';
import { pick, tail, take }  from 'lodash';
import { rounder }           from '../../helpers/formatters/formatters';
import { getSettings }       from '../../services/auth/authHelper';
import SectionLink           from '../sectionLink/SectionLink';

import { ROUTES } from '../../app/constants/index';

import styles from './fundPerpetualPerformanceTable.local.less';

const columns = [
  'Asset Class',
  'QTD',
  '1 Year',
  '3 Year Ann.',
  '5 Year Ann.',
  ''
];

const parseColumnField = {
  'QTD': 'Adjusted TWR (Current Quarter, USD)',
  '1 Year': '1 Yr. Adjusted TWR (USD)',
  '3 Year Ann.' : '3 Yr. Annualized Adjusted TWR (USD)',
  '5 Year Ann.': '5 Yr. Annualized Adjusted TWR (USD)',
}

const formatSettings = { 
  minimumFractionDigits: 1, 
  maximumFractionDigits: 1 
};

class FundPerpetualPerformanceTable extends Component {

  state = {
    isLoading: true,
    lastQuarter: null
  };

  componentDidMount() {
    this.startLoading();
    const lastQuarter = getSettings().reportingQuarterEnd;

    this.finishLoading(lastQuarter);
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = (lastQuarter) => {
    this.setState({
      lastQuarter: lastQuarter,
      isLoading: false,
    });
  };

  parseYear = (item, field, years) => {
    const {
      getValue,
      props: { fund: { inceptionDate } },
      state: { lastQuarter }
    } = this;

    const parsedValue     = getValue(item, field),
      _lastQuarter    = moment.utc(lastQuarter, 'YYYY-MM-DD'),
      _inceptionDate  = moment.utc(inceptionDate, 'MM/DD/YYYY');
          
    switch(years) {
      case 1: return _lastQuarter.diff(_inceptionDate, 'days') >= 363 ? parsedValue : ' - ';
      case 3: 
      case 5: return _lastQuarter.diff(_inceptionDate, 'years') >= years ? parsedValue : ' - ';
      default: return ' - ';
    }
  }

  getValue = (item, field) => {
    let value = item[parseColumnField[field]] * 100;
    if (!value) return ' - ';
    value = rounder(formatSettings, value, 1);
    return parseFloat(value)? `${value}%` : ' - ';
  };

  getPath = ({ entityId, name }) => ROUTES.DASHBOARD.children.MAKENA_ASSET.path
    .replace(':productId', entityId)
    .replace(':assetClassName', name);

  transform = ({ name, values }) => {
    const { getValue, parseYear } = this;

    return {
      'Asset Class': name,
      'Allocation': getValue(values, 'Allocation'),
      'QTD': getValue(values, 'QTD'),
      '1 Year': parseYear(values, '1 Year', 1),
      '3 Year Ann.': parseYear(values, '3 Year Ann.', 3),
      '5 Year Ann.': parseYear(values, '5 Year Ann.', 5)
    }
  }

  pickValues = data => {
    const { props: { fund: { entityId } }, transform } = this;
    const rows = data.map(({ name, values }) => ({ name, values, fundId: entityId }));

    return rows.map(transform);
  }
  
  getBenchmarks = (dashQTD) => {
    const { 
      pickValues,
      props: { overview: { benchmarks: { data } }, fund: { benchmarks } }
    } = this;
    
    return pickValues(Object.values(pick(data, benchmarks))).map(benchmark => {
      if (dashQTD) benchmark.QTD = ' - ';
      return benchmark;
    });
  }
  
  getTotal = () => {
    const { 
      pickValues,
      props: { fund: { displayName }, overview: { total: { children: { data } } } }
    } = this;

    const total = {
      ...data,
      name: `${displayName} Total (Net)`,
      values: {
        ...data.values,
        'Adjusted % Of Portfolio (By Grouping, Visible)': 1
      }
    };

    return pickValues(Object.values([ total ]))[0];
  }

  assignPath = item => {
    const { getPath, props: { fund: { entityId } } } = this;

    return {
      ...item,
      path: getPath({ entityId, name: item['Asset Class'] })
    }
  }

  render () {
    const { 
      getBenchmarks,
      getTotal,
      state: {
        isLoading
      }
    } = this;
    const firstColumn = columns[0];
    const dataColumns = take(tail(columns), columns.length - 2);
    const total = getTotal();
    const benchmarks = getBenchmarks(!total || !total.QTD || total.QTD === ' - ');
    
    if (isLoading) return null;

    return (
      <div className='table'>
        <div className={cx('tableRow tableRowHeader', styles.assetRow)}>
          {
            columns.map((column, index) => (
              <div 
                key={index} 
                className='tableRowCell'
              >
                {column}
              </div>
            ))
          }
        </div>
        <SectionLink className={cx('tableRow', styles.assetRow, 'total')}>
          <div className='tableRowCell bold big first'>
            {total[firstColumn]}
          </div>
          {
            dataColumns.map((column, index) => (
              <div 
                key={index}
                className='tableRowCell bold big' 
              >
                {total[column]}
              </div>
            ))
          }
          <div className='tableRowCell last'></div>
        </SectionLink>
        {
          benchmarks.map((row, index) => (
            <SectionLink 
              key={index} 
              className={cx('tableRow', styles.assetRow)}
            >
              <div className='tableRowCell italic first'>
                {row[firstColumn]}
              </div>
              {
                dataColumns.map((column, idx) =>  (
                  <div 
                    key={idx}
                    className='tableRowCell italic' 
                  >
                    {row[column]}
                  </div>
                ))
              }
              <div className='tableRowCell italic last' />
            </SectionLink>
          ))
        }
      </div>
    );
  }
}

FundPerpetualPerformanceTable.propTypes = {
  fund: PropTypes.object,
  overview: PropTypes.object
};

export default FundPerpetualPerformanceTable;