import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import Svg                  from '../svg/Svg';
import styles               from './datatableHeaderColumnCheckbox.local.less';
import checkmark            from '../../assets/svg/checkmark-white.svg';

class DatatableRowColumnCheckbox extends Component {
  onChange = () => {
    const { onChange = () => {}, data } = this.props;
    onChange({ ...data, isChecked: !data.isChecked });
  }

  render() {
    const { props: { data, column: { show = () => true } = { } } } = this;
    const { isChecked = false } = data;
    return show(data) ? (
      <div className={`${styles.datatableHeaderColumnCustom} ${styles.row}`}>
        <div className={`${styles.box} ${isChecked? styles.active : ''}`} onClick={this.onChange}>
          { isChecked? <Svg svg={checkmark}/> : null }
        </div>
      </div>
    ) : <></>
  }
}

DatatableRowColumnCheckbox.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
  column: PropTypes.any
}

export default DatatableRowColumnCheckbox;