import { ACTIONS } from '../../app/constants/index';


export const accountCreateSuccess = account => ({
  type: ACTIONS.ACCOUNT_CREATE_SUCCESS,
  account,
});

export const accountInviteSuccess = accountId => ({
  type: ACTIONS.ACCOUNT_DISPATCH_INVITE_SUCCESS,
  accountId,
});

export const accountDeleteSuccess = accountId => ({
  type: ACTIONS.ACCOUNT_DELETE_SUCCESS,
  accountId,
});

export const accountUpdateSuccess = account => ({
  type: ACTIONS.ACCOUNT_UPDATE_SUCCESS,
  account,
});

export const accountSetupSuccess = accountId => ({
  type: ACTIONS.ACCOUNT_SETUP_SUCCESS,
  accountId,
});

export const accountsFetchSuccess = accountList => ({
  type: ACTIONS.ACCOUNTS_FETCH_SUCCESS,
  accountList,
});
