import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { trackEvent } from '../../helpers/gaHelpers/gaHelpers';
import { validateUserAuth } from '../../services/auth/authService';

import styles from './noMatch.local.less';

class NoMatch extends Component {

  componentDidMount() {
    trackEvent('404', {
      event_category: 'Page errors',
      event_label: this.props.history.location
    });
  }

  openModal = () => this.setState({open: true})

  goBack = () => {
    const { props: { history } } = this;
    history.goBack(2);
  }

  render() {
    const { props: { auth: { level = 'document' } = {  } } } = this;
    const haveFullAccess = level === 'full';
    const defaultRoute = haveFullAccess ? '/dashboard' : '/investor-documents';
    return (
      <div className={`${styles.noMatchContainer} ${styles.noMatchContainerLoggedIn}`}>
        <div className={styles.noMatchContent}>
          <div className={styles.noMatchTitle}>
            We’re sorry, the page you requested either doesn’t exist or isn’t available now.
          </div>
          <div>
            <button type="button" className={styles.noMatchButton} onClick={this.goBack}>
              Return to Previous Page
            </button>
          </div>
          { haveFullAccess? (
            <div className={styles.noMatchText}>
              <a className={styles.noMatchLink} href={defaultRoute}>Go to home page</a>
            </div>
          ) : null
          }
          <div className={styles.noMatchText}>
            <div>
              We’ve noted this problem and we’re looking into it. If you need  further assistance, please contact us at
            </div>
            <div>
              <a className={styles.noMatchLink} href="mailto:websupport@makenacap.com">websupport@makenacap.com</a>.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NoMatch.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    goBack: PropTypes.func
  }),
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default compose(
  withRouter,
  connect(mapStateToProps, { validateUserAuthAction: validateUserAuth }),
)(NoMatch);
