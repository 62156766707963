import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError} from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Card from '../../../components/card/Card';
import CardPanel from '../../../components/cardPanel/CardPanel';
import CardHeader from '../../../components/card/CardHeader';
import { trackEvent } from '../../../helpers/gaHelpers/gaHelpers';
import { validateUserByEmail } from '../../../services/auth/authService';

import styles from '../loginForm/loginForm.local.less';

const requiredField = value => value.trim() !== '';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailValidator = value => emailRegex.test(String(value).toLowerCase());

const CODE_INVALID = `Please enter a valid email address.`;

const CODE_7000 = `Please enter your email address. If you're having trouble, please contact your Makena account manager.`;

class CreateSessionForm extends Component {
  state = {
    formControls: {
      email: {
        value: '',
        valid: true,
        validationRules: {
          isRequired: true,
          isEmail: true
        }
      }
    },
    isValidForm: true,
    isActiveUser: false
  }

  getChildContext() {
    const { history } = this.props;

    return {
      router: {
        history
      }
    }
  }

  componentDidMount() {
    trackEvent('login', {
      event_category: 'Account',
      event_label: 'login'
    });

    const emailField = this.emailFieldRef.current.getRenderedComponent();
    emailField.focus();
  }

  emailFieldRef = createRef();

  validate = (itemDescriptor) => {
    let { isValid = true, value } = itemDescriptor;
    for (let rule in itemDescriptor.validationRules) {
      switch (rule) {
        case 'isRequired': isValid = isValid && requiredField(value); break;
        case 'isEmail': isValid = isValid && emailValidator(value); break;
        default: isValid = true;
      }
    }
    return isValid;
  }

  handleInputBlur = async event => {
    const { formControls } = this.state;
    const target = event.target;
    const name = target.name === 'username'? 'email' : target.name;
    const updatedControls = { ...formControls };

    updatedControls[name].valid = updatedControls[name].value == '' ? true : this.validate(updatedControls[name]);
    const validForm = Object.keys(updatedControls).every(item => updatedControls[item].valid)
    await this.setState({
      formControls: updatedControls,
      isValidForm: validForm
    });
  }

  handleOnKeyPress = async event => {
    if (event.key !== 'Enter') return;

    const { formControls } = this.state;
    const target = event.target;
    const name = target.name;
    const updatedControls = { ...formControls };

    updatedControls[name].valid = updatedControls[name].value == '' ? true : this.validate(updatedControls[name]);
    const validForm = Object.keys(updatedControls).every(item => updatedControls[item].valid)
    await this.setState({
      formControls: updatedControls,
      isValidForm: validForm
    });
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name === 'username'? 'email' : target.name;
    this.setState(prevState => {
      const { formControls } = prevState;
      const updatedControls = { ...formControls };
      const updateFormElements = { ...updatedControls[name] };

      updateFormElements.value = value;
      updatedControls[name] = updateFormElements;
      return { formControls: updatedControls };
    });
  }

  handleOnFocus = () => {
    const { formControls } = this.state;
    const { submitting } = this.props;
    const updatedControls = { ...formControls };

    updatedControls['email'].valid = updatedControls['email'].value == '' ? true : this.validate(updatedControls['email']);
    if(!updatedControls['email'].valid || submitting) return;
    this.props.handleSubmit(this.handleStartSession)();
  }

  handleStartSession = async ({username}) => {
    const { handleValidationSuccess } = this.props;
    const email = username;
    try {
      if (!emailValidator(email)) return this.setState({ isValidForm: false });
      const account = await validateUserByEmail({ email });
      const { err: { code } = { } } = account || {};
      if (code === 7000) throw { message: CODE_7000 };
      return handleValidationSuccess(email, account? { ...account, email } : { err: 'FAKE' });
    } catch (error) {
      throw new SubmissionError({ _error: error.message });
    }
  };

  errorHandler = (isValidForm, error) => {
    if (error) return (
      <div className={styles.bigErrorMessage}>
        <div className={styles.errorIcon}><span>!</span></div>
        <p>{error}</p>
      </div>
    )

    if (!isValidForm) return (
      <div className={styles.errorMessage}>
        <div className={styles.errorIcon}><span>!</span></div>
        <p className={styles.emailErrorMessage}>{ CODE_INVALID }</p>
      </div>
    )
  }

  getSessionMessage = () => {
    const { history } = this.props;
    const { location : { state: locationState = {}} } = history;
    const { flashMessage = null } = locationState;

    const signInMessage = "Please sign in to access your account.";

    if (flashMessage) {
      return flashMessage;
    }

    return signInMessage;   
  }

  render() {
    const { handleSubmit, error = null } = this.props;
    const { formControls, isActiveUser, isValidForm } = this.state;
    const { email : { value } } = formControls;

    return (
      <Fragment>
        <Card
          layoutStyle="cardMain"
          trackEventLabel='Makena Investor Site'
          trackEventCategory='Authentication'
        >
          <CardHeader>Makena Investor Site</CardHeader>
          <CardPanel panelStyle="cardPanel noMargin">
            <p className={styles.signInText}>{ this.getSessionMessage() }</p>
            { this.errorHandler(isValidForm, error) }
            <form
              autoComplete="off"
              onSubmit={handleSubmit(this.handleStartSession)}
              className={`attached fluid segment ${styles.loginFormWrap}`}
            >
              <div className={styles.formField}>
                <label className={styles.formLabels}>Email</label>
                <Field
                  component="input"
                  type="text"
                  name="username"
                  autoComplete="email"
                  className={styles.formInput}
                  onBlur={this.handleInputBlur}
                  onKeyPress={this.handleOnKeyPress}
                  onChange={this.handleInputChange}
                  tabIndex="1"
                  forwardRef={true}
                  ref={this.emailFieldRef}               
                  value={value}
                />
              </div>
              <div className={styles.formField}>
                <button type="submit" onFocus={this.handleOnFocus} className={styles.submitBtn}>{isActiveUser ? 'Sign In' : 'Continue'}</button>
              </div>
            </form>
          </CardPanel>
        </Card>
      </Fragment>
    );
  }
}

CreateSessionForm.propTypes = {
  error: PropTypes.string,
  handleValidationSuccess: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  history: PropTypes.object
};

CreateSessionForm.childContextTypes = {
  router: PropTypes.object
}


export default compose(
  connect(
    null,
    null
  ),
  reduxForm({ form: 'sessionForm' }),
)(CreateSessionForm);
