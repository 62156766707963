export const MergeAll = arrayData => {
  let object = {};
  arrayData.forEach(data => object = { ...object, ...data });
  return object;
};

export const IsMobile = () => {
  if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {
    return true;
  }
  if (navigator.userAgent.match(/Android/i)) {
    return true;
  }
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) { // iPad OS 13
    return true;
  }
  return false;
}

export const isIOS = () => {
  return navigator.userAgent.match(/(iPhone)/) ||
          navigator.userAgent.match(/(iPod)/) ||
          navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // iPad OS 13

};

export const isSafari = () => {
  return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
}

