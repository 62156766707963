import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './survey.local.less';
import Close from '../../assets/svg/close-white.svg';
import Survey from '../../assets/svg/survey.svg';
import Svg from '../svg/Svg';

class SurveyTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
  }

  onClick = () => {
    const { onTabClicked = () => {} } = this.props;

    onTabClicked();
  }

  onClose = () => {
    this.setState(() => ({ open: false }));
  }

  render() {

    const { open } = this.state || { };

    if (!open) {
      return null;
    }

    return <div className={styles.surveyTab}>
      <div className={styles.surveyTabTitle} onClick={this.onClick}>
        <Svg svg={Survey}/> {this.props.tab}
      </div>
      <div className={styles.surveyTabIcon} onClick={this.onClose}>
        <Svg svg={Close}/>
      </div>
    </div>;
  }
}

SurveyTab.propTypes = {
  tab: PropTypes.string,
  onTabClicked : PropTypes.func
};

export default SurveyTab;