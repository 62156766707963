import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import cx                   from 'classnames';
import { Message }          from 'semantic-ui-react';

import AllManagersCard      from '../allManagersCard/AllManagersCard';
import ExploreManagerSlider from '../exploreManagerSlider/ExploreManagerSlider'; 
import Footer               from '../../components/footer/Footer';

import styles from './explore.local.less';
import ManagerDisclaimer from '../managerDisclaimer/ManagerDisclaimer';


class Explore extends Component {
  static propTypes = { hasBeenVisited: PropTypes.bool };

  state = { showDisclaimer: !this.props.hasBeenVisited }

  dismissDisclaimerCard = () => { this.setState({ showDisclaimer: false }) };

  render() {
    const {
      props: {
        recentInvestments,
        error, 
        managersWithFunds,
        history
      },
      state: { showDisclaimer },
      dismissDisclaimerCard
    } = this;
    return (
      <div className={cx('fullBleed--top', styles.exploreWrapper)}>
        <Message
          negative
          hidden={!error}
          content={error}
        />
        <div className={cx('contentRowFullWidth--masthead-fixed', styles.carouselPlaceholder)}>
          <ExploreManagerSlider 
            recentInvestments={recentInvestments}
            managersWithFunds={managersWithFunds}
          />
        </div>
        { showDisclaimer? <ManagerDisclaimer onDismiss={dismissDisclaimerCard} /> : null }
        <div className={cx('contentRowFull', styles.tablePlaceholder)}>        
          <AllManagersCard history={history} showDisclaimer={showDisclaimer}/>
        </div>
        <Footer/>
      </div>
    );
  }
}

Explore.propTypes = {
  recentInvestments: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  managersWithFunds: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.any
};

export default Explore;
