import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import HeaderNavIcon from '../headerNavIcon/HeaderNavIcon';

class HeaderNav extends Component {
  constructor(props){
    super(props);
  }
  
  render() {
    const { isLoggedIn, className } = this.props;
    if (!isLoggedIn) return null;

    return (
      <nav className={className}>
        <HeaderNavIcon {...this.props} />
      </nav>
    );
  }
}

HeaderNav.propTypes = {
  isLoggedIn: PropTypes.bool,
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
}

export default compose(
  connect(mapStateToProps, { })
)(HeaderNav);