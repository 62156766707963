import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';

import Explore                      from '../../components/explore/Explore';
import { clientPortalManagerFetch } from '../../services/reporting/reportingService';
import { isMakenaManagerVisited }   from '../../services/makenaManagers/makenaManagerService';
import { MergeAll }                 from '../../helpers/globalHelpers';

const ParseManagers = hierarchy => data => {
  const {
    products = []
  } = hierarchy;
  const dataManagers = data.managers? data.managers : {};
  const managersWithFunds = Object.values(Object.keys(dataManagers).map(managerName => {
    const manager = dataManagers[managerName];
    manager.funds = products.filter(({ assetClasses = {} }) => {
      return Object.keys(assetClasses).find(assetClassName => {
        const { managers = [] } = assetClasses[assetClassName];
        return managers.find(assetClassManagerName => assetClassManagerName === managerName);
      })
    }).map(fund => fund.displayName);
    return manager;
  }));
  return {
    hierarchy, ...data, managersWithFunds
  }
}

class ExploreContainer extends Component {
  static propTypes = {
    entityId: PropTypes.number.isRequired,
    recentInvestments: PropTypes.array,
    hierarchy: PropTypes.object,
    history: PropTypes.object
  };
  state = {
    isLoading: true,
    data: {}
  }
  async componentDidMount() {
    const { finishLoading, refresh } = this;
    await refresh().then(finishLoading);
  }

  refresh = () => {    
    const { props: { entityId, hierarchy } } = this;
    const promises = [ 
      clientPortalManagerFetch(entityId).then(managers => ({ managers })).catch(() => ({ managers: {} })),
      isMakenaManagerVisited('AllManagers').then(({ isVisited }) => ({ hasBeenVisited: isVisited })).catch(() => ({ hasBeenVisited: false }))
    ];
    return Promise.all(promises)
      .then(MergeAll)
      .then(ParseManagers(hierarchy));
  }

  startLoading = () => {
    this.setState({
      isLoading: true,
    });
  };

  finishLoading = data => {
    this.setState({
      data,
      isLoading: false,
    });
  };
  render() {
    const {
      props: { recentInvestments, history },
      error,
      filters,
      state: { isLoading, data: { managersWithFunds, hasBeenVisited } }
    } = this;
    return !isLoading
      && (recentInvestments.length && managersWithFunds.length)
      ? (
        <div className="dashboardFullBleed"><Explore
          error={error}
          filters={filters}
          history={history}
          hasBeenVisited={hasBeenVisited}
          recentInvestments={recentInvestments}
          managersWithFunds={managersWithFunds}
        /></div>
      )
      : null;
  }
}

export default ExploreContainer;
