import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import AnnotatedPasswordField from '../../components/annotatedPasswordField/AnnotatedPasswordField';
import { Button, Form, Message} from 'semantic-ui-react';
import IconCircleWarning from '../../assets/svg/IconCircleWarning';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from './changePasswordForm.local.less';
import {requiredField, minChars, hasOneUpperCase, numberOrSpecialChar, exactMatch} from '../../utils/validators';


class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidForm: false,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      validationState: this.validateFields({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      })
    };
  }

  handleFormChange = (e) => {
    let changedField = e.target.name;

    const changedState = Object.assign({}, this.state, {
      [changedField]: e.target.value 
    });

    const formFields = {
      currentPassword : changedState.currentPassword,
      newPassword: changedState.newPassword,
      newPasswordConfirm: changedState.newPasswordConfirm
    }

    const validationState = this.validateFields(formFields);
    const validForm = this.validateForm(validationState);
    const newState = Object.assign({}, changedState, { 
      validationState: validationState, 
      isValidForm: validForm
    });
    this.setState(() => newState);
  }

  validateFields = (fields) => {
    const validationState = {
      currentPassword: { validators: [], isValidField: false},
      newPassword: {
        validators: [
          {validationFunction: minChars, error:"8 Characters", props: {length: 8},  isValid: false},
          {validationFunction: hasOneUpperCase, error:"1 uppercase character", props: {}, isValid: false},
          {validationFunction: numberOrSpecialChar, error:"1 number or special character", props: {}, isValid: false},
        ],
        isValidField: false
      },
      newPasswordConfirm: {
        validators: [
          {validationFunction: exactMatch, error:"Both passwords must match", props:{compare:'password'}, isValid: false }
        ], 
        isValidField: false
      }
    };
    
    let formFields = Object.keys(validationState);
    
    formFields.forEach(field => {
      const validators = validationState[field].validators;
      validationState[field].isValidField = true; 

      validators.forEach(validator => {
        validator.isValid = validator.validationFunction(fields[field], fields);
        if (!validator.isValid && validationState[field].isValidField) {
          validationState[field].isValidField = false;
        }
      })
    })

    return validationState;
  }

  validateForm = (validationState) => {
    let formFields = Object.keys(validationState);
    return formFields.every(field => validationState[field].isValidField);
  }

  render() {
    const { handleSubmit, handleFormSubmit, handleCancelClick, error } = this.props;
    const actionButtonClass = () => this.state.isValidForm ? `${styles.actionButton}` : `${styles.actionButton} ${styles.disabled}`;
    
    const { currentPassword, newPassword, newPasswordConfirm } = this.state.validationState;
    
    return (
      <div className={styles.passwordPanel}>
        <Message icon className={styles.modalError} hidden={!error}>
          <IconCircleWarning className={styles.warnIcon} />
          <Message.Content>
            {error}
          </Message.Content>
        </Message>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Form.Field className={styles.formRow}>
            <Field
              name="currentPassword"
              type="password"
              component={AnnotatedPasswordField}
              validate={[requiredField]}
              label="Current Password"
              onChange={this.handleFormChange}
              value={this.state.currentPassword}
              validationState={currentPassword}
            />
          </Form.Field>
          <Form.Field className={styles.formRow}>
            <Field
              name="newPassword"
              type="password"
              component={AnnotatedPasswordField}
              validate={[requiredField]}
              label="New Password"
              toolTip={true}
              onChange={this.handleFormChange}
              value={this.state.newPassword}
              validationState={newPassword}
            />
          </Form.Field>
          <Form.Field className={styles.formRow}> 
            <Field
              name="newPasswordConfirm"
              type="password"
              component={AnnotatedPasswordField}
              validate={[requiredField]}
              label="Type your new password again"
              toolTip={true}
              onChange={this.handleFormChange}
              value={this.state.newPasswordConfirm}
              validationState={newPasswordConfirm}
            />
          </Form.Field>
          <Form.Field className={styles.formRow}>
            <Button disabled={!this.state.isValidForm} className={actionButtonClass()}>
              Change Password
            </Button>
            <span className={styles.actionLink} onClick={handleCancelClick}>
              Cancel
            </span>
          </Form.Field>
        </Form>
      </div>
    )
  }
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  handleCancelClick: PropTypes.func,
  error: PropTypes.string
}

export default compose(
  connect(null, null),
  reduxForm({ form: 'changePassword' }),
)(ChangePasswordForm);
