import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { NavLink }          from 'react-router-dom';
import cx                   from 'classnames';
import styles               from './navItem.local.less';

/**
 * NavItem
 * @param arrowOnClick
 * @param isActive
 * @param linkName
 * @param linkTo
 * @param noArrow   Completely omit the nested items arrow
 * @param hideArrow Visually hide the arrow when the title should still remain indented. Nice
 *                  for when this is in a list, and some have nested items and some don't.
 * @returns {*}
 * @constructor
 */

const toggleParser = {
  right : 'down',
  down  : 'right'
}

const openParser = {
  right: false,
  down: true
}

class NavItem extends Component { 
  
  state = { arrowDirection: this.props.isActive? 'down' : 'right' };

  componentDidUpdate(prevProps) {
    const { isActive, reset } = prevProps;
    if (this.props.isActive !== isActive) return this.setState({ arrowDirection: this.props.isActive? 'down' : 'right' });
    if (!!this.props.reset && this.props.reset !== reset) return this.setState({ arrowDirection: 'right' });
    return;
  }

  toggle = () => {
    const { onToggle = () => {} } = this.props;
    const arrowDirection = toggleParser[this.state.arrowDirection];
    this.setState({ arrowDirection });
    onToggle(openParser[arrowDirection]);
  }
  
  render() {
    const {
      toggle,
      state: { arrowDirection = 'right' },
      props: {
        showArrow = false,
        keepPadding = false,
        isActive = false,
        linkName = '',
        linkTo = null
      }
    } = this;
    
    return (
      <div
        className={cx(
          styles.navItem,
          { [styles.isActive]: isActive }
        )}
        onClick={showArrow? toggle : () => {}}
      >
        {
          showArrow? (
            <button className={styles.arrow}>
              <i
                className={cx({
                  [styles.arrowDown]: arrowDirection === 'down',
                  [styles.arrowRight]: arrowDirection === 'right'
                })
                }
              />
            </button>
          ) : keepPadding? <div className={styles.mimicArrow}></div> : null
          
        }

        <NavLink activeClassName={styles.isActive} to={linkTo}>
          {linkName}
        </NavLink>
      </div>
    )
  }
}

NavItem.propTypes = {
  showArrow   : PropTypes.bool,
  keepPadding : PropTypes.bool,
  isActive    : PropTypes.bool,
  reset       : PropTypes.bool,
  linkName    : PropTypes.string,
  linkTo      : PropTypes.string,
  onToggle    : PropTypes.func
};

export default NavItem;
