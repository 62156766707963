import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';
import Svg                  from '../svg/Svg';
import styles               from './datatable.local.less';
import LeftChevronBlue      from '../../assets/svg/icon-arrow-left-blue.svg';
import LeftChevronWhite     from '../../assets/svg/icon-arrow-left-white.svg';
import RightChevronBlue     from '../../assets/svg/icon-arrow-right-blue.svg';
import RightChevronWhite    from '../../assets/svg/icon-arrow-right-white.svg';

class DatatablePaginator extends Component {

  onPageChange = number => {
    const { pageSize, onPageChange = () => {}} = this.props;
    onPageChange({ page: number, offset: (number - 1) * pageSize});
  }

  renderNumber = (number, index) => {
    const pageClass = this.props.currentPage === number? styles.selected : '';
    return (<div key={index} className={`${styles.page} ${pageClass}`} onClick={() => this.onPageChange(number)}>{ number }</div>)
  }

  renderMore = index => (<div key={index} className={`${styles.page} ${styles.nonPage}`}>...</div>);

  render(){ 
    const { currentPage, totalPages } = this.props;
    let pages = _.range(1, totalPages + 1);
    
    if (totalPages >= 6) { 
      if (currentPage <= 3) pages = [..._.take(pages, 4), '...', totalPages];
      else if(currentPage >= totalPages - 4) pages = [1, '...', ..._.takeRight(pages, 4)];
      else pages = ['...', ..._.slice(pages, currentPage - 2, currentPage + 3), '...'];
    }
    return (
      <div className={styles.paginator}>
        <div className={`${styles.control} ${currentPage !== 1? styles.enabled : ''}`}
          onClick={currentPage <=1? () => {} : () => this.onPageChange(currentPage - 1)}>
          <Svg svg={currentPage === 1? LeftChevronWhite : LeftChevronBlue}></Svg>
        </div>
        {
          _.map(pages, (page, index) => page === '...'? this.renderMore(index) : this.renderNumber(page, index))
        }
        <div className={`${styles.control} ${currentPage !== totalPages? styles.enabled : ''}`}
          onClick={currentPage >= totalPages? () => {} : () => this.onPageChange(currentPage + 1)}>
          <Svg svg={currentPage === totalPages? RightChevronWhite : RightChevronBlue}></Svg>
        </div>
      </div>
    );
  
  }
}

DatatablePaginator.propTypes = {
  onPageChange: PropTypes.func,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number
}

export default DatatablePaginator;