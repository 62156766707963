import React, { Component }           from 'react';
import PropTypes                      from 'prop-types';
import _                              from 'lodash';
import DatatableHeaderColumnCheckbox  from './DatatableHeaderColumnCheckbox';
import DatatableHeaderColumnString    from './DatatableHeaderColumnString';
import DatatableHeaderColumnHTML      from './DatatableHeaderColumnHTML';

const getRandom = () => Math.floor(Math.random() * 1000000) + 1;

class DatatableHeaders extends Component {

  onColumnClick = column => value => {
    const { onColumnClick = () => {} } = this.props;
    onColumnClick({ ...column, value });
  }

  onSortChange = value => {
    const { onSortChange = () => {} } = this.props;
    onSortChange(value);
  }

  onFilterOptionClicked = value => {
    const { onFilterOptionClicked = () => {} } = this.props;
    onFilterOptionClicked(value);
  }

  columnRenderer = (column, rowContainerClasses, key) => {
    switch(column.type) {
      case 'checkbox': return <DatatableHeaderColumnCheckbox column={column} onChange={this.onColumnClick(column)}/>
      case 'render': return <DatatableHeaderColumnHTML column={column}/>
      default: return <DatatableHeaderColumnString id={key} rowContainerClasses={rowContainerClasses} column={column} onSortChange={this.onSortChange} onFilterOptionClicked={this.onFilterOptionClicked}/>
    }
  }

  render(){ 
    const { data: { columns, headersClasses, rowContainerClasses } } = this.props;
    return (
      <div className={headersClasses}>
        { 
          _.map(columns, (column, key) => {
            const DatatableHeaderKey = `${column.title? column.title : getRandom()}${key}`;
            return (
              <div key={DatatableHeaderKey} className={`${column.headerColumnClass} dataTableColumnHeader`}>
                { this.columnRenderer(column, rowContainerClasses, DatatableHeaderKey) } 
              </div>
            );
          }) 
        }
      </div>
    );
  
  }
}

DatatableHeaders.propTypes = {
  onFilterOptionClicked: PropTypes.func,
  onSortChange: PropTypes.func,
  onColumnClick: PropTypes.func,
  data: PropTypes.object
}

export default DatatableHeaders;